import { createSlice } from '@reduxjs/toolkit';
import { selectorHookFactory } from '@utils/selector-hook-factory';

const name = 'auth';

const initialState = {
  tokens: null,
  loading: false,
  error: null,
  userFound: false,
  forgetPhoneModal: false,
  blockModal: false,
  phoneNotificationModal: false,
  emailNotification: false,
  forgetEmail: false,
  confirmPhoneModal: false,
  confirmCodeValues: {
    phoneNumber: null,
    leftTime: null
  },
  confirmEmailModal: false,
  successModal: false,
  successRegister: false,
  initialPhone: null,
  initialEmail: null,
  initialPassword: null,
  isShowCaptchaError: false
};

export const { actions: authActions, reducer: authReducer } = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    logout(state) {
      state.tokens = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUserFound(state, action) {
      state.userFound = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },
    register(state) {
      state.loading = true;
    },
    verifyPhoneNumber(state) {
      state.loading = true;
    },
    login(state) {
      state.loading = true;
    },
    forgetPasswordPhone(state) {
      state.loading = true;
    },
    forgetPasswordCode(state) {
      state.loading = true;
    },
    setForgetPhoneModal(state, action) {
      state.forgetPhoneModal = action.payload;
    },
    setBlockModal(state, action) {
      state.blockModal = action.payload;
    },
    setPhoneNotificationModal(state, action) {
      state.phoneNotificationModal = action.payload;
    },
    setEmailNotificationModal(state, action) {
      state.emailNotification = action.payload;
    },
    setForgetEmailModal(state, action) {
      state.forgetEmail = action.payload;
    },
    signUpWithGoogle(state) {
      state.loading = true;
    },
    setConfirmPhoneModal(state, action) {
      state.confirmPhoneModal = action.payload;
    },
    setConfirmPhone(state, action) {
      state.confirmCodeValues = action.payload;
    },
    setConfirmEmailModal(state, action) {
      state.confirmEmailModal = action.payload;
    },
    setSuccessModal(state, action) {
      state.successModal = action.payload;
    },
    setSuccessRegester(state, action) {
      state.successRegister = action.payload;
    },
    setInitialPhone(state, action) {
      state.initialPhone = action.payload
    },
    setInitialEmail(state, action) {
      state.initialEmail = action.payload
    },
    setInitialPassword(state, action) {
      state.initialPassword = action.payload
    },
    setShowCaptchaError(state, action) {
      state.isShowCaptchaError = action.payload
    }
  }
});

export const useAuthSelector = selectorHookFactory(name);
export const useAuthTokens = () => useAuthSelector('tokens');
export const useAuthUserFound = () => useAuthSelector('userFound');
export const useAuthLoading = () => useAuthSelector('loading');
export const useAuthError = () => useAuthSelector('error');
