import styled from "styled-components";

export const CourseTopicTitle = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 12px;
`

export const CourseTopicText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.5);
`