import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';
import AuthIllustration from '@assets/img/auth-illustration.png';

export const RegisterWrapper = styled(Scrollable)(
  () => `
    display: flex;
    box-sizing: border-box;
  
    .wrapper {
      border: 2px solid #dadada;
      border-radius: 100px;
      overflow: hidden;
  
      &.input-error {
        box-shadow: 0 0 1px 1px #e84949;
      }
  
      input {
        border: none;
        outline: none;
      }
    }
    `
);

export const RegisterLeftSide = styled.div(
  () => `
    width: 50%;
    height: 100vh;
    background-color: #f8f7ef;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 370px 424px contain;
    background-image: url(${AuthIllustration});
    position: sticky;
    left: 0;
    top: 0; 
  `,

  ({ theme: { down } }) => `
    ${down('768px')} {
      display: none;
    }
  `
);

