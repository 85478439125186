import { hoc } from '@utils/hoc';
import { useHomeworkPending } from './homework-pending.prop';
import PendingImage from '@assets/img/pending.png';
import {
  PendingImg,
  PendingTitle,
  PendingWrapper
} from './homework-pending.style';

export const HomeworkPending = hoc(useHomeworkPending, () => (
  <PendingWrapper mt='24px' gap='16px' align='center'>
    <PendingImg src={PendingImage} />
    <PendingTitle>Sizning vazifangiz tekshirilmoqda...</PendingTitle>
  </PendingWrapper>
));
