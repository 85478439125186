import './loader.css';

export const Loader = props => {
  return (
    <div className='loader-wrapper'>
      <div className='loader__logo'>
        <div class='loader'></div>
      </div>
    </div>
  );
};
