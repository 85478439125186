import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { RightSidebar } from '@components/right-sidebar';
import { hoc } from '@utils/hoc';
import { useAllCoursesSingle } from './all-courses-single.prop';
import {
  CourseHeroWrapper,
  CourseInfoDuration,
  CourseInfoIcon,
  CourseInfoIllustrationImage,
  CourseInfoSubtitle,
  CourseInfoTitle,
  CourseTitle,
  LeftSide,
  LeftSideWrapper,
  RightSideWrapper,
  Wrapper,
  WrapperInner
} from './all-courses-single.style';

import ModuleCount from '@assets/img/number-group.png';
import ThemeCount from '@assets/img/count-theme.png';
import LessonCount from '@assets/img/nout-lesson.png';
import Calendar from '@assets/img/calendar-theme.png';
import SingleIllustration from '@assets/img/single-illustration.png';
import { FreeModal } from '@all-courses/components/free-modal';
import { PaymentModal } from '@all-courses/components/payment-modal/payment-modal.component';
import { MyCourseAccordion } from '@all-courses/components/my-course-accordion';
import { MainButton } from '@components/atoms/main-button/main-button.style';
import { config } from '@config/config';
import { secondsToHmsHour, secondsToHmsMinute } from '@utils/date-format-time';
import { PayzeModal } from '@all-courses/components/payze-modal';
import Modal from '@components/pay-modal/pay-modal.component';
import { UpdateInfo } from '@modules/all-courses/components/update-info';
import { ConfirmCode } from '@modules/all-courses/components/confirm-code';
import { AllCoursesSingleSkeleton, SingleCourseAccardionSkeleton, SingleCourseModuleSkeleton } from './all-courses-skeleton';

export const AllCoursesSingle = hoc(
  useAllCoursesSingle,
  ({
    handleGoLanding,
    handleOpenPaymentModal,
    isOpenPaymentModal,
    setIsOpenPaymentModal,
    handleClosePaymentModal,
    isOpenPayzeModal,
    setIsOpenPayzeModal,
    singleCourse,
    mqMaxWidth1110,
    mqMaxWidth1015,
    mqMaxWidth820,
    mqMaxWidth650,
    mqMaxWidth600,
    mqMaxWidth370,
    courseId,
    isPaidInThisCourse,
    isAvailableBuy,
    unMoundPayzeModal,
    isAllPurchasedModalShown,
    isAllModuleBought,
    handleClose,
    isModulePurchased,
    isShowUpdateInfoModal,
    isShowConfirmModal
  }) => {
    return (
      <>
        <FlexWrapper gap={mqMaxWidth1015 && '14px'} align='flex-start'>
          <LeftSide>
            <LeftSideWrapper>
              <Wrapper>
                {
                  singleCourse?.name
                    ? <WrapperInner>
                      <FlexWrapper
                        align='center'
                        justify='space-between'
                        direction={mqMaxWidth600 ? 'column-reverse' : ''}
                      >
                        <CourseHeroWrapper>
                          <CourseTitle>{singleCourse?.name}</CourseTitle>
                          <FlexWrapper gap={mqMaxWidth370 ? '14px' : '18px'}>
                            <MainButton
                              className='course-about__btn'
                              onClick={handleGoLanding}
                            >
                              Kurs haqida batafsil
                            </MainButton>

                            <MainButton
                              className='course-about__btn'
                              onClick={handleOpenPaymentModal}
                              disabled={isAvailableBuy}
                            >
                              Sotib olish
                            </MainButton>
                          </FlexWrapper>
                        </CourseHeroWrapper>
                        <CourseInfoIllustrationImage
                          src={`${config.imgApiUrl}/${singleCourse?.imageIllustration || SingleIllustration
                            }`}
                          width={208}
                          height={208}
                        />
                      </FlexWrapper>
                    </WrapperInner>
                    : <AllCoursesSingleSkeleton />
                }
              </Wrapper>
              <WrapperInner small={true}>
                {
                  singleCourse?.name
                    ? <FlexWrapper
                      className='info-wrapper'
                      gap={mqMaxWidth1110 || mqMaxWidth820 ? '24px' : '64px'}
                      justify={
                        mqMaxWidth1110 || mqMaxWidth820
                          ? 'space-between'
                          : 'flex-start'
                      }
                      wrap={mqMaxWidth650 ? 'wrap' : ''}
                    >
                      <FlexWrapper
                        className={mqMaxWidth650 && 'info-top'}
                        direction='column'
                      >
                        <CourseInfoIcon src={ModuleCount} />
                        <CourseInfoTitle>Modullar soni</CourseInfoTitle>
                        <CourseInfoSubtitle>
                          {singleCourse?.modulesCount || 0} ta
                        </CourseInfoSubtitle>
                      </FlexWrapper>

                      <FlexWrapper
                        className={mqMaxWidth650 && 'info-top'}
                        direction='column'
                      >
                        <CourseInfoIcon src={ThemeCount} />
                        <CourseInfoTitle>Mavzular soni</CourseInfoTitle>
                        <CourseInfoSubtitle>
                          {singleCourse?.chaptersCount || 0} ta
                        </CourseInfoSubtitle>
                      </FlexWrapper>

                      <FlexWrapper
                        className={mqMaxWidth650 && 'info-top'}
                        direction='column'
                      >
                        <CourseInfoIcon src={LessonCount} />
                        <CourseInfoTitle>Darslar soni</CourseInfoTitle>
                        <CourseInfoSubtitle>
                          {singleCourse?.lessonsCount} ta
                        </CourseInfoSubtitle>
                      </FlexWrapper>

                      <FlexWrapper
                        className={mqMaxWidth650 && 'info-top'}
                        direction='column'
                      >
                        <CourseInfoIcon src={Calendar} />
                        <CourseInfoTitle>Kurs davomiyligi</CourseInfoTitle>
                        <CourseInfoSubtitle>
                          <FlexWrapper className='course-about' mr={'8px'}>
                            {secondsToHmsHour(singleCourse?.duration) || 0}
                            <CourseInfoDuration className='course-about'>
                              soat
                            </CourseInfoDuration>
                          </FlexWrapper>
                          <FlexWrapper className='course-about'>
                            {secondsToHmsMinute(singleCourse?.duration) || 0}
                            <CourseInfoDuration className='course-about'>
                              daqiqa
                            </CourseInfoDuration>
                          </FlexWrapper>
                        </CourseInfoSubtitle>
                      </FlexWrapper>
                    </FlexWrapper>
                    : <SingleCourseModuleSkeleton />
                }

                {singleCourse?.modules?.length > 0
                  ? (<MyCourseAccordion
                    courseId={courseId}
                    courseInfo={singleCourse}
                  />)
                  : singleCourse?.modules?.length === 0
                    ? (<span>Ma’lumot mavjud emas</span>)
                    : <SingleCourseAccardionSkeleton />
                }
              </WrapperInner>
            </LeftSideWrapper>
          </LeftSide>

          {!mqMaxWidth1015 ? (
            <RightSideWrapper>
              <RightSidebar />
            </RightSideWrapper>
          ) : null}
          <FreeModal />

          {(isAllPurchasedModalShown || isPaidInThisCourse) && (
            <Modal onClose={handleClose} isAllModuleBought={isAllModuleBought} isModulePaid={isModulePurchased} />
          )}

          {isOpenPaymentModal && !isAllModuleBought && !isPaidInThisCourse && (
            <PaymentModal
              onClose={handleClosePaymentModal}
              setIsOpenPaymentModal={setIsOpenPaymentModal}
            />
          )}

          {isOpenPayzeModal && (
            <PayzeModal
              modal={isOpenPayzeModal}
              setModal={setIsOpenPayzeModal}
              unMountModal={unMoundPayzeModal}
            />
          )}
        </FlexWrapper>
        {isShowUpdateInfoModal && <UpdateInfo />}
        {isShowConfirmModal && <ConfirmCode />}
      </>
    );
  }
);
