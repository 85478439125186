import { api } from '@services/base.service';

class SettingsService {
  constructor() {
    this.api = api;
  }

  changePhoneNumber = data =>
    this.api.put('/student/profile/change-phone-number', data);

  sentOtp = data => this.api.post('/student/sendOtp', data);

  changePassword = data =>
    this.api.put('/student/profile/change-password', data);

  uploadImg = data => this.api.post('/upload', data);

  updateUserInfo = data => this.api.put('/student/profile', data);

  refreshToken = () => this.api.get('/student/refresh-token');

  changeLogin = data => this.api.put('/student/profile/change-login', data);
}

export const settingsService = new SettingsService();
