import styled from 'styled-components';
import Google from '@assets/img/google.png';

export const InfoImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: ${prop => (prop.mr ? '7px' : '0')};
  object-fit: contain;
`;

export const InfoText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  text-align: ${prop => (prop.oferta ? 'center' : 'left')};
  letter-spacing: -0.02em;
  max-width: 406px;
  color: ${prop => (prop.oferta ? '#000' : 'rgba(0, 0, 0, 0.35)')};

  span,
  a {
    display: inline-block;
    margin: 0 2px;
    color: #ba8d5b;
  }
`;

export const GoogleButton = styled.button(
  () => `
    background: #f1f0ee;
    border-radius: 100px;
    padding: 12px 38px 14px 67px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
    width: 50%;
    border: none;
    box-sizing: border-box;
    position: relative;
    height: 45px;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      left: 39px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-repeat: no-repeat;
      background-image: url(${Google});
    }

    &.login {
      width: 100%;
      margin-bottom: 24px;
      
      &::before {
        left: 0 !important;
        right: 0 !important;
        transform: translateX(-56px);
      }
    }
  `,

  ({ theme: { down } }) => `
    ${down('768px')} {
      padding: 8px 8px 11px 30px;
    }

    &::before {
      left: 20px;
    }
  `
);

export const RegisterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-top: 1px solid rgba(218, 218, 218, 0.4);
  padding-top: 18px;
  margin-top: 16px;
`;


export const RegExInfoText = styled.p`
  font-weight: 400;
  margin-top: 1px;
  margin-bottom: -19px;
  margin-left: 15px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
`;
 