import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RouteLinks } from 'src/constants/route';

import {
  AdditionalWrapper,
  Button,
  ImgWrapper,
  ModalTitle,
  StyledBottomNav,
  WrapperSubtitle,
  WrapperTitle,
  WrapperTop
} from './bottom-nav.styles';

import { SidebarNavLink } from '../sidebar/sidebar-nav-link';
import { UnModal } from '@components/modal';
import DefaultAvatar from '@assets/img/default-avatar.png';
import { config } from '@config/config';
import { UserImg } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { ProfileMenuItem } from '../header/nav-user/nav-user.style';
import { storageService } from '@services/storage.service';
import { commonActions } from '@store/common';
import { ReactComponent as IconExit } from '@assets/img/icon-exit.svg';
import { ReactComponent as IconHome } from '@assets/img/icon-home.svg';
import { ReactComponent as IconMyCourses } from '@assets/img/icon-my-courses.svg';
import { ReactComponent as IconAllCourses } from '@assets/img/icon-all-courses.svg';
import { ReactComponent as IconVideos } from '@assets/img/icon-videos-list.svg';
import { ReactComponent as IconSettings } from '@assets/img/settings.svg';
import { ReactComponent as IconTab } from '@assets/img/tab-icon.svg';
import { ReactComponent as IconCertificate } from '@assets/img/certificate.svg';
import { isLink } from '@utils/linkChecker';

export const BottomNav = () => {
  const profile = useSelector(state => state.common.profile);
  const [additionalWrapperState, setAdditionalWrapperState] = useState(false);
  const [modalState, setModalState] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const mobileMenuRef = useRef(null)

  useEffect(() => {
    const handleClose = (e) => {

      if (mobileMenuRef.current && !mobileMenuRef.current.contains(e.target)) {
        setAdditionalWrapperState(false)
      }
    }

    if(additionalWrapperState) {
      window.addEventListener('touchstart', handleClose)
    }

    return () => {
      window.removeEventListener('touchstart', handleClose)
    }
  }, [additionalWrapperState])

  const toggleAdditionalWrapper = () => {
    setAdditionalWrapperState(!additionalWrapperState);
  };

  const handleClickMenuItem = (link, e) => {
    e.preventDefault();

    if (link === '/') {
      setModalState(true);
      setAdditionalWrapperState(false);
      return;
    }

    history.push(link);
    setAdditionalWrapperState(false);
  };

  const handleCloseConfirmModal = () => {
    setModalState(false);
  };

  const handleLogOut = () => {
    storageService.removeRefreshToken();
    storageService.removeAccessToken();
    storageService.removeItem('studentInfo');
    dispatch(commonActions.fetchProfileFulfilled(null));
    history.push(RouteLinks.auth.login);
  };

  const routes = useMemo(() => {
    return [
      {
        link: '/dashboard',
        iconName: <IconHome />
      },
      {
        link: '/my-courses',
        iconName: <IconMyCourses />
      },
      {
        link: '/courses',
        iconName: <IconAllCourses />
      },
    ];
  }, []);

  return (
    <StyledBottomNav>
      <ul>
        {routes?.map((item, index) => (
          <SidebarNavLink key={index} to={item.link} iconName={item.iconName} />
        ))}

        {additionalWrapperState && (
          <AdditionalWrapper ref={mobileMenuRef}>
            <WrapperTop>
              <FlexWrapper direction='column' gap='6px'>
                <WrapperTitle>
                  {profile?.firstName + ' ' + profile?.lastName}
                </WrapperTitle>

                <WrapperSubtitle>O‘quvchi</WrapperSubtitle>
              </FlexWrapper>
            </WrapperTop>

            <FlexWrapper direction='column'>
              <ProfileMenuItem
                onClick={handleClickMenuItem.bind(null, '/videos')}
                className='menu-title'
                to='/videos'
              >
                <IconVideos />
                Videolar
              </ProfileMenuItem>
              <ProfileMenuItem
                onClick={handleClickMenuItem.bind(null, '/certificates')}
                className='menu-title'
                to='/certificates'
              >
                <IconCertificate />
                Sertifikatlarim
              </ProfileMenuItem>
              <ProfileMenuItem
                onClick={handleClickMenuItem.bind(null, '/settings')}
                className='menu-title'
                to='/settings'
              >
                <IconSettings />
                Sozlamalar
              </ProfileMenuItem>

              <ProfileMenuItem
                onClick={handleClickMenuItem.bind(null, '/payments')}
                className='menu-title'
                to='/payments'
              >
                <IconTab />
                To‘lovlar
              </ProfileMenuItem>

              {/* <ProfileMenuItem
                onClick={handleClickMenuItem.bind(null, '/rating')}
                className='menu-title'
                to='/rating'
              >
                <IconResult />
                Reyting
              </ProfileMenuItem>

              <ProfileMenuItem
                onClick={handleClickMenuItem.bind(null, '/my-ratings')}
                className='menu-title'
                to='/my-ratings'
              >
                <IconResult fill='#bfbfbf' />
                Ko‘rsatgichlarim
              </ProfileMenuItem> */}

              <ProfileMenuItem
                onClick={handleClickMenuItem.bind(null, '/')}
                className='menu-title title-exit'
                to='/'
              >
                <IconExit />
                Chiqish
              </ProfileMenuItem>
            </FlexWrapper>
          </AdditionalWrapper>
        )}
        <ImgWrapper onClick={toggleAdditionalWrapper}>
          <UserImg
            size='small'
            src={
              profile?.photo
                ? isLink(profile?.photo)
                  ? profile?.photo
                  : `${config.imgApiUrl}/${profile.photo}`
                : DefaultAvatar
            }
            alt={profile?.firstName + ' ' + profile?.lastName}
          />
        </ImgWrapper>
      </ul>

      {modalState && (
        <UnModal modal={modalState} setModal={setModalState}>
          <ModalTitle>Platformadan chiqishni xohlaysizmi?</ModalTitle>

          <FlexWrapper justify='center'>
            <Button onClick={handleCloseConfirmModal}>Yo'q</Button>

            <Button danger onClick={handleLogOut}>
              Ha
            </Button>
          </FlexWrapper>
        </UnModal>
      )}
    </StyledBottomNav>
  );
};
