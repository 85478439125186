import { MainButton } from '@components/atoms/main-button/main-button.style';
import styled from 'styled-components';

export const CourseTabContainer = styled.div`
  border-radius: 4px;
  background-color: #fff;
  margin-top: 20px;
`;

export const CourseTabContent = styled.div(
  ({ theme: { down }}) => `
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #dadada;
  border-radius: 0px 0px 4px 4px;

  ${down('768px')} {
    padding: 16px;
  }
`);

export const CourseTabs = styled.div(
  () => `
    background-color: #fff;
    padding: 19px 24px 0 0;
    margin-left: 24px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    
    @media only screen and (max-width: 658px) {
      margin-left: 0;
      padding: 19px 16px 0 0;
    }
   `,

  ({ theme: { down } }) => `

   ${down('676px')} {
    display: flex;
    align-items: center;

    .course-tab {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
   }
   `
);

export const CourseTabItemCore = styled.button`
  color: ${props => (props.active ? '#006CD0' : 'rgba(0, 0, 0, 0.5)')};

  &::after {
    border-bottom: ${props => (props.active ? '2px solid #006CD0' : 'none')};
  }

  @media (max-width: 1024px) {
    &::after {
      top: 35px;
    }
  }
`;

export const CourseTabItem = styled(CourseTabItemCore)(
  () => `
    position: relative;
    z-index: 1;
    padding: 0 20px 19px 20px;
    font-weight: normal;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    
    @media only screen and (max-width: 400px) {
      padding: 0 0px 19px 0px;
    }

    &::after {
      position: absolute;
      z-index: 2;
      top: 36px;
      left: 0;
      width: 100%;
      content: '';
    }
   `,

  ({ theme: { down } }) => `
    ${down('450px')} {
        &:not(:last-child) {
            margin-right: 0;
        }
        &:last-child{
          margin-right: 10px;
      }
    }
   `
);

export const Button = styled(MainButton)(
  () => `
    position: relative;
    padding: 8px 40px;
    transform: translateY(-10px);
        
    &:disabled {
        opacity: 0.5;
    }
    `,

  ({ theme: { down } }) => `
      ${down('490px')} {
        padding: 4px 14px;
        font-size: 14px;
        margin-bottom: 0;
        z-index: 5;
      }
      
      ${down('400px')} {
        padding: 3px 6px;
        transform: translateY(-5px);
      }
    `
);

export const BtnWrapper = styled.div`
  display: ${props => (props.none === 'none' ? 'none' : 'block')};
  position: relative;
  cursor: pointer;
  user-select: none;
  background-color: #fff;

  &.available {
    &:hover {
      span {
        visibility: visible;
      }
    }
  }

  span {
    background: #323232;
    border-radius: 8px;
    padding: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0001em;
    color: #ffffff;
    position: absolute;
    right: 0;
    bottom: -50px;
    width: 254px;
    visibility: hidden;
  }
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  position: relative;
  width: 100%;
    
  @media only screen and (min-width: 658px) {
    display: flex;
  }
`
