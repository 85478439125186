import { createSlice } from '@reduxjs/toolkit';
import { selectorHookFactory } from '@utils/selector-hook-factory';

const name = 'common';

const initialState = {
  loading: true,
  recoPostLoad: true,
  myCoursesLoading: true,
  lastVideoLoading: true,
  recommendedCourseLoading: true,
  error: null,
  profile: null,
  ready: false,
  showAlert: false,
  session: false,
  sidebarState: false,
  payzeModal: false,
  subHeaderTitle: '',
  recommendedCourse: [],
  myCourses: [],
  recommendedPost: {},
  lastVideo: {},
  upsertError: false,
  isHasButton: {
    hasButton: false,
    link: '/'
  },
  isHasSubHeader: true,
  supportLink: null,
  notificationCount: 0,
  courseLoading: false,
};

export const { actions: commonActions, reducer: commonReducer } = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },

    setUpsertError(state, action) {
      state.upsertError = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
      state.ready = true;
    },

    setSubheader(state, action) {
      state.isHasSubHeader = action.payload;
    },

    fetchProfile(state) {
      state.loading = true;
    },

    setReady(state, action) {
      state.ready = action.payload;
    },

    fetchProfileFulfilled(state, action) {
      state.profile = action.payload;
    },

    setSession(state, action) {
      state.session = action.payload;
    },

    setPayzeModal(state, action) {
      state.payzeModal = action.payload;
    },

    removeProfile(state) {
      state.profile = null;
      state.ready = true;
    },
    setShowAlert(state, action) {
      state.showAlert = action.payload;
    },
    setSidebar(state, action) {
      state.sidebarState = action.payload;
    },
    setSubHeaderTitle(state, action) {
      state.subHeaderTitle = action.payload;
    },
    fetchRecommendedCourses(state) {
      state.loading = true;
      state.recommendedCourseLoading = true;
    },
    fetchRecommendedCoursesFulfilled(state, action) {
      state.recommendedCourse = action.payload;
      state.recommendedCourseLoading = false;
    },
    fetchMyCourses(state) {
      state.loading = true;
      state.myCoursesLoading = true;
    },
    fetchMyCoursesFulfilled(state, action) {
      state.myCourses = action.payload;
      state.ready = true;
      state.myCoursesLoading = false;
    },
    fetchRecommendedPost(state) {
      state.loading = true;
    },
    fetchRecommendedPostFulfilled(state, action) {
      state.recommendedPost = action.payload;
      state.recoPostLoad = false;
    },
    fetchLastVideo(state) {
      state.loading = true;
    },
    fetchLastVideoFulfilled(state, action) {
      state.lastVideo = action.payload;
      state.lastVideoLoading = false;
    },
    setIsHasButton(state, action) {
      state.isHasButton = action.payload;
    },
    fetchSupportLink(state) {
      state.loading = true;
    },
    fetchSupportLinkFulfilled(state, action) {
      state.supportLink = action.payload;
    },
    fetchNotificationCount(state) {
      state.loading = true;
    },
    fetchNotificationCountFulfilled(state, action) {
      state.notificationCount = action.payload;
    }
  }
});

export const useCommonSelector = selectorHookFactory(name);

export const useProfileSelector = () => useCommonSelector('profile');
export const useReadySelector = () => useCommonSelector('ready');
