import { hoc } from '@utils/hoc';
import { DropDownComponent } from '@components/drop-down';
import { StudentRatingTop } from './rating.style';
import {useRatingTopProps} from "@modules/rating/components/rating-top/rating-top.prop";

export const RatingTop = hoc(
  useRatingTopProps,
  ({courses, handleChange}) => {
    return (
      <StudentRatingTop>
        <DropDownComponent courseAward onChange={handleChange} placeholder="Kursni tanlang" options={courses} />
      </StudentRatingTop>
    );
  }
);
