import { useState } from 'react';

export const useAccountError = ({
  error,
  setError,
  closeModal = () => null
}) => {
  const [callCenter, setCallCenter] = useState(false);

  const handleCLose = () => {
    setError(false);
    setCallCenter(false);
    closeModal();
  };

  const openCallCenterBlock = () => {
    setCallCenter(true);
  };

  return {
    error,
    setError,
    handleCLose,
    openCallCenterBlock,
    callCenter
  };
};
