import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import { useForgetPhoneProp } from './forget-phone.prop';
import { InitialForm } from './InitialForm';
import { RecoveryForm } from './RecoveryForm';

export const ForgetPhone = hoc(
  useForgetPhoneProp,
  ({
    forgetPhone,
    setForgetPhone,
    handleClose,
    initialForm,
    setInitialForm,
    setPhoneNumber,
    phoneNumber,
    setTime,
    setTimeIndex,
    timeIndex,
    time
  }) => {
    return (
      <UnModal
        modal={forgetPhone}
        setModal={setForgetPhone}
        modalTitle='Parolni tiklash'
        unMountModal={handleClose}
      >
        {initialForm ? (
          <InitialForm
            setTime={setTime}
            setTimeIndex={setTimeIndex}
            setPhoneNumber={setPhoneNumber}
            setInitialForm={setInitialForm}
          />
        ) : (
          <RecoveryForm
            phoneNumber={phoneNumber}
            setInitialForm={setInitialForm}
            setTime={setTime}
            setTimeIndex={setTimeIndex}
            time={time}
            timeIndex={timeIndex}
          />
        )}
      </UnModal>
    );
  }
);
