import { useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { AllCoursesActions } from '@all-courses/store'
import { commonActions } from '@store/common'
import { useMediaQuery } from '@utils/useMediaQuery'
import { RouteLinks } from 'src/constants/route'
import { videosActions } from '@modules/videos/store'
import { addNotification } from '@components/notifications'
import { config } from '@config/config'
import { ERROR_TYPES } from '@modules/all-courses/constants'
import { useHideRecaptchaVisibility } from '@utils/useHideRecaptchaVisibility'

export const useModuleSingleProp = () => {
  const videoRef = useRef()

  const dispatch = useDispatch()
  const params = useParams()
  const [visible, setVisible] = useState(false)
  const [isBookmarked, setIsBookmarked] = useState(false)
  const sidebarState = useSelector((state) => state.common.sidebarState)
  const activeChapter = useSelector((state) => state.courses?.singleModule)
  const lastVideo = useSelector((state) => state.courses?.lastVideo)
  const bookmarkLoader = useSelector((state) => state.courses?.bookmarkLoader)
  const [isShowModal, setisShowModal] = useState(false)
  const [rate, setRate] = useState('')
  const [textareaError, setTextareaError] = useState('')
  useHideRecaptchaVisibility(true)

  const lastVideoLoading = useSelector((state) => state.courses?.lastVideoLoading)
  const singleModuleLoading = useSelector((state) => state.courses?.singleModuleLoading)
  const activeLesson = useSelector((state) => state.courses.activeLesson)
  const mqMaxWidth1175px = useMediaQuery('(max-width: 1175px)')
  const mqMaxWidth1015px = useMediaQuery('(max-width: 1015px)')
  const ready = useSelector((state) => state.common.ready)
  const [isDisabled, setIsDisabled] = useState(false)
  const history = useHistory()
  const userInfo = useSelector((state) => state.common.profile)

  const currentLesson = useMemo(() => {
    const chapter = activeChapter?.chapters?.find((item) => item?.id === lastVideo?.chapterId)

    const lesson = chapter?.lessons?.find((item) => item?.id === lastVideo?.lessonId)

    return {
      lesson,
      chapter,
    }
  }, [activeChapter, lastVideo])

  const handleToggleChapters = () => {
    setVisible(!visible)
  }

  const handleStopPropagation = (e) => {
    e.stopPropagation()
  }

  const src = useMemo(() => {
    if (activeLesson?.lesson?.video?.videoId) {
      return `${config.imgApiUrl}/videos/${activeLesson?.lesson?.video?.videoId}/cmaf/h264.mpd`
    }
  }, [activeLesson?.lesson?.video?.videoId])

  useEffect(() => {
    if (currentLesson?.lesson?.id && !activeLesson?.lesson?.id) {
      dispatch(AllCoursesActions.changeActiveCourse(currentLesson))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLesson?.lesson?.id, activeLesson?.lesson?.id])

  useEffect(() => {
    dispatch(AllCoursesActions.setBookmarkLoader(true))
    if (params?.moduleId) {
      dispatch(
        AllCoursesActions.fetchSingleModule({
          id: params?.moduleId,
          callback: (res) => {
            if (res?.error?.errId === ERROR_TYPES.courseModuleNotFound) {
              history.push('/inactive-module')
            } else if (res?.error?.errId === ERROR_TYPES.courseNotFound) {
              history.push('/inactive')
            }

            if (res?.error?.errId === 151) {
              history.push(`${RouteLinks.notPurchased}/${params?.courseId}`)
            }

            if (res?.error?.errId === 126) {
              history.push(`${RouteLinks.myCoursesSingle}/${params?.courseId}`)
              dispatch(AllCoursesActions.setUpdateInfoModal(true))
            }

            const chapter = res?.data?.chapters?.find((item) => item?.id === activeLesson?.chapter?.id)
            const lesson = chapter?.lessons?.find((item) => item?.id === activeLesson?.lesson?.id)
            dispatch(AllCoursesActions.setBookmarkLoader(false))
            dispatch(AllCoursesActions.changeActiveCourse({ chapter, lesson }))
          },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (activeLesson?.lesson?.id && activeLesson?.chapter?.id) {
      setTimeout(() => {
        dispatch(commonActions.setReady(true))
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLesson?.chapter?.id, activeLesson?.lesson?.id])

  useEffect(() => {
    if (!activeLesson?.lesson?.id && activeChapter?.id) {
      dispatch(
        AllCoursesActions.fetchLastVideo({
          data: {
            courseId: params.courseId,
            moduleId: params?.moduleId,
          },
          callback: (res) => {
            if (!res?.data && !res?.error) {
              history.push('/inactive-module')
            }
          },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLesson?.lesson?.id, activeChapter])

  useEffect(() => {
    if (activeLesson?.chapter?.id) {
      if (activeLesson?.chapter?.homeworkType === 1) {
        dispatch(AllCoursesActions.fetchCourseHomework(activeLesson?.chapter?.id))
      } else if (activeLesson?.chapter?.homeworkType === 2) {
        dispatch(AllCoursesActions.fetchCourseByLessonHomework(activeLesson?.lesson?.id))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLesson?.lesson?.id, activeLesson?.chapter?.homeworkType])

  useEffect(() => {
    dispatch(
      commonActions.setIsHasButton({
        hasButton: true,
        link: `/my-course-single/${params.courseId}`,
      }),
    )
    dispatch(commonActions.setSubHeaderTitle('Mavzular'))
    dispatch(commonActions.setSubheader(true))

    return () => {
      dispatch(commonActions.setIsHasButton({ isHasButton: false }))
      dispatch(commonActions.setSubHeaderTitle(''))
      dispatch(AllCoursesActions.setLastVideoLoading(true))
    }
  }, [])

  const handleBookmarkClick = () => {
    setIsDisabled(true)
    const data = {
      info: {
        lessonId: activeLesson.lesson?.id,
      },
      callback: (res) => {
        if (res?.success) {
          setIsBookmarked((value) => !value)
          const isSave = !isBookmarked

          addNotification({
            description: `Video “Saqlangan videolar” ${
              isSave ? 'ro‘yxatiga muvaffaqiyatli qo‘shildi' : 'ro‘yxatidan o‘chirildi'
            }`,
            title: `${isSave ? 'Muvaffaqiyatli qo‘shildi' : 'O‘chirildi'}`,
            options: {
              autoClose: true,
              type: `${isSave ? 'success' : 'error'}`,
            },
          })
          setIsDisabled(false)
        }
      },
    }
    dispatch(videosActions.bookmarkVideo(data))
  }

  useEffect(() => {
    if ((!userInfo?.phoneNumber || !userInfo?.lastName) && activeChapter?.id && lastVideo) {
      history.push(`/my-course-single/${params.courseId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChapter, userInfo])

  useEffect(() => {
    if (activeLesson?.lesson?.id) {
      setIsBookmarked(activeLesson?.lesson?.bookmarkId > 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLesson?.lesson?.id])

  useEffect(() => {
    if (currentLesson?.lesson?.id) {
      setIsBookmarked(currentLesson?.lesson?.bookmarkId > 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLesson?.lesson?.id])

  const handleRateClick = () => {
    setisShowModal(true)
  }

  const handleRateModalClose = () => {
    setisShowModal(false)
  }

  const successSubmit = ({ comment, rate }) => {
    const info = {
      data: {
        lessonId: activeLesson?.lesson?.id,
        rate,
        comment,
      },
      callback: (res) => {
        const newLesson = {
          chapter: activeLesson?.chapter,
          lesson: {
            ...activeLesson?.lesson,
            isRated: true,
          },
        }

        if (res.success) {
          dispatch(AllCoursesActions.changeActiveCourse(newLesson))
          addNotification({
            title: 'Dars muvaffaqiyatli baholandi',
            options: {
              autoClose: 1500,
              type: 'success',
            },
          })
          handleRateModalClose()
          setTextareaError('')
          setRate(0)
        } else {
          addNotification({
            title: res?.error?.errMsg,
            options: {
              autoClose: 1000,
              type: 'error',
            },
          })
        }
      },
    }

    dispatch(AllCoursesActions.rateLesson(info))
  }

  const handleRateFormSubmit = (e) => {
    e.preventDefault()
    const comment = e.target[0]?.value?.trim()

    if (!comment.length && rate <= 3) {
      setTextareaError(`Izoh bo'sh bo'lishi mumkin emas.`)

      return
    }

    if (!rate) {
      setTextareaError(`Darsni baholang!`)

      return
    }

    successSubmit({
      comment,
      rate,
    })
  }

  const handleRateChange = (value) => {
    setRate(value)
  }

  return {
    videoRef,
    sidebarState,
    mqMaxWidth1015px,
    mqMaxWidth1175px,
    src,
    activeLesson,
    visible,
    handleToggleChapters,
    handleStopPropagation,
    ready,
    currentLesson,
    isBookmarked,
    handleBookmarkClick,
    lastVideoLoading,
    singleModuleLoading,
    isDisabled,
    isShowModal,
    setisShowModal,
    handleRateClick,
    handleRateFormSubmit,
    rate,
    setRate,
    handleRateChange,
    textareaError,
    bookmarkLoader,
  }
}
