import React from 'react';
import { hoc } from '@utils/hoc';
import { Player } from '@modules/player';
import { useCourseVideoProps } from './course-video.props';
import { CourseVideoContainer } from './course-video.style';

export const CourseVideo = hoc(
  useCourseVideoProps,
  ({ watermark, next, src }) => (
    <CourseVideoContainer>
      <Player watermark={watermark} src={src} next={next} />
    </CourseVideoContainer>
  )
);
