import { CourseLessons } from '@all-courses/components/course-lessons';
import { CourseTab } from '@all-courses/components/course-tab';
import { hoc } from '@utils/hoc';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { VideoContext } from './module-context';
import { useModuleSingleProp } from './module-single.prop';
import TopArrow from '@assets/img/top-arrow.svg';
import {
  ActiveLessonTitle,
  LeftSide,
  LessonWrapperTitle,
  RightSide,
  RightSideInner,
  TableChaptersTitle,
  TabletChapterImg,
  TabletChapters,
  TabletChaptersBottom,
  TabletChaptersTop,
  LessonNumber,
  LessonTitleWrapper,
  BookmarkButton,
  LessonTitleWrap,
  RateButtonWrap
} from './module-single.style';
import { CourseVideo } from '@all-courses/components/course-video';
import {
  ModuleSingleSkeletonTabs,
  ModuleSingleSkeletonTheme
} from './module-single-skeleton';
import { LessonRateModal } from '@modules/all-courses/components/lesson-rate-modal/lesson-rate-modal.component';
import { Button as RateButton } from '@all-courses/components/course-tab/course-tab.style';
import { Loader } from '@components/Loader';

export const ModuleSingle = hoc(
  useModuleSingleProp,
  ({
    videoRef,
    sidebarState,
    mqMaxWidth1015px,
    mqMaxWidth1175px,
    activeLesson,
    handleToggleChapters,
    visible,
    handleStopPropagation,
    ready,
    isBookmarked,
    handleBookmarkClick,
    isDisabled,
    bookmarkLoader,
    lastVideoLoading,
    singleModuleLoading,
    isShowModal,
    setisShowModal,
    handleRateClick,
    handleRateFormSubmit,
    rate,
    setRate,
    handleRateChange,
    textareaError
  }) => {
    if (!ready) {
      return <Loader />;
    }

    return (
      <VideoContext.Provider value={videoRef}>
        <FlexWrapper height={'fill'} align='flex-start' gap='14px'>
          <LeftSide sidebarState={sidebarState}>
            {!!activeLesson?.lesson?.video?.id && <CourseVideo />}

            {bookmarkLoader || !activeLesson?.lesson?.title ? (
              <ModuleSingleSkeletonTheme />
            ) : (
              <LessonTitleWrap
                hasVideo={activeLesson?.lesson?.video?.id}
                direction='column'
              >
                <LessonTitleWrapper
                  isRated={activeLesson?.lesson?.isRated}
                  justify='space-between'
                >
                  <LessonWrapperTitle>
                    <LessonNumber>
                      {activeLesson?.lesson?.position + '-dars'}
                    </LessonNumber>
                    <ActiveLessonTitle>
                      {activeLesson?.lesson?.title}
                    </ActiveLessonTitle>
                  </LessonWrapperTitle>
                  <BookmarkButton
                    onClick={handleBookmarkClick}
                    isBookmarked={isBookmarked}
                    disabled={isDisabled}
                  />
                </LessonTitleWrapper>
                {!activeLesson?.lesson?.isRated && (
                  <RateButtonWrap>
                    <RateButton onClick={handleRateClick}>
                      Darsni baholang
                    </RateButton>
                  </RateButtonWrap>
                )}
              </LessonTitleWrap>
            )}

            {((sidebarState && mqMaxWidth1175px) ||
              (!sidebarState && mqMaxWidth1015px)) && (
              <TabletChapters>
                <TabletChaptersTop
                  onClick={handleToggleChapters}
                  justify='space-between'
                  align='center'
                >
                  <TableChaptersTitle>Mavzular</TableChaptersTitle>

                  <TabletChapterImg
                    rotate={visible}
                    src={TopArrow}
                    alt='top arrow'
                    width='12'
                    height='14'
                  />
                </TabletChaptersTop>

                <TabletChaptersBottom
                  className={visible ? 'show' : 'hide'}
                  onClick={handleStopPropagation}
                >
                  <RightSide sidebarState={sidebarState}>
                    <RightSideInner>
                      <CourseLessons />
                    </RightSideInner>
                  </RightSide>
                </TabletChaptersBottom>
              </TabletChapters>
            )}
            <CourseTab />
          </LeftSide>

          {((sidebarState && !mqMaxWidth1175px) ||
            (!sidebarState && !mqMaxWidth1015px)) && (
            <RightSide className={sidebarState && 'large'}>
              <RightSideInner>
                {singleModuleLoading ? (
                  <ModuleSingleSkeletonTabs />
                ) : (
                  <CourseLessons />
                )}
              </RightSideInner>
            </RightSide>
          )}
        </FlexWrapper>
        {isShowModal && (
          <LessonRateModal
            isShowModal={isShowModal}
            setisShowModal={setisShowModal}
            onSubmit={handleRateFormSubmit}
            rate={rate}
            setRate={setRate}
            onChange={handleRateChange}
            error={textareaError}
          />
        )}
      </VideoContext.Provider>
    );
  }
);
