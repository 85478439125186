import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { CourseHomeWork } from '../course-homework';
import { useCourseTabProp } from './course-tab.prop';
import {
    BtnWrapper,
    Button,
    CourseTabContainer,
    CourseTabContent,
    CourseTabItem,
    CourseTabs, GridWrapper
} from './course-tab.style';

export const CourseTab = hoc(
  useCourseTabProp,
  ({ handleChangeTab, activeTab, tabs, tabsTitle, activeLesson, mqMaxWidth400 }) => {
    const isAssignmentsButtonDisabled =
      (activeLesson.chapter?.homeworkType === 2 &&
        !activeLesson?.lesson?.hasHomework) ||
      !activeLesson.chapter?.homeworkType;

    const btnWrapperNone =
      (activeLesson.chapter?.homeworkType === 1 &&
        activeLesson.chapter?.lessons[activeLesson.chapter?.lessons.length - 1]
          ?.id === activeLesson.lesson?.id) ||
      (activeLesson.chapter?.homeworkType === 2 &&
        activeLesson.lesson?.hasHomework)
        ? 'block'
        : 'none';

    return (
      <CourseTabContainer>
        <CourseTabs>
          <GridWrapper >
            {tabsTitle.map(item => (
              <CourseTabItem
                key={item.value}
                onClick={handleChangeTab.bind(null, item.value)}
                active={activeTab === item.value}
              >
                {item.name}
              </CourseTabItem>
            ))}
              {mqMaxWidth400 && (
              <BtnWrapper none={btnWrapperNone}>
                  <Button
                      onClick={handleChangeTab.bind(null, null)}
                      disabled={isAssignmentsButtonDisabled}
                  >
                      Vazifalar
                  </Button>
                  <span>Mavzu uchun uyga vazifa berilmagan</span>
              </BtnWrapper>
              )}
          </GridWrapper>
          {!mqMaxWidth400 && (
            <BtnWrapper none={btnWrapperNone}>
              <Button
                onClick={handleChangeTab.bind(null, null)}
                disabled={isAssignmentsButtonDisabled}
              >
                Vazifalar
              </Button>
              <span>Mavzu uchun uyga vazifa berilmagan</span>
            </BtnWrapper>)
          }
        </CourseTabs>
        <CourseTabContent>
          {activeTab ? tabs[activeTab] : <CourseHomeWork />}
        </CourseTabContent>
      </CourseTabContainer>
    );
  }
);
