import { commonActions } from '@store/common';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@utils/useMediaQuery';

export const useRightSidebarProps = ({ hasDashboard }) => {
  const dispatch = useDispatch();
  const blog = useSelector(state => state.common.recommendedPost);
  const myCourses = useSelector(state => state.common.myCourses);
  const sidebarState = useSelector(state => state.common.sidebarState);
  const recoPostLoad = useSelector(state => state.common.recoPostLoad);
  const mq1213MaxWidth = useMediaQuery('(max-width: 1213px)');

  useEffect(() => {
    dispatch(commonActions.fetchRecommendedPost());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasDashboard) {
      dispatch(commonActions.fetchMyCourses());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    blog,
    recoPostLoad,
    myCourses,
    sidebarState,
    mq1213MaxWidth
  };
};
