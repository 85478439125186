import React from 'react';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useAllCoursesProp } from './all-courses.prop';
import {
  AllCoursesWrapper,
  ALlCoursesWrapperTitle,
  CategoryBtn,
  NoCourseImg,
  NoCourseTitle,
  Wrapper,
  WrapperInner
} from './all-courses.style';
import EmptyBox from '@assets/img/empty-box.png';
import { CourseCard } from '@components/course-card';
import { CategoryDropDown } from '@all-courses/components/category-dron-down';
import AllCoursesTabSkeleton, {
  AllCoursesSkeleton
} from './all-courses-skeleton';

export const AllCourses = React.memo(
  hoc(
    useAllCoursesProp,
    ({
      active,
      handleSetActiveBtn,
      categories,
      emptyError,
      allCourses,
      sidebarState,
      allOptions,
      handleToggleDropDown,
      categoriesRef,
      isDropDown,
      mq1562MaxWidth,
      mq1292MaxWidth,
      mq870MaxWidth,
      allCourseLoading
    }) => {
      return (
        <Wrapper>
          <WrapperInner>
            <ALlCoursesWrapperTitle>Barcha kurslar</ALlCoursesWrapperTitle>

            {categories?.length > 0 && (
              <FlexWrapper
                ref={categoriesRef}
                align='center'
                wrap='true'
                mb='30px'
                gap='14px'
              >
                {!isDropDown && (
                  <CategoryBtn
                    className={active === 'all' && 'active'}
                    onClick={handleSetActiveBtn.bind(null, {
                      name: 'all',
                      id: 0
                    })}
                  >
                    Barchasi
                  </CategoryBtn>
                )}

                {!isDropDown &&
                  categories?.map(item => (
                    <CategoryBtn
                      key={item.id}
                      onClick={handleSetActiveBtn.bind(null, item)}
                      className={active === item.name && 'active'}
                    >
                      {item.name}
                    </CategoryBtn>
                  ))}

                {isDropDown && (
                  <CategoryDropDown
                    onChange={handleToggleDropDown}
                    options={allOptions}
                  />
                )}
              </FlexWrapper>
            )}

            {allCourseLoading ? (
              <AllCoursesSkeleton
                threeCardsWithSidebar={sidebarState && mq1562MaxWidth}
                twoCardsWithSidebar={sidebarState && mq1292MaxWidth}
                oneCardWithSidebar={sidebarState && mq870MaxWidth}
                justifyContent={sidebarState && mq1292MaxWidth}
                justifyContentCenter={sidebarState && mq870MaxWidth}
              />
            ) : allCourses?.length > 0 ? (
              <AllCoursesWrapper
                threeCardsWithSidebar={sidebarState && mq1562MaxWidth}
                twoCardsWithSidebar={sidebarState && mq1292MaxWidth}
                oneCardWithSidebar={sidebarState && mq870MaxWidth}
                justifyContent={sidebarState && mq1292MaxWidth}
                justifyContentCenter={sidebarState && mq870MaxWidth}
              >
                {allCourses?.map((item, index) => (
                  <CourseCard
                    className='available all-courses'
                    moduleName={item?.name}
                    category={item?.category?.name}
                    moduleCount={item?.lessonsCount}
                    available={item?.isComingSoon}
                    link={
                      item?.isComingSoon
                        ? undefined
                        : `/course-single/${item.id}`
                    }
                    key={index}
                    img={item?.imageIllustration}
                    imgColor={item?.color}
                    color={item?.category?.color}
                    textColor={item?.category?.textColor}
                    courseType={item.courseType}
                    closeLink={item?.detailUrl}
                  />
                ))}
              </AllCoursesWrapper>
            ) : (
              <>
                <FlexWrapper direction='column' align='center'>
                  <NoCourseTitle>{emptyError}</NoCourseTitle>

                  <NoCourseImg src={EmptyBox} />
                </FlexWrapper>
              </>
            )}
          </WrapperInner>
        </Wrapper>
      );
    }
  )
);
