import { api } from '@services/base.service';

export class CertificateService {
  constructor() {
    this.api = api;
  }

  fetchCertificates = () => this.api.get('/student/certificates');

  fetchCertificate = id => this.api.get(`/student/certificate/${id}`);

  fetchCertificateDetail = id =>
    this.api.get(`/student/certificate/${id}/detail`);

  fetchHomeworks = data =>
    this.api.get(
      `/student/homework/by-chapter/${data?.id}/public?studentId=${data?.studentId}`
    );

  fetchHomeworksByLessonId = data =>
    this.api.get(
      `/student/homework/by-lesson/${data?.id}/public?studentId=${data?.studentId}`
    );
}

export const certificateService = new CertificateService();
