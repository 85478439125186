import { hoc } from '@utils/hoc';
import { useErrorProps } from './error.props';
import { ErrorBtn, ErrorImage, ErrorTitle, ErrorWrapper } from './error.style';
import ErrorImg from '@assets/img/error.svg';

export const ErrorPage = hoc(useErrorProps, ({ handleClick }) => {
  return (
    <ErrorWrapper>
      <ErrorImage src={ErrorImg} />

      <ErrorTitle>Bu sahifa topilmadi! Bosh sahifaga qayting.</ErrorTitle>
      <ErrorBtn onClick={handleClick}>Bosh sahifaga qaytish</ErrorBtn>
    </ErrorWrapper>
  );
});
