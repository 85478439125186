import { settingsService } from "@settings/services/settings.service";
import { tryCatchSagaFactory } from "@utils/try-catch-saga";
import { call, all, takeLatest } from "redux-saga/effects";
import { settingsActions } from "./settings.slice";

export const tryCatchSaga = tryCatchSagaFactory(settingsActions)

export function* requestChangePhoneNumber({payload}) {
    const res = yield call(settingsService.changePhoneNumber, payload.data)
    yield payload.callback(res)
}

export function* changeLogin({payload}) {
    const res = yield call(settingsService.changeLogin, payload.data)
    yield payload.callback(res)
}

export function* requestSentOpt({payload}) {
    const res = yield call(settingsService.sentOtp, payload.data)
    yield payload.callback(res)
}

export function* requestChangePassword({payload}) {
    const res = yield call(settingsService.changePassword, payload.data)
    yield payload.callback(res)
}

export function* requestUploadFile({payload}) {
    const res = yield call(settingsService.uploadImg, payload.data)
    yield payload.callback(res)
}

export function* requestUpdateUserInfo({payload}) {
    const res = yield call(settingsService.updateUserInfo, payload.data)
    yield payload.callback(res)
}

export function* requestRefreshToken({payload}) {
    const res = yield call(settingsService.refreshToken)
    yield payload.callback(res)
}

export function* settingsRootSaga() {
    yield all([
        takeLatest(settingsActions.changePhoneNumber, tryCatchSaga(requestChangePhoneNumber)),
        takeLatest(settingsActions.sentOtp, tryCatchSaga(requestSentOpt)),
        takeLatest(settingsActions.changePassword, tryCatchSaga(requestChangePassword)),
        takeLatest(settingsActions.uploadImg, tryCatchSaga(requestUploadFile)),
        takeLatest(settingsActions.updateUserInfo, tryCatchSaga(requestUpdateUserInfo)),
        takeLatest(settingsActions.refreshToken, tryCatchSaga(requestRefreshToken)),
        takeLatest(settingsActions.changeLogin, tryCatchSaga(changeLogin)),
    ])
}