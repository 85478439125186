import { useMediaQuery } from '@utils/useMediaQuery';
import { useEffect, useRef, useState } from 'react';

const addCss = (element, style) => {
  for (const property in style) element.style[property] = style[property];
};

const styles = {
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '10px',
  color: '#ffffff',
  display: 'block',
  fontSize: '1.5rem',
  left: '5%',
  opacity: 0.2,
  padding: '1px 5px',
  pointerEvents: 'none',
  position: 'absolute',
  textIndent: 'initial',
  top: '5%',
  transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1)',
  visibility: 'visible',
  zIndex: 99
};

const watermarkElementDiv = document.createElement('div');
const watermarkElementSpan = document.createElement('span');
const watermarkElementI = document.createElement('i');
const watermarkElementEm = document.createElement('em');
const watermarkElementCode = document.createElement('code');
const watermarkElementEl = document.createElement('el');

addCss(watermarkElementDiv, styles);
addCss(watermarkElementSpan, styles);
addCss(watermarkElementI, styles);
addCss(watermarkElementEm, styles);
addCss(watermarkElementCode, styles);
addCss(watermarkElementEl, styles);

const elms = [
  watermarkElementDiv,
  watermarkElementSpan,
  watermarkElementI,
  watermarkElementEm,
  watermarkElementCode,
  watermarkElementEl
];

const MAX_NUMBER = 80;
const MIN_NUMBER = 5;

const randomInteger = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const randomPercent = () => `${randomInteger(MIN_NUMBER, MAX_NUMBER)}%`;

export const NativeWatermark = ({ dataName, watermark, played }) => {
  const [dataElement, setDataElement] = useState(null);
  const mq450MaxWidth = useMediaQuery('(max-width: 450px)');
  const watermarkElementRef = useRef(elms[0]);
  let intervalId = null;

  const setElement = () => {
    if (!watermarkElementRef.current || !dataElement || !watermark) {
      return;
    }

    const el = elms[randomInteger(0, 5)];

    if (mq450MaxWidth) {
      el.style.fontSize = '1.2rem'
    } else {
      el.style.fontSize = '1.5rem'
    }

    el.style.top = randomPercent();
    el.style.left = randomPercent();
    el.innerText = watermark;

    watermarkElementRef.current.remove();
    watermarkElementRef.current = el;

    dataElement.parentNode.insertBefore(el, dataElement.nextSibling);
  };

  useEffect(() => {
    const element = document.querySelector(`[data-video=${dataName}]`);

    if (element) {
      setDataElement(element);
    }
  }, [dataName]);

  useEffect(() => {
    setElement();

    if (!played) {
      clearInterval(intervalId);
    }

    if (intervalId) {
      clearInterval(intervalId);
    }

    intervalId = setInterval(setElement, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [played, intervalId, watermark, dataElement, watermarkElementRef, mq450MaxWidth]);

  return null;
};
