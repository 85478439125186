import styled from "styled-components";
import PlayButton from '@assets/img/play-btn-new.svg';
import NajotLogo from '@assets/img/najot-logo.svg';

export const VideoCardItem = styled.li`
  width: calc(100% / 6 - 24px);
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-bottom: 28px;

  @media only screen and (max-width: 1585px) {
    width: calc(100% / 5 - 24px);
  }
  
  @media only screen and (max-width: 1320px) {
    width: calc(100% / 4 - 24px);
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 1035px) {
    width: calc(100% / 3 - 16px);
  }

  @media only screen and (max-width: 900px) {
    width: calc(100% / 2 - 16px);
  }
  
  @media only screen and (max-width: 720px) {
    max-width: 400px;
    width: 100%;
    margin-bottom: 16px;
  }
  
  &:not(:last-child) {
    margin-right: 24px;

    @media only screen and (max-width: 1320px) {
      margin-right: 16px;
    }
    
    @media only screen and (max-width: 720px) {
      margin-right: 0;
    }
  }

  &:hover {
    background-color: #fff;
    box-shadow: 0px 2px 16px 3px rgba(0, 0, 0, 0.1);
  }
`;

export const VideoCardLink = styled.a`
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 180px;
  background-color: #f3eee9;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  background-image: url(${NajotLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
`;

export const VideoCardPlayBtn = styled.button`
  width: 22px;
  height: 26px;
  background-image: url(${PlayButton});
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
  background-size: contain;
`;

export const VideoCardTitle = styled.h3`
  max-width: 255px;
  font-weight: 700;
  font-size: 18px;
  line-height: 136%;
  letter-spacing: -0.02em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const VideoCardMoreBookmarkMoreBtn = styled.button`
  position: relative;
  background-color: transparent;
  border: 0;
`;

export const VideoCardManage = styled.div`
  position: absolute;
  top: 28px;
  right: 0;
  min-width: 200px;
  background-color: #fff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const VideoCardManageList = styled.ul`
  padding: 4px 0;
`;

export const VideoCardManageItem = styled.li`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  padding: 12px 18px;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: rgba(186,141,91,0.1);
  }
`;

export const VideoCardLabel = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.35);
  margin-right: 6px;

  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background-color: #ba8d5b;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

export const VideoCardCourseName = styled.h4`
  display: inline;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
`;

export const VideoCardViewPercentage = styled.span`
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.75);
  margin-right: 5px;
`;

export const VideoCardView = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.75);
`;

export const VideoCardProgressBox = styled.div`
  width: 100%;
  height: 6px;
  background-color: rgba(186, 141, 91, 0.1);
  border-radius: 4px;
`;

export const VideoCardProgress = styled.span`
  display: block;
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #ba8d5b;
  border-radius: 4px;
`;
export const Wrap = styled.div``
export const VideoCardProgressWrap = styled.div``
