import { hoc } from '@utils/hoc';
import {
  CourseAnswersShow,
  CourseQuestionsAnswerBtn,
  CourseQuestionsCommentedUserImg,
  CourseQuestionsCommentWrapper,
  CourseQuestionsDate,
  CourseQuestionsInfo,
  CourseQuestionsInfoWrapper,
  CourseQuestionsUserName,
  CourseQuestionsUserQuestion,
  CourseQuestionsUserWrapper,
  CourseQuestionTime,
  IsEditedText,
  ReplyComment,
  StaffWrapper,
  VoteBtn,
  VoteCount
} from '../../course-questions.style';
import { timeFormat, commentTimeFormat } from '@utils/date-format-time';
import { timeFormat as commentEditableTimeFormat } from '@utils/date-format';
import { config } from '@config/config';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { useMediaQuery } from '@utils/useMediaQuery';
import { dateFormatForQuestion } from '@utils/date-format';
import DefaultAvatar from '@assets/img/default-avatar.png';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AllCoursesActions } from '@all-courses/store';
// import { Vote } from '@components/atoms/icons/icons';
import { ReactComponent as Vote } from '@assets/img/vote.svg';
import { storageService } from '@services/storage.service';
import { DeletedComment } from '../deleted-comment';
import { isLink } from '@utils/linkChecker';

const DELETED = 'deleted';

const useCommentProps = ({ item }) => {
  const mq500MaxWidth = useMediaQuery('(max-width: 500px)');
  const mq450MaxWidth = useMediaQuery('(max-width: 450px)');

  return {
    mq500MaxWidth,
    mq450MaxWidth
  };
};

export const Comment = hoc(
  useCommentProps,
  ({
    item,
    mq500MaxWidth,
    mq450MaxWidth,
    isShowReplies,
    toggleReplies,
    isReply,
    hasParent
  }) => {
    const [topVoteColor, setTopVoteColor] = useState(false);
    const [downVoteColor, setDownVoteColor] = useState(false);
    const [countColor, setCountColor] = useState('black');
    const [voteCount, setVoteCount] = useState(0);
    const dispatch = useDispatch();

    const userInfo = JSON.parse(storageService.getItem('studentInfo'));

    const userTypes = useMemo(() => {
      return {
        teacher: 'O‘qituvchi',
        assistant: 'Assistent'
      };
    }, []);

    const userType = useMemo(() => {
      return [userTypes.teacher, userTypes.assistant];
    }, []);

    const resetVote = resVoteCount => {
      setVoteCount(resVoteCount);
      setTopVoteColor(false);
      setDownVoteColor(false);
      setCountColor('black');
    };

    const handleDelete = id => {
      dispatch(AllCoursesActions.setReportedCommentId(id));
      dispatch(AllCoursesActions.setConfirmDeleteModal(true));
    };

    const handleEditComment = item => {
      dispatch(AllCoursesActions.setCommentEditInfo(item));
    };

    const handleTopClick = () => {
      const info = {
        data: {
          id: item?.id,
          vote: 'UP_VOTE'
        },
        callback: res => {
          if (res.success) {
            const resVoteCount = res?.data?.voteCount;
            const resVote = res?.data?.vote;
            if (resVote === 0) {
              resetVote(resVoteCount);
              return;
            }

            setVoteCount(resVoteCount);
            setTopVoteColor(true);
            setDownVoteColor(false);
            setCountColor('green');
          }
        }
      };

      dispatch(AllCoursesActions.voted(info));
    };

    const isEditable = useMemo(() => {
      return commentEditableTimeFormat(item?.createdAt, 2880);
    }, [item?.createdAt]);

    const handleDownClick = () => {
      const info = {
        data: {
          id: item?.id,
          vote: 'DOWN_VOTE'
        },
        callback: res => {
          if (res.success) {
            const resVoteCount = res?.data?.voteCount;
            const resVote = res?.data?.vote;
            if (resVote === 0) {
              resetVote(resVoteCount);
              return;
            }
            setVoteCount(resVoteCount);
            setTopVoteColor(false);
            setDownVoteColor(true);
            setCountColor('red');
          }
        }
      };

      dispatch(AllCoursesActions.voted(info));
    };

    const handleAppeal = id => {
      dispatch(AllCoursesActions.setReportedCommentId(id));
      dispatch(AllCoursesActions.setAppealModal(true));
    };

    useEffect(() => {
      setVoteCount(item?.voteCount);

      if (item?.vote === 1) {
        setTopVoteColor(true);
        setDownVoteColor(false);
        setCountColor('green');
      } else if (item?.vote === -1) {
        setDownVoteColor(true);
        setTopVoteColor(false);
        setCountColor('red');
      } else {
        setDownVoteColor(false);
        setTopVoteColor(false);
        setCountColor('black');
      }
    }, [item]);

    return (
      <ReplyComment
        mine={item?.parentCommentAuthor?.userId}
        hasParent={hasParent}
      >
        <FlexWrapper justify='space-between' gap='26px'>
          <CourseQuestionsUserWrapper>
            <CourseQuestionsCommentWrapper>
              <CourseQuestionsInfoWrapper>
                <CourseQuestionsCommentedUserImg
                  src={
                    item?.author?.photo
                      ? isLink(item?.author?.photo)
                        ? item?.author?.photo
                        : `${config.imgApiUrl}/${item?.author?.photo}`
                      : DefaultAvatar
                  }
                  alt='student'
                />
                <CourseQuestionsInfo>
                  <FlexWrapper
                    mb='4px'
                    align={mq500MaxWidth ? 'flex-start' : 'center'}
                    gap='16px'
                  >
                    <CourseQuestionsUserQuestion>
                      {item?.author?.firstName + ' ' + item?.author?.lastName}
                    </CourseQuestionsUserQuestion>

                    {item?.author?.userType !== 4 && (
                      <StaffWrapper>
                        {userType?.[item?.author?.userType - 2]}
                      </StaffWrapper>
                    )}

                    {!mq500MaxWidth && (
                      <FlexWrapper
                        align={mq500MaxWidth ? 'flex-end' : 'center'}
                        gap={mq500MaxWidth ? '5px' : 0}
                        direction={mq500MaxWidth ? 'column' : 'row'}
                      >
                        <CourseQuestionTime>
                          {timeFormat?.(item?.createdAt)}
                        </CourseQuestionTime>
                        <CourseQuestionsDate>
                          {mq450MaxWidth
                            ? dateFormatForQuestion?.(item?.createdAt)
                            : commentTimeFormat?.(item?.createdAt)}
                        </CourseQuestionsDate>
                      </FlexWrapper>
                    )}
                    {item?.isEdited && (
                      <IsEditedText>Tahrirlangan</IsEditedText>
                    )}
                  </FlexWrapper>
                  {item?.status === DELETED ? (
                    <DeletedComment admin />
                  ) : (
                    <CourseQuestionsUserName>
                      <span>
                        {item?.parentCommentAuthor &&
                          `@${item?.parentCommentAuthor?.firstName || ''} ${
                            item?.parentCommentAuthor?.lastName || ''
                          }`}
                      </span>
                      {item?.message}
                    </CourseQuestionsUserName>
                  )}
                </CourseQuestionsInfo>
              </CourseQuestionsInfoWrapper>
            </CourseQuestionsCommentWrapper>
            {mq500MaxWidth && (
              <FlexWrapper
                align='flex-start'
                gap='5px'
                direction='column'
                mt='16px'
              >
                <CourseQuestionTime>
                  {timeFormat?.(item?.createdAt)}
                </CourseQuestionTime>
                <CourseQuestionsDate>
                  {mq450MaxWidth
                    ? dateFormatForQuestion?.(item?.createdAt)
                    : commentTimeFormat?.(item?.createdAt)}
                </CourseQuestionsDate>
              </FlexWrapper>
            )}
          </CourseQuestionsUserWrapper>

          <FlexWrapper
            align='center'
            gap='5px'
            direction='column'
            justify='center'
          >
            <VoteBtn
              className={topVoteColor ? 'top' : ''}
              onClick={handleTopClick}
            >
              <Vote />
            </VoteBtn>

            <VoteCount
              minus={voteCount < 0}
              color={countColor}
              className={
                countColor === 'green'
                  ? 'success'
                  : countColor === 'red'
                  ? 'fail'
                  : 'black'
              }
            >
              {voteCount}
            </VoteCount>

            <VoteBtn
              className={downVoteColor ? 'down' : ''}
              onClick={handleDownClick}
              down
            >
              <Vote />
            </VoteBtn>
          </FlexWrapper>
        </FlexWrapper>

        {isReply &&
          (userInfo?.userId === item?.author?.userId ? (
            <CourseAnswersShow isMine>
              <CourseQuestionsAnswerBtn
                onClick={handleEditComment.bind(null, item)}
                className='edit'
                disabled={!isEditable || item?.status === DELETED}
              >
                Tahrirlash
              </CourseQuestionsAnswerBtn>

              <CourseQuestionsAnswerBtn
                onClick={handleDelete.bind(null, item?.id)}
                className='delete'
                disabled={item?.status === DELETED}
              >
                O'chirish
              </CourseQuestionsAnswerBtn>
            </CourseAnswersShow>
          ) : (
            <>
              <CourseAnswersShow>
                <CourseQuestionsAnswerBtn
                  show={isShowReplies}
                  onClick={toggleReplies?.bind(null, item)}
                  disabled={item?.status === DELETED}
                >
                  Javob yozish
                </CourseQuestionsAnswerBtn>

                <CourseQuestionsAnswerBtn
                  onClick={handleAppeal.bind(null, item?.id)}
                  appeal
                  disabled={item?.isReported || item?.status === DELETED}
                >
                  Shikoyat yozish
                </CourseQuestionsAnswerBtn>
              </CourseAnswersShow>
            </>
          ))}
      </ReplyComment>
    );
  }
);
