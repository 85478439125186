import { tryCatchSaga } from "@auth/store";
import { paymentsService } from "@payments/services/payments.service";
import { fetchSagaFactory } from "@utils/fetch-saga-factory";
import { call, put, takeLatest, all } from "redux-saga/effects";
import { paymentsAction } from "./payments.slice";

export function* fetchPayments({ payload }) {
    const res = yield call(paymentsService.fetchPayments, payload);
    yield put(paymentsAction.fetchPaymentsFulfilled(res.data))
}

export function* watchFetchPayments() {
    yield takeLatest(paymentsAction.fetchPayments, tryCatchSaga(fetchPayments));
  }
  
  export function* PaymentsRootSaga() {
    yield watchFetchPayments();
  }