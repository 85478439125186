import styled from 'styled-components';

export const AppealSubtitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #81755c;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const AppealForm = styled.form``;

export const AppealLabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
  margin-bottom: 12px;
  display: inline-block;
`;

export const AppealTextarea = styled.textarea`
  border: 1px solid #dadada;
  border-radius: 22px;
  resize: none;
  width: 100%;
  padding: 12px 16px;
  min-height: 132px;
  margin-bottom: 24px;
  outline: none;
`;

export const AppealBtn = styled.button`
  background-color: ${props => (props.cancel ? '#fff' : '#BA8D5B')};
  color: ${props => (props.cancel ? '#000' : '#fff')};
  border-radius: 100px;
  border: ${props => (props.cancel ? '1px solid #DADADA;' : 'none')};
  padding: 11px 46px;
  width: 177px;
  box-sizing: border-box;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => (props.cancel ? '#BA8D5B' : '#fff')};
    color: ${props => (props.cancel ? '#fff' : '#000')};
    border: 1px solid ${props => (props.cancel ? 'transparent' : '#BA8D5B')};
  }

  &.delete {
    background-color: #ea4335 !important;

    &:hover {
      background-color: #fff !important;
      color: #000;
      border: 1px solid #ea4335;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;
