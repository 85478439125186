import { hoc } from '@utils/hoc';
import { useSubHeaderProps } from './sub-header.prop';
import { SubHeaderTitle, SubHeaderWrapper, StyledLink } from './sub-header.style';

export const SubHeader = hoc(
  useSubHeaderProps,
  ({ sidebarState, subHeaderTitle, toBack, isHasButton, notFound }) => {
    return (
      <SubHeaderWrapper padLef={isHasButton} state={sidebarState}>
        {(isHasButton?.hasButton || notFound) && (
          <StyledLink onClick={toBack} className={`back`}>
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.99996 1.4502L1.66663 6.78353L6.99996 12.1169'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </StyledLink>
          
        )}
        <SubHeaderTitle isHasButton={isHasButton?.isHasButton || notFound}>
          {subHeaderTitle ? subHeaderTitle : 'Bosh sahifa'}
        </SubHeaderTitle>
      </SubHeaderWrapper>
    );
  }
);
