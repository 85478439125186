import { SvgIcon } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useTextAreaProps } from './text-area.prop';
import {
  SendBtn,
  TextareaContainer,
  TextareaField,
  TextAreaWrapper,
  Time,
  TimeWrapper
} from './text-area.style';

export const Textarea = hoc(
  useTextAreaProps,
  ({
    _onChange,
    textarea,
    _textareaRef,
    time,
    click,
    videoTime,
    handleSubmit,
    submit,
    ...rest
  }) => {
    return (
      <TextareaContainer>
        <FlexWrapper justify='space-between'>
          <TextAreaWrapper>
            <TimeWrapper>
              <Time
                className={
                  textarea?.length > 0 && videoTime > 0 ? 'editable' : ''
                }
              >
                {time || '0:00:00'}
              </Time>
            </TimeWrapper>

            <TextareaField
              rows={1}
              ref={_textareaRef}
              onKeyDown={handleSubmit}
              onChange={e => _onChange(e.target.value)}
              value={textarea}
              placeholder='Qayd qoldirish'
              {...rest}
            />
          </TextAreaWrapper>

          <SendBtn
            onClick={submit}
            disabled={textarea?.length === 0 || videoTime < 1}
          >
            <SvgIcon
              iconName='send'
              size='20px'
              iconColor='#BA8D5B'
              disabled={textarea?.length > 1000 || textarea?.length === 0 || videoTime < 1}
            />
          </SendBtn>
        </FlexWrapper>
      </TextareaContainer>
    );
  }
);
