import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import { AppealBtn } from '../appealComment/appealComment.style';
import { CancelAppealConfirmText } from '../CancelAppeal/cancel-appeal.style';
import { useConfirmDeleteProps } from './confirm-delete.prop';

export const ConfirmDelete = hoc(
  useConfirmDeleteProps,
  ({
    handleUnMountCancelDelete,
    confirmDelete,
    setConfirmDelete,
    handleConfirmDelete
  }) => (
    <UnModal
      modal={confirmDelete}
      setModal={setConfirmDelete}
      unMountModal={handleUnMountCancelDelete}
    >
      <CancelAppealConfirmText>
        Siz izohni o'chirmoqchimisiz?
      </CancelAppealConfirmText>

      <FlexWrapper justify='space-between' gap='20px'>
        <AppealBtn onClick={handleUnMountCancelDelete} cancel>
          Yo‘q
        </AppealBtn>
        <AppealBtn onClick={handleConfirmDelete} className='delete'>
          Ha
        </AppealBtn>
      </FlexWrapper>
    </UnModal>
  )
);
