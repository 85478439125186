import { hoc } from '@utils/hoc';
import { HomeworkFileProp } from './homework-file.prop';
import Done from '@assets/img/done.png';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { formatBytes } from '@utils/byteToMb';
import {
  CancelFile,
  CircularProgress,
  HomeworkFile,
  HomeWorkFileBasename,
  HomeWorkFileSubtitle,
  HomeWorkFileTitle,
  ProggresbarBtn,
  ValueContainer
} from './homework-file.style';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { ReactComponent as IconCancelFile } from '@assets/img/icon-cancel-file.svg';

export const HomeworkFiles = hoc(
  HomeworkFileProp,
  ({ percentage, handleCancel, item, index }) => {
    const splitName = item?.name?.split('.') || item?.origname?.split('.');
    const fileExtension = splitName?.[splitName.length - 1];
    const fileBasename = splitName?.slice(0, -1)?.join('.');
    return (
      <HomeworkFile>
        <FlexWrapper gap='8px'>
          <ProggresbarBtn>
            {percentage !== 100 ? (
              <CircularProgress>
                <CircularProgressbar
                  value={percentage}
                  styles={buildStyles({
                    pathColor: '#ba8d5a',
                    trailColor: '#fff'
                  })}
                />
              </CircularProgress>
            ) : (
              <ValueContainer src={Done} />
            )}
          </ProggresbarBtn>
          <FlexWrapper gap='4px' direction='column'>
            <FlexWrapper>
              <HomeWorkFileBasename>{fileBasename}</HomeWorkFileBasename>
              <HomeWorkFileTitle>{'.' + fileExtension}</HomeWorkFileTitle>
            </FlexWrapper>

            <HomeWorkFileSubtitle>
              {item?.size && formatBytes(item?.size)}
            </HomeWorkFileSubtitle>
          </FlexWrapper>
        </FlexWrapper>
        <CancelFile onClick={handleCancel.bind(null, { item, index })}>
          <IconCancelFile width={14} />
        </CancelFile>
      </HomeworkFile>
    );
  }
);
