import { useMediaQuery } from "@utils/useMediaQuery"
import { useSelector } from "react-redux"

export const useMyCourseCardProp = () => {
    const sidebarState = useSelector(state => state.common.sidebarState)
    const mq1070MaxWidth = useMediaQuery("(max-width: 1070px)")
    const mq985MaxWidth = useMediaQuery("(max-width: 985px)")
    const mq885MaxWidth = useMediaQuery("(max-width: 885px)")
    const mq825MaxWidth = useMediaQuery("(max-width: 825px)")
    const mq440MaxWidth = useMediaQuery("(max-width: 440px)")
    const mq781MaxWidth = useMediaQuery("(max-width: 781px)")
    const mq1122MaxWidth = useMediaQuery("(max-width: 1122px)")
    const mq1122MinWidth = useMediaQuery("(min-width: 1122px)")
    const isSidebarOpened = useSelector(state => state.common.sidebarState)


    return {
        sidebarState,
        mq1070MaxWidth,
        mq885MaxWidth,
        mq825MaxWidth,
        mq440MaxWidth,
        mq781MaxWidth,
        mq1122MaxWidth,
        isSidebarOpened,
        mq985MaxWidth,
        mq1122MinWidth
    }
}
