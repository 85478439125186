import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const InputField = styled(NumberFormat)`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: ${props => props.paddingSize || '11px'} 16px  ${props => props.paddingSize || '11px'} ${props => (props.isprefix ? '56px' : '16px')};
  background-color: #fff;
  border: 1px solid ${props => (props.isError ? 'rgba(255, 0, 0, 0.5)' : '#dadada')};
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato';
  font-style: 'normal';

  &.input-error {
    box-shadow: 0 0 1px 1px #e84949;
  }

  &::placeholder {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.35);
  }

  &:focus {
    outline: none;
    border: 1px solid #2697ff;
  }
`;
export const InputWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #fff;
`;
export const InputPrefix = styled.span`
  position: absolute;
  z-index: 99;
  left: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  font-family: 'Lato';
  top: 12px;
`;
export const InputSuffix = styled.span`
  position: absolute;
  z-index: 99;
  top: ${props => props.top || '12px'};
  right: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;
