import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

export const dateFormatByMonth = date => {
  const month = [
    'yanvar',
    'fevral',
    'mart',
    'aprel',
    'may',
    'iyun',
    'iyul',
    'avgust',
    'sentabr',
    'oktabr',
    'noyabr',
    'dekabr'
  ];
  const year = date.getFullYear();
  return date.getDate() + '-' + month[date.getMonth()] + ', ' + year + '-yil';
};

export const dateFormat = date => {
  var year = date.getFullYear();
  var month = String(date.getMonth() + 1).padStart(2, 0);
  var day = String(date.getDate()).padStart(2, 0);
  return day + '.' + month + '.' + year;
};

export const timeFormat = (date, minute) => {
  const past = new Date(date).getTime();
  const now = new Date().getTime();

  return now - past < minute * 60 * 1000;
};

export const dateFormatForQuestion = date => {
  const data = new Date(date);

  return (
    data.getDate() + '/' + (data.getMonth() + 1) + '/' + data.getFullYear()
  );
};

export const dateFormatForHmw = (date, timeInTheEnd) => {
  if (!date) return '-';

  const timestamp = new Date(date);

  const hours = timestamp.getHours().toString().padStart(2, '0');
  const minutes = timestamp.getMinutes().toString().padStart(2, '0');

  const year = timestamp.getFullYear().toString().slice(-2);
  const month = (timestamp.getMonth() + 1).toString().padStart(2, '0');
  const day = timestamp.getDate().toString().padStart(2, '0');

  if(timeInTheEnd) {
    return `${day}.${month}.${year} ${hours}:${minutes}`
  }
  return `${hours}:${minutes} ${month}.${day}.${year}`;
};

dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tashkent')

export const awardDateFormatter = (date) => {
  if(!date) return '-'


  return dayjs(date).format('DD-MM-YYYY HH:mm')
}
