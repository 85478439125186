import { useMediaQuery } from '@utils/useMediaQuery';
import {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

export const useCourseLessonItem = ({item}) => {
  const [isOpen, setOpen] = useState(false);
  const [isInitiallyOpen, setIsInitiallyOpen] = useState(false);
  const completedLessons = useSelector(state => state.courses.completedLessons);
  const activeLesson = useSelector(state => state.courses.activeLesson)
  const mqMaxWidth1015px = useMediaQuery("(max-width: 1015px)");
  const mqMaxWidth705px = useMediaQuery("(max-width: 705px)");

  const onHeaderBtnClick = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    if(activeLesson?.chapter?.id === item?.id) {
      setIsInitiallyOpen(true)
      setOpen(true);
    } else {
      setOpen(false);
      setIsInitiallyOpen(false)
    }
  }, [activeLesson, item]);

  return {
    onHeaderBtnClick,
    isOpen,
    completedLessons,
    isInitiallyOpen,
    setIsInitiallyOpen,
    activeLesson,
    mqMaxWidth1015px,
    mqMaxWidth705px
  };
};
