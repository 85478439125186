import { hoc } from '@utils/hoc';
import { useInputProps } from './phone-number-input.prop';
import {
  InputField,
  InputPrefix,
  InputSuffix,
  InputWrapper
} from './phone-number-input.style';

export const PhoneNumberInput = hoc(
  useInputProps,
  ({ prefix, suffix, prefixProps, suffixProps, _onChange, ...props }) => {
    const PrefixComponent = prefix;
    const SuffixComponent = suffix;

    return (
      <InputWrapper>
        <InputPrefix {...prefixProps}>
          {typeof prefix === 'function' ? <PrefixComponent /> : prefix}
        </InputPrefix>
        <InputField
          data-testid='input'
          {...props}
          isprefix={String(!!prefix)}
          // onChange={_onChange}
        />
        <InputSuffix {...suffixProps}>
          {typeof prefix === 'function' ? <SuffixComponent /> : suffix}
        </InputSuffix>
      </InputWrapper>
    );
  },
'Input'
);
