import { CourseTitle } from '@modules/certificates/routes/more-info/more-info.style';
import { hoc } from '@utils/hoc';
import { useTechInfoProp } from './tech-info.prop';
import {
  TechInfoList,
  TechInfoListItem,
  TechInfoWrapper
} from './tech-info.style';

export const TechInfo = hoc(useTechInfoProp, ({ studentSkills }) => {
  if (studentSkills.length === 0) return null;
  return (
    <TechInfoWrapper>
      <CourseTitle>Ko‘nikma va Texnologiyalar:</CourseTitle>

      <TechInfoList>
        {studentSkills?.map((item, index) => (
          <TechInfoListItem key={index}>{item}</TechInfoListItem>
        ))}
      </TechInfoList>
    </TechInfoWrapper>
  );
});
