import { formatNumber } from '@utils/format-number';
import UzcardLogo from '@assets/img/uzcard-logo.svg';
import HumoLogo from '@assets/img/humo-logo.svg';
import VisaLogo from '@assets/img/logo-visa.svg';
import MasterCardLogo from '@assets/img/logo-mastercard.svg';
import Pdf from '@assets/img/oferta.pdf';
import { ReactComponent as ChevronUp } from '@assets/img/chevron-up.svg';
import { ReactComponent as ChevronDown } from '@assets/img/chevron-down.svg';

import {
  CardTitle,
  CollapsibleButton,
  Collapsible,
  CourseDetails,
  CourseModal,
  ModalContent,
  ModalFooter,
  PaymentModalContainer,
  PaymentType,
  PromoCodeWrapper,
  ModuleTypes,
  ModuleTypeCount,
  ToolTip,
  Module,
  ModuleName,
  ModulePrice,
  ModulesWrapper,
  ErrorInfo,
  FormControl,
  PaymentModalTop,
  PaymentModalCLoseBtn,
  ButtonCore,
  IframeModal,
  CloseIframeModal,
  PromoCodeCollapseBox,
  PurchaseDiscount,
  TotalPriceCount,
  ActivedPromoCode,
  PromoCodeSalePrice,
  TotalBoughtPrice,
  TitlePrimary,
  TotalPrice,
  PaymentModalContentScroll,
  FooterWrapper,
  PromoButton
} from './payment-modal.styles';
import { hoc } from '@utils/hoc';
import { usePaymentModalProps } from './payment-modal.props';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { ThemeForm } from '@components/form';
import { Checkbox } from '../checkbox/checkbox.component';
import { Input } from '@components/atoms/Input';
import { Alert } from '@components/atoms/alert';

export const PaymentModal = hoc(
  usePaymentModalProps,
  ({
    paymentSrcLink,
    selectedModulesCount,
    modalRef,
    promoCodeSubmit,
    courseSingleInfo,
    handleChangePayment,
    handleBlurPromoCode,
    onClose,
    isModulesBoxCollapsed,
    isPromoCodeCollapsed,
    form,
    handleModuleSelect,
    handleIframeModalClose,
    isOpenIframeModal,
    calculatedPrice,
    selectedModules,
    handleChangePromoCode,
    purchasedModulesCount,
    collapseModulesContainer,
    collapsePromoCodeClick,
    mqMaxWidth450,
    isDisabled,
    alertStatus,
    courseDiscount,
    promoCodeInfo,
    promoCodeSalePrice,
    isDisabledPromoCode,
    discountAdditionalCourse,
    bulkDiscount,
    isFree,
    getPriceCount,
     paymentInfo
     
  }) => {
    return (
      <PaymentModalContainer
        onClick={paymentSrcLink ? handleIframeModalClose : onClose}
        ref={modalRef}
      >
        <CourseModal onClick={e => e.stopPropagation()}>
          {paymentSrcLink && (
            <CloseIframeModal onClick={handleIframeModalClose} />
          )}
          {
            <ModalContent>
              <ThemeForm use={form}>
                <PaymentModalTop>
                  <CardTitle>Kursni sotib olish</CardTitle>
                  <PaymentModalCLoseBtn
                    onClick={onClose}
                    type='button'
                  ></PaymentModalCLoseBtn>
                </PaymentModalTop>

                {/* ///////////////////// COURSE DETAILS ////////////////// */}
                <PaymentModalContentScroll>
                  <CourseDetails>
                    <h5>Kurs nomi</h5>
                    <FlexWrapper
                      justify='space-between'
                      direction={mqMaxWidth450 ? 'column' : 'initial'}
                    >
                      <TitlePrimary>
                        {courseSingleInfo?.name || 'Kurs nomi'}
                        {courseSingleInfo?.teacher
                          ? courseSingleInfo?.teacher?.firstName +
                            ' ' +
                            courseSingleInfo?.teacher?.lastName
                          : null}
                      </TitlePrimary>
                    </FlexWrapper>
                  </CourseDetails>

                  {/* ///////////////////// MODULES ////////////////// */}
                  <Collapsible
                    isCollapsed={isModulesBoxCollapsed}
                    minHeight={isModulesBoxCollapsed ? '50px' : '20px'}
                    maxHeight={isModulesBoxCollapsed ? '15.5rem' : '50px'}
                  >
                    <CollapsibleButton
                      type='button'
                      onClick={collapseModulesContainer}
                    >
                      {isModulesBoxCollapsed ? <ChevronUp /> : <ChevronDown />}
                    </CollapsibleButton>
                    <FlexWrapper
                      gap='1.2rem'
                      align='center'
                      mb={isModulesBoxCollapsed ? '1.7rem' : '0'}
                    >
                      <TitlePrimary>Modullar soni</TitlePrimary>
                      <ModuleTypes align='center' gap='0.5rem'>
                        <ModuleTypeCount>
                          <ToolTip>Tanlangan modullar soni</ToolTip>
                          {selectedModulesCount || 0}
                        </ModuleTypeCount>
                        /
                        <ModuleTypeCount
                          style={{
                            color: '#07CD86',
                            border: '1px solid #07CD86'
                          }}
                        >
                          <ToolTip>Sotib olingan modullar soni</ToolTip>
                          {paymentInfo?.paidModulesCount || 0}
                        </ModuleTypeCount>
                        /
                        <ModuleTypeCount>
                          <ToolTip>Umumiy modullar soni</ToolTip>
                          {courseSingleInfo?.modulesCount}
                        </ModuleTypeCount>
                      </ModuleTypes>
                    </FlexWrapper>

                    <ModulesWrapper
                      direction={mqMaxWidth450 ? 'column' : 'initial'}
                      maxHeightModule={isModulesBoxCollapsed ? '8.8rem' : '0'}
                      gap='1.4rem'
                    >
                      {courseSingleInfo?.modulesCount &&
                        courseSingleInfo.modules.map(module => (
                          <Module key={module?.id} align='center' gap='1.2rem'>
                            <FlexWrapper
                              gap={'12px'}
                              align={'center'}
                              wrap='false'
                            >
                              <Checkbox
                                name='checkbox'
                                isDisabled={module.isPaid}
                                isChecked={
                                  module.isPaid || selectedModules[module?.id]
                                }
                                onChange={isChecked =>
                                  handleModuleSelect(module?.id, isChecked)
                                }
                              />
                              <ModuleName
                                isActive={
                                  module.isPaid || selectedModules[module?.id]
                                }
                              >
                                {module.title}
                              </ModuleName>
                            </FlexWrapper>
                            <ModulePrice
                              isPurchased={module.isPaid}
                              isActive={selectedModules[module?.id]}
                            >
                              {module.isPaid
                                ? 'Sotib olingan'
                                : formatNumber(module.price) + ' UZS'}
                            </ModulePrice>
                          </Module>
                        ))}
                    </ModulesWrapper>
                  </Collapsible>

                  {/* /////////////////// PROMOCODE ////////////// */}
                  {paymentInfo?.hasPromocode && (
                    <Collapsible
                      isCollapsed={isPromoCodeCollapsed}
                      minHeight={isPromoCodeCollapsed ? '4.8rem' : '2rem'}
                      maxHeight={isPromoCodeCollapsed ? '12.8rem' : '2rem'}
                    >
                      <PromoCodeCollapseBox
                        collapsed={isPromoCodeCollapsed}
                        onClick={collapsePromoCodeClick}
                      >
                        <CollapsibleButton>
                          {isPromoCodeCollapsed ? (
                            <ChevronUp />
                          ) : (
                            <ChevronDown />
                          )}
                        </CollapsibleButton>

                        <TitlePrimary mb='0.8rem'>
                          Promokodni tekshirish
                        </TitlePrimary>
                      </PromoCodeCollapseBox>

                      <PromoCodeWrapper>
                        {alertStatus ? (
                          <ActivedPromoCode>
                            <span>Promokod aktivlashtirildi</span>
                          </ActivedPromoCode>
                        ) : isPromoCodeCollapsed ? (
                          <FormControl>
                            <Input
                              name='promoCode'
                              type='text'
                              maxLength={16}
                              onBlur={handleBlurPromoCode}
                              onChange={handleChangePromoCode}
                              value={form.values.promoCode}
                              placeholder='Promokodni kiriting'
                              className={
                                form.errors.promoCode ? 'input-error' : ''
                              }
                              autoComplete='off'
                            />
                            <PromoButton
                              onClick={promoCodeSubmit}
                              disabled={isDisabledPromoCode}
                            />
                          </FormControl>
                        ) : null}
                      </PromoCodeWrapper>
                    </Collapsible>
                  )}

                  {/* ///////////////// PREFERRED PAYMENT TYPE //////////////////// */}
                  {paymentInfo?.bulkDiscount?.isEnabled && (
                    <TotalPrice justify='space-between'>
                      <PurchaseDiscount>
                        To‘liq sotib olish chegirmasi (
                        {paymentInfo?.bulkDiscount?.value}%):
                      </PurchaseDiscount>
                      <span>
                        {formatNumber(bulkDiscount)}
                        {!!bulkDiscount && ' UZS' }
                      </span>
                    </TotalPrice>
                  )}

                  {courseDiscount && (
                    <TotalPrice
                      key={courseDiscount?.id}
                      justify='space-between'
                    >
                      <PurchaseDiscount>
                        Kursga berilgan qo‘shimcha chegirma (
                        {courseDiscount?.discountValue}%):
                      </PurchaseDiscount>
                      <span>
                        {formatNumber(discountAdditionalCourse)}{' '}
                        {discountAdditionalCourse ? 'UZS' : '—'}
                      </span>
                    </TotalPrice>
                  )}

                  {promoCodeInfo.amount > 0 ? (
                    <TotalPrice justify='space-between'>
                      <PurchaseDiscount>
                        Promokod orqali berilgan chegirma:{' '}
                        {promoCodeInfo?.promocodeAmountType === 1
                          ? `(${promoCodeInfo?.amount}%):`
                          : null}
                      </PurchaseDiscount>
                      <span>
                        {promoCodeInfo?.promocodeAmountType === 1
                          ? formatNumber(promoCodeSalePrice) || 0
                          : formatNumber(promoCodeInfo?.amount)}{' '}
                        UZS
                      </span>
                    </TotalPrice>
                  ) : null}

                  {(courseDiscount ||
                    promoCodeInfo?.amount > 0 ||
                    paymentInfo?.bulkDiscount?.isEnabled) && (
                    <TotalPrice justify='space-between'>
                      <span>Kurs asl narxi:</span>
                      <PromoCodeSalePrice
                        sale={
                          (calculatedPrice && courseDiscount) ||
                          promoCodeInfo?.amount ||
                          bulkDiscount > 0
                        }
                      >
                        {formatNumber(calculatedPrice) || 0} UZS
                      </PromoCodeSalePrice>
                    </TotalPrice>
                  )}

                  <TotalBoughtPrice
                    isActive={calculatedPrice > 0}
                    justify='space-between'
                    align='flex-end'
                  >
                    <span>Sotib olinadigan narx:</span>
                    <TotalPriceCount>{getPriceCount()} UZS</TotalPriceCount>
                  </TotalBoughtPrice>

                  {/* ///////////////// PAYMENT TYPES //////////////////// */}
                  {isFree && (
                    <FlexWrapper justify='center' align='center'>
                      <PaymentType gap='1.2rem'>
                        <label>
                          <input
                            type='radio'
                            name='paymentType'
                            aria-label={'uzcard'}
                            value={'UzCard'}
                            onChange={e => handleChangePayment(e.target.value)}
                          />
                          <div tabIndex={0}>
                            <img
                              src={UzcardLogo}
                              alt='Uzcard logo'
                              width={'50'}
                              height={'20'}
                            />
                            <img
                              src={HumoLogo}
                              alt='Humo logo'
                              width={'50'}
                              height={'20'}
                            />
                          </div>
                        </label>
                        <label>
                          <input
                            type='radio'
                            name='paymentType'
                            aria-label={'payze'}
                            value={'Payze'}
                            onChange={e => handleChangePayment(e.target.value)}
                          />
                          <div tabIndex={0}>
                            <img
                              src={VisaLogo}
                              alt='Visa logo'
                              width={'50'}
                              height={'20'}
                            />
                            <img
                              src={MasterCardLogo}
                              alt='Master logo'
                              width={'50'}
                              height={'20'}
                            />
                          </div>
                        </label>
                      </PaymentType>
                    </FlexWrapper>
                  )}
                </PaymentModalContentScroll>
                <FooterWrapper>
                  {isDisabled && (
                    <ErrorInfo>
                      Davom etishdan avval biron modulni va to‘lov turini
                      tanlang
                    </ErrorInfo>
                  )}

                  {/* ///////////////// MODAL FOOTER //////////////////// */}
                  <ModalFooter
                    direction='column'
                    justify='space-between'
                    align='center'
                    wrap
                  >
                    <ButtonCore
                      type='submit'
                      buttonType='button'
                      size='large'
                      disabled={isDisabled}
                    >
                      {isFree ? 'Davom ettirish' : 'Qo’lga kiritish'}
                    </ButtonCore>
                    <p>
                      Tugmani bosish orqali men{' '}
                      <a target='_blank' href={Pdf} rel='noreferrer'>
                        shaxsiy ma'lumotlarni
                      </a>{' '}
                      qayta ishlashga va{' '}
                      <a target='_blank' href={Pdf} rel='noreferrer'>
                        Platformadan foydalanish shartlariga
                      </a>{' '}
                      roziman
                    </p>
                  </ModalFooter>
                </FooterWrapper>
              </ThemeForm>
            </ModalContent>
          }
        </CourseModal>
        {paymentSrcLink && (
          <IframeModal
            src={paymentSrcLink}
            isHideIframeModal={isOpenIframeModal}
          />
        )}
      </PaymentModalContainer>
    );
  }
);
