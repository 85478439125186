import {hoc} from "@utils/hoc";
import {useCoursesModalProps} from './courses-modal.prop'
import {UnModal} from "@components/modal";
import {RatingTable} from "./courses-modal.style";

export const CoursesModal = hoc(useCoursesModalProps, ({open, setModal, columns, courses}) => {
  return (
    <UnModal
      modal={open}
      setModal={setModal}
      modalTitle="Kurslar"
    >
      <RatingTable columns={columns} data={courses} />
    </UnModal>
  )
})
