import { AllCoursesActions } from '@all-courses/store';
import { commonActions } from '@store/common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useAppealComponentProps = () => {
  const [appealModal, setAppealModal] = useState(false);
  const [appealText, setAppealText] = useState('');
  const dispatch = useDispatch();
  const appealModalState = useSelector(state => state.courses.appealModal);
  const commentId = useSelector(state => state.courses.reportedCommentId);
  const activeLesson = useSelector(state => state.courses.activeLesson);
  const activeChapter = activeLesson?.chapter?.id;

  useEffect(() => {
    setAppealModal(appealModalState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appealModalState]);

  const handleChangeAppealText = e => {
    setAppealText(e.target.value);
  };

  const handleCancel = () => {
    dispatch(AllCoursesActions.setCancelAppealModal(true));
  };

  const handleFinish = e => {
    e.preventDefault();

    const data = {
      info: {
        commentId,
        message: appealText
      },
      callback: res => {
        if (res?.success) {
          handleCloseAppealModal();
          dispatch(AllCoursesActions.setAlertMsg('Shikoyatingiz qabul qilindi'))
          dispatch(commonActions.setShowAlert(true));
          dispatch(
            AllCoursesActions.fetchComments({
              chapterId: activeChapter,
              isMine: true
            })
          );
          dispatch(
            AllCoursesActions.getAllComments({
              chapterId: activeChapter,
              isMine: false
            })
          );
        }
      }
    };

    dispatch(AllCoursesActions.commentReport(data))
  };

  const handleCloseAppealModal = () => {
    setAppealModal(false);
    dispatch(AllCoursesActions.setAppealModal(false));
  };

  return {
    appealModal,
    setAppealModal,
    handleCloseAppealModal,
    appealText,
    handleChangeAppealText,
    handleFinish,
    handleCancel
  };
};
