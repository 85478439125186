import { Facebook, ShareLink, Telegram } from '@components/atoms/icons/icons';
import { hoc } from '@utils/hoc';
import { useShareProp } from './share.prop';
import { ShareWrapper, SocialButton, SocialText } from './share.style';

export const Share = hoc(
  useShareProp,
  ({ isTop, handleShareByTelegram, handleShareByFaceBook, handleCopyLink }) => {
    return (
      <ShareWrapper onClick={handleShareByTelegram} top={isTop}>
        <SocialButton className='telegram'>
          <Telegram />
          <SocialText>Telegram</SocialText>
        </SocialButton>

        <SocialButton onClick={handleShareByFaceBook} className='facebook'>
          <Facebook />
          <SocialText>Facebook</SocialText>
        </SocialButton>

        <SocialButton onClick={handleCopyLink} className='share'>
          <ShareLink />
          <SocialText>Havolani nusxalash</SocialText>
        </SocialButton>
      </ShareWrapper>
    );
  }
);
