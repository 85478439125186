import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { config } from '@config/config';
import { api } from '@services/base.service';
import { parseQueryToString } from '@utils/get-query';
import { useMediaQuery } from '@utils/useMediaQuery';
import { AllCoursesActions } from '@all-courses/store';
import { commonActions } from '@store/common';
import { formatNumber } from '@utils/format-number';
import { addNotification } from '@components/notifications';

const paymentTypes = {
  click: 'CLICK',
  payme: 'PAYME'
};

const privacyPolicyLink = `${config.landingUrl}/oferta.pdf`;

const PROMOCODE_ERROR = 'Noto‘g‘ri promocode';

export const usePaymentModalProps = ({ setIsOpenPaymentModal }) => {
  const theme = useTheme();

  const enroll = useSelector(state => state.courses.enroll);
  const courseSingleInfo = useSelector(state => state.courses.singleCourse);
  const courseDiscount = useSelector(state => state.courses?.courseDiscount);
  const paymentInfo = useSelector(state => state.courses?.paymentInfo);
  const modalRef = useRef(null);
  const formRefType = useRef(null);
  const formRefClick = useRef(null);
  const dispatch = useDispatch();

  // ----------- UI --------------
  const [isModulesBoxCollapsed, setIsModulesBoxCollapsed] = useState(true);
  const [isPromoCodeCollapsed, setIsPromoCodeCollapsed] = useState(false);
  const [noModuleSelected, setNoModuleSelected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledPromoCode, setIsDisabledPromoCode] = useState(true);
  const [paymentSrcLink, setPaymentSrcLink] = useState('');
  const [isOpenIframeModal, setIsOpenIframeModal] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertMessageDesc, setAlertMessageDesc] = useState('');
  const [alertStatus, setAlertStatus] = useState(null);
  const [promoCodeInfo, setPromoCodeInfo] = useState({});
  const [promoCodeSalePrice, setPromoCodeSalePrice] = useState(0);
  const [promoCodeSaleTotalPrice, setPromoSaleCodeTotalPrice] = useState(0);
  const [discountSaleModulePrice, setDiscountSaleModulePrice] = useState(0);
  const [bulkDiscount, setBulkDiscount] = useState(0);
  const [bulkTotalDiscount, setBulkTotalDiscount] = useState(0);
  const [isFree, setIsFree] = useState(true);

  const [selectedModules, setSelectedModules] = useState({});
  const [selectedModulesCount, setSelectedModulesCount] = useState(0);
  const [purchasedModulesCount, setPurchasedModulesCount] = useState(0);
  const [preferredPaymentType, setPreferredPaymentType] = useState(null);
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountTotalPrice, setDiscountTotalPrice] = useState(0);
  const [discountAdditionalCourse, setDiscountAdditionalCourse] = useState(0);
  const [selected, setSelected] = useState([]);

  const location = useLocation();
  const mqMaxWidth552 = useMediaQuery('max-width: 552px');
  const mqMaxWidth450 = useMediaQuery('max-width: 450px');
  const mqWrap508 = useMediaQuery('(max-width: 450px)');
  const history = useHistory();

  const search = useMemo(
    () => parseQueryToString(location.search),
    [location.search]
  );

  const initialValues = {
    promoCode: '',
    paymentType: '',
    promocode: ''
  };

  const validationSchema = Yup.object().shape({
    paymentType: Yup.string(),
    promoCode: Yup.string().min(4, PROMOCODE_ERROR)
  });

  const handleOnSubmit = async () => {
    if (!isFree) {
      const info = {
        data: {
          courseId: courseSingleInfo.id,
          moduleIds: selected,
          paymentType: 'SUPER_FREE',
          promocode: promoCodeInfo?.serialNumber
        },
        callback: res => {
          if (res.success) {
            addNotification({
              title: 'Muvaffaqiyatli',
              description: `Sizga ${
                courseSingleInfo?.name
              } kursining ${res?.data?.modules?.map(item => item.title)}, ${
                res?.data?.modules?.length > 1 ? 'modullari' : 'moduli'
              } chegirmalar orqali tekinga berildi`,
              options: {
                autoClose: true,
                type: 'success'
              }
            });
            history.push(`/my-course-single/${courseSingleInfo.id}`);
            setIsOpenPaymentModal(false);
          } else {
            addNotification({
              title: 'Xatolik yuz berdi',
              description: res?.error?.errMsg,
              options: {
                autoClose: true,
                type: 'error'
              }
            });
            setIsOpenPaymentModal(false);
          }
        }
      };

      dispatch(AllCoursesActions.payForSuperFree(info));
      return;
    }

    if (!preferredPaymentType && isFree) return;

    if (selectedModulesCount === 0) {
      setNoModuleSelected(true);
    } else if (preferredPaymentType === 'Payze') {
      const data = {
        info: {
          courseId: courseSingleInfo.id,
          moduleIds: selected,
          method: 'JUST_PAY',
          promocode: promoCodeInfo?.serialNumber
        },
        callback: response => {
          if (response.success) {
            dispatch(
              AllCoursesActions.getTransactionId(response?.data?.transactionId)
            );
            dispatch(commonActions.setPayzeModal(true));
            history.goBack();
          }
        }
      };
      dispatch(AllCoursesActions.payzeToken(data));
    } else {
      setNoModuleSelected(false);
      setIsSubmitting(true);

      const obj = {
        courseId: courseSingleInfo.id,
        moduleIds: selected,
        paymentType: 'APELSIN',
        promocode: promoCodeInfo?.serialNumber
      };

      if (!courseSingleInfo) {
        delete obj.promocode;
      }

      const paymentLink = await api.post(`/student/generate-payment-link`, obj);
      if (paymentLink?.success) {
        setPaymentSrcLink(paymentLink?.data);
      } else {
        console.error(paymentLink?.error?.errMsg);
      }
    }
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: handleOnSubmit
  });

  const calculateTotalPrice = () => {
    const total = courseSingleInfo?.modules?.reduce(
      (acc, module) => acc + module.price,
      0
    );

    setTotalPrice(total);
    if (courseDiscount) {
      setDiscountTotalPrice(
        Math.round(total * ((100 - courseDiscount?.discountValue) / 100))
      );
    }
  };

  useEffect(() => {
    if ((selectedModulesCount > 0 && preferredPaymentType) || !isFree) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedModulesCount, preferredPaymentType, isFree]);

  const calCulateModulePrice = useMemo(() => {
    let calculatedPrice = 0;

    if (!courseSingleInfo || Object.keys(courseSingleInfo)?.length === 0)
      return;

    courseSingleInfo?.modules.forEach(module => {
      if (selectedModules?.[module.id]) {
        calculatedPrice += module.price;
      }
    });

    return calculatedPrice;
  }, [courseSingleInfo, selectedModules]);

  const calculatePrice = useCallback(() => {
    setCalculatedPrice(calCulateModulePrice);
    setDiscountAdditionalCourse(
      Math.round(calCulateModulePrice * (courseDiscount?.discountValue / 100))
    );
    if (
      paymentInfo?.bulkDiscount?.isEnabled &&
      selected?.length === courseSingleInfo?.modules?.length &&
      paymentInfo?.paidModulesCount < 1
    ) {
      if (courseDiscount) {
        setBulkDiscount(
          Math.round(calculatedPrice * (paymentInfo?.bulkDiscount?.value / 100))
        );
        setDiscountSaleModulePrice(discountTotalPrice - bulkDiscount);

        if (promoCodeInfo?.promocodeAmountType === 1) {
          setPromoCodeSalePrice(
            Math.round((calculatedPrice * promoCodeInfo?.amount) / 100)
          );
          setPromoSaleCodeTotalPrice(bulkTotalDiscount - promoCodeSalePrice);
        } else if (promoCodeInfo?.promocodeAmountType === 2) {
          setPromoSaleCodeTotalPrice(bulkTotalDiscount - promoCodeInfo?.amount);
        }
      } else if (promoCodeInfo?.promocodeAmountType === 1) {
        setBulkDiscount(
          Math.round(calculatedPrice * (paymentInfo?.bulkDiscount?.value / 100))
        );
        setPromoCodeSalePrice(
          Math.round((calculatedPrice * promoCodeInfo?.amount) / 100)
        );
        setPromoSaleCodeTotalPrice(bulkTotalDiscount - promoCodeSalePrice);
      } else if (promoCodeInfo?.promocodeAmountType === 2) {
        setBulkDiscount(
          Math.round(calculatedPrice * (paymentInfo?.bulkDiscount?.value / 100))
        );
        setPromoSaleCodeTotalPrice(bulkTotalDiscount - promoCodeInfo?.amount);
      } else if (selected?.length === paymentInfo?.modules?.length) {
        setBulkDiscount(
          Math.round(calculatedPrice * (paymentInfo?.bulkDiscount?.value / 100))
        );
        setBulkTotalDiscount(Math.round(calculatedPrice - bulkDiscount));
      } else {
        setBulkTotalDiscount(calculatedPrice);
        setDiscountSaleModulePrice(
          Math.round(
            calculatedPrice * ((100 - courseDiscount?.discountValue) / 100)
          )
        );
        setBulkDiscount(0);
        setPromoSaleCodeTotalPrice(calculatedPrice - promoCodeInfo?.amount);
      }
    } else if (courseDiscount) {
      setDiscountSaleModulePrice(
        Math.round(
          calculatedPrice * ((100 - courseDiscount?.discountValue) / 100)
        )
      );
      setBulkDiscount(0);
      if (promoCodeInfo?.promocodeAmountType === 1) {
        setDiscountSaleModulePrice(0);
        setPromoSaleCodeTotalPrice(
          Math.round(
            calculatedPrice *
              ((100 - (courseDiscount?.discountValue + promoCodeInfo?.amount)) /
                100)
          )
        );
        setBulkDiscount(0);
        setPromoCodeSalePrice(
          Math.round((calculatedPrice * promoCodeInfo?.amount) / 100)
        );
      } else if (promoCodeInfo?.promocodeAmountType === 2) {
        setDiscountSaleModulePrice(0);
        setBulkDiscount(
          Math.round(calculatedPrice * (paymentInfo?.bulkDiscount?.value / 100))
        );
        setPromoSaleCodeTotalPrice(
          calculatedPrice -
            promoCodeInfo?.amount -
            (calculatedPrice * courseDiscount?.discountValue) / 100
        );
      } else {
        setPromoSaleCodeTotalPrice(0);
      }
    } else if (promoCodeInfo?.promocodeAmountType === 1) {
      setPromoSaleCodeTotalPrice(
        Math.round(calculatedPrice * ((100 - promoCodeInfo?.amount) / 100))
      );
      setBulkDiscount(0);
      setPromoCodeSalePrice(
        Math.round((calculatedPrice * promoCodeInfo?.amount) / 100)
      );
    } else {
      setDiscountSaleModulePrice(0);
      if (!promoCodeInfo.amount) {
        setPromoSaleCodeTotalPrice(calculatedPrice - 0);
        setBulkTotalDiscount(calculatedPrice);
        setBulkDiscount(0);
      } else {
        setPromoSaleCodeTotalPrice(calculatedPrice - promoCodeInfo?.amount);
        setBulkTotalDiscount(calculatedPrice);
        setBulkDiscount(0);
      }
    }

    dispatch(
      AllCoursesActions.setCourseTotalPrice(
        courseDiscount
          ? discountSaleModulePrice
          : promoCodeInfo?.amount > 0
          ? promoCodeSaleTotalPrice
          : bulkTotalDiscount > 0
          ? bulkTotalDiscount
          : calculatedPrice
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calCulateModulePrice,
    paymentInfo,
    calculatedPrice,
    promoCodeInfo,
    courseDiscount,
    selected,
    courseSingleInfo,
    bulkDiscount,
    bulkTotalDiscount,
    discountTotalPrice,
    promoCodeSalePrice,
    discountSaleModulePrice,
    promoCodeSaleTotalPrice
  ]);

  const selectAllModules = useCallback(() => {
    let _selectedModules = {};

    courseSingleInfo?.modules?.forEach(module => {
      if (!module.isPaid) {
        _selectedModules[module.id] = module.id;
      }
    });

    updatePurchasedModulesCount();
    setSelected(Object.values(_selectedModules));
    setSelectedModules(_selectedModules);
    updateSelectedModulesCount(Object.keys(_selectedModules));
  }, [courseSingleInfo, selected, selectedModules]);

  const updatePurchasedModulesCount = () => {
    setPurchasedModulesCount(paymentInfo?.paidModulesCount);
  };

  const handleModuleSelect = (id, isChecked) => {
    let _selectedModules = { ...selectedModules };
    if (isChecked) {
      _selectedModules[id] = id;
      setSelected([...selected, id]);
    } else {
      _selectedModules[id] = '';
      const filtered = selected.filter(item => item !== id);
      setSelected(filtered);
    }

    setSelectedModules(_selectedModules);
    updateSelectedModulesCount(Object.values(_selectedModules));
    setIsSubmitting(false);
  };

  const updateSelectedModulesCount = selectedModulesId => {
    selectedModulesId = selectedModulesId.filter(moduleId => moduleId);
    setSelectedModulesCount(selectedModulesId.length);
  };

  const collapseModulesContainer = () => {
    setIsModulesBoxCollapsed(!isModulesBoxCollapsed);
  };

  const collapsePromoCodeClick = () => {
    setIsPromoCodeCollapsed(!isPromoCodeCollapsed);
  };

  const handleChangePayment = () => {
    setPreferredPaymentType('Payze');
    form.setFieldValue('paymentType', 'Payze');
  };

  const checkModulesId = id =>
    courseSingleInfo?.modules?.find(module => module.id === parseInt(id));

  useEffect(() => {
    if (courseSingleInfo) {
      calculateTotalPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSingleInfo, courseDiscount]);

  useEffect(() => {
    calculatePrice();
  }, [calculatePrice]);

  useEffect(() => {
    if (!courseSingleInfo) return;

    if (search?.buy === 'all') {
      selectAllModules();
    }

    if (search.buy) {
      const isHasModule = checkModulesId(search.buy);

      if (isHasModule) {
        let _selectedModules = {
          [isHasModule.id]: isHasModule.id
        };
        setSelectedModules(_selectedModules);
        setSelected([isHasModule?.id]);
        updatePurchasedModulesCount();
        updateSelectedModulesCount(Object.keys(_selectedModules));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.buy, courseSingleInfo]);

  useEffect(() => {
    if (!courseSingleInfo) return;
    setPurchasedModulesCount(paymentInfo?.paidModulesCount);
  }, [courseSingleInfo]);

  useEffect(() => {
    if (
      courseSingleInfo?.id &&
      courseSingleInfo?.modules?.length === paymentInfo?.paidModulesCount
    ) {
      setIsSubmitting(true);
    }
  }, [courseSingleInfo]);

  const handleIframeModalClose = () => {
    setIsOpenIframeModal(false);
    setIsOpenPaymentModal(false);
    dispatch(AllCoursesActions.setPurchasingModule(search?.buy));
    window.location.reload();
  };

  const handleBlurPromoCode = event => {
    if (event.target.value.length <= 3) {
      form.setErrors({ ...form.errors, promoCode: PROMOCODE_ERROR });
    }
  };

  const handleChangePromoCode = e => {
    form.setFieldValue('promoCode', e.target.value.toUpperCase());
    form.setErrors({ ...form.errors, promoCode: '' });
  };

  useEffect(() => {
    if (selectedModulesCount > 0) {
      setIsDisabledPromoCode(false);
    } else {
      setIsDisabledPromoCode(true);
    }
  }, [selectedModulesCount]);

  const promoCodeSubmit = () => {
    const data = {
      info: {
        courseId: courseSingleInfo.id,
        serialNumber: form.values.promoCode.toUpperCase()
      },
      callback: res => {
        if (res.success) {
          setPromoCodeInfo(res?.data);
          dispatch(commonActions.setShowAlert(true));
          setAlertMessage('Muvaffaqiyatli aktivlashtirildi');
          setAlertMessageDesc(
            'Kurs uchun promocode muvaffaqiyatli aktivlashtirildi'
          );
          setIsPromoCodeCollapsed(true);
          setAlertStatus(res.success);
        } else {
          addNotification({
            title: 'Noto‘g‘ri promocode',
            description: 'Promokod noto‘g‘ri yoki amal qilish muddati tugagan',
            options: {
              autoClose: true,
              type: 'error'
            }
          });
        }
      }
    };
    dispatch(AllCoursesActions.fetchPromoCode(data));
  };

  useEffect(() => {
    if (courseSingleInfo.id) {
      dispatch(AllCoursesActions.fetchCourseDiscount(courseSingleInfo?.id));
      dispatch(AllCoursesActions.fetchCoursePaymentInfo(courseSingleInfo?.id));
    }
  }, [courseSingleInfo.id]);

  const getPriceCount = useCallback(() => {
    return formatNumber(
      courseDiscount
        ? discountSaleModulePrice + promoCodeSaleTotalPrice
        : promoCodeInfo?.amount > 0
        ? promoCodeSaleTotalPrice
        : bulkTotalDiscount > 0
        ? bulkTotalDiscount
        : calculatedPrice
    );
  }, [
    courseDiscount,
    discountSaleModulePrice,
    promoCodeInfo?.amount,
    promoCodeSaleTotalPrice,
    bulkTotalDiscount,
    calculatedPrice
  ]);

  useEffect(() => {
    const price = getPriceCount();
    if (parseInt(price?.replace(/\s+/g, '')) <= 1000 && selected?.length) {
      setIsFree(false);
    } else {
      setIsFree(true);
    }
  }, [getPriceCount, selected.length]);

  return {
    alertMessage,
    alertMessageDesc,
    alertStatus,
    paymentSrcLink,
    handleModuleSelect,
    enroll,
    handleChangePayment,
    modalRef,
    preferredPaymentType,
    formRefType,
    formRefClick,
    paymentTypes,
    form,
    privacyPolicyLink,
    handleIframeModalClose,
    handleChangePromoCode,
    theme,
    calculatedPrice,
    courseSingleInfo,
    selectedModules,
    totalPrice,
    selectedModulesCount,
    isModulesBoxCollapsed,
    isPromoCodeCollapsed,
    purchasedModulesCount,
    collapseModulesContainer,
    collapsePromoCodeClick,
    noModuleSelected,
    isSubmitting,
    mqMaxWidth450,
    mqWrap508,
    isDisabled,
    isOpenIframeModal,
    promoCodeSubmit,
    handleBlurPromoCode,
    courseDiscount,
    promoCodeInfo,
    promoCodeSalePrice,
    promoCodeSaleTotalPrice,
    discountTotalPrice,
    discountSaleModulePrice,
    isDisabledPromoCode,
    discountAdditionalCourse,
    bulkDiscount,
    bulkTotalDiscount,
    mqMaxWidth552,
    getPriceCount,
    isFree,
    paymentInfo
  };
};
