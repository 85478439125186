import { FlexWrapper } from '@components/layouts/flex-wrapper';
import styled from 'styled-components';

export const TextareaContainer = styled.div(
  () => `
    border: 1px solid #dadada;
    border-radius: 8px;
   `,
);

export const TimeWrapper = styled.div(
  () => `
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-right: 2px solid #eae9e7;
   `,

  ({ theme: { down } }) => `
   ${down('600px')} {
    padding: 12px;
   }
   `
);

export const Time = styled.button(
  () => `
    background: #eae9e7;
    border-radius: 100px;
    border: none;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.35);

    &.editable {
      background: rgba(186, 141, 91, 0.1);
      color: #ba8d5b;
    }
   `,

  ({ theme: { down } }) => `
   ${down('600px')} {
    font-size: 12px;
   }
   `
);

export const TextareaFieldCore = styled.textarea`
  width: ${props => props.width || '100%'};
`;

export const TextareaField = styled(TextareaFieldCore)(
  () => `
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    border: none;
    resize: none;
    outline: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: rgba(0, 0, 0, 0.35);
    }
   `,
);

export const TextAreaWrapper = styled(FlexWrapper)`
  width: 100%;
  padding: 16px 24px;
  padding-bottom: 10px;
`;

export const SendBtn = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  align-self: flex-end;
  background-color: transparent;
  margin-bottom: 9px;
  padding: 0;
    svg {
     cursor: pointer;
    }
`;
