import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';
import IconBookmark from '@assets/img/icon-bookmark.svg';
import IconBookmarked from '@assets/img/icon-bookmarked.svg';

export const LeftSide = styled(Scrollable)(
  ({ sidebarState }) => `
    width: 80%;
    height: 100%;
    overflow-y: auto;
    padding-right: 14px;

    @media (max-width: 1175px) {
      width: ${sidebarState ? '100%' : '80%'};
    }
   `,

  ({ theme: { down } }) => `
    ${down('1015px')} {
      padding-right: 24px;
      width: 100%;
    }
    ${down('576px')} {
      padding-bottom: 50px;
      padding-right: 16px;
    }
  `
);

export const TabletChapters = styled.div(
  () => `
        padding: 16px 21px 16px 16px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        -webkit-transition: background .1s linear;
        -moz-transition: background .1s linear;
        -ms-transition: background .1s linear;
        -o-transition: background .1s linear;
        transition: background .1s linear;
    `,

  ({ theme: { down } }) => `
      ${down('1034px')} {
        padding: 16px;
      }
      
     @media only screen and (max-width: 390px) {
      padding: 16px 11px 16px 16px;
     }
    `

);

export const TableChaptersTitle = styled.h4(
  () => `
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
   `
);

export const TabletChapterImg = styled.img`
  transform: ${props => (props.rotate ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: all ease 0.8s;
`;

export const TabletChaptersTop = styled(FlexWrapper)(
  () => `
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      padding-bottom: 16px;
      border-bottom: 1px solid #dadada;
      `
);

export const TabletChaptersBottom = styled.div(
  () => `
        padding: 16px 0;
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        -ms-transition: max-height 0.8s;
        -o-transition: max-height 0.8s;
        transition: max-height 0.8s;
        max-height: 0;
        overflow: hidden;

        &.show {
          max-height: 1000vh;
        }

        &.hide {
          max-height: 0;
          overflow: hidden;
          margin: 0;
          padding: 0;
        }
    `,

  ({ theme: { down } }) => `
        ${down('1034px')} {
            padding: 0;
        }
    `
);

export const Wrapper = styled.div(
  () => `
        background: #ffffff;
        border-radius: 4px;
        padding: 24px;

        &:first-child {
        margin-bottom: 24px;
        }
    `,

  ({ theme: { down } }) => `
        ${down('1033px')} {
            padding: 24px 12px;
        }
    `
);

export const LessonTitleWrapper = styled(FlexWrapper)`
  margin-bottom: ${props => (props.isRated ? '0px' : '25px')};
`;

export const LessonWrapperTitle = styled.div(
  () => `
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #4E6575;
    display: flex;
    gap: 16px;
    align-items: center;
  `
);
export const LessonWrapperTitleSkeleton = styled.div(
  () => `
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #4E6575;
    display: flex;
    gap: 16px;
    align-items: center;
    width: 90%
  `
);

export const BookmarkButton = styled.button`
  display: block;
  width: 48px;
  height: 48px;
  padding: 8px;
  background-color: #f1f0ee;
  border-radius: 3px;
  border: 0;
  background-image: url(${props => props.isBookmarked ? IconBookmarked : IconBookmark});
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: center;

  &:disabled {
    opacity: 0.5;
  }
`;

export const LessonNumber = styled.span`
  white-space: nowrap;
`;

export const RightSide = styled.div(
  ({ sidebarState }) => `
    width: 381px;
    padding: 14px 6px 16px 24px;
    border-radius: 4px;
    overflow: hidden;
    background: #ffffff;

    @media (max-width: 1175px) {
      width: ${sidebarState ? '100%' : '381px'};
      padding: ${sidebarState ? '0' : '14px 6px 16px 24px'};
    }
    `,

  ({ theme: { down } }) => `
    ${down('1015px')} {
      width: 100%;
      &.large {
        width: 100%;
      }
    }

    ${down('1015px')} {
      width: 100%;
      padding: 0;
    }
  `
);

export const RightSideInner = styled(Scrollable)(
  () => `
    max-height: calc(100vh - 190px);
    padding-right: 20px;
    min-height: 100%;
    height: 100%;
    overflow: auto;
   `,

  ({ theme: { down } }) => `
   ${down('1015px')} {
        max-height: initial;
        padding-right: 0;
    }
   `
);

export const ActiveLessonTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.0001em;
  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 95%;
`;

export const ActiveLessonPosition = styled.h4``;

export const LessonTitleWrap = styled(FlexWrapper)(
  () => `
  background-color: #fff;
  border-radius: 4px;
  padding: 14px 16px;
  margin-top: 24px;
`
);

export const RateButtonWrap = styled.div`
  margin-left: auto;
`;

export const Button = styled.button`
  display: block;
  text-align: center;
  background: #ba8d5b;
  border-radius: 100px;
  padding: 12px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff;
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;
