import styled from 'styled-components';

export const CourseLessonItem = styled.li`
  &:last-child {
    h4:hover + span {
      top: -50px;
      bottom: auto;
      display: block;
    }
  }
`;
export const CourseLessonItemLink = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 8px;
  /* font-size: 14px; */
  /* line-height: 18px; */
  /* color: rgba(0, 0, 0, 0.6); */
  text-decoration: none;
  cursor: pointer;
`;
export const CourseLessonItemLinkIcon = styled.span`
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: ${props =>
    !((props.isViewing && props.isViewed) || !props.isEmpty) && '3px solid rgba(0, 0, 0, 0.1)'};
  background-color: ${props => !props.isEmpty ? '#BA8D5B' : ''};
  border-radius: 50%;
`;
export const CourseLessonItemLinkContent = styled.div(
  ()=>`
    margin-left: 16px;
  `,
  ({theme: {down}}) =>`
    ${down('1034px')}{
      .course-lesson-item{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-right: 24px;
      }
    }
  `
);
export const CourseLessonItemLinkLessonCount = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #4e6575;
  /* color: ${props =>
    props.inProgress ? '#BA8D5B' : 'rgba(0, 0, 0, 0.35)'}; */
`;
export const CourseLessonItemLinkLessonHeading = styled.h4`
  /* font-weight: 600; */
  /* color: ${props =>
    props.inProgress ? '#000000' : 'rgba(0, 0, 0, 0.6)'}; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CourseLessonDuration = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 15px;
  position: relative;

  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #4e6575;
    position: absolute;
    left: 6px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  span {
    color: #ba8d5b;
  }
`;

export const CoursesTitleWrapper = styled.div`
  position: relative;
  margin-right: 14px;

  h4:hover + span {
    display: block;
  }

  span {
    background: #313131;
    border-radius: 8px;
    padding: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0001em;
    color: #ffffff;
    position: absolute;
    height: auto;
    width: max-content;
    z-index: 2;
    display: inline-block;
    display: none;
    bottom: -65px;
    left: 0;
  }
`;

export const CourseItemLinkWrapper = styled.div`
position: relative;
 & .play {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   margin: auto;
   }
`
