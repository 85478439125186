import { FlexWrapper } from '@components/layouts/flex-wrapper'
import { Skeleton } from '@components/skeleton/skeleton-box'
import { MyCourseCardLeftWrapper, MyCourseCardRightWrapper, MyCourseCardWrapper } from '@modules/all-courses/components/my-course-card/my-course-card.style'
import { useMediaQuery } from '@utils/useMediaQuery'
import React from 'react'

export default function MyCoursesSkeleton() {
  const mq1070MaxWidth = useMediaQuery("(max-width: 1070px)")
  const mq885MaxWidth = useMediaQuery("(max-width: 885px)")
  const mq825MaxWidth = useMediaQuery("(max-width: 825px)")
  const mq440MaxWidth = useMediaQuery("(max-width: 440px)")
  return (
    <MyCourseCardWrapper to={`/my-courses`}>
      <MyCourseCardLeftWrapper
        direction={mq440MaxWidth ? 'column' : 'row'}
        gap={mq885MaxWidth ? '15px' : '24px'}
      >
        <Skeleton width={160} height={135} />
        <FlexWrapper direction='column'>
          <Skeleton width={225} height={32} marginBottom={24} />
          <Skeleton width={225} height={12} marginBottom={4} />
          <Skeleton width={225} height={12} />
        </FlexWrapper>
      </MyCourseCardLeftWrapper>

      <MyCourseCardRightWrapper wrap='wrap' justify='space-between'>
        <FlexWrapper>
          <FlexWrapper mr={mq885MaxWidth ? '25px' : '35px'}>
            <FlexWrapper direction='column'>
              <Skeleton width={130} height={20} marginBottom={7}/>
              <Skeleton width={130} height={25}/>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper mr={mq1070MaxWidth ? '0' : '60px'}>
            <FlexWrapper direction='column'>
              <Skeleton width={130} height={20} marginBottom={7}/>
              <Skeleton width={130} height={25}/>
            </FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>
        {(!mq1070MaxWidth || mq825MaxWidth) && <Skeleton width={140} height={50} borderRadius={32} />}
      </MyCourseCardRightWrapper>
    </MyCourseCardWrapper>
  )
}
