import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {ratingActions} from "@modules/rating/store/rating.slice";

const allOption = {
  label: 'Barcha kurslar',
  value: 0
}

export const useRatingTopProps = () => {
  const dispatch = useDispatch()
  const filterObjects = useSelector(state =>  state.rating?.ratingFilter)
  const allCourses = useSelector(state => state.rating?.courses)

  const handleChange = (value) => {
    const info = {
      ...filterObjects,
      courseId: value || '',
      page: 1
    }

    dispatch(ratingActions.setRatingFilter(info))
  }

const courses = useMemo(() => {
    const fetchedCourses =  allCourses?.map(item => {
      return {
        label: item?.name,
        value: item?.id,
        isPaid: item?.isPaid
      }
    })

  if(!fetchedCourses?.length) {
    return [allOption]
  }

  return [allOption, ...fetchedCourses]
}, [allCourses])

  useEffect(() => {
    dispatch(ratingActions.fetchCourses());
  }, []);

  return {
    courses,
    handleChange
  }
}
