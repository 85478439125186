import { storageService } from '@services/storage.service'
import { useSelector } from 'react-redux'

/**
 * <ProtectedRoute /> props
 */
export const useProtectedRouteProps = () => {
  const token = storageService.getItem('accessToken')
  const profile = JSON.parse(storageService.getItem('studentInfo'))
  const isLoggedIn = useSelector((state) => state.common.session)

  return {
    profile,
    token,
    isLoggedIn,
  }
}
