import { hoc } from '@utils/hoc';
import { useMyRatingsProps } from './my-ratings.prop';
import {
  CourseTitle,
  MyRatingWrapperTitle,
  MyRatingsAccordionWrapper,
  MyRatingsWrapper, NoData, AllScore, LoaderWrapper, NoDataTitle
} from './my-ratings.style';
import { Accordion } from './components/accordion';
import { Table } from './components/table';
import {LoaderComponent} from "@modules/my-ratings/components/loader/loader.component";

export const MyRatings = hoc(
  useMyRatingsProps,
  ({ homeworkColumns, videoColumns, myRatings, loader, myCourses }) => {
    return (
      <MyRatingsWrapper>
        {
          !loader && myCourses?.length === 0 ? (
            <NoDataTitle>
              Sizda birorta ham kurs mavjud emas
            </NoDataTitle>
          ) : (
            <>
              {
                loader ? (
                  <LoaderWrapper>
                    <LoaderComponent />
                  </LoaderWrapper>
                ) : myRatings?.courses?.length ? (
                  <>
                    <MyRatingWrapperTitle>Jami ballarim: {myRatings?.totalPoints || 0}</MyRatingWrapperTitle>
                    {
                      myRatings?.courses?.map(item => (
                        <MyRatingsAccordionWrapper key={item.courseName}>
                          <CourseTitle>{item?.courseName}</CourseTitle>
                          <Accordion header="Vazifalar bo'yicha ballarim">
                            {
                              item?.awardsForHomework?.modules?.map(module => (
                                <Accordion key={module.id} header={`${module?.position}-module ${module?.title}`}>
                                  {!module?.homework?.length ? (
                                    <NoData>Ma'lumot mavjud emas</NoData>
                                  ) : (
                                    <Table columns={homeworkColumns} data={module?.homework} />
                                  )}

                                </Accordion>
                              ))
                            }

                            {item?.awardsForHomework?.modules?.length && (
                              <AllScore>Vazifalar bo'yicha jami ballar: {item?.awardsForHomework?.totalPoints || 0}</AllScore>
                            )}
                          </Accordion>

                          <Accordion header="Video darslar bo'yicha ballarim">
                            {
                              item?.awardsForVideos?.modules?.map(module => (
                                <Accordion key={module.id} header={`${module?.position}-module ${module?.title}`}>
                                  {
                                    module.chapters?.map(chapter => (
                                      <Accordion key={chapter.id} header={`${chapter?.position}-mavzu ${chapter?.name}`}>
                                        {
                                          !chapter?.lessons.length ? (
                                            <NoData>Ma'lumot mavjud emas</NoData>
                                          ) : (
                                            <Table columns={videoColumns} data={chapter?.lessons} />
                                          )
                                        }
                                      </Accordion>
                                    ))
                                  }
                                </Accordion>
                              ))
                            }

                            {item?.awardsForHomework?.modules?.length && (
                              <AllScore>Videodarslar bo'yicha jami ballar: {item?.awardsForVideos?.totalPoints || 0}</AllScore>
                            )}
                          </Accordion>
                        </MyRatingsAccordionWrapper>
                      ))
                    }
                  </>
                ) : <NoData>Ma'lumot mavjud emas</NoData>
              }
            </>
          )
        }
      </MyRatingsWrapper>
    );
  }
);
