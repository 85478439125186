import styled from 'styled-components';

export const CourseHomeworkWrapper = styled.div(
  ({ theme: { down } }) => `
    background: #F9F4EF;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;

    ${down('768px')} {
      padding: 16px;

      & > .homework-files {
        gap: 16px;
      }

    }
`
);

export const HomeworkTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const HomeworkCount = styled.h4(
  ({ theme: { down } }) => `
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #BA8D5B;

    ${down('576px')} {
      font-size: 12px;
    }
`
);

export const HomeworkText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 24px;
`;

export const HomeworkBottom = styled.div`
  padding-top: 23px;
  border-top: 1px solid #dadada;
`;

export const HomeworkAcceptedMessage = styled.h2`
  color: #ba8d5b;
  font-size: 22px;
`;
