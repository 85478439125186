import { certificateActions } from '@modules/certificates/store';
import { useMediaQuery } from '@utils/useMediaQuery';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useMoreInfoProps = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.certificates.loading);
  const { id } = useParams();
  const mq650MaxWidth = useMediaQuery('(max-width: 650px)');

  useEffect(() => {
    if (id) {
      dispatch(certificateActions.fetchCertificateDetail(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    loading,
    mq650MaxWidth
  };
};
