import styled from 'styled-components';

export const StyledBottomNavCore = styled.div(
  () => `
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;
    width: 100%;

    ul {
      display: flex;
    }
  `,

  ({ theme: { down, up } }) => `
    ${down('576px')} {
      ul {
        justify-content: space-between;
        align-items: center;
        padding-right: 17px;
      }
    }

    ${up('576px')} {
      display: none;
    }

    ${down('400px')} {
      ul {
        padding-right: 20px;
      }
    }
  `
);

export const ImgWrapper = styled.div`
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBottomNav = styled(StyledBottomNavCore)`
  background: ${props => props.theme.colors.white};
`;

export const AdditionalWrapper = styled.div`
  position: absolute;
  z-index: 2;
  right: 3px;
  top: -350px;
  width: 222px;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .menu-title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #000000;
  }

  .title-exit {
    color: #f07a7a;
  }

  &:before {
    content: '';
    border-style: solid;
    border-width: 15px 20px 15px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    bottom: -21px;
    transform: rotate(270deg);
    right: 40px;
    backdrop-filter: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
`;

export const WrapperTop = styled.div`
  padding: 15px 16px;
  border-bottom: 1px solid #dadada;
`;

export const WrapperTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const WrapperSubtitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
`;

export const ModalButton = styled.button`
  padding: 6px 12px;
  border: 1px solid ${props => (props.danger ? 'transparent' : '#3D444F')};
  border-radius: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  background-color: ${props => (props.danger ? '#FF5454' : '#fff')};
  cursor: pointer;
  color: ${props => (props.danger ? '#fff' : '#0D1523')};
  outline: none;
  margin-left: ${props => (props.danger ? '10px' : '0')};
`;

export const ModalTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  max-width: 272px;
  margin: 0 auto;
  margin-bottom: 32px;
`;

export const Button = styled.button`
  background: ${props => (props.danger ? '#EA4335' : '#FFFFFF')};
  border: ${props =>
    props.danger ? '1px solid transparent' : '1px solid #DADADA'};
  border-radius: 100px;
  outline: none;
  width: 179px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${props => (props.danger ? '#fff' : '#000000')};
  padding: 12px 0;

  &:first-child {
    margin-right: 16px;
  }
`;
