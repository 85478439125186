import { addNotification } from '@components/notifications';
import { AllCoursesActions } from '@modules/all-courses/store';
import { authActions } from '@modules/auth/store';
import { ENTER_SMS_CODE, LOGIN_TYPES } from '@modules/new-auth/constants';
import { settingsActions } from '@modules/settings/store';
import { storageService } from '@services/storage.service';
import { commonActions } from '@store/common';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

const initialValues = {
  code: ''
};

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, ENTER_SMS_CODE)
    .max(6, ENTER_SMS_CODE)
    .required(ENTER_SMS_CODE)
});

export const useConfirmCodeProp = () => {
  const dispatch = useDispatch();
  const confirmModalState = useSelector(
    state => state.courses.confirmCodeModal
  );
  const confirmPhone = useSelector(state => state.courses.confirmCodeValues);
  const [modal, setModal] = useState(false);
  const [retry, setRetry] = useState(false);
  const [timeIndex, setTimeIndex] = useState(0);
  const [time, setTime] = useState(Date.now());
  const counterRef = useRef();
  const studentInfo = useSelector(state => state.courses?.studentInfo);

  const handleClose = () => {
    setModal(false);
    dispatch(AllCoursesActions.setConfirmModal(false));
  };

  const handleChangePhoneNumber = () => {
    dispatch(AllCoursesActions.setUpdateInfoModal(true));
    handleClose();
  };

  const handleSubmit = values => {
    const info = {
      data: {
        newLogin: confirmPhone?.phoneNumber,
        code: values.code,
        loginType: LOGIN_TYPES.phone
      },
      callback: res => {
        if (res.success) {
          handleClose();
          addNotification({
            title: 'Muvaffaqiyatli kiritildi',
            description:
              'Telefon raqamingiz va shaxsiy ma’lumotlaringiz muvaffaqiyatli ro’yxatga olindi!',
            options: {
              autoClose: true,
              type: 'success'
            }
          });

          storageService.setItem(
            'studentInfo',
            JSON.stringify({
              ...studentInfo,
              phoneNumber: confirmPhone?.phoneNumber
            })
          );
          dispatch(
            commonActions.fetchProfileFulfilled({
              ...studentInfo,
              phoneNumber: confirmPhone?.phoneNumber
            })
          );
          dispatch(AllCoursesActions.setUpdateInfoModal(false));
        }

        if (res.error) {
          formik.setFieldError('code', res?.error?.errMsg);
        }
      }
    };
    dispatch(settingsActions.changeLogin(info));
  };

  const handleSendOtp = () => {
    if (!retry) return;
    const info = {
      data: {
        login: confirmPhone?.phoneNumber,
        actionType: 'RECOVERY_PASSWORD',
        loginType: LOGIN_TYPES.email
      },
      callback: res => {
        if (res.success) {
          setTime(Date.now() + (res?.data?.leftTime || 0));
          setTimeIndex(timeIndex + 1);
        }
      }
    };

    dispatch(authActions.verifyPhoneNumber(info));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  const handleChangeLoginCode = e => {
    formik.setFieldValue('code', e.target.value);
    formik.setErrors({ ...formik.errors, code: null });
  };

  const handleBlurLoginCode = e => {
    if (formik.values.code.length !== 6) {
      formik.setErrors({ ...formik.errors, code: ENTER_SMS_CODE });
    }
  };

  useEffect(() => {
    setModal(confirmModalState);
  }, [confirmModalState]);

  useEffect(() => {
    if (confirmPhone.leftTime) {
      setTime(Date.now() + confirmPhone.leftTime || 0);
      setTimeIndex(prev => prev + 1);
    }
  }, [confirmPhone.leftTime]);

  return {
    confirmModalState,
    modal,
    setModal,
    handleClose,
    confirmPhone,
    setRetry,
    timeIndex,
    retry,
    handleChangeLoginCode,
    handleBlurLoginCode,
    formik,
    time,
    handleSendOtp,
    counterRef,
    handleChangePhoneNumber
  };
};
