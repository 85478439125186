import styled from 'styled-components';
import AuthIllustration from '@assets/img/auth-illustration.png';
import { Link } from 'react-router-dom';
import { Scrollable } from '@styles/scroll.style';
import EditOutlined from '@assets/img/edit-outlined.svg';
import RetryCode from '@assets/img/retry-code.svg';

export const LoginWrapper = styled(Scrollable)(
  () => `
  height: 100vh;
  display: flex;
  box-sizing: border-box;

  .wrapper {
    border: 2px solid #dadada;
    border-radius: 100px;
    overflow: hidden;

    &.input-error {
      box-shadow: 0 0 1px 1px #e84949;
    }

    input {
      border: none;
      outline: none;
    }
  }
  `
);

export const LoginLeftSide = styled.div(
  () => `
    width: 50vw;
    height: 100%;
    background-color: #f8f7ef;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 370px 424px contain;
    background-image: url(${AuthIllustration});
  `,

  ({ theme: { down } }) => `
  ${down('886px')} {
    display: none;
  }
  `
);

export const Form = styled.form`
  position: relative;
`;

export const InputLabels = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
  display: inline-block;
  margin-bottom: 12px;
`;

export const FormGroupCore = styled.div(
  () => `
    .wrapper {
      border-radius: 100px;
      overflow: hidden;
      border: 2px solid #dadada;

      &.focused {
        border: 2px solid #000;
      }

      &.error {
        box-shadow: 0 0 1px 1px #e84949;
      }
    }

    .password-input {
      border: none;
      outline: none;
    }
  `,

  ({ theme: { down } }) => `
  .password-input {
    ${down('768px')} {
      padding: 11px 15px 15px 16px;
    }
  }
  `
);

export const FormGroup = styled(FormGroupCore)`
  margin-bottom: ${props => (props?.isRecovery ? '32px' : props?.isError ? '11px' : props?.formAd ? '28px' : props?.mb ? '26px' : '0')};
  position: relative;

  .input-error {
    box-shadow: 0 0 1px 1px #e84949;
  }

  &.special-code {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(218, 218, 218, 0.4);
  }
`;

export const Button = styled.button`
  width: ${prop => (prop.min ? '50%' : '100%')};
  display: block;
  text-align: center;
  background: #ba8d5b;
  border-radius: 100px;
  padding: 12px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: ${props => (props.max ? '0 auto' : 'initial')};
  margin-bottom: ${props => (props.mb ? '24px' : '0')};
  border: 1px solid transparent;
  cursor: pointer;
  max-width: ${props => (props.max ? '308px' : 'initial')};

  &:hover {
    background-color: #fff;
    border: 1px solid #ba8d5b;
    color: #000;

    span {
      color: #000;
    }
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    display: inline-block;
    margin-left: 16px;
    transform: translateY(0);
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const ForgetPassword = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #2697ff;
  margin-top: 26px;
  margin-bottom: 24px;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`;

export const EyeImg = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;

export const EyeButton = styled.button`
  background-color: transparent;

  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

export const HaveProfile = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #81755c;
`;

export const ChangePage = styled(Link)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #ba8d5b;
`;

export const InputError = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #ea4335;
  position: absolute;
  left: 18px;
  bottom: ${props => (props.bottom === 'initial' ? 'initial' : props.mb ? '3px' : '-20px')};
  display: inline-block;
`;

export const InputLongError = styled.span`
  position: relative;
  top: 3px;
  left: 18px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #ea4335;
`

export const LoginRecoveryNumberBtn = styled.button`
  background: #f8f7ef;
  border-radius: 24px;
  padding: 4px 38px 4px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #81755c;
  border: none;
  outline: none;
  position: relative;
  margin-left: 20px;
  margin-right: 10px;

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${EditOutlined});
  }
`;

export const LoginSubtitleRecoverPassword = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #81755c;
  margin-top: 20px;
  margin-bottom: 24px;
`;

export const LoginTimer = styled.div`
  background: #f1f0ee;
  border-radius: 20px;
  padding: 6px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #81755c;
  margin-right: 8px;

  &.retry {
    padding: 6px 8px 8px 28px;
    position: relative;
    width: 175px;
    cursor: pointer;

    &::before {
      content: '';
      width: 11px;
      height: 11px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 11px;
      margin: auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(${RetryCode});
    }
  }
`;

export const PasswordChecker = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 12;
  padding: 12px;
  box-sizing: border-box;
  bottom: -41px;
`;

export const CheckerImg = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

export const CheckerTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const ErrorModalTitle = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  max-width: 374px;
  margin-bottom: 16px;
`;

export const ErrorModalSubtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
  font-family: 'Lato';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
`;
