import styled from "styled-components";
import AccordionToggleDown from '@assets/img/accordion-toggle-down.svg';
import AccordionToggleDownSmall from '@assets/img/accordion-toggle-down-small.svg';
import BlockTheme from '@assets/img/lock-circle.png';

export const CourseFaqTitle = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 12px;
`

export const CourseAccordionCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 0px 15px 15px 15px;
  cursor: pointer;
  user-select: none;
`;

export const CourseAccordionCardList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 16px;

  li {
      border-bottom: 1px solid black;
      margin-bottom: 16px;
      padding-bottom: 10px;
  }
`;

export const CourseAccordionCardListItem = styled.li`
  position: relative;
  z-index: 1;
  transition: all ease 0.3s;

  &.accordion-card__item--open {
    padding-top: 8px;
    margin-bottom: 16px;
    & .accordion-card__item-content--faq {
      display: block;
    }

    & .accordion-card__item-content-wrapper {
      display: block;
    }

    .btn--large {
      transform-origin: center;
      transform: rotate(180deg);
    }
  }

  .btn--small {
    transform-origin: center;
    transform: rotate(180deg);
  }
`;

export const CourseAccordionListItemToggle = styled.button`
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  &.large {
    width: ${props => (props.small ? '26px' : props.block ? '26px' : '32px')};
    height: ${props => (props.small ? '26px' : props.block ? '26px' : '32px')};
    background-image: url(${props => props.small ? AccordionToggleDownSmall : props.block ? BlockTheme : AccordionToggleDown});
    background-size: contain;
    margin: ${props => (props.block ? '0 6px 0 3px' : '0 3px')};
  }
`;

export const AccordionCardWrapper = styled.div`
  display: none;

  &.accordion-card__item--open {
    display: block;
  }
`;

export const AccordionThemeCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;
`;

export const ModuleWrapper = styled.div`
  margin-left: 10px;
`;

export const CourseLessonFaqDescription = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -.02em;
    color: #4e6575;
`
