import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';

export const UnModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0.2, 0.3);
  overflow: hidden;
  overflow-y: auto;
  height: ${props => (props.isOpen ? '100vh' : '0')};
  width: ${props => (props.isOpen ? '100%' : '0')};
  z-index: ${props => (props.isOpen ? '99' : '0')};
`;

export const UnModalInner = styled(Scrollable)`
  background: #ffffff;
  border-radius: 16px;
  padding: 32px 32px 24px 32px;
  min-width: ${props => props.modalWidth || '438px'};
  max-width: 448px;
  overflow: auto;

  @media only screen and (max-width: 580px) {
    min-width: 90%;
    max-width: 90%;
  }

  @media only screen and (max-height: 770px) {
    max-height: ${props => (props.profile ? '600px' : 'auto')};
  }

  @media only screen and (max-width: 460px) {
    padding: 20px;
  }

  .close-img {
    cursor: pointer;
  }

  .back-img {
    cursor: pointer;
    margin-right: 12px;
  }
`;

export const UnModalHead = styled.div`
  display: flex;
  align-items: center;
`;

export const UnModalTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const UnModalBottom = styled.div``;
