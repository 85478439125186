import { CAPTCHA_ACTIONS } from '@constants/app'
import { authActions } from '@modules/auth/store'
import {
  LOGIN_TYPES,
  RECOVERY_PASSWORD,
  REQUIRED_PHONE_NUMBER,
  MINIO_UPLOAD_ERROR,
  UNKNOWN_ERROR,
} from '@modules/new-auth/constants'
import { api } from '@services/base.service'
import { useFormik } from 'formik'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

const initialValues = {
  phone: '',
}

const validNumberLength = 9

const validationSchema = Yup.object().shape({
  phone: Yup.string().min(9, REQUIRED_PHONE_NUMBER).max(9, REQUIRED_PHONE_NUMBER).required(REQUIRED_PHONE_NUMBER),
})

export const useInitialFormProp = ({ setInitialForm, setPhoneNumber, setTime, setTimeIndex }) => {
  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async (values) => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.sendOtp)

      api.setHeader('recaptcha', token)

      const verifyPhone = {
        data: {
          login: `998${values.phone}`,
          actionType: RECOVERY_PASSWORD,
          loginType: LOGIN_TYPES.phone,
        },
        callback: (res) => {
          setPhoneNumber(`998${values?.phone}`)
          const resErrId = res?.error?.errId
          if (res.success) {
            setInitialForm(false)
            setTime(Date.now() + (res?.data?.leftTime || 0))
            setTimeIndex((prev) => prev + 1)
            api.setHeader('recaptcha', '')
            return
          }

          if (resErrId === UNKNOWN_ERROR) {
            dispatch(authActions.setForgetPhoneModal(false))
            dispatch(authActions.setBlockModal(true))
          }

          if (resErrId === MINIO_UPLOAD_ERROR) {
            setInitialForm(false)
            return
          }
          if (resErrId) {
            initialFormik.setErrors({ phone: res?.error?.errMsg })
            dispatch(authActions.setShowCaptchaError(true))
          }
        },
      }

      await dispatch(authActions.forgetPasswordPhone(verifyPhone))
    } catch (error) {
      console.error('initial-form', error)
    }
  }

  const initialFormik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const handleBlurPhone = () => {
    const phoneLength = initialFormik.values.phone.length
    if (phoneLength < validNumberLength) {
      initialFormik.setErrors({ phone: REQUIRED_PHONE_NUMBER })
    }
  }

  const handleChangePhoneInput = (e) => {
    initialFormik.setErrors({ phone: '', password: '' })
    initialFormik.setFieldValue('phone', e.target.value.trim().replace(/ /g, ''))
  }

  return {
    initialFormik,
    handleBlurPhone,
    handleChangePhoneInput,
  }
}
