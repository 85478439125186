import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SubHeaderWrapperCore = styled.div(
  () => `
    background-color: #fff;
    padding: 13px 0 16px 0;
    display: flex;
    width: 100%;
    align-items: center;
    margin-left: auto;
    transition: width 0.2s linear;
  `,
  ({ theme: { down } }) => `

  ${down('768px')} {
    width: calc(100% - 71px);
    padding-left: 2.2rem !important;
      }
    }
    ${down('576px')} {
    width: 100% !important;
    padding-left: 1.4rem !important;
    }
`
);

export const SubHeaderWrapper = styled(SubHeaderWrapperCore)(
  ({ state }) => `
  margin-left: ${state ? '224px' : '70px'};
  transition: margin-left 0.4s linear;

  svg {
    fill: #ba8d5b;
    path {
      fill: #fff;
      stroke: #ba8d5b;
    }
  }

  .back {
    border-radius: 50%;
    background: rgba(186, 141, 91, 0.1);
    width: 24px;
    height: 24px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    padding-right: 1.5px;
    box-sizing: border-box;

    &:active {
      background: #ba8d5b;

      svg {
        fill: #fff;

        path {
          stroke: #fff;
          fill: #ba8d5b;
        }
      }
    }
  }
`,
  ({ theme: { down } }) => `
    ${down('768px')} {
        margin-left: 71px;
    }
  `,
  ({ theme: { down } }) => `
    ${down('576px')} {
        margin-left: 0;
    }
  `
);

export const SubHeaderTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #000000;
  padding-left: ${props => (props?.isHasButton ? '0px' : '48px')};
`;

export const StyledLink = styled(Link)(
  () => `
    margin-left: 24px;
  `,
  ({ theme: { down } }) => `
    ${down('769px')} {
      margin-left: 0;
    }
  `
);
