import { useSelector } from 'react-redux';
import { CourseLesson } from './course-lessons-item/course-lessons-item.component';
import {
  CourseLessonHeader,
  CourseLessonHeaderDuration,
  CourseLessonHeaderTitle,
  CourseLessonsWrapper,
  CourseTitleWrapper
} from './course-lessons.style';

export const CourseLessons = () => {
  const singleModule = useSelector(state => state.courses.singleModule);

  return (
    <CourseLessonsWrapper>
      <CourseLessonHeader>
        <CourseTitleWrapper>
          <CourseLessonHeaderTitle>
            {singleModule?.title || 'kurs nomi topilmadi'}
          </CourseLessonHeaderTitle>
        </CourseTitleWrapper>
        <CourseLessonHeaderDuration>
          Mavzular soni: {singleModule?.chaptersCount || 0} ta
        </CourseLessonHeaderDuration>
      </CourseLessonHeader>
      {singleModule?.chapters?.map((item, idx) => (
        <CourseLesson item={item} index={idx} key={idx} />
      ))}
    </CourseLessonsWrapper>
  );
};
