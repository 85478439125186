export const perPageOptions = [
  {
    label: 'Sahifada / 10 ta',
    value: 10
  },
  {
    label: 'Sahifada / 20 ta',
    value: 20
  },
  {
    label: 'Sahifada / 50 ta',
    value: 50
  },
  {
    label: 'Sahifada / 100 ta',
    value: 100
  },
]
