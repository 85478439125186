import { api } from '@services/base.service';

export class PaymentsService {
  constructor() {
    this.api = api;
  }

  fetchPayments = () => this.api.get('/student/my-courses');
}

export const paymentsService = new PaymentsService();
