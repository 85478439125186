import { CourseTitle } from '@modules/certificates/routes/more-info/more-info.style';
import { hoc } from '@utils/hoc';
import { useHomeworkProp } from './homework.prop';
import Tick from '@assets/img/tick.svg';
import Fail from '@assets/img/fail.svg';
import * as DOMPurify from 'dompurify';
import {
  HomeworkWrapper,
  ResultImg,
  ResultWrapper,
  Table,
  TableBody,
  TableContent,
  TableContentInnerTop,
  TableDirection,
  TableHead,
  TableHeadTitle,
  TableRow,
  TableWrapper
} from './homework.style';
import { RouteLinks } from 'src/constants/route';
import { acceptedHomeworkStatus } from '@modules/certificates/constants';

export const HomeworkTable = hoc(
  useHomeworkProp,
  ({ certificateDetail, handleClickLink }) => {
    return (
      <HomeworkWrapper>
        <CourseTitle>Asosiy vazifalar natijalari</CourseTitle>

        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableDirection head align='center'>
                  <TableHeadTitle>Modul nomi</TableHeadTitle>
                </TableDirection>

                <TableDirection head align='center'>
                  <TableHeadTitle>Vazifa nomi</TableHeadTitle>
                </TableDirection>

                <TableDirection head align='center'>
                  <TableHeadTitle>Natija</TableHeadTitle>
                </TableDirection>
              </TableRow>
            </TableHead>

            <TableBody>
              {certificateDetail?.modules?.map((item, index) =>
                item?.homeworks?.map((homework, index) => (
                  <TableRow key={homework?.id}>
                    {index < 1 && (
                      <TableDirection rowSpan={item.homeworks?.length}>
                        <TableContent>{item?.title}</TableContent>
                      </TableDirection>
                    )}
                    <TableDirection>
                      <TableContent>
                        <TableContentInnerTop>{homework?.chapterPosition}-mavzu: </TableContentInnerTop>
                        {homework?.lessonPosition && (
                            <TableContentInnerTop>{homework.lessonPosition}-dars:</TableContentInnerTop>
                        )}
                        <p
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homework?.desc) }}
                        />
                      </TableContent>
                    </TableDirection>
                    <TableDirection>
                      <ResultWrapper
                        onClick={handleClickLink?.bind(null, homework)}
                        to={`${RouteLinks.homeworkDetail?.replace(
                          ':id',
                          homework?.id
                        )}}${homework?.lessonId ? `?lessonId=${homework?.lessonId}` : ''}`}
                      >
                        <ResultImg
                          alt='result'
                          src={
                            homework?.status === acceptedHomeworkStatus
                              ? Tick
                              : Fail
                          }
                        />
                        Havola
                      </ResultWrapper>
                    </TableDirection>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </HomeworkWrapper>
    );
  }
);
