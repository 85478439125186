import { FlexWrapper } from '@components/layouts/flex-wrapper'
import { Skeleton } from '@components/skeleton/skeleton-box'
import { AllCoursesCategoriesWrapper, AllCoursesSkeletonWrapper } from '@modules/all-courses/routes/all-courses/all-courses.style'
import { CourseSelectWrapper, LastVideoInfoWrapper, LastVideoTitleWrapper, LastVideoWrapper } from '@modules/dashboard/components/last-video/last-video.style'
import { useMediaQuery } from '@utils/useMediaQuery'
import React from 'react'
import { DashboardRecommendedCourseWrapper, FlexWrapLastVideo, Wrapper } from './dashboard.style'

export default function LastSeenVideoSkeleton() {
  const mq645MaxWidth = useMediaQuery('(max-width: 645px)');
  const mq1000MaxWidth = useMediaQuery('(max-width: 1000px)');
  return (
    <Wrapper>
      <LastVideoTitleWrapper>
        <Skeleton width={160} height={26} marginBottom={15} />

        <CourseSelectWrapper>
          <Skeleton width={200} height={26} marginRight={10} />
          <Skeleton width={200} height={26} />
        </CourseSelectWrapper>
      </LastVideoTitleWrapper>
      <FlexWrapLastVideo>
        <FlexWrapper direction={mq645MaxWidth ? 'column' : 'row'}>
          <LastVideoWrapper
            mqMaxWidth={mq1000MaxWidth}
          >
            <Skeleton width={210} height={125} />
          </LastVideoWrapper>

          <LastVideoInfoWrapper
            mqMaxWidth={mq1000MaxWidth}
          >
            <FlexWrapper
              className={'last-video-info'}
              align='flex-start'
              direction='column'
            >
              <Skeleton width={300} height={28} marginBottom={16} />
              <Skeleton width={300} height={20} marginBottom={8} />
              <Skeleton width={300} height={20} marginBottom={8} />
            </FlexWrapper>
          </LastVideoInfoWrapper>
        </FlexWrapper>
        <Skeleton width={200} height={20} marginTop={16} />
        <Skeleton fullWidth={100} height={12} marginTop={4} />
      </FlexWrapLastVideo>
    </Wrapper>
  )
}

export function LastSeenRecoSkeleton() {
  return <DashboardRecommendedCourseWrapper>
    <AllCoursesSkeletonWrapper>
      <Skeleton height={180} marginBottom={12}
        fullWidth={100} />
      <AllCoursesCategoriesWrapper>
        <Skeleton width={130} height={28} />
        <Skeleton width={130} height={28} />
      </AllCoursesCategoriesWrapper>
      <Skeleton fullWidth={100} height={28} />
    </AllCoursesSkeletonWrapper>
    <AllCoursesSkeletonWrapper>
      <Skeleton height={180} marginBottom={12}
        fullWidth={100} />
      <AllCoursesCategoriesWrapper>
        <Skeleton width={130} height={28} />
        <Skeleton width={130} height={28} />
      </AllCoursesCategoriesWrapper>
      <Skeleton fullWidth={100} height={28} />
    </AllCoursesSkeletonWrapper>
    <AllCoursesSkeletonWrapper>
      <Skeleton height={180} marginBottom={12}
        fullWidth={100} />
      <AllCoursesCategoriesWrapper>
        <Skeleton width={130} height={28} />
        <Skeleton width={130} height={28} />
      </AllCoursesCategoriesWrapper>
      <Skeleton fullWidth={100} height={28} />
    </AllCoursesSkeletonWrapper>
    <AllCoursesSkeletonWrapper>
      <Skeleton height={180} marginBottom={12}
        fullWidth={100} />
      <AllCoursesCategoriesWrapper>
        <Skeleton width={130} height={28} />
        <Skeleton width={130} height={28} />
      </AllCoursesCategoriesWrapper>
      <Skeleton fullWidth={100} height={28} />
    </AllCoursesSkeletonWrapper>
  </DashboardRecommendedCourseWrapper>
}
