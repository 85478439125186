import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';

export const RightSide = styled(Scrollable)(
  () => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 100%;
    padding: 64px 0 52px 0;
    overflow-y: auto;
    box-sizing: border-box;
  `,

  ({ theme: { down } }) => `
        ${down('886px')} {
          width: 100%;
        }
        
        ${down('420px')} {
          max-width: 100%;
          padding: 40px 10px;
        }
    
       
      `
);
