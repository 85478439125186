/* eslint-disable no-restricted-globals */
import { authActions } from '@modules/auth/store';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { storageService } from '@services/storage.service';
import { commonActions } from '@store/common';
import { addNotification } from '@components/notifications';
import { useMemo } from 'react';

export const useRegisterWithGoogleProp = () => {
  const dispatch = useDispatch();

  const search = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const redirect = () => {
    const redirectInside = search.get('redirect_inside');
    if (redirectInside) {
      history.push(redirectInside);
    }
  };

  const handleClickRegister = useGoogleLogin({
    onSuccess: tokenResponse => {
      const googleObj = {
        data: {
          token: tokenResponse.access_token
        },
        callback: res => {
          const resData = res?.data;
          if (res.success) {
            storageService.setAccessToken(resData?.accessToken);
            storageService.setRefreshToken(resData?.refreshToken);
            storageService.setItem(
              'studentInfo',
              JSON.stringify(resData?.studentInfo)
            );
            dispatch(commonActions.fetchProfileFulfilled(resData?.studentInfo));
            redirect();
            return;
          }

          if (res.error) {
            addNotification({
              title: res?.error?.errMsg,
              options: {
                autoClose: true,
                type: 'error'
              }
            });
          }
        }
      };
      dispatch(authActions.signUpWithGoogle(googleObj));
    },
    onError: error => console.error(error)
  });

  return {
    handleClickRegister
  };
};
