import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  height: calc(100vh - 160px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ErrorImage = styled.img`
  width: 431.7px;
  height: 244.6px;
  margin-bottom: 117px;
`;

export const ErrorTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 32px;
`;

export const ErrorBtn = styled.button`
  border: none;
  outline: none;
  background: #ba8d5b;
  border-radius: 100px;
  width: 213px;
  padding: 13px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  border: 1px solid transparent;
  transition: all linear 0.3s;

  &:hover {
    border: 1px solid #ba8d5b;
    color: #000000;
    background-color: #fff;
  }

  &:active {
    background: #A77946;
    color: #fff;
  }
`;
