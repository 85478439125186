import { AllCoursesActions } from '@all-courses/store';
import { storageService } from '@services/storage.service';
import { useMediaQuery } from '@utils/useMediaQuery';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {useError} from "react-use";

export const useArchivedModuleProp = () => {
  const [clicked, setClicked] = useState(false);
  const [smallClicked, setSmallClicked] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const mqMaxWidth900 = useMediaQuery('(max-width: 900px)');
  const mqMaxWidth520 = useMediaQuery('(max-width: 520px)');
  const userInfo = JSON.parse(storageService.getItem('studentInfo'));
  const singleCourseInfo = useSelector(state => state.courses.singleCourse);

  const selectedModule = useMemo(() => {
    return parseInt(location?.search.split('=')?.at(-1))
  }, [location]);

  useEffect(() => {
    if(selectedModule) {
      setClicked(selectedModule)
      const info = {
        id: selectedModule,
        callback: res => {
          const updatedCourseModules = singleCourseInfo.modules.map(module => {
            if(module.id === selectedModule) {
              return {
                ...module,
                chapters: res?.data
              }
            }

            return module
          })

          const updatedCourses = {
            ...singleCourseInfo,
            modules: updatedCourseModules
          }

          dispatch(AllCoursesActions.setSingleCourse(updatedCourses))
        }
      }
      dispatch(AllCoursesActions.fetchModuleChapters(info))
    }
  }, [location]);

  const openModalForUnpaidModules = (moduleInfo, event) => {
    event.stopPropagation();

    if (moduleInfo.item.hasFreeLesson) {
      toggleSmall(moduleInfo.index);
      return;
    }

    if (
      (!userInfo?.phoneNumber || !userInfo?.lastName) &&
      moduleInfo?.item?.isPaid
    ) {
      dispatch(AllCoursesActions.setUpdateInfoModal(true));
    }

    if (moduleInfo.item?.isPaid) {
      toggleSmall(moduleInfo.index);
    } else {
      toggleSmall(null);
      history.push(location.pathname + '?buy=' + moduleInfo.item.id);
    }
  };

  const handleClickChapterTitle = ({ item, index, url }) => {
    if (!item?.isPaid) {
      if (clicked === index) {
        return setClicked(null);
      }
      setClicked(index);
      return;
    }
    const chapter = item.chapters?.[0];

    const info = {
      chapter,
      lessons: chapter?.lessons?.[0]
    };

    dispatch(AllCoursesActions.changeActiveCourse(info));
    history.push(url);
  };

  const handleOpenCourseFreeModal = (data, e) => {
    e.stopPropagation();
    setClicked(clicked);
    setSmallClicked(smallClicked);
    dispatch(AllCoursesActions.openFreeCourseModal(true));
    dispatch(AllCoursesActions.getFreeVideo(data));
  };

  const handlePaidModuleClick = item => {
    if (!userInfo?.phoneNumber || !userInfo?.lastName) {
      dispatch(AllCoursesActions.setUpdateInfoModal(true));
      return;
    }
      history.push(location.pathname + '?buy=' + item.id);
  };

  const handleClick = (les, url, chapter, item, event) => {
    event.preventDefault();
    event.stopPropagation();
    if ((item?.isPaid && userInfo?.phoneNumber) || userInfo?.lastName) {
      const obj = {
        chapter,
        lesson: les
      };

      dispatch(AllCoursesActions.changeActiveCourse(obj));
      history.push(`/course-single/${id}/module/${url}`);
    } else {
      history.push(location.pathname + '?buy=' + item.id);
    }
  };

  const handlePaidChaptersClick = (item, chapter, index, event) => {
    event.preventDefault();
    event.stopPropagation();
    if ((!userInfo?.phoneNumber || !userInfo?.lastName) && item?.isPaid) {
      dispatch(AllCoursesActions.setUpdateInfoModal(true));
      return;
    }

    if (chapter?.hasFreeLesson || item.isPaid) {
      toggleSmall(index)
    } else {
      history.push(location.pathname + '?buy=' + item.id);
    }
  };

  const toggle = (item, index, event) => {
    event.stopPropagation();

    if(item?.id)  {
      const info = {
        id: item.id,
        callback: res => {

          const updatedCourseModules = singleCourseInfo.modules.map(module => {
            if(module.id === item.id) {
              return {
                ...module,
                chapters: res?.data
              }
            }

            return module
          })

          const updatedCourses = {
            ...singleCourseInfo,
            modules: updatedCourseModules
          }

          dispatch(AllCoursesActions.setSingleCourse(updatedCourses))
        }
      }
      dispatch(AllCoursesActions.fetchModuleChapters(info))
    }

    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  const toggleSmall = index => {
    if (smallClicked === index) {
      return setSmallClicked(null);
    }
    setSmallClicked(index);
  };

  return {
    toggle,
    clicked,
    smallClicked,
    openModalForUnpaidModules,
    handleClick,
    toggleSmall,
    mqMaxWidth900,
    mqMaxWidth520,
    handlePaidModuleClick,
    handlePaidChaptersClick,
    handleClickChapterTitle,
    handleOpenCourseFreeModal,
    selectedModule
  };
};
