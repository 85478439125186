//messages
const PASSWORD = 'password';
const TEXT = 'text';
const PROBLEM_WITH_PN_PW = 'Telefon raqam yoki parolda xatolik bor';
const BLOCKED_STUDENT = 'Bu foydalanuvchi bloklangan';
const REQUIRED_PHONE_NUMBER = 'Telefon raqamingizni kiriting';
const PROBLEM_WITH_EM_PW = 'Elektron pochta yoki parolda xatolik bor';
const EMAIL_REQUIRED = 'Emailingizni kiriting';
const REQUIRED_PASSWORD = 'Parolingizni kiriting';
const PASSWORD_REQUIREMENT =
  "Parol 8 tadan 16 tagacha belgili va kamida 1 ta raqamga ega bo'lishi kerak";
const ENTER_SMS_CODE = 'Maxsus kodni kiriting';
const PASSWORD_NOT_MATCH = 'Parol mos emas';
const GENDER_REQUIRED = 'Jinsingizni tanlang';
const VERIFY_PHONE = 'VERIFY_LOGIN';
const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';
const EMAIL_DOESNT_MATCH = 'Elektron pochtada @ belgisi qatnashishi kerak';
const PROBLEM_WITH_EMAIL = 'Elektron pochtada xatolik bor';
const MIN_NAME = "Ism kamida 3 ta harfdan iboarat bo'lishi kerak";
const MIN_LASTNAME = 'Familiya 3 ta harfdan kam bo‘lmasligi kerak';
const NAME_REQUIRED = 'Ismingizni kiriting';
const PHONE_REQUIRED = 'Telefon raqamingizni kiriting';
const LASTNAME_REQUIRED = 'Familyangizni kiritishingiz shart';
const BIRTH_DATE = 'Tug‘ilgan sanangizni kiriting';
const PHONE_REQUIREMENT = 'Telefon raqamda muammo bor';
const CHANGE_LOGIN = 'CHANGE_LOGIN';
const clientId =
  '581838564571-n5l67o7o72n4egc89buvjpp4knf3ap1q.apps.googleusercontent.com';
const ANOTHER_EMAIL = 172;
const invalid = 'invalid';
const SESSION_TERMINATED = 'session-terminated';
const LoginEmail = '/auth/register/by-email';

//regex
const passwordRgp = /^(?=.*\d).{8,16}$/;
const lengthChecker = /^.{8,16}$/;
const numberReg = /\d/;
const isValidEmail = /@/;
const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const newPasswordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^()-_=+])[A-Za-z\d@$!%*?&^()-_=+]{8,16}$/;
const atLeastOneUppercaseReg =  /[A-Z]/;
const atLeastOneLowercaseReg =  /[a-z]/;
const atLeastOneDigitReg =  /^(?=.*?[0-9])$/;
const combinedRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[A-Za-z\d'!@#$%^&*()_+{}|:;<>,.?~\\[\]\-=/`]+$/;

const alphaNumericLettersReg = /[^a-zA-Z0-9']/g;
const inputTelReg = /^\+998(-)?( )?/;
const revoveryTelReg = /^(?=.*\d).{0,9}$/;

const passwordRequirementsRegs = [
  lengthChecker,
  numberReg,
];

//types
const LOGIN_TYPES = {
  phone: 'phone',
  email: 'email'
};

//contsants
const SPAM_DETECTED = 185
const INVALID_TOKEN = 104
const LOGIN_ALREADY_EXISTS = 110
const MINIO_UPLOAD_ERROR = 125
const UNKNOWN_ERROR = 157

const INVALID_CREDENTIALS = 158

const EMAIL_ALREADY_EXISTS = 172

const NUMBER_ALREADY_EXISTS = 110

const WRONG_OTP_CODE = 108

const PASSWORD_LONG_ERROR_MIN_LENGTH = 77

export {
  PASSWORD,
  TEXT,
  PROBLEM_WITH_PN_PW,
  REQUIRED_PHONE_NUMBER,
  PROBLEM_WITH_EM_PW,
  EMAIL_REQUIRED,
  LOGIN_TYPES,
  ENTER_SMS_CODE,
  REQUIRED_PASSWORD,
  PASSWORD_REQUIREMENT,
  passwordRgp,
  lengthChecker,
  numberReg,
  PASSWORD_NOT_MATCH,
  VERIFY_PHONE,
  RECOVERY_PASSWORD,
  isValidEmail,
  EMAIL_DOESNT_MATCH,
  PROBLEM_WITH_EMAIL,
  emailReg,
  NAME_REQUIRED,
  MIN_NAME,
  PHONE_REQUIRED,
  clientId,
  LASTNAME_REQUIRED,
  MIN_LASTNAME,
  GENDER_REQUIRED,
  BIRTH_DATE,
  PHONE_REQUIREMENT,
  CHANGE_LOGIN,
  ANOTHER_EMAIL,
  BLOCKED_STUDENT,
  alphaNumericLettersReg,
  invalid,
  inputTelReg,
  SESSION_TERMINATED,
  LoginEmail,
  SPAM_DETECTED,
  INVALID_TOKEN,
  LOGIN_ALREADY_EXISTS,
  MINIO_UPLOAD_ERROR,
  UNKNOWN_ERROR,
  INVALID_CREDENTIALS,
  EMAIL_ALREADY_EXISTS,
  NUMBER_ALREADY_EXISTS,
  WRONG_OTP_CODE,
  newPasswordReg,
  atLeastOneDigitReg,
  atLeastOneLowercaseReg,
  combinedRegex,
  atLeastOneUppercaseReg,
  PASSWORD_LONG_ERROR_MIN_LENGTH,
  revoveryTelReg,
  passwordRequirementsRegs
};
