import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { MoreInfoRight } from '@modules/certificates/routes/more-info/more-info.style';
import { hoc } from '@utils/hoc';
import { useUserInfoProp } from './user-info.prop';
import { UserImg, UserName } from './user-info.style';
import { config } from '@config/config';

export const UserInfo = hoc(useUserInfoProp, ({ certificateDetail }) => {
  return (
    <MoreInfoRight>
      <FlexWrapper
        direction='column'
        align='center'
        justify='center'
        gap='16px'
      >
        <UserImg
          alt='user-img'
          src={`${config?.imgApiUrl}/${certificateDetail?.photo}`}
        />

        <UserName>{`${certificateDetail?.firstName} ${certificateDetail?.lastName}`}</UserName>
      </FlexWrapper>
    </MoreInfoRight>
  );
});
