import { hoc } from '@utils/hoc';
import {
  HeaderLeft,
  StyledHeader,
  StyledInnerHeader,
  BurgerBtn,
  StyledLink,
  HeaderRight,
  StyledImg
} from './header.style';
import MainLogo from '@assets/img/edfix-logo.svg';
import { NavUser } from './nav-user';
import { SubHeader } from '../sub-header';
import { useHeaderProps } from './header.prop';
import { ReactComponent as IconBurger } from '@assets/img/icon-burger.svg';

export const Header = hoc(
  useHeaderProps,
  ({ handleClickBurgerBtn, btnRef, isHasSubheader }) => {
    return (
      <StyledHeader>
        <StyledInnerHeader>
          <HeaderLeft>
            <BurgerBtn ref={btnRef} onClick={handleClickBurgerBtn}>
              <IconBurger />
            </BurgerBtn>

            <StyledLink to='/'>
              <StyledImg alt='logo' src={MainLogo} width={136} height={30} />
            </StyledLink>
          </HeaderLeft>

          <HeaderRight>
            <NavUser />
          </HeaderRight>
        </StyledInnerHeader>
        {isHasSubheader && <SubHeader />}
      </StyledHeader>
    );
  }
);
