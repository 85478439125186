import styled from 'styled-components';

export const CancelAppealConfirmText = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 14px;
  margin-bottom: 24px;
`;
