import { authActions } from '@modules/auth/store'
import { ENTER_SMS_CODE, SPAM_DETECTED, LOGIN_TYPES, WRONG_OTP_CODE } from '@modules/new-auth/constants'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

const initialValues = {
  code: '',
}

const validationSchema = Yup.object().shape({
  code: Yup.string().min(6, ENTER_SMS_CODE).max(6, ENTER_SMS_CODE),
})

const MANY_REQUESTS = 157
const VERIFICATION_CODE_LENGTH = 6

export const useConfirmCodeProp = () => {
  const dispatch = useDispatch()
  const confirmModalState = useSelector((state) => state.auth?.confirmEmailModal)
  const [modal, setModal] = useState(false)
  const [retry, setRetry] = useState(false)
  const [timeIndex, setTimeIndex] = useState(0)
  const [time, setTime] = useState(Date.now())
  const counterRef = useRef()
  const confirmPhone = useSelector((state) => state.auth.confirmCodeValues)
  const isCaptchaError = useSelector((state) => state.auth?.isShowCaptchaError)

  const handleClose = () => {
    setModal(false)
    dispatch(authActions.setConfirmEmailModal(false))
  }

  const handleSubmit = (values) => {
    try {
      if (!values?.code) {
        formik.setErrors({ code: ENTER_SMS_CODE })
      }

      const info = {
        data: {
          login: confirmPhone?.email,
          code: values.code,
          loginType: LOGIN_TYPES.email,
        },
        callback: (res) => {
          if (res.success) {
            dispatch(authActions.setSuccessRegester(true))
            dispatch(authActions.setSuccessModal(true))
            handleClose()
          }

          if (res?.error?.errId === MANY_REQUESTS) {
            dispatch(authActions.setEmailNotificationModal(true))
            dispatch(authActions.setConfirmEmailModal(false))
          }

          if (res.error) {
            formik.setFieldError('code', res?.error?.errMsg)
          }
        },
      }
      dispatch(authActions.verifyPhoneNumber(info))
    } catch (error) {
      console.error('SubmitEmail:', error)
    }
  }

  const handleSendOtp = () => {
    try {
      if (!retry) return
      const info = {
        data: {
          login: confirmPhone?.email,
          actionType: 'VERIFY_LOGIN',
          loginType: LOGIN_TYPES.email,
        },
        callback: (res) => {
          if (res.success) {
            setTime(Date.now() + (res?.data?.leftTime || 0))
            setTimeIndex(timeIndex + 1)
            formik.setFieldError('code', res?.error?.errMsg)
          }
        },
      }

      dispatch(authActions.forgetPasswordPhone(info))
    } catch (error) {
      console.error('SendOTP:', error)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  })

  const handleChangeLoginCode = (e) => {
    formik.setFieldValue('code', e.target.value)
    formik.setErrors({ ...formik.errors, code: null })
  }

  const handleBlurLoginCode = (e) => {
    if (formik.values.code.length !== 6) {
      formik.setErrors({ ...formik.errors, code: ENTER_SMS_CODE })
    }
  }

  useEffect(() => {
    setModal(confirmModalState)
  }, [confirmModalState])

  useEffect(() => {
    if (confirmPhone.leftTime) {
      setTime(Date.now() + confirmPhone.leftTime || 0)
      setTimeIndex((prev) => prev + 1)
    }
  }, [confirmPhone.leftTime])

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()

        formik.handleSubmit()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  return {
    confirmModalState,
    modal,
    setModal,
    handleClose,
    confirmPhone,
    setRetry,
    timeIndex,
    retry,
    handleChangeLoginCode,
    handleBlurLoginCode,
    formik,
    time,
    handleSendOtp,
    counterRef,
    isCaptchaError,
  }
}
