import { createSlice } from '@reduxjs/toolkit';

const name = 'certificates';

const initialState = {
  loading: false,
  error: null,
  certificates: null,
  certificate: null,
  certificateDetail: null,
  homeworks: null
};

export const { actions: certificateActions, reducer: certificateReducer } =
  createSlice({
    name,
    initialState,
    reducers: {
      setLoading(state, action) {
        state.loading = action.payload;
      },
      setError(state, action) {
        state.error = action.payload;
      },
      fetchCertificates(state) {
        state.loading = true;
      },
      fetchCertificatesFulfilled(state, action) {
        state.certificates = action.payload;
      },
      fetchSingleCertificate(state) {
        state.loading = true;
      },
      fetchSingleCertificateFulfilled(state, action) {
        state.certificate = action.payload;
      },
      fetchCertificateDetail(state) {
        state.loading = true;
      },
      fetchCertificateDetailFulfilled(state, action) {
        state.certificateDetail = action.payload;
      },
      fetchHomeworks(state) {
        state.loading = true;
      },
      fetchHomeworksFulfilled(state, action) {
        state.homeworks = action.payload;
      },
      fetchHomeworksByLessonId(state) {
        state.loading = true
      },
      fetchHomeworksByLessonIdFulfilled(state, action) {
        state.homeworks = action.payload
      }
    }
  });
