import { authActions } from '@modules/auth/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useForgetPhoneProp = () => {
  const forgetPhoneModal = useSelector(state => state.auth?.forgetPhoneModal);
  const [forgetPhone, setForgetPhone] = useState(false);
  const dispatch = useDispatch();
  const [initialForm, setInitialForm] = useState(true);
  const [timeIndex, setTimeIndex] = useState(0);
  const [time, setTime] = useState(Date.now());
  const [phoneNumber, setPhoneNumber] = useState();

  const handleClose = () => {
    setForgetPhone(false);
    dispatch(authActions.setForgetPhoneModal(false));
  };

  useEffect(() => {
    setForgetPhone(forgetPhoneModal);
  }, [forgetPhoneModal]);

  return {
    forgetPhone,
    setForgetPhone,
    handleClose,
    initialForm,
    setInitialForm,
    setPhoneNumber,
    phoneNumber,
    setTime,
    setTimeIndex,
    timeIndex,
    time
  };
};
