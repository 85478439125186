import { hoc } from "@utils/hoc";
import {RatingTable, TableLoader, TableWrapper} from "./rating-table.style";
import './rating-table.css'
import {CoursesModal} from "@modules/rating/components/courses-modal";
import {useRatingTableProp} from "@modules/rating/components/table/rating-table.prop";
import {AnimationLoader} from "@modules/rating/components/animation/animation.component";
import ReactPaginate from "react-paginate";
import {FlexWrapper} from "@components/layouts/flex-wrapper";
import { ReactComponent as LeftPagination } from '@assets/img/left-pagination.svg';
import { ReactComponent as RightPagination } from '@assets/img/right-pagination.svg';
import {DropDownComponent} from "@components/drop-down";
import {perPageOptions} from "@modules/rating/constants";

export const StudentsRatingTable = hoc(useRatingTableProp, ({filterObjects, loading, conditionalRowStyles, perPage, handlePerPageChange, courses, ranks,handleOrderChange, onChangePage, isOpen, setIsOpen, bodyRef, columns}) => {
    return (
        <>
          <TableWrapper>
            {
              loading && (
                <TableLoader >
                  <AnimationLoader />
                </TableLoader>
              )
            }
            <RatingTable
              pagination={false}
              paginationServer
              defaultSortAsc
              defaultSortFieldId={'asc'}
              conditionalRowStyles={conditionalRowStyles}
              direction='auto'
              paginationTotalRows={ranks?.total}
              columns={columns}
              onSort={handleOrderChange}
              fixedHeader
              sortServer
              data={ranks?.downRank?.studentId ? [...ranks?.upRanks, ranks?.downRank] : ranks?.upRanks}
            />

            {
              !!ranks?.total && (
                <FlexWrapper align="center" ref={bodyRef} mt='34px' gap="25px"  justify='flex-end'>
                  <ReactPaginate
                    className='pagination'
                    breakLabel='...'
                    nextLabel={<RightPagination />}
                    pageRangeDisplayed={2}
                    onPageChange={onChangePage}
                    previousLabel={<LeftPagination />}
                    forcePage={filterObjects?.page - 1 || 0}
                    renderOnZeroPageCount={null}
                    pageCount={Math.ceil(ranks?.total / perPage)}
                    marginPagesDisplayed={2}
                  />

                  <DropDownComponent options={perPageOptions} onChange={handlePerPageChange} activeValue={{label: `Sahifada / ${perPage || 10} ta`, value: perPage}} perPage />
                </FlexWrapper>
              )
            }
          </TableWrapper>

          <CoursesModal courses={courses} open={isOpen} setModal={setIsOpen}/>
        </>
    )
})
