/* eslint-disable no-restricted-globals */
import { CAPTCHA_ACTIONS } from '@constants/app'
import { authActions } from '@modules/auth/store'
import {
  LOGIN_TYPES,
  PASSWORD,
  PROBLEM_WITH_PN_PW,
  BLOCKED_STUDENT,
  REQUIRED_PHONE_NUMBER,
  TEXT,
  passwordRgp,
  REQUIRED_PASSWORD,
} from '@modules/new-auth/constants'
import { api } from '@services/base.service'
import { storageService } from '@services/storage.service'
import { commonActions } from '@store/common'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useMemo, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

const initialValues = {
  phone: '',
  password: '',
}

const validationSchema = Yup.object().shape({
  phone: Yup.string().min(9, PROBLEM_WITH_PN_PW).max(12, PROBLEM_WITH_PN_PW),
  password: Yup.string(),
})

export const usePhoneFormProp = () => {
  const [inputType, setInputType] = useState('password')
  const dispatch = useDispatch()
  const history = useHistory()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const initialPhone = useSelector((state) => state.auth.initialPhone)
  const initialPassword = useSelector((state) => state.auth.initialPassword)
  const successRegister = useSelector((state) => state.auth.successRegister)
  const isForgetPhoneModal = useSelector((state) => state.auth?.forgetPhoneModal)

  const search = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const redirect = () => {
    const redirectInside = search.get('redirect_inside')
    if (redirectInside) {
      history.push(redirectInside)
    }
  }

  const handleChangePasswordInput = () => {
    setInputType(inputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleFinishLogin = async (res) => {
    try {
      const resData = res?.data

      if (res.success) {
        storageService.setAccessToken(resData?.accessToken)
        storageService.setRefreshToken(resData?.refreshToken)
        storageService.setItem('studentInfo', JSON.stringify(resData?.studentInfo))
        dispatch(commonActions.fetchProfileFulfilled(resData?.studentInfo))
        api.setHeader('recaptcha', '')
        await redirect()
      } else {
        if (res.error?.errId === 149) {
          formik.setErrors({
            phone: BLOCKED_STUDENT,
            password: BLOCKED_STUDENT,
          })
          return
        }
        formik.setErrors({
          phone: PROBLEM_WITH_PN_PW,
          password: PROBLEM_WITH_PN_PW,
        })
      }
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  const handleSubmit = async (values) => {
    try {
      if (!(values.phone && values.password)) {
        formik.setErrors({
          phone: REQUIRED_PHONE_NUMBER,
          password: REQUIRED_PASSWORD,
        })
      }

      const token = await executeRecaptcha(CAPTCHA_ACTIONS.singIn)

      api.setHeader('recaptcha', token)

      const loginObj = {
        data: {
          login: `998${values.phone.replace(/[\s-]/g, '')}`,
          password: values.password,
          loginType: LOGIN_TYPES.phone,
        },
        callback: handleFinishLogin,
      }
      dispatch(authActions.login(loginObj))
    } catch (error) {
      console.error('Login phoneForm:', error)
    }
  }

  const handleOpenForgetPhoneModal = () => {
    dispatch(authActions.setForgetPhoneModal(true))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const handleInputChange = (e) => {
    if (e.target.name === 'phone') {
      formik.setFieldValue('phone', e.target.value?.trim().replace(/ /g, ''))
    }
    formik.setErrors({ phone: '', password: '' })
    formik.handleChange(e)
  }

  const handleChangePhoneInput = (e) => {
    formik.setFieldValue('phone', e.target.value.trim().replace(/ /g, ''))

    formik.setErrors({ ...formik.errors, phone: '' })
  }

  const handleBlurPhone = () => {
    const phoneLength = formik.values.phone.length
    if (phoneLength < 9) {
      formik.setErrors({ phone: REQUIRED_PHONE_NUMBER })
    }
  }

  const handlePastePhoneInput = (e) => {
    const phoneNumber = e.clipboardData.getData('Text').replace(/\D/gi, '').substr(-9)

    if (phoneNumber.length !== 9) {
      return
    }

    formik.setFieldValue('phone', phoneNumber)
  }

  const handleInputPasswordChange = (e) => {
    const passwordValue = e.target.value
    formik.handleChange(e)

    formik.setErrors({ ...formik.errors, password: '' })

    if (passwordValue.match(passwordRgp)) {
      formik.setErrors({ ...formik.errors, password: '' })
    }
  }

  const handleInputPasswordBlur = () => {
    if (!formik.values.password) {
      formik.setErrors({ password: REQUIRED_PASSWORD })
    }
  }

  useEffect(() => {
    if (successRegister) {
      formik.setValues({
        phone: initialPhone,
        password: initialPassword,
      })
    }
  }, [initialPhone, initialPassword])

  useEffect(
    () => () => {
      dispatch(authActions.setSuccessRegester(false))
    },
    [],
  )

  return {
    inputType,
    handleChangePasswordInput,
    formik,
    handleInputChange,
    handleBlurPhone,
    handleChangePhoneInput,
    handleOpenForgetPhoneModal,
    handleInputPasswordChange,
    handlePastePhoneInput,
    isForgetPhoneModal,
    handleInputPasswordBlur,
  }
}
