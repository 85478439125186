import { config } from '@config/config';
import { hoc } from '@utils/hoc';
import { useBlogCardProp } from './blog-card.prop';
import {
  BlogCardImg,
  BlogCardMore,
  BlogCardTheme,
  BlogCardTitle,
  BlogCardWrapper
} from './blog-card.style';
import { NoImage } from '@components/no-image';

export const BlogCard = hoc(useBlogCardProp, ({ blog, handleViewBlog }) => {
  return (
    <BlogCardWrapper>
      <BlogCardTitle>Tavsiya etamiz:</BlogCardTitle>

      {blog?.image
      ? (
        <BlogCardImg src={config.imgApiUrl + '/' + blog?.image} />
      ) : (
        <NoImage width={231} height={164} />
      )}

      <BlogCardTheme>{blog?.title}</BlogCardTheme>

      <BlogCardMore onClick={handleViewBlog.bind(null, blog?.id)}>
        Batafsil
      </BlogCardMore>
    </BlogCardWrapper>
  );
});
