import fetchIntercept from 'fetch-intercept';
import { storageService } from '@services/storage.service';
import { store } from '@store/index';
import { commonActions } from '@store/common';
import { authService } from '@auth/services/auth.service';
import { ResponseErrorCodes } from '@constants/response';
import { RouteLinks } from '@constants/route';
import { SESSION_TERMINATED } from '@modules/new-auth/constants';

fetchIntercept.register({
  request: function (url, config) {
    const refreshToken = storageService.getRefreshToken();
    const accessToken = storageService.getAccessToken();

    if (url.includes('/student/refresh-token') && refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    } else if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return [url, config];
  },

  response: async res => {
    if (res.status === 401) {
      const response = await res.json();

      if (response.error.errId === ResponseErrorCodes.TOKEN_EXPIRED) {
        /**
         * {
         *   "data": {
         *     "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjM5NTYsInVzZXJUeXBlIjo0LCJyb2xlSWQiOjIsInN0dWRlbnRJZCI6ImUyZjdkZThlLWEyM2QtNDAxNC05ZDg2LWRlYzQ2ZmZhN2VmNSIsImVtYWlsIjoiYWhyaWtyaUBnbWFpbC5jb20iLCJsb2dpbiI6bnVsbCwiaWF0IjoxNjg5NzQ5Njg1LCJleHAiOjE2ODk3NDk3MTV9.wyrMw-wxFS6LXq6y8WIk6AD7HMAPWtfmhueqCvk9TNc"
         *   },
         *   "error": null,
         *   "success": true
         * }
         * **/
        authService.refreshToken().then(res => {
          if (res.success) {
            storageService.setAccessToken(res.data.accessToken);

            window?.location?.reload();
          }
        });

        return;
      }

      storageService.removeRefreshToken();
      storageService.removeAccessToken();
      storageService.removeItem('studentInfo');

      store.dispatch(commonActions.fetchProfileFulfilled({}));
    }

    if (res.status === ResponseErrorCodes.SESSION_ENDED) {
      storageService.removeRefreshToken();
      storageService.removeAccessToken();
      storageService.removeItem('studentInfo');

      store.dispatch(commonActions.fetchProfileFulfilled({}));
      window.location = `${RouteLinks.auth.login}?${SESSION_TERMINATED}=true`;
    }

    return res;
  },

  responseError: function (error) {
    return Promise.resolve(error);
  }
});
