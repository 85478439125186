import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';

export const Wrapper = styled(Scrollable)(
  () => `
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 14px;
`
);

export const WrapperInner = styled.div(
  () => `
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;
    
    &:last-child {
      margin-top: 6px;
    }
  `,

  ({ theme: { down } }) => `
  ${down('768px')} {
    padding: 20px;
  }

    ${down('576px')} {
      padding: 16px;
    }
  `
);
