/* eslint-disable no-restricted-globals */
import { CAPTCHA_ACTIONS } from '@constants/app'
import { authActions } from '@modules/auth/store'
import {
  EMAIL_REQUIRED,
  LOGIN_TYPES,
  PASSWORD,
  PROBLEM_WITH_EM_PW,
  TEXT,
  passwordRgp,
  REQUIRED_PASSWORD,
  PROBLEM_WITH_PN_PW,
} from '@modules/new-auth/constants'
import { api } from '@services/base.service'
import { storageService } from '@services/storage.service'
import { commonActions } from '@store/common'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useMemo, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

const initialValues = {
  email: '',
  password: '',
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email(PROBLEM_WITH_EM_PW).required(EMAIL_REQUIRED),
  password: Yup.string().max(16, PROBLEM_WITH_PN_PW).required(REQUIRED_PASSWORD),
})

export const useEmailForm = () => {
  const [inputType, setInputType] = useState('password')
  const dispatch = useDispatch()
  const history = useHistory()
  const forgetEmailModal = useSelector((state) => state.auth.forgetEmail)
  const initialEmail = useSelector((state) => state.auth.initialEmail)
  const initialPassword = useSelector((state) => state.auth.initialPassword)
  const successRegister = useSelector((state) => state.auth.successRegister)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleChangePasswordInput = () => {
    setInputType(inputType === PASSWORD ? TEXT : PASSWORD)
  }

  const search = useMemo(() => {
    return new URLSearchParams(location.search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const redirect = () => {
    const redirectInside = search.get('redirect_inside')
    if (redirectInside) {
      history.push(redirectInside)
    }
  }

  const handleFinishLogin = async (res) => {
    try {
      const resData = res?.data
      if (res.success) {
        storageService.setAccessToken(resData?.accessToken)
        storageService.setRefreshToken(resData?.refreshToken)
        storageService.setItem('studentInfo', JSON.stringify(resData?.studentInfo))
        dispatch(commonActions.fetchProfileFulfilled(resData?.studentInfo))
        api.setHeader('recaptcha', '')
        await redirect()
      } else {
        formik.setErrors({
          email: PROBLEM_WITH_EM_PW,
          password: PROBLEM_WITH_EM_PW,
        })
        dispatch(authActions.setShowCaptchaError(true))
      }
    } catch (error) {
      console.error('Login emailForm error:', error)
    }
  }

  const handleOpenEmailForgetModal = () => {
    dispatch(authActions.setForgetEmailModal(true))
  }

  const handleSubmit = async (values) => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.singIn)

      api.setHeader('recaptcha', token)

      const loginObj = {
        data: {
          login: values.email,
          password: values.password,
          loginType: LOGIN_TYPES.email,
        },
        callback: handleFinishLogin,
      }

      await dispatch(authActions.login(loginObj))
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  const handleInputChange = (e) => {
    formik.handleChange(e)

    if (e.target.name === 'password') {
      const passwordValue = e.target.value
      formik.setErrors({ ...formik.errors, password: '' })

      if (passwordValue.match(passwordRgp)) {
        formik.setErrors({ ...formik.errors, password: '' })
      }
    } else {
      formik.setErrors({ ...formik.errors, email: '' })
    }
  }

  const handleBlurEmail = () => {
    const emailLength = formik.values.email.length
    if (emailLength === 0) {
      formik.setErrors({ email: EMAIL_REQUIRED })
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (successRegister) {
      formik.setValues({
        email: initialEmail,
        password: initialPassword,
      })
    }
  }, [initialEmail, initialPassword])

  useEffect(
    () => () => {
      dispatch(authActions.setSuccessRegester(false))
    },
    [],
  )

  return {
    inputType,
    handleInputChange,
    formik,
    handleChangePasswordInput,
    handleBlurEmail,
    forgetEmailModal,
    handleOpenEmailForgetModal,
  }
}
