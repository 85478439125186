export const CourseTypes = {
  open: 1,
  close: 2
};

export const ERROR_TYPES = {
  courseModuleNotFound: 131,
  courseNotFound: 129
};

export const COURSE_HOMEWORK = 1;
export const COURSE_LESSON_HOMEWORK = 2
