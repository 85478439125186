import styled, { keyframes } from 'styled-components';

const animationSkeleton = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`

export const SkeletonComponent = styled.div`
  width: ${({fullWidth, width}) => (fullWidth ? fullWidth + '%' : width ? (width + 'px') : '100px')};
  height: ${props => (props.height ? (props.height + 'px') : '100px')};
  border-radius: ${props => (props.variant === 'circle' ? '50%' : props.borderRadius ? (props.borderRadius + 'px') : '5px')};
  margin-top: ${props => (props.marginTop ? (props.marginTop + 'px') : '0')};
  margin-bottom: ${props => (props.marginBottom ? (props.marginBottom + 'px') : '0')};
  margin-left: ${props => (props.marginLeft ? (props.marginLeft + 'px') : '0')};
  margin-right: ${props => (props.marginRight ? (props.marginRight + 'px') : '0')};
  background: transparent;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: ${animationSkeleton} 2s infinite ease-out;
`;
