import { FlexWrapper } from '@components/layouts/flex-wrapper';
import {
  CourseName,
  CourseTitle,
  ModuleCount,
  ModuleInfo,
  ModuleSubtitle,
  ModuleTitle,
  MoreInfoLeftCourse
} from '@modules/certificates/routes/more-info/more-info.style';
import { hoc } from '@utils/hoc';
import { useCourseInfoProp } from './course-info.prop';
import { certificateDate } from '@utils/date-format-time';

export const CourseInfo = hoc(useCourseInfoProp, ({ certificateDetail }) => {
  return (
    <MoreInfoLeftCourse>
      <CourseName>Kurs nomi</CourseName>
      <CourseTitle>{certificateDetail?.course?.name}</CourseTitle>
      <CourseName>Modullar</CourseName>

      <FlexWrapper mb='24px' direction='column' gap='8px' mt='8px'>
        {certificateDetail?.modules?.map((item, index) => (
          <ModuleTitle key={item.id}>
            {index + 1}. {item?.title}
          </ModuleTitle>
        ))}
      </FlexWrapper>

      <FlexWrapper mb='20px' gap='20px' justify='space-between'>
        <ModuleInfo>
          <ModuleSubtitle>Modullar soni</ModuleSubtitle>
          <ModuleCount>
            {certificateDetail?.modules?.length}/
            {certificateDetail?.modules?.length}
          </ModuleCount>
        </ModuleInfo>

        <ModuleInfo>
          <ModuleSubtitle>Darslar soni</ModuleSubtitle>
          <ModuleCount>
            {certificateDetail?.lessonsCount}/{certificateDetail?.lessonsCount}
          </ModuleCount>
        </ModuleInfo>

        <ModuleInfo>
          <ModuleSubtitle>Asosiy vazifalar</ModuleSubtitle>
          <ModuleCount>
            {certificateDetail?.homeworksCount}/
            {certificateDetail?.acceptedHomeworksCount}
          </ModuleCount>
        </ModuleInfo>
      </FlexWrapper>

      <FlexWrapper gap='20px'>
        <ModuleInfo big>
          <ModuleSubtitle>Boshlash vaqti</ModuleSubtitle>
          <ModuleCount>
            {certificateDate?.(certificateDetail?.createdAt)}
          </ModuleCount>
        </ModuleInfo>

        <ModuleInfo big>
          <ModuleSubtitle>Tugatish vaqti</ModuleSubtitle>
          <ModuleCount>
            {certificateDate?.(certificateDetail?.awardedAt)}
          </ModuleCount>
        </ModuleInfo>
      </FlexWrapper>
    </MoreInfoLeftCourse>
  );
});
