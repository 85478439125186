import { useSelector } from "react-redux"

export const useCourseTopicProp = () => {
    const activeLesson = useSelector(state => state.courses.activeLesson)
    const singleModuleLoading = useSelector(state => state.courses.singleModuleLoading)

    return {
        activeLesson,
        singleModuleLoading,
    }
}
