import styled from 'styled-components';

export const StyledMainLayout = styled.div`
  display: flex;
  height: 100vh;
  overflow: auto;
  background: #e5e5e5;

  &.inner-wrapper {
    display: flex;
    width: 100%;
    padding: 2.4rem 3.2rem;
    padding-top: 14rem;
    padding-left: 3.3rem;
  }
`;

export const StyledMainLayoutWrapperCore = styled.div(
  () => `
    width: 100%;
  `,

  ({ theme: { down } }) => `
  
    ${down('576px')} {
      margin-left: 0 !important;
    }
  `
);

export const StyledMainLayoutWrapper = styled(StyledMainLayoutWrapperCore)`
  width: ${props => (props.isTablet && props.isOpened ? '71%' : '100%')};  
  margin-left: ${props => (props.state ? '0' : '7.5rem')};
  transition: width .4s linear;  
  &:has(.homework-wrapper) {
    overflow: hidden;
  }
  overflow-y: hidden;
`;

export const StyledMainLayoutInnerWrapper = styled.div(
  () => `
  gap: 2rem;
  transition: margin 0.2s linear;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 13.6rem 24px 24px 24px;
`,

  ({ theme: { down } }) => `
    ${down('1015px')} {
      padding: 13.6rem 22px 24px 24px;
    }
    ${down('576px')} {
    padding: 13.4rem 16px 80px 16px;
  }
`
);
