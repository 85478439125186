import { MainButton } from '@components/atoms/main-button/main-button.style';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { RightSidebar } from '@components/right-sidebar';
import { hoc } from '@utils/hoc';
import { useMyCourseSingleProp } from './my-course-single.prop';
import {
    CourseTitle,
    LeftSide,
    Wrapper,
    WrapperInner,
    CourseInfoIcon,
    CourseInfoTitle,
    CourseInfoSubtitle,
    CourseInfoArchivedTitle,
    LeftSideWrapper,
    RightSideWrapper,
    MyCoursesWrapper,
    CourseHeroWrapper,
    CourseInfoIllustrationImage,
    CourseArchivedWrapper, CourseInfoDuration
} from './my-course-single.style';

import ModuleCount from '@assets/img/number-group.png';
import ThemeCount from '@assets/img/count-theme.png';
import LessonCount from '@assets/img/nout-lesson.png';
import Calendar from '@assets/img/calendar-theme.png';
import SingleIllustration from '@assets/img/single-illustration.png';
import { FreeModal } from '../free-modal';
import { ArchivedModule } from '../archived-module';
import { config } from '@config/config';
import { secondsToHmsHour, secondsToHmsMinute} from '@utils/date-format-time';
import { PaymentModal } from '../payment-modal/payment-modal.component';
import { PayzeModal } from '../payze-modal';
import { UpdateInfo } from '../update-info';
import { ConfirmCode } from '../confirm-code';
import { AllCoursesSingleSkeleton, SingleCourseAccardionSkeleton, SingleCourseModuleSkeleton } from '@modules/all-courses/routes/all-courses-single/all-courses-skeleton';

export const MyCourseSingle = hoc(
  useMyCourseSingleProp,
  ({
    handleGoLanding,
    singleCourseInfo,
    sidebarState,
    allMyCourses,
    archivedCourses,
    handleOpenPaymentModalClick,
    setIsOpenPaymentModal,
    isOpenPaymentModal,
    handleClosePaymentModal,
    mqMaxWidth1213,
    mqMaxWidth1110,
    mqMaxWidth820,
    mqMaxWidth700,
    mqMaxWidth650,
    isAvailableBuy,
    mqMaxWidth600,
    mqMaxWidth370,
    isOpenPayzeModal,
    setIsOpenPayzeModal,
    unMoundPayzeModal,
    isShowUpdateInfoModal,
    isShowConfirmModal,
    singleCourseLoading
  }) => {
    let CourseCardImgSrc = config.imgApiUrl
    if(singleCourseInfo?.imageIllustration?.[0] === '/') {
      CourseCardImgSrc += singleCourseInfo?.imageIllustration
    } else {
      CourseCardImgSrc += `/${singleCourseInfo?.imageIllustration}`
    }
    return (
      <MyCoursesWrapper gap='14px' align='flex-start'>
        <LeftSide flexShrink={sidebarState && mqMaxWidth1213}>
          <LeftSideWrapper>
            <Wrapper>
              {
                singleCourseLoading && !singleCourseInfo?.name
                  ? <AllCoursesSingleSkeleton />
                  : <WrapperInner>
                    <FlexWrapper
                      align='center'
                      justify='space-between'
                      direction={mqMaxWidth600 ? 'column-reverse' : ''}
                    >
                      <CourseHeroWrapper>
                        <CourseTitle>{singleCourseInfo?.name}</CourseTitle>

                        <FlexWrapper gap={mqMaxWidth370 ? '14px' : '18px'}>
                          <MainButton
                            className='course-about__btn'
                            onClick={handleGoLanding}
                          >
                            Kurs haqida batafsil
                          </MainButton>
                          <MainButton
                            className='course-about__btn'
                            onClick={handleOpenPaymentModalClick}
                            disabled={isAvailableBuy}
                          >
                            Sotib olish
                          </MainButton>
                        </FlexWrapper>
                      </CourseHeroWrapper>
                      <CourseInfoIllustrationImage
                        src={CourseCardImgSrc || SingleIllustration}
                        width={208}
                        height={208}
                      />
                    </FlexWrapper>
                  </WrapperInner>
              }
            </Wrapper>

            <WrapperInner small={true}>
              {
                singleCourseInfo?.name
                  ? <FlexWrapper
                    className='info-wrapper'
                    gap={mqMaxWidth1110 || mqMaxWidth820 ? '24px' : '64px'}
                    justify={
                      mqMaxWidth1110 || mqMaxWidth820
                        ? 'space-between'
                        : 'flex-start'
                    }
                    wrap={mqMaxWidth700 ? 'wrap' : ''}
                  >
                    <FlexWrapper
                      className={mqMaxWidth700 && 'info-top'}
                      direction='column'
                    >
                      <CourseInfoIcon src={ModuleCount} />
                      <CourseInfoTitle>Modullar soni</CourseInfoTitle>
                      <CourseInfoSubtitle>
                        {singleCourseInfo?.modulesCount || 0} ta
                      </CourseInfoSubtitle>
                    </FlexWrapper>

                    <FlexWrapper
                      className={mqMaxWidth700 && 'info-top'}
                      direction='column'
                    >
                      <CourseInfoIcon src={ThemeCount} />
                      <CourseInfoTitle>Mavzular soni</CourseInfoTitle>
                      <CourseInfoSubtitle>
                        {singleCourseInfo?.chaptersCount || 0} ta
                      </CourseInfoSubtitle>
                    </FlexWrapper>

                    <FlexWrapper
                      className={mqMaxWidth700 && 'info-top'}
                      direction='column'
                    >
                      <CourseInfoIcon src={LessonCount} />
                      <CourseInfoTitle>Darslar soni</CourseInfoTitle>
                      <CourseInfoSubtitle>
                        {singleCourseInfo?.lessonsCount || 0} ta
                      </CourseInfoSubtitle>
                    </FlexWrapper>

                    <FlexWrapper
                      className={mqMaxWidth700 && 'info-top'}
                      direction='column'
                    >
                      <CourseInfoIcon src={Calendar} />
                      <CourseInfoTitle>Kurs davomiyligi</CourseInfoTitle>
                      <CourseInfoSubtitle>
                        <FlexWrapper className='course-about' mr={'8px'}>
                            {secondsToHmsHour(singleCourseInfo?.duration) || 0}
                            <CourseInfoDuration className='course-about'>
                                soat
                            </CourseInfoDuration>
                        </FlexWrapper>
                          <FlexWrapper className='course-about'>
                              {secondsToHmsMinute(singleCourseInfo?.duration) || 0}
                              <CourseInfoDuration className='course-about'>
                                  daqiqa
                              </CourseInfoDuration>
                        </FlexWrapper>
                      </CourseInfoSubtitle>
                    </FlexWrapper>
                  </FlexWrapper>
                  : <SingleCourseModuleSkeleton />
              }
              {
                singleCourseLoading && !singleCourseInfo?.name
                  ? <SingleCourseAccardionSkeleton />
                  : <ArchivedModule bgc={true} courseInfo={allMyCourses} />
              }
            </WrapperInner>

            {archivedCourses?.length > 0 && (
              <CourseInfoArchivedTitle>
                Arxivlangan modullar
              </CourseInfoArchivedTitle>
            )}

            <CourseArchivedWrapper>
              <ArchivedModule courseInfo={archivedCourses && archivedCourses} />
            </CourseArchivedWrapper>
          </LeftSideWrapper>
        </LeftSide>
        <FreeModal />

        <RightSideWrapper>
          <RightSidebar />
        </RightSideWrapper>
        {isOpenPaymentModal && !isAvailableBuy && (
          <PaymentModal
            setIsOpenPaymentModal={setIsOpenPaymentModal}
            onClose={handleClosePaymentModal}
          />
        )}
        {isOpenPayzeModal && (
          <PayzeModal
            modal={isOpenPayzeModal}
            setModal={setIsOpenPayzeModal}
            unMountModal={unMoundPayzeModal}
          />
        )}
        {isShowUpdateInfoModal && <UpdateInfo />}
        {isShowConfirmModal && <ConfirmCode />}
      </MyCoursesWrapper>
    );
  }
);
