import styled from 'styled-components';

export const CourseNotesItemWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const CourseNotesItemTime = styled.h5(
  () => `
    background: rgba(186, 141, 91, 0.1);
    height: 27px;
    padding: 4px 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ba8d5b;
    border-radius: 100px;
    cursor: pointer;
   `,

   ({ theme: { down } }) => `
   ${down('600px')} {
    font-size: 12px;
   }
   `
);

export const CourseNotesItemTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-left: 16px;
`;

export const CourseNotesItemBtn = styled.button`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: rgba(186, 141, 91, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &:last-child {
    margin-left: 16px;
  }

  &:hover {
    border: 1px solid #ba8d5b;
    background-color: #fff;

    svg {
      fill: #000;
    }
  }

  &:active {
    background: #ba8d5b;
    svg {
      fill: #fff;
    }
  }
`;

export const CourseNotesItemText = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
    overflow-wrap: anywhere;
`;
