import { hoc } from '@utils/hoc';
import { useCourseQuestionsProps } from './course-questions.props';
import {
  CourseQuestionNoItemImg,
  CourseQuestionsContainerTop,
  CourseQuestionsFormContent,
  CourseQuestionsTitle,
  CourseQustionNotItemTitle,
  MineCommentsWrapper
} from './course-questions.style';
import {
  CourseNotesBottom,
  CourseNotesBtn,
  CourseNotesEditExample,
  CourseNotesEditExampleLeft,
  CourseNotesEditText,
  CourseNotesEditTitle,
  CourseQuestionWrapper
} from '../course-notes/course-notes.style';
import { CourseComments } from './course-comments/course-comments.components';
import { Textarea } from './text-area';
import { SvgIcon } from '@components/atoms/icons';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import NoQuestionImg from '@assets/img/error-page.png';
import { Alert } from '@components/atoms/alert';
import IconTurnRight from '@assets/img/IconTurnRight.svg';
import { CourseQuestionSkeleton } from './course-question-skeleton';

/**
 * <CourseQuestions />
 */
const CourseQuestions = hoc(
  useCourseQuestionsProps,
  ({
    textareaRef,
    courseComments,
    handleSubmit,
    commentReply,
    handleCancel,
    mq500MaxWidth,
    hasAlert,
    alertMsg,
    allComments,
    commentEdit,
    handleCancelEdit,
    commentsLoading,
    isLoading
  }) => {
    return (
      <div>
        {hasAlert && alertMsg && <Alert success message={alertMsg} />}
        {commentsLoading ? (
          <CourseQuestionSkeleton />
        ) : courseComments?.total > 0 || allComments?.total > 0 ? (
          <>
            {!!courseComments?.total && (
              <MineCommentsWrapper mine>
                <CourseQuestionsContainerTop>
                  <CourseQuestionsTitle>Mening izohlarim</CourseQuestionsTitle>
                </CourseQuestionsContainerTop>

                {
                  <CourseComments
                    textareaRef={textareaRef}
                    items={courseComments?.data}
                  />
                }
              </MineCommentsWrapper>
            )}

            {!!allComments?.total > 0 && (
              <MineCommentsWrapper>
                <CourseQuestionsContainerTop>
                  <CourseQuestionsTitle>Qolgan izohlar</CourseQuestionsTitle>
                </CourseQuestionsContainerTop>

                {
                  <CourseComments
                    textareaRef={textareaRef}
                    items={allComments?.data}
                  />
                }
              </MineCommentsWrapper>
            )}
          </>
        ) : (
          <FlexWrapper
            justify='center'
            direction='column'
            align='center'
            className='svg-wrapper'
          >
            <CourseQuestionNoItemImg
              width='80'
              height='93'
              src={NoQuestionImg}
            />
            <CourseQustionNotItemTitle>
              Izohlar mavjud emas, birinchi bo‘ling :)
            </CourseQustionNotItemTitle>
          </FlexWrapper>
        )}

        <CourseQuestionsFormContent available={!commentReply?.id}>
          {commentReply?.id && (
            <CourseNotesBottom>
              <CourseNotesEditExample>
                <CourseQuestionWrapper>
                  <CourseNotesEditExampleLeft>
                    <img
                      src={IconTurnRight}
                      alt='reply'
                      width={15}
                      height={15}
                    />
                  </CourseNotesEditExampleLeft>

                  <CourseQuestionWrapper direction='column' ml='8px'>
                    <FlexWrapper justify='space-between' gap='10px' mb='8px'>
                      <CourseNotesEditTitle>
                        {commentReply?.author?.firstName +
                          ' ' +
                          commentReply?.author?.lastName}
                      </CourseNotesEditTitle>

                      {mq500MaxWidth && (
                        <CourseNotesBtn onClick={handleCancel}>
                          <SvgIcon iconName='cancel-edit' size='20px' />
                        </CourseNotesBtn>
                      )}
                    </FlexWrapper>

                    <CourseNotesEditText>
                      {commentReply?.message}
                    </CourseNotesEditText>
                  </CourseQuestionWrapper>
                </CourseQuestionWrapper>
                {!mq500MaxWidth && (
                  <CourseNotesBtn onClick={handleCancel}>
                    <SvgIcon iconName='cancel-edit' size='27px' />
                  </CourseNotesBtn>
                )}
              </CourseNotesEditExample>
            </CourseNotesBottom>
          )}
          {commentEdit?.id && (
            <CourseNotesBottom>
              <CourseNotesEditExample>
                <CourseQuestionWrapper>
                  <CourseNotesEditExampleLeft>
                    <SvgIcon iconName='edit-notes' />
                  </CourseNotesEditExampleLeft>

                  <CourseQuestionWrapper direction='column' ml='8px'>
                    <FlexWrapper justify='space-between' gap='10px' mb='8px'>
                      <CourseNotesEditTitle>
                        {commentEdit?.author?.firstName +
                          ' ' +
                          commentEdit?.author?.lastName}
                      </CourseNotesEditTitle>

                      {mq500MaxWidth && (
                        <CourseNotesBtn onClick={handleCancelEdit}>
                          <SvgIcon iconName='cancel-edit' size='20px' />
                        </CourseNotesBtn>
                      )}
                    </FlexWrapper>

                    <CourseNotesEditText>
                      {commentEdit?.message}
                    </CourseNotesEditText>
                  </CourseQuestionWrapper>
                </CourseQuestionWrapper>
                {!mq500MaxWidth && (
                  <CourseNotesBtn onClick={handleCancelEdit}>
                    <SvgIcon iconName='cancel-edit' size='27px' />
                  </CourseNotesBtn>
                )}
              </CourseNotesEditExample>
            </CourseNotesBottom>
          )}
          <Textarea
            isLoading={isLoading}
            submit={handleSubmit}
            onSubmit={handleSubmit}
            textareaRef={textareaRef}
          />
        </CourseQuestionsFormContent>
      </div>
    );
  }
);

export { CourseQuestions };
