import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';

export const MyCoursesWrapper = styled.div(
  () => `
      display: flex;
      align-items: flex-start;
    `,
  ({ theme: { down } }) => `
      ${down('768px')} {
        gap: 12px;
      }
      ${down('850px')} {
        flex-direction: column;
        gap: 24px;
      }
    `
);

export const LeftSide = styled(Scrollable)(
  ({ theme: { down } , flexShrink}) => `
    width: 100%;
    height: calc(100vh - 160px);
    overflow-y: auto;
    padding-right: 14px;
    margin-right: 14px;
    flex-shrink: ${flexShrink ? 0 : 1};

    ${down('1015px')} {
      padding-right: 12px;
      margin: 0;
    }
    ${down('576px')} {
      padding-right: 8px;
    }
  `
);

export const RightSideWrapper = styled.div(
  () => `
  width: 320px;
`,

  ({ theme: { down } }) => `
  ${down('1015px')} {
    width: 0;
  }
`
);

export const LeftSideWrapper = styled.div`
  padding: 24px;
  background-color: #fff;
`;

export const Wrapper = styled.div(
  () => `
      background: #ffffff;
      border-radius: 4px;
  
      &:first-child {
          margin-bottom: 16px;
      }
  `,
  ({ theme: { down } }) => `
    ${down('1152px')} {
      .course-about__btn {
        padding: 9px 16px;
        font-size: 14px;
        line-height: 16px;
      }
    }
      `,
  ({ theme: { down } }) => `
    ${down('628px')} {
    .course-about__btn {
      font-size: 12px;
    }
  }`,

  ({ theme: { down } }) => `
    ${down('370px')} {
    .course-about__btn {
      padding: 7px 14px;
    }
  }`
);

export const WrapperInner = styled.div(
  () => `
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 28px 24px;
  background-repeat: no-repeat;
  background-position: center right;

  .info-wrapper {
    padding-bottom: 28px;
    border-bottom: 1px solid #dadada;
  }

  .info-top {
    width: 45%;
  }`,

  ({theme: {down}}) => `
    ${down('440px')} {
      .info-top {
        width: 35%;
      }
    }
  `
);

export const CourseHeroWrapper = styled.div(
  () => `
  margin-right: 18px;
  `,

  ({ theme: { down } }) => `
    ${down('600px')} {
      margin-right: auto;
    }
  `
);

export const CourseTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #000000;
  max-width: 433px;
  margin-bottom: 36px;
`;

export const CourseInfoIcon = styled.img`
  width: 48px;
  height: 43.1px;
  object-fit: contain;
  margin-bottom: 18px;
`;

export const CourseInfoTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  white-space: nowrap;
  color: #4e6575;
  margin-bottom: 2px;
`;

export const CourseInfoIllustrationImage = styled.img(
  () => `
    object-fit: contain;
  `,

  ({ theme: { down } }) => `
    ${down('1152px')} {
      width: 140px;
      height: 140px;
    }
  `,

  ({ theme: { down } }) => `
    ${down('600px')} {
      width: 190px;
      height: 190px;
    }
  `
);

export const CourseInfoSubtitle = styled.div(
  () => `
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      white-space: nowrap;
      color: #BA8D5B;
  
      .course-about {
        font-weight: 600;
      }  
      `,
  ({ theme: { down } }) => `
          ${down('420px')} {
              font-size: 16px;
              line-height: 30px;
          }
      `
);

export const CourseInfoDuration = styled.span`
  margin-left: 4px;
`;

export const CourseInfoArchivedTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 24px 0;
`;

export const CourseArchivedWrapper = styled.div`
  padding: 8px 24px 24px;
  background-color: #F8F7EF;
`;
