import styled from 'styled-components';

export const FreeModalOverlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 0;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 556;

  &.free-course-modal__open {
    height: 100vh;

    video {
      object-fit: contain;
    }
  }
`;
