import styled from 'styled-components'

export const  Loader = styled.div`
  .loader {
    width: 20px;
    height: 20px;
    border: 5px dotted #ba8d5b;
    z-index: 1;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`
