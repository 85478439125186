import { hoc } from '@utils/hoc';
import { GoogleButton } from '../PhoneForm/phone-form.style';
import { useRegisterWithGoogleProp } from './register-with-google.prop';

export const RegisterWithGoogle = hoc(
  useRegisterWithGoogleProp,
  ({ handleClickRegister, isLogin }) => {
    return (
      <GoogleButton
        className={isLogin ? 'login' : ''}
        type='button'
        onClick={handleClickRegister}
      >
        Google orqali
      </GoogleButton>
    );
  }
);
