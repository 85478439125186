import styled from 'styled-components';

export const BlogCardWrapper = styled.div`
  background-color: #f7f7f5;
  border-radius: 12px;
  padding: 16px;
`;

export const BlogCardTitle = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.35);
  margin-bottom: 16px;
`;

export const BlogCardImg = styled.img`
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  height: 164px;
  object-fit: cover;
  margin-bottom: 16px;
`;

export const BlogCardTheme = styled.h4(
  () => `
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      font-size: 12px;
    }
  `
);

export const BlogCardMore = styled.h4(
  () => `
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #BA8D5B;
    cursor: pointer;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      font-size: 12px;
    }
  `
);
