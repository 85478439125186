import { RegisterRightSide } from '@modules/new-auth/components/RegisterRightSide';
import { hoc } from '@utils/hoc';
import { Redirect } from 'react-router-dom';
import { useRegisterProp } from './register.prop';
import { RegisterLeftSide, RegisterWrapper } from './resgiter.style';


export const Register = hoc(
  useRegisterProp,
  ({ token, profile }) => {
    if (profile && token) {
      return <Redirect to='/dashboard' />;
    }
    return (
         <RegisterWrapper>
           <RegisterLeftSide />
           <RegisterRightSide />
         </RegisterWrapper>
    );
  }
);
