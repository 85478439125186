import { hoc } from '@utils/hoc';
import Collapse from 'rc-collapse';
import { useAccordionProps } from './accordion.prop';
import 'rc-collapse/assets/index.css';

export const Accordion = hoc(useAccordionProps, ({ header, children }) => {
  return (
    <Collapse accordion={true}>
      <Collapse.Panel header={header} headerClass='my-header-class'>
        {children}
      </Collapse.Panel>
    </Collapse>
  );
});
