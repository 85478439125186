import { AllCoursesActions } from '@all-courses/store';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useCancelAppealProps = () => {
  const [cancelAppealModal, setCancelAppealModal] = useState(false);
  const cancelAppealModalState = useSelector(
    state => state.courses.cancelAppealModal
  );
  const dispatch = useDispatch();

  const handleUnMountCancelAppealModal = () => {
    setCancelAppealModal(false);
    dispatch(AllCoursesActions.setCancelAppealModal(false));
  };

  const handleSubmit = () => {
    // Logic submit appeal

    handleUnMountCancelAppealModal();
    dispatch(AllCoursesActions.setAppealModal(false));
  };

  useEffect(() => {
    setCancelAppealModal(cancelAppealModalState);
  }, [cancelAppealModalState]);

  return {
    cancelAppealModal,
    setCancelAppealModal,
    handleUnMountCancelAppealModal,
    handleSubmit
  };
};
