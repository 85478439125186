import styled from 'styled-components';

export const FaStar = styled.img`
  width: 35px;
  height: 35px;
`;

export const RateComment = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 8px;
  border-color: #4242ffbd;
  resize: none;
  &:focus-visible {
    border-color: #4242ffbd !important;
  }
`;

export const RatingWrap = styled.div`
  display: flex;
  flex-direction: column;

  & span:first-child {
    display: flex !important;
    justify-content: space-evenly !important;
    margin-bottom: 12px;
  }

  & h2 {
    margin-bottom: 11px;
  }
`;

export const RateCommentTitle = styled.h2``;

export const Form = styled.form``;

export const RateCommentLength = styled.p`
  margin-bottom: 25px;
  margin-top: 10px;
`;

export const RateCommentError = styled.p`
  color: red;
`;

export const CommentLengthWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`