import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PlayBtnNew from '@assets/img/play-btn-new.svg';
import { MainButton } from '@components/atoms/main-button/main-button.style';
import NajotLogo from '@assets/img/najot-logo.svg';

export const Wrapper = styled.div(
  ({ theme: { down } }) => `
    background: #FFFFFF;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;
    .last-video{
      background: #F9F9F9;
      border-radius: 8px;
      padding: 16px;
      flex-direction: column;
    }
    ${down('576px')} {
      padding: 16px;
      .last-video {
        flex-direction: column;
      }
    }
    }

`
);

export const CourseSelectWrapper = styled.div(
  () => `
    display: flex;
  `,
  ({ theme: { down } }) => `
    ${down('407px')}{
      flex-direction: column;
      align-items: flex-start;
    }
  `
);

export const VideoWrapperHeight = styled(Link)`
  height: ${props => (props.min ? '184px' : '124px')};
`;
export const VideoWrapper = styled(VideoWrapperHeight)(
  () => `
    background: #F3EEE9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    &::after{
      content: '';
      width: 63%;
      height: 88%;
      position: absolute;
      right: 0;
      top: 11.52%;
      bottom: -17.74%;
      background-repeat: no-repeat;
      background-image: url(${NajotLogo});
      background-size: cover;
    }
`,
  ({ theme: { down } }) => `
     ${down('576px')}{
      height: 203px;
     }
  `,
  ({ theme: { down } }) => `
     ${down('420px')}{
      height: 143px;
     }
  `
);

export const VideoPlayBtn = styled.button(
  () => `
    width: 22px;
    height: 22px;
    background-image: url(${PlayBtnNew});
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    outline: none;
    background-size: contain;
  `,
  ({ theme: { down } }) => `
     ${down('576px')}{
      width: 26px;
      height: 26px;
     }
  `
);

export const Title = styled.h3(
  ({ theme: { down } }) => `
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    letter-spacing: -0.02em;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
    width: 100%;
    &:hover {
        p {
            visibility: visible;
        }
    }
    ${down('868px')} {
      font-size: 20px;
      line-height: 24px;
    }
`
);

export const CourseProgressInfo = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 20px;
`;

export const CourseProgressBar = styled.div(
  ({ theme: { down }, sidebarState, mqMaxWidth }) => `
    background: rgba(186, 141, 91, 0.1);
    border-radius: 2px;
    width: 100%;
    height: 4px;
    margin-top: 8px;
    margin-bottom: 0;

    ${
      (sidebarState || mqMaxWidth) &&
      `
      width: 100%;
    `
    }
    ${down('868px')} {
      width: 100%;
    }
`
);

export const CourseProgressEnded = styled.span`
  background: #ba8d5b;
  border-radius: 2px;
  height: 100%;
  width: ${props => props.width}%;
  display: block;
`;
export const PlayNoutWrapper = styled.span`
  display: flex;
  align-items: center;
`;
export const PlayNoutSpan = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 8px;
  background: #ba8d5b;
`;

export const PlayNoutTitle = styled.h4(
  () => `
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: rgba(0, 0, 0, 0.35);
    width: 44px;
    margin-right: 6px;
  `,
  ({ theme: { down } }) => `
     ${down('576px')}{
        font-size: 12px;
        line-height: 130%;
      }
     }
  `
);
export const PlayNoutDescription = styled.span(
  () => `
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
    color: #000000;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
   `,
  ({ theme: { down } }) => `
     ${down('576px')}{      
        font-size: 12px;
        line-height: 130%;
      }
     }
  `
);

export const StartVideoBtn = styled(MainButton)(
  ({ theme: { down }, sidebarState, mqMaxWidth }) => `
    align-self: ${sidebarState ? 'flex-start' : 'flex-end'};
    padding: 12px 16px;
    width: 100%;
    max-width: 139px;


    ${
      (sidebarState || mqMaxWidth) &&
      `
      align-self: flex-start;
      margin-top: 19px;
    `
    }

    ${down('868px')} {
      font-size: 12px;
      padding: 9px 0;
      max-width: 102px;
    }

    ${down('576px')} {
      padding: 7px 0;
    }

  `
);

export const LastVideoInfoWrapper = styled.div(
  ({ theme: { down }, sidebarState, mqMaxWidth }) => `
    width: 75%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .last-video-info {
        width: 100%;

        .last-video-info-name{
          width: 100%;
        }
    }

    ${
      (sidebarState || mqMaxWidth) &&
      `
      flex-direction: column;
      width: 71%;

      .last-video-info {
        width: 100%;
      }
    `
    }
    ${down('646px')} {
      padding-top: 16px;
    }
    ${down('576px')} {
      width: 100%;
    }
  `
);

export const LastVideoWrapper = styled.div(
  ({ sidebarState, mqMaxWidth, theme: { down } }) => `
    width: 208px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    position: relative;
    ${
      (sidebarState || mqMaxWidth) &&
      `
    width: 208px;
    `
    }
    ${down('576px')} {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
  `
);

export const LastVideoTitleWrapper = styled.div(
  () => `
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      `,
  ({ theme: { down } }) => `
          ${down('720px')}{
            flex-direction: column;
            align-items: flex-start;
          }
      `
);

export const LastVideoTitle = styled.h3(
  () => `
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #000000;
    padding-bottom: 8px;
  `
);

export const NotBoughtModalText = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 136%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 4px;

  span {
    color: #BA8D5B;
    display: inline-block;
  }
`;
