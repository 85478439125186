import { api } from "@services/base.service";

class VideosServices {
  constructor (api) {
    this.api = api
  }

  recentlyVideos = (perPage) => this.api.get(`/student/last-videos${perPage?.page ? `?page=${perPage?.page}&perPage=${perPage?.perPage}` : ''}`);

  fetchBookmarkVideos = (perPage) => this.api.get(`/student/bookmark-videos${perPage?.page ? `?page=${perPage?.page}&perPage=${perPage?.perPage}` : ''}`);

  bookmarkVideo = (data) => this.api.post('/student/bookmark-video', data);

  changePositionBookmarVideo = (data) => this.api.put(`/student/bookmark-video/${data.id}/move`, data.info);

  deleteBookmarkVideo = (id) => this.api.delete(`/student/bookmark-video/${id}`);
}

export const videosServices = new VideosServices(api);