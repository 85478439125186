import styled from 'styled-components';

export const MyHomeworksWrapper = styled.div(
  ({ theme: { down }}) => `
    background: #f7f7f5;
    padding: 24px;
    margin-bottom: 24px;

    ${down('768px')} {
      padding: 16px;

      & > .homework-files {
        gap: 16px;
      }

    }

`);

export const MyHomeworkTitle = styled.h4`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
`;

export const MyHomeworkSubtitle = styled.h5(
  ({ theme: { down }}) => `
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 24px;
    overflow-wrap: anywhere;

    ${down('430px')} {
      width: 60%;
    }
`);

export const EditButton = styled.button`
    outline: none;
    background: rgba(186, 141, 91, 0.1);
    border-radius: 46px;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid transparent;
    transition: all ease 0.3s;

    &:hover {
        background-color: #fff;
        border: 1px solid #ba8d5b;

        svg {
            fill: #000;
        }
    }

    &:active {
        background: #ba8d5b;

        svg {
            fill: #fff;
        }
    }

    &:disabled {
        opacity: 0.5;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }
`;

export const CountOfFiles = styled.h4(
  ({ theme: { down }}) => `
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #ba8d5b;

    ${down('576px')} {
      font-size: 12px;
    }

`);

export const Tooltip = styled.span`
    width: 227px;
    background: #323232;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0001em;
    color: #ffffff;
    position: absolute;
    right: 0;
    bottom: -75px;
    padding: 12px;
    margin: auto;
    visibility: hidden;
`;

export const ToolTipWrapper = styled.div`
    cursor: pointer;
    position: relative;
    user-select: none;
    bottom: 4px;
    &:hover {
        .tool-tip {
            visibility: visible;
        }
    }
`;
