import styled from 'styled-components';
import ArrowBottom from '@assets/img/drop.svg';
import Checked from '@assets/img/check.svg';

export const SelectWrapper = styled.div`
  background: #ffffff;
  border-radius: 100px;
  padding: 11px 20px 14px 16px;
  position: relative;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 19;
  }

  &.error {
    border: 1px solid #e84949;
  }
`;

export const SelectPlaceholder = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: -0.015em;
  color: #000000;
  position: relative;

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-image: url(${ArrowBottom});
    background-size: contain;
    transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;

export const SelectOptionsWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  display: ${props => (props.active ? 'flex' : 'none')};
  position: absolute;
  flex-direction: column;
  gap: 4px;
  left: 0;
  right: 0;
  top: 40px;
  margin: auto;
  z-index: 19;
  min-width: 242px;
  max-height: 205px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background: #dadada;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ba8d5b;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SelectOption = styled.button`
  background-color: #fff;
  border-radius: 4px;
  border: none;
  padding: 10px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => (props.selected ? '#BA8D5B' : '#000')};
  text-align: left;
  outline: none;
  position: relative;

  &::after {
    content: ${props => (props.selected ? `url(${Checked})` : 'none')};
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    /* background-image: ; */
    margin: auto;
  }
`;
