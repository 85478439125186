import styled from 'styled-components';

export const UserImg = styled.img`
  width: 82px;
  height: 82px;
  border-radius: 50%;
  object-fit: cover;
  border-radius: 50%;
`;
export const UserName = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
`;
