import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import { useForgetEmailProp } from './forget-email.prop';
import { InitialForm } from './InitialForm';
import { RecoveryEmail } from './RecoveryEmail';

export const ForgetEmail = hoc(
  useForgetEmailProp,
  ({
    modal,
    setModal,
    handleCLoseModal,
    initialForm,
    setInitialForm,
    time,
    setTime,
    timeIndex,
    setTimeIndex,
    email,
    setEmail
  }) => {
    return (
      <UnModal
        modal={modal}
        setModal={setModal}
        unMountModal={handleCLoseModal}
        modalTitle='Parolni tiklash'
      >
        {initialForm ? (
          <InitialForm
            setTimeIndex={setTimeIndex}
            setInitialForm={setInitialForm}
            setTime={setTime}
            setEmail={setEmail}
          />
        ) : (
          <RecoveryEmail
            email={email}
            time={time}
            timeIndex={timeIndex}
            setInitialForm={setInitialForm}
            setTime={setTime}
            setTimeIndex={setTimeIndex}
          />
        )}
      </UnModal>
    );
  }
);
