import {ratingActions} from "@modules/rating/store/rating.slice";
import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {storageService} from "@services/storage.service";
import {StudentCourseButton, StudentImg, TableContent} from "@modules/rating/components/table/rating-table.style";
import {isLink} from "@utils/linkChecker";
import {config} from "@config/config";
import DefaultAvatar from '@assets/img/default-avatar.png';
import {perPageOptions} from "@modules/rating/constants";

const orderBy = {
  asc: 'ASC',
  desc: 'DESC'
}
export const useRatingTableProp = () => {
  const ranks = useSelector(state => state.rating?.ratings)
  const loading = useSelector(state => state.rating?.loading)
  const filterObjects = useSelector(state =>  state.rating?.ratingFilter)
  const dispatch = useDispatch()
  let studentInfo = JSON.parse(storageService.getItem('studentInfo'))
  const [isOpen, setIsOpen] = useState(false)
  const [courses, setCourses] = useState([])
  const bodyRef = useRef(null);
  const [perPage, setPerPage] = useState(perPageOptions?.[0]?.value)

  const handleChange = (page) => {
    const info = {
      ...filterObjects,
      page: page?.selected + 1
    }

    dispatch(ratingActions.setRatingFilter(info))
  }

  const conditionalRowStyles = useMemo(() => {
    return [
      {
        when: row => row?.studentId === studentInfo?.id,
        style: {
          backgroundColor: 'rgba(63, 195, 128, 0.9)',
          color: 'white',
          '&:hover': {
            cursor: 'pointer'
          }
        }
      },
    ];
  }, [ranks?.upRanks, studentInfo])

  const handleOpenCourseModal = (courses) => {
      setIsOpen(true)
      setCourses(courses)
  }

  const handleOrderChange = async (order, sortDirection) => {
    const info = {
      ...filterObjects,
      order: orderBy[sortDirection],
    }

    dispatch(ratingActions.setRatingFilter(info))
  }

  const columns = useMemo(()=>  {
    return [
      {
        name: 'O`rin',
        selector: row => row.title,
        sortable: true,
        grow: 0.2,
        cell: (data) => {
          return (
            <TableContent>{data?.position || '-'}</TableContent>
          )
        }
      },
      {
        name: 'Image',
        grow: 0.5,
        selector: row => row.year,
        cell: (data) => {
          if(!data?.photo) {
            return <StudentImg src={DefaultAvatar} />
          }

          return (
            <StudentImg src={isLink(data?.photo) ? data?.photo : `${config.imgApiUrl}/${data?.photo}`} alt={''} />
          )
        }
      },
      {
        name: 'Ism Familiya',
        selector: row => row.title,
        align: 'center',
        grow: 3,
        cell: (data) => {
          return (
            <TableContent>{`${data?.firstName || '-'} ${data?.lastName}`}</TableContent>
          )
        }
      },
      {
        name: 'Kurs',
        selector: row => row.title,
        grow: 0.5,
        align: 'center',
        cell: (data) => {
          return (
            <StudentCourseButton
              onClick={handleOpenCourseModal.bind(null, data?.courses)}
            >
              {data?.courses?.length}
            </StudentCourseButton>
          )
        }
      },
      {
        name: 'Ball',
        align: 'center',
        selector: row => row.title,
        grow: 0.8,
        cell: (data) => {

          return (
            <TableContent>{data?.totalPoints}</TableContent>
          )
        }
      },
    ]
  }, [ranks])

  const handlePerPageChange = (perPage) => {
    setPerPage(perPage)

    const info = {
      ...filterObjects,
      perPage: perPage || 10
    }

    dispatch(ratingActions.setRatingFilter(info))
  }

  return {
    setIsOpen,
    ranks,
    conditionalRowStyles,
    loading,
    onChangePage: handleChange,
    isOpen,
    columns,
    courses,
    handleOrderChange,
    bodyRef,
    filterObjects,
    handlePerPageChange,
    perPage
  }
}
