import { Skeleton } from '@components/skeleton/skeleton-box'
import { VideoCardItem } from '@modules/videos/components/video-card-style'
import React from 'react'

const VideosSkeleton = () => {
  return (
    <>
      {
        Array.from(Array(5))?.map((_, index) => {
          return <VideoCardItem key={index}>
          <Skeleton fullWidth={100} height={180} marginBottom={20}/>
          <Skeleton fullWidth={100} height={40} marginBottom={12}/>
          <Skeleton fullWidth={100} height={20} marginBottom={6}/>
          <Skeleton fullWidth={100} height={20} marginBottom={44}/>
          <Skeleton fullWidth={100} height={10} marginBottom={10}/>
          <Skeleton fullWidth={100} height={10}/>
        </VideoCardItem>
        })
      }
    </>
  )
}

export default VideosSkeleton
