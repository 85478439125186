import { SvgIcon } from '@components/atoms';
import React from 'react';
import {
  ConfirmButton,
  IconStyle,
  MessageText,
  ModalMessage,
  ModalStyle
} from './pay-modal.styles';

function Modal({ onClose, isModulePaid, isAllModuleBought }) {
  return (
    <ModalStyle onClick={onClose}>
      <ModalMessage onClick={e => e.stopPropagation()}>
        <IconStyle>
          <SvgIcon iconName='tick' size='6rem'></SvgIcon>
        </IconStyle>
        <MessageText>
            {isAllModuleBought ?
                'Hurmatli mijoz siz kursimizning barcha modullarini sotib olgansiz.' :
                isModulePaid ? 'Hurmatli mijoz siz bu modulni sotib olgansiz.' : ''

            }
        </MessageText>
        <ConfirmButton onClick={onClose}>Tushunarli</ConfirmButton>
      </ModalMessage>
    </ModalStyle>
  );
}

export default Modal;
