import {api} from "@services/base.service";

export class MyRatingsService {
  constructor() {
    this.api = api
  }

  getMyRatings = () => this.api.get('/student/awards')
}

export const myRatingService = new MyRatingsService()
