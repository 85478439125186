import { AllCoursesActions } from '@all-courses/store';
import { useDispatch } from 'react-redux';

export const useCourseNotesItemProp = ({
  handleDeleteNoteItem,
  id,
  setModal,
  handleEditNoteItem,
  title,
  time,
  note
}) => {
  const dispatch = useDispatch();

  const deleteItem = () => {
    handleDeleteNoteItem?.(id);
    setModal?.(true);
  };

  const editNote = e => {
    handleEditNoteItem?.({
      id,
      lessonTitle: title,
      videoTime: time,
      text: note
    });

    // buyoda item ni bervorish kere qoshimcha qilib
    dispatch(
      AllCoursesActions.editNotes({
        available: true,
        text: note
      })
    );
  };

  return {
    deleteItem,
    editNote,
  };
};
