import { commonActions } from "@store/common";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useAlertProps = () => {
  const dispatch = useDispatch();
  const isShowAlert = useSelector(state => state.common.showAlert)

  useEffect(() => {
    if (isShowAlert) {
      setTimeout(() => {
        dispatch(commonActions.setShowAlert(false))
      }, 10000)
    }
  }, [isShowAlert, dispatch])

  const handleCloseAlertClick = () => {
    dispatch(commonActions.setShowAlert(false))
  }

  return {
    isShowAlert,
    handleCloseAlertClick,
  }
};
