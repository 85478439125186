import { Switch, Route } from 'react-router-dom'
import { AllCourses } from './all-courses'
import { AllCoursesSingle } from './all-courses-single'
import { ModuleSingle } from './module-single'
import { MyCourseSingle } from '../components/my-course-single'
import { RouteLinks } from '@constants/route'
import { MyCourses } from './my-courses'

export const AllCoursesRoutes = () => (
  <Switch>
    <Route exact path="/courses" component={AllCourses} />
    <Route exact path="/course-single/:courseId" component={AllCoursesSingle} />
    <Route path="/course-single/:courseId/module/:moduleId" component={ModuleSingle} />
    <Route path={RouteLinks.myCourses} component={MyCourses} />
    <Route exact path={`${RouteLinks.myCoursesSingle}/:id`} component={MyCourseSingle} />
  </Switch>
)
