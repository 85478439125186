import styled from 'styled-components';

export const ShareWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 16px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  min-width: 196px;
  top: ${props => props.top ? '-150px' : 'auto'};
  bottom: ${props => props.top ? 'auto' : '-150px'};
  z-index: 10;
`;

export const SocialButton = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  transition: all linear 0.3s;

  svg {
    transition: all linear 0.3s;
    border-radius: 50%;
    color: #ba8d5b;
  }

  &:hover {
    background: #f7f7f5;
    border-radius: 4px;
  }

  &.share {
    &:hover svg {
      border: 1px solid #ba8d5b;
      color: #fff;
    }

    &:hover .copy-path {
      stroke: #ba8d5b;
      fill: #fff;
    }
  }

  &.telegram {
    &:hover svg {
      color: #27a6e5;
    }
  }

  &.facebook {
    &:hover svg {
      color: #1877f2;
    }
  }
`;

export const SocialText = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
