import { createSlice } from '@reduxjs/toolkit';

const name = 'notifications';

const initialState = {
  loading: false,
  notificationLoading: true,
  singleNotificationLoad: true,
  error: null,
  notifications: null,
  singleNotification: null,
  activeType: {
    label: 'Barcha turlar bo‘yicha',
    value: undefined
  },
  activePage: 0,
};

export const { actions: notificationActions, reducer: notificationReducer } =
  createSlice({
    name,
    initialState,
    reducers: {
      setLoading(state, action) {
        state.loading = action.payload;
      },
      setError(state, action) {
        state.error = action.payload;
      },
      fetchNotifications(state) {
        state.loading = true;
        state.notificationLoading = true;
      },
      fetchNotificationsFulfilled(state, action) {
        state.notifications = action.payload;
        state.notificationLoading = false
      },
      readNotification(state) {
        state.loading = true;
      },
      fetchSingleNotification(state) {
        state.loading = true;
        state.singleNotificationLoad = true;
      },
      fetchSingleNotificationFulfilled(state, action) {
        state.singleNotification = action.payload;
        state.singleNotificationLoad = false;
      },
      setActiveType(state, action) {
        state.activeType = action.payload;
      },
      setActivePage(state, action) {
        state.activePage = action.payload
      }
    }
  });
