import styled from 'styled-components';

export const HomeworkCardWrapper = styled.a(
  ({ theme: { down }}) => `
    background: #ffffff;
    border: 1px solid #f1f0ee;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    width: 316px;

    ${down('768px')} {
      width: 280px;
    }

    ${down('576px')} {
      width: 264px;
    }

`);

export const HomeworkCardIcon = styled.button`
    background: rgba(186, 141, 91, 0.1);
    border-radius: 24px;
    padding: 13px;
    border: none;
    outline: none;
    margin-right: 16px;
    width: 44px;
    height: 44px;
`;

export const IconImg = styled.img`
    width: 18px;
    height: 20px;
`;

export const IconTitle = styled.h4(
  ({ theme: { down }}) => `
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 4px;
    display: inline-block;

    ${down('768px')} {
      font-size: 14px;
    }
`);
export const IconBasename = styled(IconTitle)(
  ({ theme: { down }}) => `
  white-space: nowrap;
  max-width: 186px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${down('768px')} {
    max-width: 154px;
  }
  ${down('576px')} {
    max-width: 138px;
  }
`)

export const IconSubtitle = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.35);
`;
