import { MainButton } from '@components/atoms/main-button/main-button.style';
import { Scrollable } from '@styles/scroll.style';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled(Scrollable)(
  () => `
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 14px;
  `,

  ({ theme: { down } }) => `
    ${down('1015px')} {
      padding-right: 14px;
    }
    ${down('576px')} {
      height: calc(100vh - 222px);
      padding-right: 8px;
    }
  `
);

export const WrapperInnerCore = styled.div(
  () => `
    padding: 24px;
    background: #ffffff;
    border-radius: 4px;
    
    &:last-child {
      margin-top: 24px;
    }
  `,

  ({ theme: { down } }) => `
  ${down('768px')} {
    padding: 20px;
  }

    ${down('576px')} {
      padding: 16px;
    }
  `
);

export const WrapperInner = styled(WrapperInnerCore)`
  margin-top: ${props => (props.top ? '24px' : 0)} !important;
`;

export const WrapperTitleCore = styled.h3(
  () => `
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 11px;
  `,

  ({ theme: { down } }) => `
    ${down('968px')} {
      font-size: 22px;
      line-height: 25px;
    }

    ${down('768px')} {
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 24px;
    }

    ${down('680px')} {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    ${down('576px')} {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 16px;
    }
  `
);

export const WrapperTitle = styled(WrapperTitleCore)`
  font-weight: ${props => (props.small ? '600' : '700')};
`;

export const WrapperTitleForRecommend = styled(WrapperTitle)`
  margin-bottom: 0 !important;
`;

export const EmptyTitle = styled.h4(
  () => `
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #ba8d5b;
    margin-bottom: 27px;
  `,

  ({ theme: { down } }) => `
    ${down('986px')} {
      font-size: 18px;
      line-height: 20px;
    }

    ${down('768px')} {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 32px;
    }

    ${down('680px')} {
      margin: 0 auto;
      text-align: center;
      margin-bottom: 28px;
      max-width: 248px;
    }

    ${down('576px')} {
      font-size: 14px;
      margin-bottom: 24px;
    }
  `
);

export const EmptyCourseTitle = styled.h4(
  () => `
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.02em;
    color: #ba8d5b;
  `,
  ({ theme: { down } }) => `
    ${down('986px')} {
      font-size: 18px;
    }
  
    ${down('768px')} {
      font-size: 16px;
    }

    ${down('576px')} {
      font-size: 14px;
      max-width: 248px;
      text-align: center;
      margin: 0 auto;
    }
  `
);

export const EmptyCourseImg = styled.img(
  () => `
    width: 80px;
    height: 80px;
    object-fit: contain;
  `,

  ({theme: { down }}) => `
    ${down('576px')} {
      height: 75px;
    }
  `
);

export const AllCourses = styled(Link)(
  () => `
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    letter-spacing: -0.015em;
    color: #ba8d5b;

    &:hover {
      text-decoration: underline;
    }
  `,

  ({ theme: { down } }) => `
    ${down('968px')} {
      font-size: 16px;
    }

    ${down('768px')} {
      font-size: 14px;
    }

    ${down('576px')} {
      font-size: 12px;
    }
  `
);

export const RecommendedWrapper = styled.div(
  ({sidebarState}) => `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 380px));
  justify-content: space-between;
  gap: 24px;

  &:after {
    content: '';
    flex: 0 1 24%;
    visibility: hidden;
    margin: 0 15px;
  }

  @media (max-width: 1846px) {
    grid-template-columns: ${sidebarState ? 'repeat(4, minmax(260px, 380px))' : 'repeat(5, minmax(260px, 380px))'};
  }
  
  @media (max-width: 1562px) {
    grid-template-columns: ${sidebarState ? 'repeat(3, minmax(260px,380px))' : 'repeat(4, minmax(260px,380px))'};
  }
  
  @media (max-width: 1300px) {
    grid-template-columns: ${sidebarState ? 'repeat(2, minmax(260px,380px))' : 'repeat(3, minmax(260px,380px))'};
  }
  
  @media (max-width: 1008px) {
    grid-template-columns: repeat(2, minmax(260px, 380px));
    justify-content: space-evenly;
  }
  
  @media (max-width: 885px) {
    grid-template-columns: ${sidebarState ? 'repeat(auto-fill, minmax(260px,380px))' : 'repeat(2, minmax(260px,380px))'};
    justify-content: center;
  }
  
  @media (max-width: 712px) {
    grid-template-columns: repeat(auto-fill, minmax(260px,380px));
  }
  `,
);

export const BuyButton = styled(MainButton)(
  () => `
    padding: 12px 51px;
  `,

  ({ theme: { down } }) => `
    ${down('968px')} {
      padding: 10px 30px;
    }

    ${down('768px')} {
      padding: 9px 25px;
    }
  `
);
