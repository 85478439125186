import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import { SidebarNav } from './sidebar-nav';
import { SidebarNavLink } from './sidebar-nav-link';
import { useSidebarProps } from './sidebar.prop';
import { LogoutBtn, StyledLeftSidebar, ModalTitle, Button } from './sidebar.style';
import {ReactComponent as IconExit} from "@assets/img/icon-exit.svg";

export const Sidebar = hoc(
  useSidebarProps,
  ({ sidebarRef, handleMouseLeave, handleMouseEnter, sidebarState, setModalState,
    modalState, handleToggleConfirmModal, handleCloseConfirmModal, handleLogOut, isRotated }) => {
    return (
      <>
        <StyledLeftSidebar
          ref={sidebarRef}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
          className={sidebarState && 'open'}
        >
          <SidebarNav handleToggleConfirmModal={handleToggleConfirmModal}/>
            {!isRotated && (

          <LogoutBtn>
            <SidebarNavLink
              onClick={handleToggleConfirmModal}
              to='/login'
            >
              <IconExit />
              <h4 className='sidebar-navs'>Chiqish</h4>
            </SidebarNavLink>
          </LogoutBtn>
            )}
        </StyledLeftSidebar>
        {
          modalState && (
            <UnModal
              modal={modalState}
              setModal={setModalState}
            >
              <ModalTitle>
                Platformadan chiqishni xohlaysizmi?
              </ModalTitle>

              <FlexWrapper
                justify='center'
              >
                <Button onClick={handleCloseConfirmModal}>
                  Yo'q
                </Button>

                <Button
                  danger
                  onClick={handleLogOut}
                >
                  Ha
                </Button>
              </FlexWrapper>
            </UnModal>
          )
        }
      </>
    );
  }
);
