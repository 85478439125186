import { addNotification } from '@components/notifications';

const URLFacebook = `https://www.facebook.com/sharer.php?s=100&p[title]=${`title`}&[summary]=${`shortdesc`}`;

export const useShareProp = ({ setIsOpen }) => {
  const handleShareByTelegram = e => {
    e.stopPropagation();
    window &&
      window.open(
        `https://telegram.me/share/url?url=${window.location.href}`,
        '_blank'
      );
  };

  const handleShareByFaceBook = e => {
    e.stopPropagation();
    window &&
      window.open(`${URLFacebook}${window.location.pathname}`, '_blank');
  };

  const handleCopyLink = e => {
    e.stopPropagation();
    if (typeof window !== 'undefined') {
      navigator.clipboard.writeText(window.location.href);
      setIsOpen(false);
      addNotification({
        title: 'Havola nusxalandi',
        options: {
          autoClose: true,
          type: 'success'
        }
      });
    }
  };

  return {
    handleShareByTelegram,
    handleShareByFaceBook,
    handleCopyLink
  };
};
