import React from 'react';
import {ToastContainerStyled} from './styles';

const AUTO_CLOSE = 10000;

export const NotificationContainer = () => (
  <ToastContainerStyled
    autoClose={AUTO_CLOSE}
    closeButton={false}
    closeOnClick={false}
    icon={false}
    newestOnTop={false}
    position="top-right"
    rtl={false}
    draggable
    pauseOnFocusLoss
    pauseOnHover
  />
);
