import { RouteLinks } from '@constants/route';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const useSubHeaderProps = () => {
  const sidebarState = useSelector(state => state.common.sidebarState);
  const subHeaderTitle = useSelector(state => state.common.subHeaderTitle);
  const history = useHistory();
  const isHasButton = useSelector(state => state.common.isHasButton);
  const notFound = useSelector(state => state.common.upsertError);

  const toBack = e => {
    e.preventDefault();

    if (notFound) {
      history.push(RouteLinks.myCourses);
      return;
    }

    history.push(isHasButton?.link);
  };

  return {
    sidebarState,
    subHeaderTitle,
    toBack,
    isHasButton,
    notFound
  };
};
