import styled from 'styled-components';

export const RatingWrapper = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
`;

export const ErrorTitle = styled.h3`
  text-align: center;
  font-size: 18px;
  color: #ba8d5b;
`

export const NoDataTitle = styled.h4`
  text-align: center;
  color: #ba8d5b;
  font-size: 22px;
  margin-bottom: 20px;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 15px;
  }
`
