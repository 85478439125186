import { commonActions } from '@store/common';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const useErrorProps = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    history.push('/dashboard');
    dispatch(commonActions.setSubheader(true));
  };

  useEffect(() => {
    dispatch(commonActions.setSubheader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleClick
  };
};
