import { useEffect } from 'react';
import { ErrorImage, ErrorTitle, EmptyWrapper } from './inactive.style';
import ErrorImg from '@assets/img/foydalanish_cheklangan.png';
import { useDispatch } from 'react-redux';
import { commonActions } from '@store/common';
import { AllCoursesActions } from '@modules/all-courses/store';
import { RouteLinks } from '@constants/route';

export const InactiveCourse = ({ isModule }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AllCoursesActions.resetSingleCourse());
    dispatch(
      commonActions.setIsHasButton({
        notFound: isModule,
        link: !isModule && RouteLinks.myCourses
      })
    );
    dispatch(
      commonActions.setSubHeaderTitle(
        `${isModule ? 'Module' : 'Kursdan'} foydalanish cheklangan`
      )
    );
    dispatch(commonActions.setSubheader(true));

    return () => {
      dispatch(commonActions.setIsHasButton({ isHasButton: false }));
      dispatch(commonActions.setSubHeaderTitle(''));
      dispatch(AllCoursesActions.setLastVideoLoading(true));
      dispatch(commonActions.setUpsertError(false));
    };
  }, []);

  return (
    <EmptyWrapper>
      <ErrorTitle>
        {isModule ? 'Moduledan' : 'Kursdan'} foydalanish cheklangan
      </ErrorTitle>
      <ErrorImage src={ErrorImg} />
    </EmptyWrapper>
  );
};
