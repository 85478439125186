import { useRef } from 'react';

export const useUnModalProps = ({ setModal, unMountModal = () => null }) => {
  const modalRef = useRef(null);

  const handleCloseModal = e => {
    if (modalRef.current === e.target) {
      setModal(false);
      unMountModal();
    }
  };

  return {
    modalRef,
    handleCloseModal
  };
};
