import { fetchSagaFactory } from '@utils/fetch-saga-factory';
import { tryCatchSagaFactory } from '@utils/try-catch-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { notificationService } from '../services';
import { notificationActions } from './notification.slice';

export const tryCatchSaga = tryCatchSagaFactory(notificationActions);

export function* readNotification({ payload }) {
  const res = yield call(notificationService.readNotification, payload?.id);
  yield payload.callback(res);
}

export function* getNotifications({ payload }) {
  const res = yield call(notificationService.getNotifications, payload);
  yield put(notificationActions.fetchNotificationsFulfilled(res?.data));
  yield payload?.callback(res);
}

export function* notificationRootSaga() {
  yield all([
    fetchSagaFactory({
      pattern: 'fetchSingleNotification',
      actions: notificationActions,
      api: {
        method: notificationService.getSingleNotification,
        callback: res => res?.data
      }
    }),
    takeLatest(
      notificationActions.readNotification,
      tryCatchSaga(readNotification)
    ),
    takeLatest(
      notificationActions.fetchNotifications,
      tryCatchSaga(getNotifications)
    )
  ]);
}
