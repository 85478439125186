import React, { useEffect, useRef, useState } from 'react';
import { hoc } from '@utils/hoc';
import PlayButton from '@assets/img/play-btn.svg';
import PauseButton from '@assets/img/pause-btn.png';
import {
  ThumbnailLinkContainer,
  Title,
  CancelBtn,
  CircularProgress,
  ValueContainer,
  CongratulationsText
} from './thumbnail-link.styles';
import { FlexWrapper } from '@components/layouts/flex-wrapper';

let PROGRESS_END_VALUE = 100;
const SPEED = 100;

const useThumbnailProps = ({ title, onNext }) => {
  const [btnImg, setBtnImg] = useState(true);
  const progressRef = useRef();
  const cancelBtn = useRef();
  const playBtnRef = useRef(null);

  useEffect(() => {
    let progressValue = 0;
    progressRef.current = setInterval(() => {
      if (playBtnRef.current) {
        progressValue++;
        playBtnRef.current.style.background = `conic-gradient(
                    #fff ${progressValue * 3.6}deg,
                    #6C7071 ${progressValue * 3.6}deg
                )`;
        if (progressValue === PROGRESS_END_VALUE) {
          onNext();
          clearInterval(progressRef.current);
        }
      }
    }, SPEED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickCancelBtn = () => {
    clearInterval(progressRef.current);
    setBtnImg(false);
    cancelBtn.current.style.display = 'none';
    playBtnRef.current.style.background =
      'conic-gradient(rgb(255, 255, 255) 360deg, rgb(108, 112, 113) 360deg)';
  };

  const playNext = () => {
    onNext();
    clearInterval(progressRef.current);
  };

  return {
    title,
    playBtnRef,
    btnImg,
    handleClickCancelBtn,
    cancelBtn,
    playNext
  };
};

export const ThumbnailLink = hoc(
  useThumbnailProps,
  ({
    title,
    btnImg,
    cancelBtn,
    playNext,
    handleClickCancelBtn,
    playBtnRef,
     isScored,
     hasNext,
  }) => {
    return (
      <ThumbnailLinkContainer>
        {/* {
          !isScored ? (
            <CongratulationsText>
              Tabriklaymiz! <br />
              Ushbu darsni ko'rganingiz uchun sizga 1 ball berildi!
            </CongratulationsText>
          ) : <div></div>
        } */}
       {
        hasNext && (
          <FlexWrapper direction='column'>
          <Title>Keyingi dars</Title>
          <div>
            <h4>{title}</h4>
            <CircularProgress ref={playBtnRef} onClick={playNext}>
              <ValueContainer
                src={btnImg ? PlayButton : PauseButton}
                alt='play'
              />
            </CircularProgress>
            <CancelBtn onClick={handleClickCancelBtn} ref={cancelBtn}>
              Bekor qilish
            </CancelBtn>
          </div>
        </FlexWrapper>
        )
       }
        <span />
      </ThumbnailLinkContainer>
    );
  }
);
