import { FlexWrapper } from '@components/layouts/flex-wrapper';
import styled from 'styled-components';

export const CourseNotesWrapper = styled.div``;

export const CourseNotesForm = styled.form`
  padding-top: 24px;
  border-top: 1px solid #dadada;
`;

export const CourseNotesTitleCore = styled.h3`
  max-width: ${props => (props?.long ? '311px' : '311px !important')};
`;

export const CourseNotesTitle = styled(CourseNotesTitleCore)(
  () => `
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
   `,

  ({ theme: { down } }) => `
   ${down('1034px')} {
    max-width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
   }

   ${down('464px')} {
    max-width: 220px;
   }

   ${down('430px')} {
    max-width: 150px;
   }
   `
);

export const CourseNotesCount = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #ba8d5b;
`;

export const CourseQuestionWrapper = styled(FlexWrapper)`
  width: 100%;
`

export const CourseNotesNotItemTitle = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.25);
  margin-top: 26px;
`;

export const CourseNotesNoItemImg = styled.img`
  width: 81px;
  height: 97px;
`;

export const CourseNotesEditExample = styled.div`
  background: #f7f7f5;
  border-radius: 8px;
  padding: 8px 26px 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CourseNotesEditExampleLeft = styled.div(
  () => `
    padding: 19px 25px;
    border-right: 2px solid #ba8d5b;
    display: flex;
    justify-content: center;
    align-items: center;
  
    svg {
      width: 16px;
      height: 16px;
    }
   `,

  ({ theme: { down } }) => `
   ${down('700px')} {
    padding: 19px 12px;
   }
   `
);

export const CourseNotesEditTitle = styled.h4(
  () => `
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #4e6575;
    margin-bottom: 8px;
  `,

  ({ theme: { down } }) => `
   ${down('500px')} {
    margin-bottom: 0;
   }
   `
);

export const CourseNotesEditText = styled.p(
  () => `
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #000000;
    max-width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    
   `,

  ({ theme: { down } }) => `
   ${down('1034px')} {
    max-width: 500px;
    white-space: normal;
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
   }

   ${down('768px')} {
    max-width: 400px;
   }

   ${down('576px')} {
    max-width: 250px;
   }
   `
);

export const CourseNotesBtn = styled.button(
  () => `
    border: none;
    background-color: transparent;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
    }
  `,

  ({ theme: { down } }) => `
  ${down('576px')} {
    width: 20px;
    height: 20px;
   }
  `
);

export const CourseNotesBottom = styled.div(
  () => `
  border-top: 1px solid #dadada;
  padding: 18px 0 24px 0;
  `,

  ({ theme: { down } }) => `
   ${down('500px')} {
    padding: 8px 16px 12px 10px;
   }
   `
);

export const DeleteTitle = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  max-width: 304px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 32px;
  margin-top: 12px;
`;

export const DeleteBtnsCore = styled.button`
  background: ${props => (props.danger ? '#EA4335' : '#fff')};
  border: 1px solid ${props => (props.danger ? 'transparent' : '#dadada')};
  color: ${props => (props.danger ? '#fff' : '#000000')};
  margin-left: ${props => props.danger && '16px'};
  transition: all 0.3s;

  &:hover {
    background: ${props => props.danger ? '#fff' : '#BA8D5B'};
    color: ${props => props.danger ? '#000' : '#fff'};
    border: 1px solid ${props => props.danger ? '#EA4335' : 'transparent'}
  }
`;

export const DeleteBtns = styled(DeleteBtnsCore)(
  () => `
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    padding: 13px 80px;
   `,

  ({ theme: { down } }) => `
   ${down('500px')} {
    padding: 12px 59px 13px 32px;
   }
   `
);
