export const DocumentIcon = () => {
    return (
        <>
            <svg fill="#f00" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                viewBox="0 -100 540 720" space="preserve">
                <g>
                    <g>
                        <path d="M443.103,71.306H416.76V44.963c0-5.142-4.167-9.31-9.31-9.31h-26.342V9.31c0-5.142-4.167-9.31-9.31-9.31h-302.9
			c-5.143,0-9.31,4.169-9.31,9.31v422.074c0,5.142,4.167,9.31,9.31,9.31H95.24v26.342c0,5.142,4.167,9.31,9.31,9.31h26.342v26.342
			c0,5.142,4.167,9.31,9.31,9.31h302.9c5.143,0,9.31-4.169,9.31-9.31V80.616C452.413,75.474,448.246,71.306,443.103,71.306z
			 M78.208,422.074V18.621h284.279v403.453H78.208z M113.862,457.726v-17.032h257.935c5.143,0,9.31-4.169,9.31-9.31V54.274h17.033
			v403.453H113.862z M433.792,493.379H149.513v-17.032H407.45c5.143,0,9.31-4.169,9.31-9.31V89.927h17.032V493.379z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M271.292,95.592H169.404c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h101.887c5.143,0,9.31-4.169,9.31-9.31
			S276.435,95.592,271.292,95.592z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M313.448,141.771h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31
			S318.59,141.771,313.448,141.771z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M313.448,187.948h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31
			S318.59,187.948,313.448,187.948z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M313.448,234.125h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31
			S318.59,234.125,313.448,234.125z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M313.448,280.303h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31
			S318.59,280.303,313.448,280.303z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M313.448,326.48h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31
			S318.59,326.48,313.448,326.48z"/>
                    </g>
                </g>
            </svg>

        </>
    )
}