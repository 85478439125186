import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { config } from '@config/config';
import { hoc } from '@utils/hoc';
import { useCourseNotPurchasedProp } from './not-purchased.prop';
import {
  CourseCategory,
  CourseImg,
  CourseText,
  CourseTitle,
  NotPurchasedBuyBtn,
  NotPurchasedLeft,
  NotPurchasedRight,
  NotPurchasedWrapper
} from './not-purchased.style';

export const CourseNotPurchased = hoc(
  useCourseNotPurchasedProp,
  ({ singleCourse, handleBuyAllCourses, mq864MaxWidth }) => {
    return (
      <NotPurchasedWrapper>
        <NotPurchasedLeft>
          <div>
            {!mq864MaxWidth && (
              <CourseCategory
                textColor={singleCourse?.category?.textColor}
                color={singleCourse?.category?.color}
              >
                {singleCourse?.category?.name}
              </CourseCategory>
            )}
            <FlexWrapper gap='10px' justify='space-between' align='flex-start'>
              <CourseTitle>{singleCourse?.name}</CourseTitle>

              {mq864MaxWidth && (
                <CourseCategory
                  textColor={singleCourse?.category?.textColor}
                  color={singleCourse?.category?.color}
                >
                  {singleCourse?.category?.name}
                </CourseCategory>
              )}
            </FlexWrapper>
            <CourseText
              dangerouslySetInnerHTML={{ __html: singleCourse?.desc }}
            />
          </div>

          {/* <NotPurchasedBuyBtn onClick={handleBuyAllCourses}>
            Modulni sotib olish
          </NotPurchasedBuyBtn> */}
        </NotPurchasedLeft>
        <NotPurchasedRight color={singleCourse?.color}>
          <CourseImg
            src={`${config.imgApiUrl}/${singleCourse?.imageIllustration}`}
            alt='course-img'
          />
        </NotPurchasedRight>
      </NotPurchasedWrapper>
    );
  }
);
