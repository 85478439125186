export const useInputProps = ({ onChange, value, name }) => {
  const _onChange = e => {
    const value = e.target.value;
    onChange?.(value);
  };

  return {
    _onChange
  };
};
