import {
  CourseLessonDuration,
  CourseLessonItem,
  CourseLessonItemLink,
  CourseLessonItemLinkContent,
  CourseLessonItemLinkIcon,
  CourseLessonItemLinkLessonCount,
  CourseLessonItemLinkLessonHeading,
  CoursesTitleWrapper,
  CourseItemLinkWrapper
} from './lesson-item.style';

import itemCheckedIcon from '@assets/img/checked.svg';
import incompletedIcon from '@assets/img/incomplete-icon.svg';
import itemPlayIcon from '@assets/img/play.svg';
import Documents from '@assets/img/documents.svg'
import notViewedIcon from '@assets/img/circle-icon.svg'
import itemPlayIconBg from '@assets/img/viewing.svg'
import { useDispatch } from 'react-redux';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { secondsFormat } from '@utils/date-format-time';
import { AllCoursesActions } from '@all-courses/store';
import { useParams, useHistory } from 'react-router-dom';
import { useMediaQuery } from '@utils/useMediaQuery';
import { ERROR_TYPES } from '@modules/all-courses/constants';
import { commonActions } from '@store/common';
import { DocumentIcon } from './icon';

export const LessonItem = ({
  lessonSequence,
  item,
  isViewed = false,
  isViewing = false,
  inProgress = false,
  isDisabled = false,
  chapter
}) => {
  const dispatch = useDispatch();
  const mqMaxWidth1034 = useMediaQuery('max-width: 1034px');
  const { moduleId } = useParams();
  const history = useHistory();

  const handleChangeVideo = lesson => {
    const newActiveCourse = {
      lesson,
      chapter
    };
    dispatch(AllCoursesActions.changeActiveCourse(newActiveCourse));
    dispatch(AllCoursesActions.setSendedHomeWorks(null));
    dispatch(AllCoursesActions.setDefault('topic'));
    dispatch(AllCoursesActions.fetchSingleModuleWithoutLoading({ id: moduleId }));

    if (lesson?.video?.id) {
      dispatch(
        AllCoursesActions.upsertLesson({
          data: {
            lessonId: lesson?.id,
            lastSecond: lesson?.lastSecondTime
          },
          callback: res => {
            if (res?.error?.errId === ERROR_TYPES.courseModuleNotFound) {
              history.push('/inactive-module');
              dispatch(commonActions.setUpsertError(true));
            } else if (res?.error?.errId === ERROR_TYPES.courseNotFound) {
              history.push('/inactive');
            }
          }
        })
      );
    }
  };

  return (
    <CourseLessonItem>
      <CourseLessonItemLink data-testid={'course_lesson'} onClick={handleChangeVideo.bind(null, item)}>
        <CourseLessonItemLinkIcon
          isViewed={isViewed}
          isViewing={isViewing}
          isDisabled={isDisabled}
          isEmpty={item.video?.info?.hlsLink}
        >
          {item?.video?.info?.hlsLink && isViewed ? (
            <img
              src={itemCheckedIcon}
              alt=''
              width={20}
              height={20}
            />
          ) : isViewing && !item?.video?.info?.hlsLink ? (
            <DocumentIcon />
          ) : !item?.video?.info?.hlsLink ? (
            <img
              src={Documents}
              width={20}
              height={20}
              alt={'Document'}
            />
          ) : isViewing ? (
            <CourseItemLinkWrapper>
              <img src={itemPlayIconBg} width={20} height={24} alt={'Icon background'} />
              <img className={'play'} src={itemPlayIcon} width={12} height={12} alt={'Play icon'} />
            </CourseItemLinkWrapper>
          ) : (inProgress && item.progress > 0 && item?.progress < 100) ? (
            <img
              src={incompletedIcon}
              alt={'Half play icon'}
              width={20}
              height={20}
            />
          ) : (
            <img
              src={notViewedIcon}
              alt='Circle icon'
              width={20}
              height={20}
            />
          )}
        </CourseLessonItemLinkIcon>

        <CourseLessonItemLinkContent>
          <FlexWrapper direction='column' className='course-lesson-item'>
            <CoursesTitleWrapper>
              <CourseLessonItemLinkLessonHeading data-tippy-content={item?.title}>
                {item?.title}
              </CourseLessonItemLinkLessonHeading>
            </CoursesTitleWrapper>

            <FlexWrapper mt='3px' mr={mqMaxWidth1034 && '20px'}>
              <CourseLessonItemLinkLessonCount>
                {lessonSequence}-dars
              </CourseLessonItemLinkLessonCount>

              {!!item?.video?.duration && (
                <CourseLessonDuration>
                  Davomiyligi: <span>{item?.video?.duration ? secondsFormat(item?.video?.duration) : ''}</span>
                </CourseLessonDuration>
              )}
            </FlexWrapper>
          </FlexWrapper>
        </CourseLessonItemLinkContent>
      </CourseLessonItemLink>
    </CourseLessonItem>
  );
};
