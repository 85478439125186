import { FlexWrapper } from '@components/layouts/flex-wrapper';
import styled from 'styled-components';

export const TextareaContainer = styled.div(
  () => `
    border: 1px solid #dadada;
    border-radius: 8px;
    padding: 8px 26px 8px 0;
   `,

  ({ theme: { down } }) => `
   ${down('600px')} {
    padding: 8px 18px 8px 0;
   }
   `
);

export const TimeWrapper = styled.div(
  () => `
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-right: 2px solid #eae9e7;
   `,

  ({ theme: { down } }) => `
   ${down('600px')} {
    padding: 12px;
   }
   `
);

export const Time = styled.button(
  () => `
    background: #eae9e7;
    border-radius: 100px;
    border: none;
    color: rgba(0, 0, 0, 0.35);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 12px;
    padding: 4px 8px;
    text-align: center;

    &.editable {
      background: rgba(186, 141, 91, 0.1);
      color: #ba8d5b;
    }
   `,

  ({ theme: { down } }) => `
   ${down('600px')} {
    font-size: 12px;
   }
   `
);

export const TextareaFieldCore = styled.textarea`
  width: ${props => props.width || '100%'};
`;

export const TextareaField = styled(TextareaFieldCore)(
  () => `
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    border: none;
    resize: none;
    outline: none;
    padding-left: 16px;
    padding-top: 12px;

    &::-webkit-scrollbar {
        display: none;
    }

    &::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: rgba(0, 0, 0, 0.35);
    }
   `,

  ({ theme: { down } }) => `
   ${down('600px')} {
    padding-left: 12px;
    padding-right: 5px;
   }
   `
);

export const TextAreaWrapper = styled(FlexWrapper)`
  width: 100%;
`;

export const SendBtn = styled.button`
  width: 20px;
  height: 20px;
  position: relative;
  border: none;
  align-self: flex-end;
  background-color: transparent;
  margin-bottom: 12px;
  margin-left: 8px;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;
