import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useTechInfoProp = () => {
  const certificateDetail = useSelector(
    state => state.certificates.certificateDetail
  );

  const studentSkills = useMemo(() => {
    const skills = [];

    certificateDetail?.modules?.forEach(item => {
      skills.push(item.skills);
    });

    return skills?.flat();
  }, [certificateDetail]);

  return {
    studentSkills
  };
};
