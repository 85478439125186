import { Link } from "react-router-dom";
import styled from "styled-components";

export const CourseProgressWrapper = styled(Link)(
    () =>`
        background-color: #F7F7F5;
        border-radius: 8px;
        padding: 24px;
    `,
    ({theme: {down}}) =>`
      ${down('890px')}{
        padding: 16px;
      }
    `
);

export const CourseProgressIconBtn = styled.div`
    background-color: ${props => props.bgColor ? props.bgColor : 'rgba(186, 141, 91, 0.1)'};
    border-radius: 24px;
    padding: 12px;
    height: 44px;
    width: 44px;
`

export const CourseProgressTitle = styled.h4(
    () =>`
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #000000;

        &:hover {
        cursor: pointer;
        text-decoration: underline;
        }

    `,
    ({theme: {down}}) =>`
      ${down('890px')}{
        font-size: 14px;
        line-height: 20px;
      }
    `
);

export const CourseProgressSubtitle = styled.p(
    () =>`
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: rgba(0, 0, 0, 0.35);
    `,
    ({theme: {down}}) =>`
      ${down('890px')}{
        font-size: 12px;
        line-height: 18px;
      }
    `
);

export const CourseProgressInfo = styled.h4`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.75);

`

export const CourseProgressBar = styled.div`
    background: rgba(186, 141, 91, 0.1);
    border-radius: 2px;
    width: 100%;
    height: 4px;
    margin-top: 8px;
`

export const CourseProgressEnded = styled.span`
    background: #BA8D5B;
    border-radius: 2px;
    height: 100%;
    width: ${props => props.width}%;
    display: block;
`
