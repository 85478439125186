import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useCourseHomeworkProp } from './course-homework.prop';
import {
  CourseHomeworkWrapper,
  HomeworkAcceptedMessage,
  HomeworkBottom,
  HomeworkCount,
  HomeworkText,
  HomeworkTitle
} from './course-homework.style';
import { HomeworkCard } from './homework-card';
import { HomeworkMark } from './homework-mark';
import { HomeworkPending } from './homework-pending';
import { HomeworkTextarea } from './homework-textarea';
import { MyHomework } from './my-homwork';

const ACCEPTED_HOMEWORK_ID = 4;

export const CourseHomeWork = hoc(
  useCourseHomeworkProp,
  ({
    isAvailable,
    setIsAvailable,
    homework,
    pendingResult,
    isShowTextArea,
    setSendedHomeWorks,
    sendedHomeWorks
  }) => {
    return (
      <>
        <CourseHomeworkWrapper className={'homework-wrapper'}>
          <FlexWrapper mb='12px' justify='space-between' align='center'>
            <HomeworkTitle>Uyga vazifa</HomeworkTitle>
            <HomeworkCount>
              Fayllar soni: {homework?.homeworkFiles?.length} ta
            </HomeworkCount>
          </FlexWrapper>
          <HomeworkText
            dangerouslySetInnerHTML={{ __html: homework?.homeworkInfo?.desc }}
          />

          {homework?.homeworkFiles?.length > 0 && (
            <FlexWrapper
              wrap
              justify='flex-start'
              gap={'24px'}
              className={'homework-files'}
            >
              {homework.homeworkFiles.map((item, index) => (
                <HomeworkCard
                  key={index}
                  title={item?.origname}
                  size={item?.size}
                  link={item?.url}
                />
              ))}
            </FlexWrapper>
          )}
        </CourseHomeworkWrapper>

        {homework?.homeworkChats?.length > 0 &&
          homework?.homeworkChats?.map((item, index) => (
            <div key={index}>
              {item?.userType === 4 ? (
                <MyHomework
                  setIsAvailable={setIsAvailable}
                  homework={homework}
                  item={item}
                  setSendedHomeWorks={setSendedHomeWorks}
                />
              ) : (
                <HomeworkMark
                  isAvailable={isAvailable}
                  pending={pendingResult}
                  setIsAvailable={setIsAvailable}
                  homework={homework}
                  item={item}
                />
              )}
              {item?.status === 2 && <HomeworkPending />}
            </div>
          ))}

        {isShowTextArea && (
          <HomeworkBottom>
            <HomeworkTextarea
              setSendedHomeWorks={setSendedHomeWorks}
              sendedHomeWorks={sendedHomeWorks}
              setIsAvailable={setIsAvailable}
            />
          </HomeworkBottom>
        )}


        {/* {homework?.homeworkChats?.[homework?.homeworkChats?.length - 1]
          ?.status === ACCEPTED_HOMEWORK_ID && (
          <HomeworkAcceptedMessage>
            Tabriklaymiz! Ushbu uy vazifa uchun sizga 2 ball berildi!
          </HomeworkAcceptedMessage>
        )} */}
      </>
    );
  }
);
