import { AllCoursesActions } from '@all-courses/store';
import { useMediaQuery } from '@utils/useMediaQuery';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const filterOptions = [
  { label: 'yangi savollar', value: 'desc' },
  { label: 'eski savollar', value: 'asc' },
  { label: 'mashhur savollar', value: 'popular' }
];

export const useCourseQuestionsProps = _ => {
  const dispatch = useDispatch();
  const textareaRef = useRef();
  const searChInputRef = useRef();
  const [sort, setSort] = useState('desc');
  const [search, setSearch] = useState('');
  const activeLesson = useSelector(state => state.courses.activeLesson);
  const [isSearchInputOpen, setIsSearchInputOpen] = useState('');
  const courseComments = useSelector(state => state.courses.comments);
  const commentsLoading = useSelector(state => state.courses.commentsLoading);
  const commentReply = useSelector(state => state.courses.replyInfo);
  const commentEdit = useSelector(state => state.courses.commentEditInfo);
  const [isLoading, setIsLoading] = useState(false)
  const comments = [
    {
      name: 'Test',
      title: 'Test'
    }
  ];
  const activeChapter = activeLesson?.chapter?.id;

  const handleChangeSort = value => {
    setSort(value);
  };
  const hasAlert = useSelector(state => state.common.showAlert);
  const alertMsg = useSelector(state => state.courses.alertMsg);
  const allComments = useSelector(state => state.courses.allComments);

  const mq500MaxWidth = useMediaQuery('(max-width: 500px)');

  const handleSubmit = () => {
    if(isLoading) return

    if (commentEdit?.id && !isLoading) {
      const obj = {
        data: {
          id: commentEdit?.id,
          info: {
            message: textareaRef.current?.value
          }
        },
        callback: res => {
          if (res?.success) {
            setIsLoading(false)
            dispatch(
              AllCoursesActions.fetchComments({
                chapterId: activeChapter,
                isMine: true
              })
            );
            dispatch(
              AllCoursesActions.getAllComments({
                chapterId: activeChapter,
                isMine: false
              })
            );
            textareaRef.current.value = '';
            dispatch(AllCoursesActions?.setCommentEditInfo({}));
          }
          else {
            setIsLoading(false)
          }
        }
      };

      setIsLoading(true);
      dispatch(AllCoursesActions.editComment(obj));

      return;
    }

    const obj = {
      info: {
        chapterId: activeChapter,
        message: textareaRef.current?.value,
        replyParentId: commentReply?.id ? commentReply?.id : undefined
      },
      callback: res => {
        if (res?.success) {
          dispatch(
            AllCoursesActions.fetchComments({
              chapterId: activeChapter,
              isMine: true
            })
          );
          dispatch(
            AllCoursesActions.getAllComments({
              chapterId: activeChapter,
              isMine: false
            })
          );
          textareaRef.current.value = '';

          if (commentReply?.id) {
            dispatch(AllCoursesActions?.setReply({}));
          }

          setIsLoading(false)
        }
      }
    };

    setIsLoading(true);
    dispatch(AllCoursesActions.addComment(obj));
  };

  const handleCancel = () => {
    dispatch(AllCoursesActions?.setReply({}));
  };

  const handleCancelEdit = () => {
    dispatch(AllCoursesActions.setCommentEditInfo({}));
    textareaRef.current.value = '';
  };

  const handleFocusChildInput = () => {
    searChInputRef.current.focus();
  };

  const handleOpenSearchInput = () => {
    setIsSearchInputOpen(true);
  };

  const searchQuestion = () => {
    setSearch(textareaRef.current.value);
  };

  useEffect(() => {
    const closestSearch = e => {
      if (!e.target.closest('.search-input-wrapper')) {
        setIsSearchInputOpen(false);
      }
    };
    window.addEventListener('click', closestSearch);

    return () => {
      window.removeEventListener('click', closestSearch);
    };
  }, []);

  useEffect(() => {
    dispatch(AllCoursesActions.setCommentsLoading(true))
    if (activeChapter) {
      const data = {
        chapterId: activeChapter,
        isMine: true,
        callback: () => {
          dispatch(AllCoursesActions.setCommentsLoading(false))
        }
      }
      dispatch(AllCoursesActions.fetchComments(data));

      dispatch(
        AllCoursesActions.getAllComments({
          chapterId: activeChapter,
          isMine: false
        })
      );
    }

    return () => {
      dispatch(AllCoursesActions.fetchCommentsFulfilled([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChapter]);

  useEffect(() => {
    if (commentReply?.id) {
      textareaRef?.current?.focus();
    }
  }, [commentReply?.id]);

  useEffect(() => {
    if (commentEdit?.id) {
      textareaRef?.current?.focus();
      textareaRef.current.value = commentEdit?.message;
    }
  }, [commentEdit]);

  return {
    comments,
    filterOptions,
    textareaRef,
    handleChangeSort,
    sort,
    searchQuestion,
    setSearch,
    isSearchInputOpen,
    handleOpenSearchInput,
    searChInputRef,
    handleFocusChildInput,
    courseComments,
    handleSubmit,
    commentReply,
    handleCancel,
    mq500MaxWidth,
    hasAlert,
    alertMsg,
    allComments,
    commentEdit,
    handleCancelEdit,
    commentsLoading,
    isLoading
  };
};
