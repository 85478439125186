import styled from 'styled-components';

export const HomeworkWrapper = styled.div`
  max-width: 621px;
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 26px;
`;

export const Homeworks = styled.div`
  background: #f9f4ef;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
`;

export const HomeworkTitle = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const HomeworkFilesCount = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${props => (props.success ? '#07CD86' : '#ba8d5b')};
`;

export const HomeworkText = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
`;

export const MyHomeworks = styled.div`
  background: #f7f7f5;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
`;
