import * as React from 'react'
import { Input } from '@components/atoms/Input'
import { FlexWrapper } from '@components/layouts/flex-wrapper'
import { PhoneNumberInput } from '@components/phone-number-input'
import CloseEyeImage from '@assets/img/close-eye.svg'
import OpenEyeImage from '@assets/img/open-eye.svg'
import {
  Button,
  EyeButton,
  EyeImg,
  ForgetPassword,
  Form,
  FormGroup,
  InputError,
  InputLabels,
} from '@modules/new-auth/routes/login/login.style'
import { hoc } from '@utils/hoc'
import { usePhoneFormProp } from './phone-form.prop'
import { PASSWORD } from '@modules/new-auth/constants'
import { RegisterWithGoogle } from '../../RegisterRightSide/RegisterWithGoogle'

export const PhoneForm = hoc(
  usePhoneFormProp,
  ({
    inputType,
    handleChangePasswordInput,
    handleBlurPhone,
    formik,
    handleChangePhoneInput,
    handleOpenForgetPhoneModal,
    handlePastePhoneInput,
    handleInputPasswordChange,
    handleInputPasswordBlur,
  }) => {
    return (
      <>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup mb>
            <InputLabels htmlFor="phone">Telefon raqam</InputLabels>
            <PhoneNumberInput
              format="## ### ## ##"
              prefix="+998"
              placeholder="00 000 00 00"
              id="phone"
              name="phone"
              onBlur={handleBlurPhone}
              onChange={handleChangePhoneInput}
              onPaste={handlePastePhoneInput}
              value={formik.values.phone}
              className={formik.errors?.phone ? 'input-error' : ''}
            />
            {formik.errors?.phone && <InputError>{formik.errors?.phone}</InputError>}
          </FormGroup>

          <FormGroup>
            <InputLabels>Parol</InputLabels>

            <FlexWrapper align="center" className={`wrapper ${formik.errors.password ? 'input-error' : ''}`}>
              <Input
                placeholder="Parolingizni kiriting"
                className="password-input"
                type={inputType}
                onChange={handleInputPasswordChange}
                onBlur={handleInputPasswordBlur}
                name="password"
                values={formik.values.password}
              />

              <EyeButton type="button">
                <EyeImg
                  src={inputType === PASSWORD ? CloseEyeImage : OpenEyeImage}
                  alt="eye"
                  onClick={handleChangePasswordInput}
                />
              </EyeButton>
            </FlexWrapper>
            {formik.errors?.password && <InputError bottom={'initial'}>{formik.errors?.password}</InputError>}
          </FormGroup>

          <ForgetPassword onClick={handleOpenForgetPhoneModal}>Parolingizni unutdingizmi?</ForgetPassword>
          <Button mb type={'submit'}>
            Kirish
          </Button>
          <RegisterWithGoogle isLogin />
        </Form>
      </>
    )
  },
)
