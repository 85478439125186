import { AllCoursesRootSaga } from '@all-courses/store';
import { authRootSaga } from '@auth/store';
import { dashboardRootSaga } from '@dashboard/store';
import { VideosRootSaga } from '@modules/videos/store';
import { PaymentsRootSaga } from '@payments/store';
import { settingsRootSaga } from '@settings/store';
import { all, fork } from 'redux-saga/effects';
import { commonRootSaga } from './common';
import { notificationRootSaga } from '@modules/notifications/store';
import { CertificateRootSaga } from '@modules/certificates/store';
import {ratingRootSaga} from "@modules/rating/store/rating.saga";
import {myRatingsRootSaga} from "@modules/my-ratings/store";

export function* rootSaga() {
  yield all([
    fork(commonRootSaga),
    fork(authRootSaga),
    fork(settingsRootSaga),
    fork(dashboardRootSaga),
    fork(AllCoursesRootSaga),
    fork(PaymentsRootSaga),
    fork(VideosRootSaga),
    fork(notificationRootSaga),
    fork(CertificateRootSaga),
    fork(ratingRootSaga),
    fork(myRatingsRootSaga)
  ]);
}
