import styled from 'styled-components';

export const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  cursor: pointer;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalMessage = styled.div`
  width: 41.3rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* gap: 26px; */
  padding-left: 5.1rem;
  padding-right: 5.4rem;
  padding-bottom: 5.1rem;
  border-radius: 8px;
  position: relative;
  z-index: 61;
  cursor: default;
`;
export const IconStyle = styled.div`
  background: #4bd37b;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
`;

export const MessageText = styled.p`
  color: #000;
  font-size: 1.8rem;
  line-height: 2.8rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 44px;
  margin-top: 24px;
`;
export const BackIconStyle = styled.div`
  position: absolute;
  top: 22px;
  right: 25px;
  color: #000;
  cursor: pointer;
`;

export const ConfirmButton = styled.button`
  width: 100%;
  background: #ba8d5b;
  border-radius: 100px;
  padding: 12px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  border: none;
  outline: none;
`;
