import { createSlice } from "@reduxjs/toolkit";

const name = 'videos';
const initialState = {
  loading: false,
  lastVideosLoading: true,
  error: null,
  recentlyViewedVideos: [],
  bookmarkVideos: []
}

export const {reducer: videosReducer, actions: videosActions} = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    fetchRecentyleVideos(state) {
      state.loading = true;
      state.lastVideosLoading = true;
    },
    fetchRecentyleVideosFulfilled(state, action) {
      state.recentlyViewedVideos = action.payload;
      state.lastVideosLoading = false
    },
    bookmarkVideo(state) {
      state.loading = true;
    },
    fetchBookmarkVideos(state) {
      state.loading = true;
    },
    fetchBookmarkVideosFulfilled(state, action) {
      state.loading = false
      state.bookmarkVideos = action.payload;
    },
    changePositionBookmarVideo(state) {
      state.loading = true;
    },
    deleteBookmarkVideo(state) {
      state.loading = true;
    }
  }
})
