import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const RightSide = styled.div(
  () => `
        width: 50vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    
        @media screen and (max-height: 474px) {
          height: auto;
        }
      `,

  ({ theme: { down } }) => `
        ${down('886px')} {
          width: 100%;
        }
        
        ${down('420px')} {
          max-width: 100%;
          padding: 0 10px 0 10px;
        }
    
       
      `
);

export const RightSideInner = styled.div(
  () => `
        display: flex;
        max-width: 406px;
        flex-direction: column;
        justify-content: center;
      `
);

export const LoginTitle = styled.h2(
  () => `
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.02em;
        color: #000000;
        margin-bottom: 18px;
      `,

  ({ theme: { down } }) => `
        ${down('768px')} {
          font-size: 24px;
          line-height: 29px;
        }
      `
);

export const NestedLink = styled(NavLink)(
  () => `
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.5);
    padding: 10px 26px;
    border-bottom: 1px solid #dadada;

    &.selected {
      color: #ba8d5b;
      border-bottom: 2px solid #ba8d5b;
    }
  `,

  ({ theme: { down } }) => `
        ${down('576px')} {
          font-size: 14px;
          padding: 8px 20px;
        }
      `
);
