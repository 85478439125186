import { FlexWrapper } from '@components/layouts/flex-wrapper'
import { Skeleton } from '@components/skeleton/skeleton-box'
import React from 'react'

export const CourseQuestionSkeleton = () => {
  return (
    <FlexWrapper direction='column'>
      <Skeleton marginBottom={25} width={150} height={20} />

      {
        Array.from(Array(10)).map(item => {
          return (
            <FlexWrapper
              key={item}
              mb={'30px'}
              align={'flex-start'}
              justify='space-between'
              width='100%'
            >
              <FlexWrapper width='80%'>
                <FlexWrapper>
                  <Skeleton
                    marginRight={20}
                    width={45}
                    height={45}
                    variant='circle'
                  />
                </FlexWrapper>
                <FlexWrapper width='100%' direction='column'>
                  <FlexWrapper align='center'>
                    <Skeleton width={150} height={20} marginRight={10} />
                    <Skeleton width={100} height={16} />
                  </FlexWrapper>
                  <Skeleton marginTop={8} fullWidth={50} height={12} />
                  <FlexWrapper mt='20px'>
                    <Skeleton width={80} height={16} marginRight={20} />
                    <Skeleton width={80} height={16} />
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
              <Skeleton width={20} height={40} />
            </FlexWrapper>
          )
        })
      }
    </FlexWrapper>
  )
}
