import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app.component'

import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './store'
import { GlobalStyles } from '@styles/GlobalStyles'
import { ThemeProvider } from 'styled-components'
import { theme } from './styles'
import './styles/index.css'
import 'plyr/dist/plyr.css'
import 'tippy.js/dist/tippy.css'
import 'react-toastify/dist/ReactToastify.min.css'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import './fetchIntercept'
import { SITE_KEY } from '@constants/app'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
        <GoogleOAuthProvider clientId="414378413979-n9a6laffcugocl9n33mlb2smrgv2c388.apps.googleusercontent.com">
          <ReduxProvider store={store}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <App />
            </ThemeProvider>
          </ReduxProvider>
        </GoogleOAuthProvider>
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)
