import { AllCoursesActions } from '@all-courses/store';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@utils/useMediaQuery';
import { useEffect } from 'react';
import { formatBytes } from '@utils/byteToMb';
import { addNotification } from '@components/notifications';
import {COURSE_HOMEWORK, COURSE_LESSON_HOMEWORK} from "@all-courses/constants";

const fileSizeCheck = 30;
const MB = 'MB';
const EXPIRED_EDIT_HOMEWORK_ERROR_ID = 140
export const useHomeworkTextareaProp = ({
  onChange,
  setIsAvailable,
  sendedHomeWorks,
  setSendedHomeWorks
}) => {
  const dispatch = useDispatch();
  const [textarea, setTextArea] = useState('');
  const _textareaRef = useRef();
  const [fileType, setFileType] = useState(false);
  const [files, setFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const playBtnRef = useRef();
  const barRef = useRef();
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const sliderRef = useRef(null);
  const sidebarState = useSelector(state => state.common.sidebarState);
  const sendedHomeWorksStore = useSelector(
    state => state.courses?.sendedHomeworks
  );
  const [sendHomeworkLoading, setSendHomeworkLoading] = useState(false);
  const activeLesson = useSelector(state => state.courses.activeLesson);
  const isAvailableSend = useSelector(state => state.courses.isAvailableSend);
  const homeworkId = useSelector(
    state => state.courses.homework?.homeworkInfo?.id
  );
  const [scriptBtnState, setScriptBtnState] = useState(true);
  const mqMaxWidth768 = useMediaQuery('(max-width: 768px)');
  const supportedMimetypes = {
    zip: 'application/zip',
    rar: 'application/vnd.rar'
  };
  const activeChapter = useSelector(
    state => state.courses?.activeLesson?.chapter
  );
  const isChange = useSelector(state => state.courses.isChange);

  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1
    };
  }, []);

  useEffect(() => {
    if (sendedHomeWorks) {
      setTextArea(sendedHomeWorks.desc);
      setFiles(sendedHomeWorks?.files);
      setAllFiles(sendedHomeWorks?.files);
    }


    return () => {
      setTextArea('');
      setFiles([]);
      setAllFiles([]);
      setSendedHomeWorks(null);
    };
  }, [sendedHomeWorks]);

  useEffect(() => {
    if (sendedHomeWorksStore) {
      setSendedHomeWorks(sendedHomeWorksStore);
    }

  }, [sendedHomeWorksStore]);

  const _onChange = value => {
    setTextArea(value);
    if (_textareaRef.current) {
      _textareaRef.current.style.height = 'auto';
      _textareaRef.current.style.height =
        _textareaRef.current.scrollHeight + 'px';
    }
    if (value?.trim()) {
      setScriptBtnState(false);
    } else {
      setScriptBtnState(true);
    }

    onChange?.(textarea);
  };

  const isDisabledIcon = useMemo(() => {
    return (textarea?.trim()?.length > 0 || allFiles.length) && textarea?.length < 1000;
  }, [textarea, allFiles.length]);


  const clearFormState = () => {
    setTextArea('');
    setFiles([]);
    setFile(null);
    setIsAvailable(false);
  };

  const handleHomeworkResponse = (res) => {
    if (res.success) {
      if (activeChapter?.homeworkType === COURSE_HOMEWORK) {
        dispatch(AllCoursesActions.fetchCourseHomework(activeChapter?.id));
      } else if (activeChapter?.homeworkType === COURSE_LESSON_HOMEWORK) {
        dispatch(AllCoursesActions.fetchCourseByLessonHomework(activeLesson.lesson?.id))}
      clearFormState();
      dispatch(AllCoursesActions.setIsChange(false));
    }
    else if (res?.error?.errId === EXPIRED_EDIT_HOMEWORK_ERROR_ID) {
      addNotification({
        title: 'Afsuski, vazifani faqat 10 daqiqa ichidagina o\'zgartirish mumkin',
        options: {
          autoClose: true,
          type: 'error'
        }
      });
    }
    else {
      console.error(res);
    }
    setSendHomeworkLoading(false);
  };

  const editHomework = (id) => {
    setSendHomeworkLoading(true);
    const data = {
      info: {
        data: {
          desc: textarea || '',
          homeworkId: homeworkId,
          homeworkFiles: allFiles
        },
        id: isChange?.id || id
      },
      callback: handleHomeworkResponse
    };
    dispatch(AllCoursesActions.changeHomework(data));
  };

  const sendNewHomework = () => {
    setSendHomeworkLoading(true);
    const data = {
      info: {
        desc: textarea || '',
        homeworkId: homeworkId,
        homeworkFiles: allFiles
      },
      callback: handleHomeworkResponse
    };
    dispatch(AllCoursesActions.sendHomework(data));
  };

  const handleSubmit = e => {
    e?.preventDefault();
    if (isChange?.available) {
      editHomework()
      return
    }
    sendNewHomework()
  };


  const handleCloseTypeModal = () => {
    setFileType(false);
  };

  const handleSelectFile = e => {
    const file = e.target.files;
    const fileArr = file?.[0]?.name?.split('.');
    const ext = fileArr?.[fileArr.length - 1];
    const mimetype = supportedMimetypes[ext];



    if (!fileType) {
      const eFile = e?.target?.files?.[0];
      const eFileSizeMb = formatBytes(eFile?.size);
      const splittedSize = eFileSizeMb?.split(' ');

      if (splittedSize?.[1] === MB && splittedSize?.[0] > fileSizeCheck) {
        addNotification({
          title: "Afsuski, 30mb dan ortiq fayl jo'natishning imkoni yo'q!",
          description:
            "Yuklash faqat 30mb gacha bo'lgan fayllar uchun amal qiladi.",
          options: {
            type: 'error',
            autoClose: true
          }
        });
        return;
      }
      const newFile = {
        name: eFile.name,
        type: mimetype,
        size: eFile.size,
        lastModified: eFile.lastModified,
        lastModifiedDate: eFile.lastModifiedDate,
        id: files[files.length - 1]?.id + 1 || 1
      };
      setFiles([...files, newFile]);
      setFile(e.target?.files[0]);
      e.target.value = null;
    }
  };

  const handleRemoveFile = file => {
    const filtered = files?.filter(item => item.id !== file?.item.id);
    fileRef.current = null;
    if (sendedHomeWorks) {
      const filter = allFiles?.filter((_, index) => file?.index !== index);
      setAllFiles(filter);
      setFiles(filtered);
      return;
    }
    const filter = allFiles?.filter(
      item => item?.origname !== file?.item?.name
    );
    setAllFiles(filter);
    setFiles(filtered);
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return {
    _onChange,
    textarea,
    _textareaRef,
    handleSelectFile,
    setFileType,
    fileType,
    handleCloseTypeModal,
    files,
    handleRemoveFile,
    playBtnRef,
    barRef,
    setFile,
    file,
    fileRef,
    settings,
    sliderRef,
    handlePrev,
    handleNext,
    sidebarState,
    handleSubmit,
    setAllFiles,
    allFiles,
    mqMaxWidth768,
    scriptBtnState,
    isDisabledIcon,
    isAvailableSend,
    sendHomeworkLoading
  };
};
