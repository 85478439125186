import { addNotification } from '@components/notifications'
import { AllCoursesActions } from '@modules/all-courses/store'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { videosActions } from '../store'

const ERR_ID = 131

export const useVideoCardProps = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isOpenManageList, setIsOpenManageList] = useState(false)

  const handleOpenManageListClick = () => {
    setIsOpenManageList(!isOpenManageList)
  }

  const handlePlayVideoClick = (item) => {
    dispatch(
      AllCoursesActions.fetchSingleModule({
        id: item?.moduleId,
        callback: (res) => {
          const data = res?.data
          if (data && res.success) {
            const chapter = data?.chapters?.find((chapter) => chapter?.id === item?.chapterId)
            const lesson = chapter?.lessons?.find((les) => les?.id === item?.lessonId)
            dispatch(AllCoursesActions.changeActiveCourse({ lesson, chapter }))
            history.push(`/course-single/${item?.courseId}/module/${item?.moduleId}`)
          }

          if (res?.error?.errId === ERR_ID) {
            history.push('/inactive-module')
            return
          }

          if (res?.error?.errId === ERR_ID) {
            history.push('/inactive')
          }

          console.error(res?.error?.errId)
        },
      }),
    )
  }

  const handleDeleteBookmarkClick = ({ bookmarkId, item: lesson }) => {
    const data = {
      info: {
        lessonId: lesson?.lessonId,
      },
      callback: () => {
        dispatch(videosActions.fetchBookmarkVideos())
        addNotification({
          description: 'Video “Saqlangan videolar” ro‘yxatidan o‘chirildi',
          title: 'O‘chirildi',
          options: {
            autoClose: true,
            type: 'error',
          },
        })
      },
    }
    dispatch(videosActions.bookmarkVideo(data))
  }

  const handlePerHeadBookmarkClick = (bookmarkId, moveType) => {
    const data = {
      info: {
        moveType: moveType,
      },
      id: bookmarkId,
      callback: (res) => {
        if (res.success) {
          dispatch(videosActions.fetchBookmarkVideos())
          addNotification({
            description:
              moveType === 'PER_HAD'
                ? 'Video “Saqlangan videolar” ro‘yxati boshiga surildi'
                : 'Video “Saqlangan videolar” ro‘yxati oxiriga surildi',
            title: moveType === 'PER_HAD' ? 'Ro‘yxat boshiga surildi' : 'Ro‘yxat oxiriga surildi',
            options: {
              autoClose: true,
              type: 'success',
            },
          })
        }
      },
    }
    dispatch(videosActions.changePositionBookmarVideo(data))
  }

  return {
    handleOpenManageListClick,
    isOpenManageList,
    handlePerHeadBookmarkClick,
    handleDeleteBookmarkClick,
    handlePlayVideoClick,
  }
}
