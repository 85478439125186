import { AccountError } from '@components/account-error';
import { LoginRightSide } from '@modules/new-auth/components/LoginRightSide';
import { hoc } from '@utils/hoc';
import { Redirect } from 'react-router-dom';
import { useLoginProp } from './login.prop';
import { LoginLeftSide, LoginWrapper } from './login.style';

export const Login = hoc(
  useLoginProp,
  ({ token, profile, sessionState, setSessionState, closeModal }) => {
    if (profile && token) {
      return <Redirect to='/dashboard' />;
    }
    return (
      <LoginWrapper>
        <LoginLeftSide />
        <LoginRightSide />

        {sessionState && (
          <AccountError
            error={sessionState}
            setError={setSessionState}
            closeModal={closeModal}
          />
        )}
      </LoginWrapper>
    );
  }
);
