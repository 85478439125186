import { AllCoursesActions } from '@all-courses/store';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CourseNotes } from '../course-notes';
import { CourseQuestions } from '../course-questions';
import { CourseTopic } from '../course-topic';
import { CourseFaq } from '../course-faq';
import {useParams} from "react-router-dom";
import {useMediaQuery} from "@utils/useMediaQuery";

const tabs = {
  topic: <CourseTopic />,
  notes: <CourseNotes />,
  questions: <CourseQuestions />,
  faq: <CourseFaq />
};

export const useCourseTabProp = () => {
  const [activeTab, setActiveTab] = useState('topic');
  const isAvailableHomework = useSelector(
    state => state.courses.homework?.homeworkInfo?.id
  );
  const defaultTab = useSelector(state => state.courses?.defaultTab);
  const dispatch = useDispatch();
  const activeLesson = useSelector(state => state.courses.activeLesson);
  const lastVideo = useSelector(state => state.courses.lastVideo);
  const courseLessonFaq = useSelector(state => state.courses.lessonFaq);
  const mqMaxWidth400 = useMediaQuery('(max-width: 400px)');


  const tabsTitle = useMemo(() => {
    return [
      {
        name: 'Mavzu',
        value: 'topic'
      },
      ...(activeLesson?.lesson?.video?.id
        ? [
            {
              name: 'Qaydlar',
              value: 'notes'
            }
          ]
        : []),
      {
        name: 'Izohlar',
        value: 'questions'
      },
        ...(courseLessonFaq?.length
        ? [
      {
        name: 'FAQ',
        value: 'faq'
      }
      ] : [])
    ];
  }, [activeLesson?.lesson?.video?.id, courseLessonFaq?.length, lastVideo?.lessonId]);

  useEffect(() => {
    if (defaultTab === 'homework') {
      setActiveTab(null);
    } else if (defaultTab) {
      setActiveTab(defaultTab || 'topic');
    }

    return () => {
      dispatch(AllCoursesActions.setDefault(null));
    };
  }, [defaultTab, dispatch]);

  const handleChangeTab = tab => {
    setActiveTab(tab);
  };

  return {
    handleChangeTab,
    activeTab,
    tabs,
    tabsTitle,
    isAvailableHomework,
    activeLesson,
    mqMaxWidth400
  };
};
