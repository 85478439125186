import { config } from '@config/config';
import { useSelector } from 'react-redux';

export const useBlogCardProp = props => {
  const blog = useSelector(state => state.common.recommendedPost);
  const myCourses = useSelector(state => state.common.myCourses);

  const handleViewBlog = id => {
    window.open(config.landing + '/blog/' + id);
  };

  return {
    blog,
    myCourses,
    handleViewBlog
  };
};
