import { hoc } from '@utils/hoc';
import { useHomeworkCardProp } from './homework-card.prop';
import {
  HomeworkCardIcon,
  HomeworkCardWrapper,
  IconBasename,
  IconImg,
  IconTitle
} from './homework-card.style';
import FileIcon from '@assets/img/file.png';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { config } from '@config/config';

export const HomeworkCard = hoc(
  useHomeworkCardProp,
  ({ title, link }) => {
    const splitName = title?.split('.');
    const fileExtension = splitName?.[splitName.length - 1];
    const fileBasename = splitName?.slice(0, -1)?.join('.');
    return (
      <HomeworkCardWrapper
        href={config.imgApiUrl + '/' + link}
        target='_blank'
        download={config.imgApiUrl + '/' + link}
      >
        <HomeworkCardIcon>
          <IconImg src={FileIcon} />
        </HomeworkCardIcon>

        <FlexWrapper direction='column'>
          <FlexWrapper>
            <IconBasename>{fileBasename}</IconBasename>
            <IconTitle>{'.' + fileExtension}</IconTitle>
          </FlexWrapper>
          {/* <IconSubtitle>{size && formatBytes(size)}</IconSubtitle> */}
        </FlexWrapper>
      </HomeworkCardWrapper>
    );
  }
);
