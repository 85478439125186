import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

export const useScreenRotation = () => {
    const { width, height } = useWindowSize();
    const [isRotated, setIsRotated] = useState(false);

    const handleOrientationChange = () => {
        setIsRotated(width > height);
    };

    useEffect(() => {

        handleOrientationChange();
        window.addEventListener('resize', handleOrientationChange);

        return () => {
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, [width, height]);

    return isRotated;
};

