import styled, { css } from 'styled-components';

export const CourseLessonList = styled.ul`
  transition: height 0.3s ease;
`;

export const CourseLessonWrapper = styled.div`
  & ${CourseLessonList} {
     user-select: none;
     max-height: ${props => (props.isOpen ? '100%' : '0')};
     background-color: ${props => (props.isOpen ? '#f9f4ef' : 'transparent')};
     -webkit-transition: all 0.5s;
     -moz-transition: all 0.5s;
     -ms-transition: all 0.5s;
     -o-transition: all 0.5s;
     transition: all 0.5s;
     overflow: ${props => (props.isOpen ? 'initial' : 'hidden')};
  }
`;

export const CourseLessonHeader = styled.header(
  () => `
    position: relative;
    display: flex;
    padding: 16px 0;
    background-color: #ffffff;
    flex-direction: column;
  `,

  ({ theme: { down } }) => `
    ${down('1034px')} {
      padding: 9px 0;
    }
  `
);

export const CourseLessonHeaderContent = styled.div(
  () => `
  width: 90%;`,

  ({ theme: { down } }) => `
    ${down('1034px')} {
      width: 320px;
      max-width: 100%;
    }
  `
);

export const CourseLessonHeaderSections = styled.p`
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
`;

export const CourseLessonHeaderTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CourseLessonHeaderHeading = styled.h3(
  () => `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
  width: 100%;
  max-width: 95%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  `,

  ({ theme: { down } }) => `
    ${down('1034px')} {
        width: 280px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    `
);

export const CoursesTitleWrapper = styled.div(
  () => `
    position: relative;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    h3:hover + span {
      display: block;
    }

    span {
      background: #313131;
      border-radius: 8px;
      padding: 12px;
      font-style: normal;
      font-weight: 600;
      max-width: 200px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.0001em;
      color: #ffffff;
      position: absolute;
      height: auto;
      width: max-content;
      z-index: 2;
      display: none;
      bottom: -85px;
      left: 0;
      transition: all ease 0.4s;
    }`,

  ({ theme: { down } }) => `
    ${down('1034px')} {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    ${down('420px')} {
      width: 230px;
    }
  `
);

export const CourseLessonHeaderLessonCount = styled.h4`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);

  &:last-child {
    margin-left: 14px;
  }

  span {
    color: #ba8d5b;
  }
`;

export const CourseLessonHeaderButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  opacity: ${props => (props.isDisabled ? '0.3' : '1')};

  &:hover {
    opacity: ${props => (props.isDisabled ? '0.3' : '0.8')};
  }

  ${props =>
    props.isOpen
      ? css`
          & img {
            transform: rotate(180deg);
          }
        `
      : ''}
`;
