import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const MoreInfoLeft = styled.div(
  ({ theme: { down } }) => `
  width: 70%;

  ${down('650px')} {
    width: 100%;
  }
  `
);

export const MoreInfoLeftCourse = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  margin-bottom: 20px;
`;

export const MoreInfoRight = styled.div(
  ({ theme: { down } }) => `
  background: #ffffff;
  border-radius: 8px;
  padding: 28px;
  width: 30%;
  position: sticky;
  top: 75px;

  ${down('650px')} {
    align-self: center;
    position: static;
    width: 50%;
  }
  `
);

export const CourseName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ba8d5b;
`;

export const CourseTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 24px;
`;

export const ModuleTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
`;

export const ModuleInfo = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 12px 16px;
  width: ${props => (props.big ? '49%' : '32%')};
`;

export const ModuleSubtitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #848080;
  margin-bottom: 5px;
`;

export const ModuleCount = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #000000;
`;
