import { hoc } from '@utils/hoc';
import { Fragment, useMemo, useState } from 'react';
import {
  CourseAnswersShow,
  CourseQuestionsAnswerBtn
} from '../../course-questions.style';
import { RepliesContainer } from './replies-comments.styles';
import { CourseCommentsContainer } from '../course-comments.style';
import { Comment } from '../comment';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import { AllCoursesActions } from '@all-courses/store';
import { storageService } from '@services/storage.service';
import { timeFormat } from '@utils/date-format';

const DELETED = 'deleted';

const useRepliesProps = ({ item, sort }) => {
  const [isShowReplies] = useState(true);
  const dispatch = useDispatch();
  const replyTextareaRef = useRef();
  const userInfo = JSON.parse(storageService.getItem('studentInfo'));

  const toggleReplies = item => {
    dispatch(AllCoursesActions.setReply(item));
  };

  const onSubmitReply = () => {};

  const handleAppeal = id => {
    dispatch(AllCoursesActions.setReportedCommentId(id));
    dispatch(AllCoursesActions.setAppealModal(true));
  };

  const handleDelete = id => {
    dispatch(AllCoursesActions.setReportedCommentId(id));
    dispatch(AllCoursesActions.setConfirmDeleteModal(true));
  };

  const handleEditComment = item => {
    dispatch(AllCoursesActions.setCommentEditInfo(item));
  };

  const isEditable = useMemo(() => {
    return timeFormat(item?.createdAt, 2880);
  }, [item?.createdAt]);

  return {
    isShowReplies,
    toggleReplies,
    onSubmitReply,
    replyTextareaRef,
    handleAppeal,
    handleDelete,
    userInfo,
    handleEditComment,
    isEditable
  };
};

export const Replies = hoc(
  useRepliesProps,
  ({
    items,
    depth,
    isShowReplies,
    toggleReplies,
    item,
    handleAppeal,
    handleDelete,
    userInfo,
    handleEditComment,
    hasParent,
    isEditable
  }) => {
    return (
      <>
        {item?.author && (
          <CourseAnswersShow>
            {userInfo?.userId === item?.author?.userId ? (
              <>
                <CourseQuestionsAnswerBtn
                  onClick={handleEditComment.bind(null, item)}
                  className='edit'
                  disabled={!isEditable || item?.status === DELETED}
                >
                  Tahrirlash
                </CourseQuestionsAnswerBtn>

                <CourseQuestionsAnswerBtn
                  onClick={handleDelete.bind(null, item?.id)}
                  className='delete'
                  disabled={item?.status === DELETED}
                >
                  O'chirish
                </CourseQuestionsAnswerBtn>
              </>
            ) : (
              <>
                <CourseQuestionsAnswerBtn
                  show={isShowReplies}
                  onClick={toggleReplies?.bind(null, item)}
                  disabled={item?.status === DELETED}
                >
                  Javob yozish
                </CourseQuestionsAnswerBtn>

                <CourseQuestionsAnswerBtn
                  disabled={item?.isReported || item?.status === DELETED}
                  appeal
                  onClick={handleAppeal.bind(null, item?.id)}
                >
                  Shikoyat yozish
                </CourseQuestionsAnswerBtn>
              </>
            )}
          </CourseAnswersShow>
        )}
        <RepliesContainer
          isDeleted={!item?.author?.userId}
          replied
          show={isShowReplies}
          depth={depth}
        >
          <CourseCommentsContainer>
            {items?.map((item, idx) => {
              return (
                <Fragment key={idx + depth}>
                  <Comment
                    isShowReplies={isShowReplies}
                    toggleReplies={toggleReplies}
                    item={item}
                    isReply
                    hasParent
                  />
                  {item?.replies?.length && (
                    <Replies
                      depth={depth + 1}
                      item={item}
                      items={item.replies}
                      replied
                    />
                  )}
                </Fragment>
              );
            })}
          </CourseCommentsContainer>
        </RepliesContainer>
      </>
    );
  },
  'Replies'
);
