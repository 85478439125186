import { createSlice } from '@reduxjs/toolkit';

const name = 'Payments';

const initialState = {
    loading: false,
    error: null,
    payments: [],
    paymentsLoading: true,
};

export const { actions: paymentsAction, reducer: PaymentsReducer } = createSlice(
    {
        name,
        initialState,
        reducers: {
            setLoading(state, action) {
                state.loading = action.payload;
            },
            setError(state, action) {
                state.error = action.payload;
                state.ready = true;
            },
            fetchPayments(state) {
                state.loading = true;
                state.paymentsLoading = true;
            },
            fetchPaymentsFulfilled(state, action) {
                state.payments = action.payload;
                state.paymentsLoading = false;
            }
        }
    }
);
