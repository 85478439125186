// import { config } from '@config/config';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useCourseVideoProps = () => {
  const phoneNumber = useSelector(state => state.common?.profile?.phoneNumber);
  const activeLesson = useSelector(state => state.courses?.activeLesson);
  const coursesChapters = useSelector(
    state => state.courses?.singleModule?.chapters
  );
  const watermark = `+${phoneNumber}`;
  const src = useMemo(() => {
    const activeLessonVideoId = activeLesson?.lesson?.video?.info?.hlsLink
    if (activeLesson?.lesson?.video?.info?.hlsLink) {
      return `${activeLessonVideoId}?watermark=${watermark}`
    }

    return null
  });

  const findActiveLessonIndex = useCallback(() => {
    const chapter = coursesChapters?.find(
      chapter => chapter?.id === activeLesson?.chapter?.id
    );
    const index = chapter?.lessons
      .map(item => item?.id)
      .indexOf(activeLesson?.lesson?.id);
    return { index, chapter };
  }, [coursesChapters, activeLesson?.chapter?.id, activeLesson?.lesson?.id]);

  const findChapterIndex = useCallback(() => {
    const chapterIndex = coursesChapters?.findIndex(
      chapter => chapter?.id === activeLesson?.chapter?.id
    );
    const nextChapter = coursesChapters?.[chapterIndex + 1];

    return { chapterIndex, nextChapter };
  }, [coursesChapters, activeLesson?.chapter]);

  const findNextChapter = useCallback(() => {
    const { nextChapter } = findChapterIndex();
    if (nextChapter) {
      return nextChapter?.lessons?.length > 0 && nextChapter;
    }
    return { nextChapter };
  }, [findChapterIndex]);

  const next = useMemo(() => {
    const { index, chapter } = findActiveLessonIndex();
    const { nextChapter } = findChapterIndex();
    const id = chapter?.lessons?.[chapter?.lessons.length - 1]?.id

    return {
      chapter: chapter?.lessons?.every(
        () => chapter?.lessons[index]?.id === id
      )
        ? findNextChapter()
        : chapter,
      lesson: chapter?.lessons?.every(
        () => chapter?.lessons[index]?.id === id
      )
        ? nextChapter?.lessons[0]
        : chapter?.lessons[index + 1]
    };
  }, [findActiveLessonIndex, findNextChapter, findChapterIndex]);

  return {
    watermark,
    activeLesson,
    next,
    src
  };
};
