import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

// const notifyColor = {
//   'error': 'statusLightDanger',
//   'info': 'statusLightPrimary',
//   'success': 'statusLightSuccess',
//   'warning': 'statusLightWarning',
// };
//
// const notifyBgColor = {
//   'error': 'statusLightSurfaceDanger',
//   'info': 'statusLightSurfacePrimary',
//   'success': 'statusLightSurfaceSuccess',
//   'warning': 'lightWarning',
// };

export const ToastContainerStyled = styled(ToastContainer)({
  backgroundColor: 'inherit',
  margin: 0,
  padding: 0,
  width: 'auto',
  maxWidth: '600px',

  '@media only screen and (max-width: 480px)': {
    left: 0,
    right: 0,
    top: '0',
    margin: 'auto',
    padding: '0',
  },

  '& .Toastify__toast': {
    boxShadow:
      '0 3px 5px rgb(0 0 0 / 20%), 0 5px 8px rgb(0 0 0 / 14%), 0 1px 14px rgb(0 0 0 / 12%)',
    backgroundColor: 'inherit',
    padding: 0
  },

  '& .Toastify__toast-body': {
    padding: 0,
    margin: 0,

    '& div:last-child': {
      height: '100%'
    }
  }
});

export const Notify = styled.div`
  background-color: #ffffff;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  height: 100%;
  justify-content: center;
  min-height: 56px;
  padding: 16px 10px 16px 20px;
  width: 100%;
`;

export const NotifyContent = styled.div`
  color: #000;
  hyphens: auto;
  margin: 0;
`;

export const NotifyContentTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
  word-break: break-word;
  
  '@media only screen and (max-width: 480px)': {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const NotifyContentDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
  word-break: break-word;
  color: rgba(0, 0, 0, 0.5);
`;

export const NotifyIcon = styled.div`
  align-items: center;
  color: #000;
  display: flex;
  justify-content: center;
  line-height: 0;
  margin-right: 16px;
  width: 32px;
`;

export const NotifyIconClose = styled.div`
  align-items: center;
  color: #a6a6a6;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  line-height: 0;
  margin-left: 16px;
  width: 32px;

  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
`;
