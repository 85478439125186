import { Input } from '@components/atoms/Input';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import {
  Button,
  ChangePage,
  CheckerImg,
  CheckerTitle,
  EyeButton,
  EyeImg,
  Form,
  FormGroup,
  HaveProfile,
  InputError,
  InputLabels,
  InputLongError,
  PasswordChecker
} from '@modules/new-auth/routes/login/login.style';

import Oferta from '@assets/img/oferta.pdf';
import CloseEyeImage from '@assets/img/close-eye.svg';
import OpenEyeImage from '@assets/img/open-eye.svg';
import NotMatch from '@assets/img/not-match.svg';
import Empty from '@assets/img/empty.svg';
import Success from '@assets/img/checksuccess.svg';

import { hoc } from '@utils/hoc';
import {
  InfoText,
  RegExInfoText,
  RegisterBottom
} from '../PhoneForm/phone-form.style';
import { useEmailFormProp } from './email-form.prop';
import { RegisterWithGoogle } from '../RegisterWithGoogle';
import { ConfirmCode } from './confirm-code';
import {
  invalid,
  PASSWORD_LONG_ERROR_MIN_LENGTH
} from '@modules/new-auth/constants';
import { PasswordRequirementsChecker } from '@modules/new-auth/components/PasswordRequirementsChecker';

export const EmailForm = hoc(
  useEmailFormProp,
  ({
    hasChecker,
    handleChangeNewPasswordType,
    handleChangeCheckInputType,
    checkInputType,
    inputType,
    formik,
    handleBlurUserName,
    handleChangeUserName,
    handleFocusPassword,
    handleBlurPassword,
    handleChangePassword,
    handleBlurPasswordCheck,
    handleChangePasswordCheck,
    handleChangeEmail,
    handleBlurEmail,
    emailConfirmModal,
    handleRecaptchaChange,
    isCaptchaError
  }) => {
    return (
      <>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup mb>
            <InputLabels htmlFor='firstName'>Ismingiz</InputLabels>
            <Input
              onBlur={handleBlurUserName}
              placeholder='Ismingizni kiriting'
              name='firstName'
              id='firstName'
              value={formik.values.firstName}
              onChange={handleChangeUserName}
              className={
                formik.errors?.firstName && formik.errors?.firstName !== invalid
                  ? 'input-error'
                  : formik.errors?.firstName === invalid
                  ? 'input-info'
                  : ''
              }
            />

            {formik.errors?.firstName !== invalid && (
              <InputError>{formik.errors?.firstName}</InputError>
            )}
            {formik.errors?.firstName === invalid && (
              <RegExInfoText email>
                Siz faqat ushbu harf va belgilardan foydalana olasiz: A-Z a-z
                0-9 '
              </RegExInfoText>
            )}
          </FormGroup>
          <FormGroup mb>
            <InputLabels htmlFor='email'>Elektron pochtangiz</InputLabels>

            <Input
              type='email'
              name='email'
              values={formik.values.email}
              placeholder='example@edfix.uz'
              onBlur={handleBlurEmail}
              onChange={handleChangeEmail}
              className={formik.errors.email ? 'input-error' : ''}
            />
            {formik.errors?.email && (
              <InputError>{formik.errors?.email}</InputError>
            )}
          </FormGroup>

          <FormGroup
            isError={
              formik.errors?.password?.length > PASSWORD_LONG_ERROR_MIN_LENGTH
            }
            mb
          >
            <InputLabels isError={true} htmlFor='password'>
              Parol
            </InputLabels>

            <FlexWrapper
              className={`wrapper ${
                formik.errors.password ? 'input-error' : ''
              }`}
              align='center'
              justify='space-between'
            >
              <Input
                name='password'
                type={inputType}
                id='password'
                className='password-input'
                onFocus={handleFocusPassword}
                onBlur={handleBlurPassword}
                value={formik.values.new_password}
                onChange={handleChangePassword}
                placeholder='Parolingizni kiriting'
              />
              <EyeButton type='button'>
                <EyeImg
                  src={inputType === 'password' ? CloseEyeImage : OpenEyeImage}
                  alt='eye'
                  onClick={handleChangeNewPasswordType}
                />
              </EyeButton>
            </FlexWrapper>
            {hasChecker && (
              <PasswordRequirementsChecker
                password={formik.values.password}
              ></PasswordRequirementsChecker>
            )}
            {formik.errors?.password && (
              <InputLongError>{formik.errors?.password}</InputLongError>
            )}
          </FormGroup>

          <FormGroup formAd>
            <InputLabels
              className={
                formik.errors.new_password_check &&
                formik.touched.new_password_check &&
                'error'
              }
              isError={true}
              htmlFor='new_password_check'
            >
              Parolni tasdiqlash
            </InputLabels>

            <FlexWrapper
              className={`wrapper ${
                formik.errors.password_check ? 'input-error' : ''
              }`}
              align='center'
              justify='space-between'
            >
              <Input
                name='password_check'
                type={checkInputType}
                id='password_check'
                className='password-input'
                onBlur={handleBlurPasswordCheck}
                value={formik.values.new_password_check}
                onChange={handleChangePasswordCheck}
                placeholder='Parolingizni kiriting'
              />
              <EyeButton type='button'>
                <EyeImg
                  src={
                    checkInputType === 'password' ? CloseEyeImage : OpenEyeImage
                  }
                  alt='eye'
                  onClick={handleChangeCheckInputType}
                />
              </EyeButton>
            </FlexWrapper>
            {formik.errors?.password_check && (
              <InputError>{formik.errors?.password_check}</InputError>
            )}
          </FormGroup>
          <FlexWrapper mb='16px' gap='12px'>
            <RegisterWithGoogle />
            <Button type='submit' min>
              Ro‘yxatdan o‘tish
            </Button>
          </FlexWrapper>
        </Form>

        <InfoText oferta>
          Ro‘yxatdan o‘tish tugamasini bosish orqali siz
          <a target='_blank' href={Oferta} rel='noreferrer'>
            Shaxsiy ma’lumotlarni
          </a>
          qayta ishlash va
          <a href={Oferta} target='_blank' rel='noreferrer'>
            Foydalanish qoidalariga
          </a>
          rozilik bildirasiz
        </InfoText>

        <RegisterBottom>
          <HaveProfile>Ro‘yxatdan o‘tganmisiz?</HaveProfile>

          <ChangePage to='/auth/login'>Profilga kirish</ChangePage>
        </RegisterBottom>

        {emailConfirmModal && <ConfirmCode />}
      </>
    );
  }
);
