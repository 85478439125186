import { fetchSagaFactory } from '@utils/fetch-saga-factory';
import { all } from 'redux-saga/effects';
import { certificateActions } from './certificates.slice';
import { certificateService } from '../services/certificate.service';

export function* CertificateRootSaga() {
  yield all([
    fetchSagaFactory({
      pattern: 'fetchCertificates',
      actions: certificateActions,
      api: {
        method: certificateService.fetchCertificates,
        callback: res => res?.data?.certificates
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchSingleCertificate',
      actions: certificateActions,
      api: {
        method: certificateService.fetchCertificate,
        callback: res => res.data
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchCertificateDetail',
      actions: certificateActions,
      api: {
        method: certificateService.fetchCertificateDetail,
        callback: res => res.data
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchHomeworks',
      actions: certificateActions,
      api: {
        method: certificateService.fetchHomeworks,
        callback: res => res.data
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchHomeworksByLessonId',
      actions: certificateActions,
      api: {
        method: certificateService.fetchHomeworksByLessonId,
        callback: res => res.data
      }
    }),
  ]);
}
