import styled from 'styled-components';
import IconTurnRight from '@assets/img/IconTurnRight.svg';
import IconAppeal from '@assets/img/warning.svg';
import IconEdit from '@assets/img/edit.svg';
import IconDelete from '@assets/img/delete.svg';

const fontSizes = `
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

const imageStyles = `
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const CourseQuestionsContainerTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const CourseQuestionNoItemImg = styled.img`
  object-fit: contain;
  margin-bottom: 25px;
`;

export const CourseQustionNotItemTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.25);
`;

export const SearchInputWrapper = styled.div`
  margin-left: 5.5rem;
  input {
    width: ${props => (props.isInputOpen ? '18.5rem' : '3rem')};
    line-height: 1.8rem;
    font-size: 1.4rem;
    height: 3rem;
    transition: width 250ms;

    &:focus {
      width: 18.5rem;
    }
  }

  span:last-child {
    top: 50%;
    right: 1rem;
    display: flex;
    transform: translateY(-50%);
    background-color: #fff;

    &:focus input {
      width: 18.5rem;
    }
  }
`;

export const CourseQuestionsFormContent = styled.div`
  margin-top: 24px;
  margin-left: 50px;
  padding-top: ${props => (props.available ? '24px' : '0')};
  /* border-top: ${props =>
    props.available ? '1px solid #DADADA' : 'none'}; */

  &:not(:first-child) {
    margin-left: 0px;
  }
`;

export const CourseQuestionsReplyedUser = styled.img`
  ${imageStyles}
  margin-right: 16px;
`;

export const CourseRepliesAnswers = styled.div`
  display: ${props => (props.isShow ? 'block' : 'block')};
`;

export const CourseRepliesContainer = styled.div``;

export const CourseQuestionsTitle = styled.h2`
  ${fontSizes}
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const CourseQuestionCount = styled.span`
  ${fontSizes}
  font-size: 16px;
  line-height: 20px;
`;

// QUESTIONS AND ANSWERS CHAT

export const CourseQuestionsChatContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const CourseQuestionsUserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const CourseQuestionsCommentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CourseQuestionsInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-top: 16px;
`;

export const CourseQuestionsCommentedUserImg = styled.img`
  ${imageStyles}
`;

export const CourseQuestionsInfo = styled.div`
  margin-left: 16px;
  width: 100%;
`;

export const CourseQuestionsUserQuestion = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  position: relative;
  color: #ba8d5b;

  &::before {
    content: '';
    width: 3px;
    visibility: ${props => (props.isStaff ? 'hidden' : 'visible')};
    height: 3px;
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -8px;
    border-radius: 50%;
  }
`;

export const CourseQuestionsUserName = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
  display: inline;
  overflow-wrap: anywhere;

  span {
    white-space: nowrap;
    margin-right: 5px;
    color: #006cd0;
  }
`;

export const CourseQuestionTime = styled.time`
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
`;

export const QuestionLeftSide = styled.div`
  width: 84%;
`;

export const CourseQuestionsDate = styled.time`
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 576px) {
    margin-left: 0;
  }
`;

export const CourseQuestionsLike = styled.div`
  display: flex;
  align-items: center;
`;

export const CourseQuestionsLikeCount = styled.span`
  margin-right: 7px;
`;

export const CourseQuestionsLikeBtn = styled.button`
  width: 18px;
  height: 18px;
  border: 0;
  background-color: transparent;
`;

// CHATS ANSWERS

export const CourseAnswersShow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 60px;
  gap: 22px;
  padding-bottom: 16px;
`;

export const CourseQuestionsAnswerBtn = styled.button`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  border: 0;
  padding: 0;
  line-height: 16px;
  color: #ba8d5b;
  background-color: transparent;

  &::before {
    display: block;
    content: '';
    width: 19px;
    height: 22px;
    margin-right: 8px;
    background-color: transparent;
    background-image: url(${props => props.appeal ? IconAppeal : IconTurnRight});
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &.edit::before {
    background-image: url(${IconEdit}) !important;
  }

  &.delete::before {
    background-image: url(${IconDelete}) !important;
  }

  &:disabled {
    opacity: 0.5;
    filter: grayscale(1);
  }
`;

export const CourseQuestionsAnswerCount = styled.strong``;

export const AskQuestionBtn = styled.button`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 22px;
  color: #ba8d5b;
  border: 0;
  background-color: transparent;
  margin: 0 auto;

  &::after {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    margin-left: 9px;
  }
`;

export const VoteBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  transform: rotate(${props => (props.down ? '180deg' : '0')});

  svg {
    width: 12px;
    height: 6px;

    path {
      fill: #a6a6a6;
      stroke: #a6a6a6;
    }
  }

  &.top {
    color: #07cd86;
    path {
      fill: #07cd86;
      stroke: #07cd86;
    }
  }

  &.down {
    path {
      fill: #ea4335;
      stroke: #ea4335;
    }
  }
`;

export const VoteCount = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.015em;
  margin-left: ${props => (props.minus ? '-5px' : '-2px')};
  color: #000;

  &.success {
    color: #07cd86;
  }

  &.fail {
    color: #ea4335;
  }
 `;

export const IsEditedText = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
  padding-left: 8px;
  margin-left: -8px;
  position: relative;

  &::before {
    content: '';
    width: 3px;
    position: absolute;
    height: 3px;
    background: rgba(0, 0, 0, 0.35);
    border-radius: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
  }
`;

export const StaffWrapper = styled.h4`
  background: #f8f7ef;
  border-radius: 28px;
  padding: 2px 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
`;

export const MineCommentsWrapper = styled.div`
  padding-bottom: ${props => (props.mine ? '12px' : '0')};
  margin-bottom: ${props => (props.mine ? '30px' : '0')};
  border-bottom: ${props => (props.mine ? '1px solid #DADADA' : 'none')};

  &:last-child {
    border-bottom: none !important;
  }
`;

export const ReplyComment = styled.div`
  &:not(:last-child) {
    border-bottom: ${props => ((props.mine || props.hasParent) ? '1px solid #DADADA' : 'none')};
  }

  &:first-child {
    border-top: ${props => (props.mine ? '1px solid #DADADA' : 'none')};
  }
`;
