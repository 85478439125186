import { hoc } from '@utils/hoc';
import { RatingTable } from './table.style';
import { useTableProps } from './table.prop';

export const Table = hoc(useTableProps, ({ columns, data }) => {
  return (
    <RatingTable
      pagination
      paginationComponentOptions={{
        noRowsPerPage: true,
        selectAllRowsItem: false,
        rangeSeparatorText: 'dan'
      }}
      columns={columns}
      data={data}
    />
  );
});
