import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { GeneralLayout } from '@components/layouts/general-layout';
import { CertificateButtons } from '@modules/certificates/components/certificate-buttons';
import { hoc } from '@utils/hoc';
import { useCertificateProp } from './certificate.prop';
import {
  CertificateImage,
  CertificateWrapper,
  Wrapper
} from './certificate.style';
import { Loader } from '@components/Loader';

export const Certificate = hoc(
  useCertificateProp,
  ({ handleToMoreInfo, imgUrl, loading, certificate, mq768MaxWidth }) => {
    if (loading) return <Loader />;
    return (
      <GeneralLayout>
        <Wrapper>
          <CertificateWrapper>
            <CertificateImage src={imgUrl} />
          </CertificateWrapper>

          <CertificateWrapper>
            <FlexWrapper
              direction={mq768MaxWidth ? 'column' : 'row'}
              justify='space-between'
              gap='16px'
            >
              <CertificateButtons
                url={imgUrl}
                id={certificate?.id}
                handleToMoreInfo={handleToMoreInfo}
                isTop
              />
            </FlexWrapper>
          </CertificateWrapper>
        </Wrapper>
      </GeneralLayout>
    );
  }
);
