import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNamesBind from 'classnames/bind';
import { debounce } from '@utils/debounce';
import { ThumbnailLink } from '@modules/thumbnail-link';
import { AllCoursesActions } from '@all-courses/store';
import { RouteLinks } from '@constants/route';
import { HlsPlayer } from '@components/hls-player';
import styles from './player.styles.css';
import { ERROR_TYPES } from '@modules/all-courses/constants';
import { commonActions } from '@store/common';
import { useMediaQuery } from '@utils/useMediaQuery';
import { isIphone } from '@utils/deviceDetector';

const cn = classNamesBind.bind(styles);

const UPSERT_LESSON_INITIAL_TIME = 5000
const DOUBLE_CLICK_DELAY = 300;

export const Player = ({ src, watermark, next, controlVisibleTrigger }) => {
  const dispatch = useDispatch();
  const playerRef = useRef(null);
  const [isSeekingPrevious, setIsSeekingPrevious] = useState(false);
  const [isSeekingNext, setIsSeekingNext] = useState(false);
  const history = useHistory();
  const { moduleId, courseId } = useParams();
  const videoLink = useMemo(() => src, [src]);
  const videoWatermark = useMemo(() => watermark, [watermark]);
  const [ended, setEnded] = useState(false);
  const activeLesson = useSelector(state => state.courses.activeLesson);
  const lastSecondTime = useMemo(
    () => activeLesson?.lesson?.lastSecondTime,
    [activeLesson?.lesson?.lastSecondTime]
  );
  const lessonIdRef = useRef(activeLesson?.lesson?.id);
  const chapterIdRef = useRef(activeLesson?.chapter?.id);
  const courseIdRef = useRef(courseId);
  const isShowThumbnailLink = Boolean(ended && next);
  const [videoSpeed, setVideoSpeed] = useState(1)
  const mq420MaxWidth = useMediaQuery('(max-width: 420px)');
  const [lastTapTime, setLastTapTime] = useState(0);

  const handleSeekAnimation = () => {
    setIsSeekingPrevious(true);

    setTimeout(() => {
      setIsSeekingPrevious(false);
    }, 500);
  }
  const UPSERT_LESSON_INTERVAL = useMemo(() => {
    if (!isNaN(videoSpeed)) {
      return UPSERT_LESSON_INITIAL_TIME / videoSpeed
    }

    return UPSERT_LESSON_INITIAL_TIME
  }, [videoSpeed])

  const handleSeekingNextDoubleClick = () => {
    if (playerRef.current?.currentTime) {
      playerRef.current.currentTime += 5
    }

    setIsSeekingNext(true);

    setTimeout(() => {
      setIsSeekingNext(false);
    }, 500);
  }

  const handleSeekingPreviousDoubleClick = () => {
    if (playerRef.current?.currentTime) {
      playerRef.current.currentTime -= 5
    }
    handleSeekAnimation();
  }

  const handlePlay = () => {
    handleUpdateVideoTime();
    setEnded(false);
  };

  const handleTouchStart = (isPrev) => {
    const now = new Date().getTime();

    if (now - lastTapTime < DOUBLE_CLICK_DELAY) {
      if (isPrev) {
        handleSeekingPreviousDoubleClick();
        setLastTapTime(0);
      } else {
        handleSeekingNextDoubleClick();
        setLastTapTime(0);
      }
      setLastTapTime(0);
    } else {
      setLastTapTime(now);
    }
  };

  const handleUpdateVideoTime = () => {
    if (lessonIdRef.current) {
      dispatch(
        AllCoursesActions.upsertLesson({
          data: {
            lessonId: lessonIdRef.current,
            lastSecond: Math.round(playerRef.current?.currentTime) || 1
          },
          callback: (res) => {
            if (res?.error?.errId === ERROR_TYPES.courseModuleNotFound) {
              history.push('/inactive-module');
              dispatch(commonActions.setUpsertError(true));
            }
          }
        })
      );
    }
  }
  const handleTimeUpdate = debounce((currentTime) => {
    if (lessonIdRef.current && chapterIdRef.current) {
      dispatch(
        AllCoursesActions.upsertLesson({
          data: {
            lessonId: lessonIdRef.current,
            lastSecond: Math.round(currentTime) || 1
          },
          callback: res => {
            if (res?.error?.errId === ERROR_TYPES.courseModuleNotFound) {
              history.push('/inactive-module');
              dispatch(commonActions.setUpsertError(true));
            } else if (res?.error?.errId === ERROR_TYPES.courseNotFound) {
              history.push('/inactive');
              dispatch(commonActions.setUpsertError(true));
            }
          }
        })
      );
    }
  }, UPSERT_LESSON_INTERVAL);

  const handlePause = () => {
    handleUpdateVideoTime();
    setEnded(false);
  };

  useEffect(() => {
    window.onbeforeunload = handleUpdateVideoTime();

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnded = () => {
    handleUpdateVideoTime();
    const info = {
      id: moduleId,
      callback: res => {
        if (res?.error?.errId === 151) {
          history.push(`${RouteLinks.notPurchased}/${courseId}`);
        }
      }
    };

    setEnded(true);

    dispatch(AllCoursesActions.fetchSingleModule(info));
  };

  const handleSetActive = () => {
    dispatch(AllCoursesActions.changeActiveCourse(next));
    setEnded(false);
  };

  useEffect(() => {
    lessonIdRef.current = activeLesson?.lesson?.id;
    chapterIdRef.current = activeLesson?.chapter?.id;
    courseIdRef.current = courseId;
  }, [activeLesson?.lesson?.id, activeLesson?.chapter?.id, courseId]);

  useEffect(() => {
    if (!activeLesson?.lesson?.id) {
      return
    }
    dispatch(AllCoursesActions.fetchCourseLessonFaq(activeLesson?.lesson?.id))
  }, [activeLesson?.lesson?.id])

  return (
    <div className={cn('player')}>
      {(!isShowThumbnailLink && mq420MaxWidth && !isIphone) &&
        <div
          onTouchStart={handleTouchStart.bind(null, true)}
          className={cn(`player__left player__clicked ${isSeekingPrevious ? 'player__seeking' : ''} `)}
        />}
      <HlsPlayer
        src={videoLink}
        currentTime={lastSecondTime}
        watermark={videoWatermark}
        onEnded={handleEnded}
        onPause={handlePause}
        onPlay={handlePlay}
        onTimeUpdate={handleTimeUpdate}
        playerRef={playerRef}
        onSeek={handleUpdateVideoTime}
        updateVideoState={setVideoSpeed}
        controlVisibleTrigger={controlVisibleTrigger}
        videoQualities={activeLesson?.lesson?.video?.info?.qualities}
      />
      {((isShowThumbnailLink && next?.lesson) || (isShowThumbnailLink && !activeLesson?.lesson?.isScored)) && (
        <>
          <ThumbnailLink hasNext={next?.lesson} isScored={activeLesson?.lesson?.isScored} title={next?.lesson?.title} onNext={handleSetActive} />
        </>
      )}

      {(!isShowThumbnailLink && mq420MaxWidth && !isIphone) &&
        <div
          onTouchStart={handleTouchStart.bind(null, false)}
          className={cn(`player__right player__clicked ${isSeekingNext ? 'player__seeking next' : ''}`)}
        />}
    </div>
  );
};

Player.prototype = {
  src: propTypes.string,
  watermark: propTypes.string,
  next: propTypes.object,
  controlVisibleTrigger: propTypes.func
};
