import { api } from '@services/base.service';

export class AllCoursesService {
  constructor() {
    this.api = api;
  }

  fetchCategories = () =>
    this.api.get(`/student/course-categories?page=1&perPage=101`);

  fetchAllCourses = (id = 0) =>
    this.api.get(`/student/courses?categoryId=${id}`);
  fetchSingleCourse = data => this.api.get(`/student/course/${data?.id}`);

  fetchModuleChapters = id => this.api.get(`/student/chapters/${id}`)

  fetchModuleSingle = id => this.api.get(`/student/modules/${id}`);

  fetchStudentCourses = () => this.api.get('/student/student-courses');

  fetchCourseHomeWorks = id =>
    this.api.get(`/student/homework/by-chapter/${id}`);

  fetchCourseByLessonHomeWorks = id =>
    this.api.get(`/student/homework/by-lesson/${id}`);

  sendHomeWork = data => this.api.post('/student/homework-chat', data);

  fetchCourseNotes = id =>
    this.api.get(`/student/chapter-notes/by-chapter/${id}`);

  deleteCourseNote = id => this.api.delete(`/student/chapter-note/${id}`);

  addNote = data => this.api.post('/student/chapter-note', data);

  editNote = data =>
    this.api.put(`/student/chapter-note/${data.id}`, data.data);

  changeHomeWork = data =>
    this.api.put(`/student/homework-chat/${data.id}`, data.data);

  fetchComments = data =>
    this.api.get(
      `/student/chapter-comments?chapterId=${data?.chapterId}&mine=${
        data.isMine || false
      }`
    );

  addComment = data => this.api.post(`/student/chapter-comment`, data);

  getLastVideo = data =>
    this.api.get(
      `/student/last-video${
        Object.keys(data).length &&
        `?moduleId=${data?.moduleId}${
          (data?.courseId && `&courseId=${data?.courseId}`) || undefined
        }`
      }`
    );

  upsertLesson = data => this.api.put('/student/upsert-lesson', data);

  promoCodes = data =>
    this.api.get(`/student/promocodes/${data.courseId}/${data.serialNumber}`);

  courseDiscount = courseId =>
    this.api.get(`/student/discount/by-course?courseId=${courseId}`);

  vote = data =>
    this.api.post(`/student/chapter-comment-vote/${data.id}?vote=${data.vote}`);

  deleteComment = id => this.api.delete(`/student/chapter-comment/${id}`);

  commentReport = data => this.api.post(`/student/comment-report`, data);

  payzeToken = data => this.api.post('/student/payze-token', data);

  getPaymentInfo = id => this.api.get(`/student/course/${id}/detail`);

  editComment = data =>
    this.api.put(`/student/chapter-comment/${data?.id}`, data?.info);

  exchangeRate = () => this.api.get('/student/exchange-rate');

  paySuperFree = data => this.api.post('/student/transaction', data);

  rateLesson = data => this.api.post('/student/lesson-rating', data);

  fetchCourselessonFaq = id => this.api.get(`/student/lesson-faqs/by-lesson/${id}`);
}

export const allCoursesService = new AllCoursesService();
