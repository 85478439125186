import { AllCoursesActions } from '@all-courses/store';
import { commonActions } from '@store/common';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useConfirmDeleteProps = ({ textareaRef }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const activeLesson = useSelector(state => state.courses.activeLesson);
  const confirmDeleteState = useSelector(
    state => state.courses.confirmDeleteModal
  );
  const reportedCommentId = useSelector(
    state => state.courses.reportedCommentId
  );
  const dispatch = useDispatch();
  const activeChapter = activeLesson?.chapter?.id;

  const handleUnMountCancelDelete = () => {
    setConfirmDelete(false);
    dispatch(AllCoursesActions.setReportedCommentId(null));
    dispatch(AllCoursesActions.setConfirmDeleteModal(false));
  };

  const handleConfirmDelete = () => {
    const data = {
      id: reportedCommentId,
      callback: res => {
        if (res.success) {
          dispatch(
            AllCoursesActions.setAlertMsg("Izoh muvaffaqiyatli o'chirildi")
          );
          dispatch(commonActions.setShowAlert(true));
          dispatch(
            AllCoursesActions.fetchComments({
              chapterId: activeChapter,
              isMine: true
            })
          );
          dispatch(
            AllCoursesActions.getAllComments({
              chapterId: activeChapter,
              isMine: false
            })
          );
          dispatch(AllCoursesActions.setCommentEditInfo({}));
          if (textareaRef?.current) {
            textareaRef.current.value = '';
          }
          handleUnMountCancelDelete();
        }
      }
    };

    dispatch(AllCoursesActions.deleted(data));
  };

  useEffect(() => {
    setConfirmDelete(confirmDeleteState);
  }, [confirmDeleteState]);

  return {
    handleUnMountCancelDelete,
    confirmDelete,
    setConfirmDelete,
    handleConfirmDelete
  };
};
