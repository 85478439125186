import { UnModal } from '@components/modal';
import { useLessonRateModalProps } from './lesson-rate-modal.props';
import { hoc } from '@utils/hoc';
import Rating from 'react-rating';
import FilledStar from '@assets/img/star-full.png';
import OutlinedStar from '@assets/img/gold-star-outlined.png';
import {
  FaStar,
  RateComment,
  RateCommentTitle,
  RatingWrap,
  Form,
  RateCommentLength,
  RateCommentError,
  CommentLengthWrapper
} from './lesson-rate-modal.styles';
import { Button } from '@all-courses/components/course-tab/course-tab.style';

export const LessonRateModal = hoc(
  useLessonRateModalProps,
  ({
    isShowModal,
    setisShowModal,
    rate,
    commentLength,
    handleChangeTextarea,
    onSubmit,
    onChange,
    error
  }) => {
    return (
      <>
        <UnModal
          modal={isShowModal}
          setModal={setisShowModal}
          modalWidth='538px'
        >
          <Form onSubmit={onSubmit}>
            <RatingWrap className='wrap'>
              <Rating
                emptySymbol={
                  <FaStar src={OutlinedStar} className='star' alt='Star' />
                }
                fullSymbol={
                  <FaStar src={FilledStar} className='star' alt='Star' />
                }
                fractions={2}
                onChange={onChange}
                initialRating={rate}
              />
              <RateCommentTitle>Izoh</RateCommentTitle>
              <RateComment
                maxLength='1000'
                onChange={handleChangeTextarea}
              ></RateComment>
              <CommentLengthWrapper>
                {commentLength} / 1000
              </CommentLengthWrapper>
              <RateCommentLength>
                {!!error?.length && (
                  <RateCommentError>{error}</RateCommentError>
                )}
              </RateCommentLength>
              <Button type='submit'>Baholash</Button>
            </RatingWrap>
          </Form>
        </UnModal>
      </>
    );
  }
);
