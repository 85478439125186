import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Scrollable } from '@styles/scroll.style';

export const RightSidebarWrapper = styled.div`
  width: 320px;
  margin-left: 12px;
`;
export const LeftSide = styled(Scrollable)(
  ({ flexShrink }) => `
    width: 100%;
    height: calc(100vh - 160px);
    overflow-y: auto;
    padding-right: 12px;
    flex-shrink: 1;
  `,
  ({ theme: { down } }) => `
    ${down('1015px')} {
      padding-right: 12px;
      margin: 0;
    }
    ${down('875px')} {
      margin: 0 auto;
      width: 100%;
    }
    ${down('576px')} {
      height: calc(100vh - 216px);
      padding-right: 8px;
    }
    ${down('600px')}{
      overflow-x: hidden;
    }

  `
);

export const Wrapper = styled.div(
  () => `
    background: #ffffff;
    border-radius: 4px;
    padding: 24px;

    &:first-child {
      margin-bottom: 24px;
    }
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      padding: 16px;
    }
    ${down('576px')} {
      padding: 12px;
    }
  `
);

export const RecommendedWrapper = styled.div(
  () => `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 260px));
  justify-content: space-between;
  gap: 24px;

  &:after {
    content: '';
    flex: 0 1 24%;
    visibility: hidden;
    margin: 0 15px;
  }
  `,

  ({ theme: { down } }) => `
  ${down('1600px')} {
    grid-template-columns: repeat(3, minmax(260px,380px));
  }
  ${down('1348px')} {
    grid-template-columns: repeat(2, minmax(260px, 380px));
    justify-content: space-evenly;
  }
  ${down('700px')} {
    grid-template-columns: repeat(auto-fill, minmax(260px,380px));
    justify-content: center;
  }
`
);

export const DashboardRecommendedCourseWrapper = styled.div(
  ({ sidebarState }) => `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 260px));
  justify-content: 'space-between';
  gap: 24px;
  border-radius: 4px;

  @media (max-width: 2200px) {
    grid-template-columns: ${sidebarState ? 'repeat(3, minmax(260px,380px))' : 'repeat(4, minmax(260px,380px))'};
  }

  @media (max-width: 1628px) {
    grid-template-columns: ${sidebarState ? 'repeat(2, minmax(260px,380px))' : 'repeat(3, minmax(260px,380px))'};
  }

  @media (max-width: 1345px) {
    grid-template-columns: repeat(2, minmax(260px, 380px));
    justify-content: space-evenly;
  }

  @media (max-width: 1218px) {
    grid-template-columns: ${sidebarState ? 'repeat(auto-fill, minmax(260px, 380px))' : 'repeat(2, minmax(260px, 380px))'};
    justify-content: space-evenly;
  }

  @media (max-width: 1218px) {
    grid-template-columns: ${sidebarState ? 'repeat(auto-fill, minmax(260px, 380px))' : 'repeat(2, minmax(260px, 380px))'};
  }

  @media (max-width: 1062px) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 380px));
    justify-content: space-evenly;
  }

  @media (max-width: 965px) {
    grid-template-columns: repeat(2, minmax(260px, 380px));
    justify-content: space-evenly;
  }

  @media (max-width: 875px) {
    grid-template-columns: ${sidebarState ? 'repeat(auto-fill, minmax(260px, 380px))' : 'repeat(2, minmax(260px, 380px))'};
    justify-content: space-evenly;
  }

  @media (max-width: 710px) {
    grid-template-columns: repeat(auto-fill, minmax(260px,380px));
    justify-content: center;
  }
  `,
);

export const RecommendedTitle = styled.h3(
  () => `
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-right: 16px;
  margin-bottom: 30px;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      font-size: 18px;
    }
  `,
  ({ theme: { down } }) => `
    ${down('390px')} {
      font-size: 16px;
      line-height: 19.2px;
    }
  `
);

export const AllCourses = styled(Link)(
  () => `
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.015em;
  color: #ba8d5b;

  &:hover {
    text-decoration: underline;
  }
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      font-size: 14px;
      line-height: 17px;
    }
  `,
  ({ theme: { down } }) => `
    ${down('390px')} {
      font-size: 12px;
      line-height: 14.4px;
    }
  `
);

export const EmptyCourseTitle = styled.h4(
  () => `
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.02em;
    color: #ba8d5b;
    text-align: center;
    padding: 0 30px;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      font-size: 16px;
      padding: 0 16%;
    }
    ${down('460px')} {
      font-size: 14px;
      padding: 0;
    }
  `
);

export const EmptyCourseImg = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

export const DashboardWrapper = styled.div(
  ({ sidebarState }) => `
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 1214px) {
      display: ${sidebarState ? 'block' : 'flex'};
    }
  `,
  ({ theme: { down } }) => `

    ${down('1015px')} {
      flex-direction: column;
      gap: 0;
      padding-right: 0;
    }
  `
);

export const FlexWrapLastVideo = styled.div`
    background: #F9F9F9;
    border-radius: 8px;
    padding: 16px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
`
