import { SvgIcon } from '@components/atoms';
import { Input } from '@components/atoms/Input';
import { UnModal } from '@components/modal';
import {
  CenterUpdateModal,
  InfoModalBottom,
  InfoModalTop,
  InfoSubtitle,
  ModalBottom
} from './update-info.style';
import {
  Button,
  InputError,
  InputLabels
} from '@modules/new-auth/routes/login/login.style';
import { hoc } from '@utils/hoc';
import { useUpdateInfoProp } from './update-info.prop';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import {
  InfoImage,
  InfoText,
  RegExInfoText
} from '@modules/new-auth/components/RegisterRightSide/PhoneForm/phone-form.style';

import InfoImg from '@assets/img/info.svg';
import {
  Form,
  FormGroup,
  TooltipWrapper,
  DateCalendarSelect
} from '@modules/settings/components/profile-update/profile-update.style';
import { SelectGender } from '@modules/settings/components/select-gender';
import { PhoneNumberInput } from '@components/phone-number-input';
import { invalid } from '@modules/new-auth/constants';

export const UpdateInfo = hoc(
  useUpdateInfoProp,
  ({
    modal,
    setModal,
    handleCloseModal,
    formik,
    initialDate,
    userInfo,
    openselect,
    gender,
    handleBlurUserName,
    handleChangeUserName,
    handleChangeLastName,
    handleBlurLastName,
    handleClickGender,
    handleChangeGender,
    handleBlurPhoneNumber,
    handleChangePhoneNumber,
    onSubmitForm,
    disabled,
    supportLink,
    studentInfo,
    hasSertificate,
    isAvailable,
    months,
    yearOption,
    selectedDay,
    selectedYear,
    selectedMonth,
    handleDayChange,
    handleYearChange,
    handleMonthChange,
    generateDayOptions,
    isDisabled,
    handleRecaptchaChange,
    isCaptchaError
  }) => {
    return (
      <UnModal
        modal={modal}
        setModal={setModal}
        unMountModal={handleCloseModal}
        modalTitle='Shaxsiy ma’lumotlar'
      >
        <CenterUpdateModal>
          <Form onSubmit={onSubmitForm}>
            <InfoModalTop>
              <FormGroup mb className='form-wrapper'>
                <InputLabels htmlFor='firstName'>Ism</InputLabels>
                <Input
                  name='firstName'
                  type='text'
                  id='firstName'
                  placeholder='Ismingizni kiriting'
                  values={formik.values.firstName}
                  onChange={handleChangeUserName}
                  onBlur={handleBlurUserName}
                  className={
                    formik.errors?.firstName &&
                    formik.errors?.firstName !== invalid
                      ? 'input-error'
                      : formik.errors?.firstName === invalid
                      ? 'input-info'
                      : ''
                  }
                />

                {formik.errors?.firstName !== invalid && (
                  <InputError>{formik.errors?.firstName}</InputError>
                )}
                {formik.errors?.firstName === invalid && (
                  <RegExInfoText update>
                    Siz faqat ushbu harf va belgilardan foydalana olasiz: A-Z
                    a-z 0-9 '
                  </RegExInfoText>
                )}
              </FormGroup>
              <FormGroup mb>
                <InputLabels htmlFor='lastName' mb>
                  Familiya
                </InputLabels>

                <Input
                  name='lastName'
                  type='text'
                  id='lastName'
                  placeholder='Familyangizni kiriting'
                  values={formik.values.lastName}
                  onChange={handleChangeLastName}
                  onBlur={handleBlurLastName}
                  className={
                    formik.errors?.lastName &&
                    formik.errors?.lastName !== invalid
                      ? 'input-error'
                      : formik.errors?.lastName === invalid
                      ? 'input-info'
                      : ''
                  }
                />

                {formik.errors?.lastName !== invalid && (
                  <InputError>{formik.errors?.lastName}</InputError>
                )}
                {formik.errors?.lastName === invalid && (
                  <RegExInfoText updateLast>
                    Siz faqat ushbu harf va belgilardan foydalana olasiz: A-Z
                    a-z 0-9 '
                  </RegExInfoText>
                )}
              </FormGroup>
              <FlexWrapper gap='9px' mb='16px'>
                <InfoImage src={InfoImg} alt='info img' />
                <InfoSubtitle>
                  Ism va Familiyangiz Sertifikatlaringizda aks ettiriladi va
                  qayta o'zgartirish imkoni berilmaydi, shu sababli iltimos
                  e'tiborli bo'lib Ism va Familiyangizni kiriting
                </InfoSubtitle>
              </FlexWrapper>
            </InfoModalTop>

            <InfoModalBottom>
              <FormGroup mb>
                <FlexWrapper mb='12px'>
                  <InputLabels
                    isError={true}
                    className={
                      formik.errors.birthDate &&
                      initialDate === 'kk/oo/yyyy' &&
                      'error'
                    }
                  >
                    Tug‘ilgan sana
                  </InputLabels>
                </FlexWrapper>
                <FlexWrapper gap='5px'>
                  <DateCalendarSelect
                    options={generateDayOptions()}
                    onChange={handleDayChange}
                    isSearchable={false}
                    maxMenuHeight={110}
                    value={
                      selectedDay.value
                        ? selectedDay
                        : { label: 'Kun', isDisabled: true }
                    }
                  />
                  <DateCalendarSelect
                    defaultValue={
                      selectedMonth.value
                        ? selectedMonth
                        : { label: 'Oy', isDisabled: true }
                    }
                    options={months}
                    onChange={handleMonthChange}
                    isSearchable={false}
                    maxMenuHeight={110}
                  />
                  <DateCalendarSelect
                    defaultValue={
                      selectedYear.value
                        ? selectedYear
                        : { label: 'Yil', isDisabled: true }
                    }
                    options={yearOption}
                    placeholder='Select color'
                    onChange={handleYearChange}
                    maxMenuHeight={110}
                  />
                </FlexWrapper>
                {formik.errors?.birthDate && (
                  <InputError>{formik.errors?.birthDate}</InputError>
                )}
              </FormGroup>

              <FormGroup mb>
                <FlexWrapper>
                  <InputLabels
                    isError={true}
                    className={formik.errors.genderType && !gender && 'error'}
                  >
                    Jinsi
                  </InputLabels>
                </FlexWrapper>

                <SelectGender
                  openselect={openselect}
                  handleClickGender={handleClickGender}
                  onChange={handleChangeGender}
                  onBlur={formik.handleBlur}
                  error={formik.errors.genderType && !gender}
                  defaultValue={
                    userInfo?.gender === 1 || studentInfo?.genderType === 'MALE'
                      ? 'Erkak'
                      : userInfo?.gender === 2 ||
                        studentInfo?.genderType === 'FEMALE'
                      ? 'Ayol'
                      : ''
                  }
                />

                {formik.errors?.genderType && (
                  <InputError>{formik.errors?.genderType}</InputError>
                )}
              </FormGroup>
              <FormGroup mb>
                <InputLabels htmlFor='phoneNumber'>Telefon raqam</InputLabels>

                <PhoneNumberInput
                  format='## ### ## ##'
                  prefix='+998'
                  placeholder='00 000 00 00'
                  id='phoneNumber'
                  name='phoneNumber'
                  // defaultValue={studentInfo?.phoneNumber?.substring(3)
                  defaultValue={userInfo?.phoneNumber?.substring(3)}
                  onBlur={handleBlurPhoneNumber}
                  onChange={handleChangePhoneNumber}
                  values={formik.values.phoneNumber}
                  className={formik.errors?.phoneNumber ? 'input-error' : ''}
                />
                {formik.errors?.phoneNumber && (
                  <InputError>{formik.errors?.phoneNumber}</InputError>
                )}
              </FormGroup>

              <FlexWrapper mb='16px' gap='9px'>
                <InfoImage src={InfoImg} alt='info img' />

                <InfoText>
                  Agarda siz O’zbekistondan tashqarida istiqomat qilib telefon
                  raqam orqali ro’yxatdan o’tmoqchi bo’lsangiz,
                  <a
                    href={supportLink ? supportLink : '/'}
                    target='_blank'
                    rel='noreferrer'
                  >
                    qo’llab-quvvatlash xizmati
                  </a>
                  orqali murojaat qilib telefon raqamni ro’yxatdan
                  o’tkazishingiz mumkin.
                </InfoText>
              </FlexWrapper>
            </InfoModalBottom>
          </Form>
        </CenterUpdateModal>

        <ModalBottom>
          <Button type='submit' onClick={formik.handleSubmit}>
            Saqlash
          </Button>
        </ModalBottom>
      </UnModal>
    );
  }
);
