import { useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AllCoursesActions } from '@all-courses/store';
import { config } from '@config/config';
import { commonActions } from '@store/common';
import { parseQueryToString } from '@utils/get-query';
import { useMediaQuery } from '@utils/useMediaQuery';
import { CourseTypes } from '@modules/all-courses/constants';
import { storageService } from '@services/storage.service';

export const useMyCourseSingleProp = () => {
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [isOpenPayzeModal, setIsOpenPayzeModal] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isShowConfirmModal = useSelector(
    state => state.courses.confirmCodeModal
  );
  const singleCourseInfo = useSelector(state => state.courses.singleCourse);
  const singleCourseLoading = useSelector(
    state => state.courses.singleCourseLoading
  );
  const sidebarState = useSelector(state => state.common.sidebarState);
  const isShowPayzeModal = useSelector(state => state.common.payzeModal);
  const isShowUpdateInfoModal = useSelector(
    state => state.courses.updateInfoModal
  );
  const userInfo = JSON.parse(storageService.getItem('studentInfo'));
  const mqMaxWidth1110 = useMediaQuery('(1016px <= width <= 1110px)');
  const mqMaxWidth1213 = useMediaQuery('(max-width: 1213px)');
  const mqMaxWidth820 = useMediaQuery('(max-width: 820px)');
  const mqMaxWidth700 = useMediaQuery('(max-width: 700px)');
  const mqMaxWidth685 = useMediaQuery('(max-width: 685px)');
  const mqMaxWidth650 = useMediaQuery('(max-width: 650px)');
  const mqMaxWidth600 = useMediaQuery('(max-width: 600px)');
  const mqMaxWidth370 = useMediaQuery('(max-width: 370px)');
  const myCourses = useSelector(state => state.common.myCourses);


  const handleGoLanding = () => {
    const findedCourse = myCourses?.find((obj) => obj?.imageIllustration === singleCourseInfo?.imageIllustration)

    if (findedCourse?.courseType === CourseTypes.close) {
      window.open(findedCourse?.detailUrl, '_blank');
    }else {
      window.open(`${config.landing}/courses/${params.id}`, '_blank');
    }
  };

  const isAvailableBuy = useMemo(() => {
    return singleCourseInfo?.modules?.every(module => module.isPaid) || singleCourseInfo?.status === 3;
  }, [singleCourseInfo]);

  const search = useMemo(
    () => parseQueryToString(location.search),
    [location.search]
  );

  const unMoundPayzeModal = () => {
    dispatch(commonActions.setPayzeModal(false));
    history.push(location.pathname);
  };

  useEffect(() => {
    if (search.buy && singleCourseInfo) {
      setIsOpenPaymentModal(true);
    } else if (isShowPayzeModal) {
      setIsOpenPaymentModal(false);
    }
  }, [search.buy, singleCourseInfo, isShowPayzeModal]);

  const allMyCourses = useMemo(() => {
    return singleCourseInfo?.modules?.filter(item => item.status !== 3);
  }, [singleCourseInfo]);

  const handleOpenPaymentModalClick = () => {
    setIsOpenPaymentModal(true);
  };

  const archivedCourses = useMemo(() => {
    return singleCourseInfo?.modules?.filter(item => item?.status === 3);
  }, [singleCourseInfo]);

  useEffect(() => {
    dispatch(
      commonActions.setIsHasButton({ hasButton: true, link: '/my-courses' })
    );
    dispatch(commonActions.setSubHeaderTitle('Kurs dasturi'));

    return () => {
      dispatch(commonActions.setIsHasButton({}));
      dispatch(commonActions.setSubHeaderTitle(''));
    };
  }, []);

  const handleClosePaymentModal = () => {
    setIsOpenPaymentModal(false);
    history.push(location.pathname);
  };

  useEffect(() => {
    if (!params?.id) {
      return;
    }

    dispatch(
      AllCoursesActions.fetchSingleCourse({
        id: params?.id,
        callback: res => {
          if (res?.data === null && !res?.success) {
            history.push('/inactive');
          }
        }
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isBought = singleCourseInfo?.modules?.some(item => item?.isPaid)

    if (
      singleCourseInfo?.id &&
      singleCourseInfo?.courseType === CourseTypes.close &&
      userInfo?.phoneNumber &&
      userInfo?.lastName &&
      !isBought
    ) {
      window.open(singleCourseInfo?.detailUrl, '_blank');
      history.goBack();
    }
  }, [singleCourseInfo]);

  useEffect(() => {
    setIsOpenPayzeModal(isShowPayzeModal);
  }, [isShowPayzeModal]);

  return {
    handleGoLanding,
    singleCourseInfo,
    sidebarState,
    allMyCourses,
    archivedCourses,
    handleOpenPaymentModalClick,
    isOpenPaymentModal,
    setIsOpenPaymentModal,
    handleClosePaymentModal,
    mqMaxWidth1213,
    mqMaxWidth1110,
    mqMaxWidth820,
    mqMaxWidth700,
    mqMaxWidth685,
    mqMaxWidth650,
    mqMaxWidth600,
    mqMaxWidth370,
    isAvailableBuy,
    isOpenPayzeModal,
    setIsOpenPayzeModal,
    unMoundPayzeModal,
    isShowUpdateInfoModal,
    isShowConfirmModal,
    singleCourseLoading
  };
};
