import { Skeleton } from '@components/skeleton/skeleton-box';
import React from 'react';
import { BlogCardWrapper } from './blog-card/blog-card.style';

export const RightSidebarBlogSelektor = () => {
  return <BlogCardWrapper>
    <Skeleton width={100} height={15} marginBottom={16}/>
    <Skeleton width={230} height={160} marginBottom={16}/>
    <Skeleton width={230} height={100} marginBottom={16}/>
    <Skeleton width={100} height={25} />
  </BlogCardWrapper>
}
