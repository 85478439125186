import styled from 'styled-components';

export const StyledLeftSidebarCore = styled.div(
  () => `
    position: fixed;
    bottom: 0;
    transition: width 0.2s linear;
    display: inline-block;
    width: 7.1rem;
    height: calc(100vh - 6.4rem);
    z-index: 49;
    &.open {
      z-index: 20;
      width: 225px;
      position: sticky !important;
      margin-top: auto;
      bottom: 0;
      left: 0;
      @media screen and (max-width: 768px) {
        position: absolute;
        overflow-x: auto;        
      }
      .sidebar-navs {
        width: 125px;
        opacity: 1;
        transition: all 0.4s linear;
        white-space: nowrap;
      }

      .left-sidebar__logo {
        display: block !important;
      }
    }

    &.hover {
      width: 225px;

      .sidebar-navs {
        width: 125px;
        opacity: 1;
        transition: all 0.4s linear;
        white-space: nowrap;
      }

      .left-sidebar__logo {
        display: block !important;
      }
    }

    & .logo {
      gap: 1rem;
      display: flex;
      position: relative;
      width: 30rem;
      justify-content: space-around;

      & .btn-img {
        padding: 1rem;
        position: absolute;
        right: -4rem;
        cursor: pointer;
      }

      & p {
        overflow: hidden;
        height: 30px;
        color: #021122;
        font-weight: 800;
        letter-spacing: 2px;
        font-size: 2rem;
      }
    }

    & .button-wrapper {
      position: absolute;
      bottom: 0;

      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 2.7rem;
      width: 100%;
      height: 7.2rem;
      margin-bottom: 3rem;

      & svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  `,

  ({ theme: { down } }) => `
    ${down('576px')} {
      display: none;
    }
  `
);

export const StyledLeftSidebar = styled(StyledLeftSidebarCore)`
  background-color: ${props => props.theme.colors.white};

  & .logo {
    & .btn-img {
      display: ${props => (props.collapse ? 'none' : 'block')};
    }

    & p {
      width: ${props => (props.collapse ? '100' : '0')}%;
    }
  }

  @media only screen and (max-width: 920px) {
    top: 6.4rem;
  }
`;

export const LogoutBtn = styled.ul`
  @media screen and (max-width: 1000px) and (orientation: landscape) {
    position: static;
  }
  position: absolute;
  bottom: 23px;
  width: 100%;

  @media only screen and (max-width: 920px)  {
    position: static;
  }

  li {
    list-style-type: none;
    display: block;
  }

  .sidebar-navs {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #f07a7a;
    width: 0;
    opacity: 0;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ModalTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  max-width: 272px;
  margin: 0 auto;
  margin-bottom: 32px;
`;

export const Button = styled.button`
  background: ${props => (props.danger ? '#EA4335' : '#FFFFFF')};
  border: ${props =>
    props.danger ? '1px solid transparent' : '1px solid #DADADA'};
  border-radius: 100px;
  outline: none;
  width: 179px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${props => (props.danger ? '#fff' : '#000000')};
  padding: 12px 0;
  transition: all 0.3s;

  &:first-child {
    margin-right: 16px;
  }

  &:hover {
    background: ${props => props.danger ? '#fff' : '#BA8D5B'};
    color: ${props => props.danger ? '#000' : '#fff'};
    border: 1px solid ${props => props.danger ? '#EA4335' : 'transparent'}
  }
`;
