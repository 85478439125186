import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import {
  AppealBtn,
  AppealForm,
  AppealLabel,
  AppealSubtitle,
  AppealTextarea
} from './appealComment.style';
import { useAppealComponentProps } from './appealComponent.prop';

export const AppealModal = hoc(
  useAppealComponentProps,
  ({
    setAppealModal,
    handleCloseAppealModal,
    appealModal,
    appealText,
    handleChangeAppealText,
    handleFinish,
    handleCancel
  }) => (
    <UnModal
      setModal={setAppealModal}
      modal={appealModal}
      modalTitle='Shikoyat qilish'
      unMountModal={handleCloseAppealModal}
    >
      <AppealSubtitle>
        Administratorga ushbu izoh haqida shikoyat bildirmoqchimisiz?
      </AppealSubtitle>

      <AppealForm onSubmit={handleFinish}>
        <AppealLabel>Shikoyat matni</AppealLabel>
        <AppealTextarea
          onChange={handleChangeAppealText}
          value={appealText}
          placeholder='Shikoyat matnini kiriting'
        />

        <FlexWrapper justify='space-between' gap='20px'>
          <AppealBtn type={'button'} cancel onClick={handleCancel}>
            Bekor qilish
          </AppealBtn>
          <AppealBtn type={'submit'} disabled={!appealText}>Jo‘natish</AppealBtn>
        </FlexWrapper>
      </AppealForm>
    </UnModal>
  )
);
