import { authActions } from '@modules/auth/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useForgetEmailProp = () => {
  const forgetEmailModal = useSelector(state => state.auth.forgetEmail);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [initialForm, setInitialForm] = useState(true);
  const [timeIndex, setTimeIndex] = useState(0);
  const [time, setTime] = useState(Date.now());
  const [email, setEmail] = useState();

  const handleCLoseModal = () => {
    setModal(false);
    dispatch(authActions.setForgetEmailModal(false));
  };

  useEffect(() => {
    setModal(forgetEmailModal);
  }, [forgetEmailModal]);

  return {
    modal,
    setModal,
    handleCLoseModal,
    initialForm,
    setInitialForm,
    time,
    setTime,
    timeIndex,
    setTimeIndex,
    email,
    setEmail
  };
};
