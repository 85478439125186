import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVideoLink } from '@utils/video';
import { AllCoursesActions } from '@all-courses/store';
import CloseBtn from '@assets/img/close-btn.png';
import {
  Loader,
  LoaderWrapper,
  Modal,
  ModalCloseBtn,
  ModalSubtitle,
  ModalTitle,
  ModalTitleWrapper,
  ModalWrapper,
  ModalWrapperTop,
} from './ModalPlayer.style';

export const PLAYER_WIDTH = 755
export const PLAYER_HEIGHT = 520

export const ModalPlayer = () => {
  const dispatch = useDispatch();
  const videoId = useSelector(state => state.courses?.videoId);
  const modalRef = useRef(null);

  const handleClose = () => {
    dispatch(AllCoursesActions.openFreeCourseModal(false));
    dispatch(AllCoursesActions.getFreeVideo(null));
  };

  const handleModalClick = (event) => {
    if (event.target === modalRef.current) {
      handleClose();
    }
  }

  const src = getVideoLink(videoId?.video?.videoId)

  return (
    <Modal onClick={handleModalClick} ref={modalRef}>
      <ModalWrapper>
        <ModalWrapperTop>
          <ModalTitleWrapper>
            <ModalSubtitle>{videoId?.index || 0} - mavzu</ModalSubtitle>
            <ModalTitle>{videoId?.title || ''}</ModalTitle>
          </ModalTitleWrapper>

          <ModalCloseBtn onClick={handleClose}>
            <img src={CloseBtn} alt='close' />
          </ModalCloseBtn>
        </ModalWrapperTop>

        <div>
          <Loader />

          <iframe title={videoId?.title} src={src} className={'player__iframe'} height={PLAYER_HEIGHT} />
        </div>
      </ModalWrapper>
    </Modal>
  );
};
