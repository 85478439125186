import headerArrow from '@assets/img/arrow.svg';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { secondsFormat } from '@utils/date-format-time';
import { LessonItem } from './lesson-item/lesson-item.component';
import { useCourseLessonItem } from './course-lesson-item.props';

import {
  CourseLessonHeader,
  CourseLessonHeaderButton,
  CourseLessonHeaderContent,
  CourseLessonHeaderHeading,
  CourseLessonHeaderLessonCount,
  CourseLessonHeaderTitleBox,
  CourseLessonList,
  CourseLessonWrapper,
  CoursesTitleWrapper
} from './course-lessons-item.style';
import { useTooltips } from '@modules/hooks';

export const CourseLesson = hoc(
  useCourseLessonItem,
  ({
    item,
    isOpen,
    isInitiallyOpen,
    setIsInitiallyOpen,
    onHeaderBtnClick,
    activeLesson,
    mqMaxWidth1015px,
    mqMaxWidth705px
  }) => {
    const chapterName = `${item?.position}-mavzu` || '';
    const courseName = item.name || 'Kurs nomi topilmadi';
    const lessonName = `${
      chapterName ? `${chapterName}: ` : ''
    }${courseName}`?.trim();

    useTooltips();

    return (
      <CourseLessonWrapper
        className='course-item'
        isOpen={isOpen || isInitiallyOpen}
      >
        <CourseLessonHeader>
          <FlexWrapper
            data-testid={'course_chapter'}
            onClick={onHeaderBtnClick}
            justify='space-between'
            align='center'
            style={{ cursor: 'pointer' }}
          >
            <CourseLessonHeaderContent>
              <CourseLessonHeaderTitleBox>
                <CoursesTitleWrapper>
                  <CourseLessonHeaderHeading data-tippy-content={lessonName}>
                    {lessonName}
                  </CourseLessonHeaderHeading>
                </CoursesTitleWrapper>
              </CourseLessonHeaderTitleBox>
            </CourseLessonHeaderContent>
            <FlexWrapper gap='16px'>
              <CourseLessonHeaderButton
                isDisabled={isInitiallyOpen}
                isOpen={isOpen || isInitiallyOpen}
              >
                <img src={headerArrow} alt='' width='14' height='10' />
              </CourseLessonHeaderButton>
            </FlexWrapper>
          </FlexWrapper>

          {(!mqMaxWidth1015px || mqMaxWidth705px) && (
            <FlexWrapper mt={mqMaxWidth705px ? '8px' : 0}>
              <CourseLessonHeaderLessonCount>
                Darslar soni: <span>{item?.lessonsCount} ta</span>
              </CourseLessonHeaderLessonCount>

              <CourseLessonHeaderLessonCount>
                Mavzu vaqti: <span>{secondsFormat(item?.duration)}</span>
              </CourseLessonHeaderLessonCount>
            </FlexWrapper>
          )}
        </CourseLessonHeader>

        <CourseLessonList>
          {item?.lessons?.map((lesson, i) => {
            lesson?.id === activeLesson?.lesson?.id
              ? setIsInitiallyOpen(true)
              : setIsInitiallyOpen(false);
            return (
              <LessonItem
                item={lesson}
                chapter={item}
                lessonSequence={i + 1}
                key={lesson?.id + i}
                isViewed={lesson?.progress === 100}
                isViewing={activeLesson?.lesson?.id === lesson?.id}
                inProgress={lesson?.progress > 0 && lesson?.progress < 100}
              />
            );
          })}
        </CourseLessonList>
      </CourseLessonWrapper>
    );
  }
);

CourseLesson.defaultProps = {
  item: {}
};
