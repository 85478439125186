import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storageService } from './services';
import { commonActions } from '@store/common';

export const useAppProps = () => {
  const dispatch = useDispatch();
  const token = storageService.getItem('accessToken');
  const profile = JSON.parse(storageService.getItem('studentInfo'));

  useEffect(() => {
    if (token) {
      dispatch(commonActions.fetchProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return {
    token
  };
};
