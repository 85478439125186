import { config } from '@config/config';
import { AllCoursesActions } from '@modules/all-courses/store';
import { storageService } from '@services/storage.service';
import { commonActions } from '@store/common';
import { store } from '@store/index';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RouteLinks } from 'src/constants/route';

export const HomeworkFileProp = ({ file, setFile, setAllFiles, allFiles }) => {
  const [percentage, setPercentage] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const uploadFile = async () => {
    if (!file) {
      return null;
    }

      const formData = new FormData();
      formData.append('fileFolder', 'students/homeworks/');
      formData.append('files', file);

      const options = {
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          dispatch(AllCoursesActions.setIsAvailableSend(true));
          setPercentage(percent);

          if (percent >= 100) {
            setFile(null);
            setTimeout(() => {
              dispatch(AllCoursesActions.setIsAvailableSend(false));
            }, 1000);
          }
        },
        headers: {
          Authorization: `Bearer ${storageService.getAccessToken()}`
        }
      };

      try {
        const response = await axios.post(config.apiUrl + '/api/upload', formData, options);
        const uploadFile = response?.data?.data?.data?.[0];
        const homeworkFile = {
          url: uploadFile?.url,
          origname: uploadFile?.filename,
          size: uploadFile?.size
        };
        if (uploadFile) {
          setAllFiles([...allFiles, homeworkFile]);
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          storageService.removeRefreshToken();
          storageService.removeAccessToken();
          storageService.deleteCookie('shared_token');
          storageService.removeItem('studentInfo');
          store.dispatch(commonActions.fetchProfileFulfilled({}));
          history.push(RouteLinks.auth.login);
        }
      }
  };

  useEffect(() => {
    uploadFile()

  }, []);

  return {
    percentage
  };
};
