import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import {
  Button,
  SuccessImg,
  SuccessModalSubtitle,
  SuccessModalTitle
} from './success-modal.style';
import { useSuccessModalProp } from './success-modal.prop';

import SuccessIcon from '@assets/img/success-tick.svg';

export const RegisterSuccessModal = hoc(
  useSuccessModalProp,
  ({ successModalUnmount, count, success, setSuccess }) => {
    return (
      <UnModal
        modal={success}
        setModal={setSuccess}
        unMountModal={successModalUnmount}
      >
        <FlexWrapper mb='24px' justify='center'>
          <SuccessImg src={SuccessIcon} alt='success' />
        </FlexWrapper>
        <SuccessModalTitle>
          Akkauntingiz muvaffaqiyatli ro‘yxatdan o‘tdi!
        </SuccessModalTitle>

        <SuccessModalSubtitle>
          Platformaga kirish uchun telefon raqam va parolingizni kiriting.
        </SuccessModalSubtitle>

        <Button onClick={successModalUnmount} max>
          Kirishga qaytish <span>{count}</span>
        </Button>
      </UnModal>
    );
  }
);
