import styled from 'styled-components';
import Checked from '@assets/img/checked-pay.svg';
import AddIcon from '@assets/img/add.svg';
import { Button } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';

export const RememberedModalWrapper = styled.div``;

export const RememberedCardsList = styled.ul`
  margin: 24px 0 20px;
`;

export const IframeWrapper = styled.div`
  height: ${props => props.iframe ? '575px' : '210px'};
  overflow-y: auto;

  #card-form-iframe {
    height: ${props => props.iframe ? '575px' : '210px'} !important;
  }
`

export const RememberedCardItem = styled.li`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 12px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const RememberCardLabel = styled.label`
  border-radius: 8px;
  background-color: #fff;

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    color: #07cf86;
    border: 2px solid #f1f0ee;
    border-radius: 50%;
    padding: 8px;
    margin-right: 14px;
  }

  input[type='radio']:checked {
    background-image: url(${Checked});
    background-size: cover;
    border: none;
    padding: 10px;
  }
`;

export const RememberCardImg = styled.img`
  margin-right: 12px;
`;

export const RememberCardNumber = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;

  &::after {
    content: '';
    width: 4px;
    height: 4px;
    margin: 0 8px;
    background-color: #4e6575;
    border-radius: 50%;
  }
`;

export const RememberCardUserName = styled.span`
  color: #4e6575;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const NextAnotherCardBtn = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  background-color: transparent;
  border: 1px solid #dadada;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 24px;

  &::before {
    content: "";
    display: block;
    width: 28px;
    height: 28px;
    background-image: url(${AddIcon}); 
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 12px;
  }
`;

export const ButtonCore = styled(Button)`
  width: 100%;
`;

export const PayzeFormModal = styled.div`
  margin: 24px 0;
`;

export const PayzeFormHead = styled(FlexWrapper)`
  padding-bottom: 24px;
  border-bottom: 1px solid #dadada;
`;

export const PayzeFormInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 17px;
`;

export const PayzeFormPaymentText = styled.span`
  font-size: 14px;
  color: #81755C;
  margin-bottom: 8px;
`;

export const PayzeFormPrice = styled.span`
  font-size: 18px;
  line-height: 130%;
  margin-bottom: 2px;
`;

export const PayzeFormDesc = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.35);
`;

export const SaveCardDateText = styled.span`
  font-weight: 700;
  font-size: 12px;
  margin-left: 8px;
`;

export const PayzeButton = styled.button`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 12px;
  margin-right:  ${props => props.cancel ? '16px' : '0'};
  color: ${props => props.cancel ? '#ba8d5b' : '#fff'};
  background-color: ${props => props.cancel ? 'rgba(186, 141, 91, 0.1)' : '#ba8d5b'};
  border-radius: 100px;
  border: 0;
`;