import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';

export const CenterUpdateModal = styled(Scrollable)`
  max-height: ${props => (props.profile ? '550px' : '472px')};
  padding-top: 16px;
  overflow-y: scroll;
  padding-right: 18px;
  margin-top: 15px;
  margin-right: -20px;
  padding-right: 20px;

  .form-wrapper {
    margin-top: 0 !important;
  }
`;

export const InfoSubtitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
  max-width: 350px;
`;

export const InfoModalTop = styled.div`
  border-bottom: 1px solid rgba(218, 218, 218, 0.4);
`;

export const InfoModalBottom = styled.div``;

export const ModalBottom = styled.div`
  padding: 20px 0 0 0;
`;
