import {useEffect} from "react";

export const useHideRecaptchaVisibility = (isShowRecaptcha) => {
    const recaptchaElement = document.querySelector('.grecaptcha-badge');
        const setRecaptchaOpacity = (opacity) => {
            if (!recaptchaElement) return

                recaptchaElement.style.opacity = opacity;
        };

    useEffect(() => {
        if (isShowRecaptcha) {
            setRecaptchaOpacity('0')
        }
        else if (!isShowRecaptcha) setRecaptchaOpacity('1')

        return () => setRecaptchaOpacity('0');

    }, [recaptchaElement, isShowRecaptcha])
}
