import { commonActions } from '@store/common';
import {useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useMediaQuery} from "@utils/useMediaQuery";
import {useScreenRotation} from "@utils/useScreenRotation";

export const useHeaderProps = () => {
  const btnRef = useRef(null);
  const dispatch = useDispatch();
  const isHasSubheader = useSelector(state => state.common.isHasSubHeader)
  const isSidebarOpened = useSelector(state => state.common.sidebarState)
  const mqMaxWidt768 = useMediaQuery('(max-width: 768px)');
  const mqMinHeight555 = useMediaQuery('(min-height: 555px)');
  const isRotated = useScreenRotation();

  const handleClickBurgerBtn = () => {
    if (mqMaxWidt768 || (isRotated && !mqMinHeight555)) return
    btnRef.current.classList.toggle('active');
    dispatch(
      commonActions.setSidebar(
        btnRef.current.classList.value.includes('active')
      )
    );
  };

  useEffect(() => {
    if (mqMaxWidt768 && isSidebarOpened) {
      btnRef.current.classList.remove('active')
    dispatch(commonActions.setSidebar(!isSidebarOpened));
    }
  }, [mqMaxWidt768])

  return {
    handleClickBurgerBtn,
    btnRef,
    isHasSubheader
  };
};
