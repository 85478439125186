import { addNotification } from '@components/notifications'
import { authActions } from '@modules/auth/store'
import {
  ENTER_SMS_CODE,
  lengthChecker,
  LOGIN_TYPES,
  numberReg,
  PASSWORD,
  passwordRgp,
  PASSWORD_NOT_MATCH,
  PASSWORD_REQUIREMENT,
  REQUIRED_PASSWORD,
  TEXT,
  WRONG_OTP_CODE,
  passwordRequirementsRegs,
} from '@modules/new-auth/constants'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { api } from '@services/base.service'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CAPTCHA_ACTIONS } from '@constants/app'

const initialValues = {
  code: '',
  new_password: '',
  new_password_check: '',
}

const MANY_REQUESTS = 157

const validationSchema = Yup.object().shape({
  code: Yup.string().min(6, ENTER_SMS_CODE).max(6, ENTER_SMS_CODE).required(ENTER_SMS_CODE),
  new_password: Yup.string().matches(passwordRgp, PASSWORD_REQUIREMENT).required(REQUIRED_PASSWORD),
  new_password_check: Yup.string()
    .oneOf([Yup.ref('new_password'), null], PASSWORD_NOT_MATCH)
    .required(PASSWORD_NOT_MATCH),
})

export const useRecoveryEmailProp = ({ setInitialForm, setTime, setTimeIndex, timeIndex, email }) => {
  const [retry, setRetry] = useState(false)
  const countRef = useRef()
  const [inputType, setInputType] = useState(PASSWORD)
  const [checkInputType, setCheckInputType] = useState(PASSWORD)
  const [hasChecker, setHasChecker] = useState(false)
  const [hasPasswordLength, setHasPasswordLength] = useState(false)
  const [isIncludeNumber, setIsIncludeNumber] = useState(false)
  const dispatch = useDispatch()
  const [isDisabled, setDisable] = useState(false)
  const [token, setToken] = useState('')
  const isCaptchaError = useSelector((state) => state.auth?.isShowCaptchaError)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleEditEmail = () => {
    setInitialForm(true)
  }

  const handleSendOtp = async () => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.sendOtp)

      api.setHeader('recaptcha', token)

      const info = {
        data: {
          login: email,
          actionType: 'RECOVERY_PASSWORD',
          loginType: LOGIN_TYPES.email,
        },
        callback: (res) => {
          if (res.success) {
            setTime(Date.now() + (res?.data?.leftTime || 0))
            setTimeIndex(timeIndex + 1)
            api.setHeader('recaptcha', '')
          }
        },
      }

      dispatch(authActions.forgetPasswordPhone(info))
    } catch (error) {
      console.error('Send OTP:', error)
    }
  }

  const handleSubmit = async (values) => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.recoveryPassword)

      api.setHeader('recaptcha', token)

      const info = {
        data: {
          login: email,
          code: values.code,
          loginType: LOGIN_TYPES.email,
          newPassword: values.new_password,
        },
        callback: (res) => {
          if (res.success) {
            dispatch(authActions.setForgetEmailModal(false))
            api.setHeader('recaptcha', '')
            addNotification({
              title: 'Muvaffaqiyatli o‘zgartirildi',
              description: 'Parolingiz muvaffaqiyatli o‘zgartirildi',
              options: {
                autoClose: true,
                type: 'success',
              },
            })
          }

          if (res.error?.errId === MANY_REQUESTS) {
            dispatch(authActions.setForgetEmailModal(false))
            dispatch(authActions.setBlockModal(true))
          }
          if (res.error?.errId === WRONG_OTP_CODE) {
            window?.grecaptcha?.reset()
          }
          if (res.error) {
            formik.setFieldError('code', res?.error?.errMsg)
          }
        },
      }
      await dispatch(authActions.forgetPasswordCode(info))
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const handleChangeLoginCode = (e) => {
    formik.setFieldValue('code', e.target.value)
    formik.setErrors({ ...formik.errors, code: null })
  }

  const handleBlurLoginCode = (e) => {
    if (formik.values.code.length !== 6) {
      formik.setErrors({ ...formik.errors, code: ENTER_SMS_CODE })
    }
  }

  const handleChangePassword = (e) => {
    formik.handleChange(e)
    const passwordValue = e.target.value
    formik.setErrors({ ...formik.errors, new_password: '' })
    if (passwordValue?.match(lengthChecker)) {
      setHasPasswordLength(true)
    } else {
      setHasPasswordLength(false)
    }

    if (passwordValue?.match(numberReg)) {
      setIsIncludeNumber(true)
    } else {
      setIsIncludeNumber(false)
    }
  }

  const handleBlurPassword = () => {
    setHasChecker(false)
    const formikValues = formik.values

    if (formikValues.new_password.length === 0) {
      formik.setErrors({
        ...formik.errors,
        new_password: REQUIRED_PASSWORD,
      })
    }

    if (formikValues.new_password !== formikValues.new_password_check) {
      formik.setErrors({
        ...formik.errors,
        new_password_check: PASSWORD_NOT_MATCH,
      })
    }

    if (!passwordRequirementsRegs.every((req) => req.test(formikValues.new_password))) {
      formik.setErrors({
        ...formik.errors,
        new_password: PASSWORD_REQUIREMENT,
      })
    }
  }

  const handleBlurNewPasswordCheck = () => {
    const formikValues = formik.values
    if (formikValues.new_password !== formikValues.new_password_check || formikValues.new_password.length === 0) {
      formik.setErrors({
        ...formik.errors,
        new_password_check: PASSWORD_NOT_MATCH,
      })
    }
  }

  const handleChangeNewPasswordType = () => {
    setInputType(inputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleChangeCheckInputType = () => {
    setCheckInputType(checkInputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleChangeNewPasswordCheck = (e) => {
    formik.handleChange(e)
    const value = e.target.value
    if (value !== formik.values.new_password) {
      formik.setErrors({
        ...formik.errors,
        new_password_check: PASSWORD_NOT_MATCH,
      })
    } else {
      formik.setErrors({
        ...formik.setErrors({ new_password_check: '' }),
      })
    }
  }

  const handleFocusPassword = () => {
    setHasChecker(true)
  }

  const handleRecaptchaChange = (value) => {
    if (!value) {
      window?.grecaptcha?.reset()

      return
    }
    api.setHeader('recaptcha', value)
    setToken(value)
  }

  useEffect(() => {
    const errors = formik.errors
    const values = formik.values
    if (token) {
      dispatch(authActions.setShowCaptchaError(false))
    } else if (values?.new_password_check && values?.new_password && values?.code && !token) {
      dispatch(authActions.setShowCaptchaError(true))
    } else if (errors?.code || errors?.new_password || errors?.new_password_check) {
      dispatch(authActions.setShowCaptchaError(true))
    }
    return () => dispatch(authActions.setShowCaptchaError(false))
  }, [token, formik.errors, formik.values])

  return {
    formik,
    retry,
    countRef,
    setRetry,
    inputType,
    checkInputType,
    handleChangeLoginCode,
    handleBlurLoginCode,
    handleChangePassword,
    handleBlurPassword,
    hasChecker,
    handleFocusPassword,
    hasPasswordLength,
    isIncludeNumber,
    handleChangeNewPasswordCheck,
    handleBlurNewPasswordCheck,
    handleChangeNewPasswordType,
    handleChangeCheckInputType,
    isDisabled,
    handleEditEmail,
    handleSendOtp,
    handleRecaptchaChange,
    isCaptchaError,
  }
}
