import React from 'react';
import {PasswordCheckerItem} from "./item.component";
import {passwordCheckConditions} from './constants'
import {PasswordChecker} from "@modules/new-auth/routes/login/login.style";


export const PasswordRequirementsChecker = ({ password }) => {
    return (
        <PasswordChecker>
            {passwordCheckConditions.map(({ condition, label, imageEmpty, imageSuccess, imageNotMatch }, index) => (
                <PasswordCheckerItem
                    key={index}
                    condition={condition(password)}
                    label={label}
                    imageEmpty={imageEmpty}
                    imageSuccess={imageSuccess}
                    imageNotMatch={imageNotMatch}
                />
            ))}
        </PasswordChecker>
    );
};

