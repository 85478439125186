import { Input } from '@components/atoms/Input';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { CodeCountDown } from '@modules/auth/components/count-down';
import {
  Button,
  CheckerImg,
  CheckerTitle,
  EyeButton,
  EyeImg,
  Form,
  FormGroup,
  InputError,
  InputLabels,
  InputLongError,
  LoginRecoveryNumberBtn,
  LoginSubtitleRecoverPassword,
  LoginTimer,
  PasswordChecker
} from '@modules/new-auth/routes/login/login.style';
import CloseEyeImage from '@assets/img/close-eye.svg';
import OpenEyeImage from '@assets/img/open-eye.svg';
import NotMatch from '@assets/img/not-match.svg';
import Empty from '@assets/img/empty.svg';
import Success from '@assets/img/checksuccess.svg';
import { hoc } from '@utils/hoc';
import { phoneFormatter } from '@utils/phoneFormatter';
import { useRecoveryFormProp } from './recovery-form.prop';
import { PASSWORD_LONG_ERROR_MIN_LENGTH } from '@modules/new-auth/constants';
import { PasswordRequirementsChecker } from '@modules/new-auth/components/PasswordRequirementsChecker';

export const RecoveryForm = hoc(
  useRecoveryFormProp,
  ({
    phoneNumber,
    formik,
    retry,
    timeIndex,
    countRef,
    time,
    setRetry,
    inputType,
    checkInputType,
    handleChangeLoginCode,
    handleBlurLoginCode,
    handleChangePassword,
    handleBlurPassword,
    hasChecker,
    handleFocusPassword,
    hasPasswordLength,
    isIncludeNumber,
    handleChangeNewPasswordCheck,
    handleBlurNewPasswordCheck,
    handleChangeNewPasswordType,
    handleChangeCheckInputType,
    isDisabled,
    handleEditPhoneNumber,
    handleSendOtp,
    handleRecaptchaChange,
    isCaptchaError
  }) => {
    return (
      <>
        <LoginSubtitleRecoverPassword>
          Maxsus kod
          <LoginRecoveryNumberBtn onClick={handleEditPhoneNumber}>
            {phoneFormatter?.(phoneNumber)}
          </LoginRecoveryNumberBtn>
          raqamiga yuboril(a)di
        </LoginSubtitleRecoverPassword>

        <Form onSubmit={formik.handleSubmit}>
          <FormGroup className='special-code' mb>
            <InputLabels
              className={formik.errors.code && 'error'}
              isError={true}
              htmlFor='code'
            >
              Maxsus kod
            </InputLabels>
            <FlexWrapper
              className={`wrapper ${formik.errors.code ? 'input-error' : ''}`}
              align='center'
              justify='space-between'
            >
              <Input
                onChange={handleChangeLoginCode}
                id='code'
                type='text'
                placeholder='Maxsus kodni kiriting'
                onBlur={handleBlurLoginCode}
                name='code'
                value={formik.values.code}
                className={'password-input'}
              />
              <LoginTimer
                onClick={handleSendOtp}
                className={retry ? 'retry' : ''}
              >
                <CodeCountDown
                  timeIndex={timeIndex}
                  counterRef={countRef}
                  time={time}
                  setRetry={setRetry}
                >
                  Qayta yuborish
                </CodeCountDown>
              </LoginTimer>
            </FlexWrapper>
            {formik.errors?.code && (
              <InputError mb>{formik.errors?.code}</InputError>
            )}
          </FormGroup>

          <FormGroup
            isError={
              formik.errors?.new_password?.length >
              PASSWORD_LONG_ERROR_MIN_LENGTH
            }
            mb
          >
            <InputLabels
              className={formik.errors.new_password && 'error'}
              isError={true}
              htmlFor='new_password'
            >
              Yangi parol
            </InputLabels>

            <FlexWrapper
              className={`wrapper ${
                formik.errors.new_password ? 'input-error' : ''
              }`}
              align='center'
              justify='space-between'
            >
              <Input
                name='new_password'
                type={inputType}
                id='new_password'
                className='password-input'
                onFocus={handleFocusPassword}
                onBlur={handleBlurPassword}
                value={formik.values.new_password}
                onChange={handleChangePassword}
                placeholder='Parolingizni kiriting'
              />
              <EyeButton type='button'>
                <EyeImg
                  src={inputType === 'password' ? CloseEyeImage : OpenEyeImage}
                  alt='eye'
                  onClick={handleChangeNewPasswordType}
                />
              </EyeButton>
            </FlexWrapper>
            {hasChecker && (
              <PasswordRequirementsChecker
                password={formik.values.new_password}
              ></PasswordRequirementsChecker>
            )}
            {formik.errors?.new_password && (
              <InputLongError>{formik.errors?.new_password}</InputLongError>
            )}
          </FormGroup>

          <FormGroup formAd>
            <InputLabels
              className={
                formik.errors.new_password_check &&
                formik.touched.new_password_check &&
                'error'
              }
              isError={true}
              htmlFor='new_password_check'
            >
              Yangi parolni tekshirish
            </InputLabels>

            <FlexWrapper
              className={`wrapper ${
                formik.errors.new_password_check ? 'input-error' : ''
              }`}
              align='center'
              justify='space-between'
            >
              <Input
                name='new_password_check'
                type={checkInputType}
                id='new_password_check'
                className='password-input'
                onBlur={handleBlurNewPasswordCheck}
                value={formik.values.new_password_check}
                onChange={handleChangeNewPasswordCheck}
                placeholder='Parolingizni kiriting'
              />
              <EyeButton type='button'>
                <EyeImg
                  src={
                    checkInputType === 'password' ? CloseEyeImage : OpenEyeImage
                  }
                  alt='eye'
                  onClick={handleChangeCheckInputType}
                />
              </EyeButton>
            </FlexWrapper>
            {formik.errors?.new_password_check && (
              <InputError>{formik.errors?.new_password_check}</InputError>
            )}
          </FormGroup>
          <Button>Davom etish</Button>
        </Form>
      </>
    );
  }
);
