import { Route, Switch } from 'react-router-dom'
import { Login } from './login'
import { Register } from './register'
import React from 'react'

export const AuthRoutes = () => {
  return (
    <Switch>
      <Route path="/auth/login" component={Login} />
      <Route path="/auth/register" component={Register} />
    </Switch>
  )
}
