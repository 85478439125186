import React, { useEffect, useMemo, useRef, useState } from 'react';
import propTypes from 'prop-types';
import Hls from 'hls.js';
import Plyr from 'plyr';
import { v4 as uuid } from 'uuid';
import classNamesBind from 'classnames/bind';
import { NativeWatermark } from './native-watermark';
import styles from './styles.css';
import { useLocation } from 'react-router-dom';

const cn = classNamesBind.bind(styles);

export const HlsPlayer = ({
  src,
  currentTime = 0,
  watermark,
  onEnded,
  onPause,
  onPlay,
  onTimeUpdate,
  playerRef,
  updateVideoState,
  controlVisibleTrigger,
  videoQualities = []
}) => {
  const [dataVideoName] = useState(`data-video-${uuid()}`);
  const hlsRef = useRef(null);
  const videoElmRef = useRef(null);
  const videoLinkRef = useRef(src);
  const location = useLocation();
  const [isEnd, setIsEnd] = useState(false);
  const [played, setPlayed] = useState(false);
  const qualities = useMemo(() => {
    return videoQualities?.map(item => parseInt(item, 10));
  }, videoQualities);
  const defaultOptions = {
    playback: {
      preferredTech: [{ player: 'html5', streaming: 'hls' }]
    },
    autoplay: true,
    loop: {
      active: false
    },
    invertTime: false,
    keyboard: {
      focused: true,
      global: true
    },
    quality: {
      default: 576,
      options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240]
    },
    seekTime: 5,
    controls: [
      'play-large',
      'play',
      'restart',
      'progress',
      'current-time',
      'mute',
      'volume',
      'captions',
      'settings',
      'fullscreen'
    ],
    currentTime
  };
  const el = document.querySelector(
    '.hls-player--restart .plyr__control[data-plyr="restart"]'
  );

  useEffect(() => {
    if (!el) {
      return;
    }

    const handleClickRestartButton = () => {
      playerRef.current?.play();
    };

    el.addEventListener('click', handleClickRestartButton);

    return () => {
      el.removeEventListener('click', handleClickRestartButton);
    };
  }, [el, playerRef]);

  const handleUpdateQuality = newQuality => {
    hlsRef.current.levels.forEach((level, levelIndex) => {
      if (level.height === newQuality) {
        console.info('Found quality match with ' + newQuality);
        hlsRef.current.currentLevel = levelIndex;
      }
    });
  };

  const init = async (videoLink, qualities) => {
    const config = {
      maxMaxBufferLength: 30,
      maxBufferSize: 30 * 1024 * 1024 // bytes
    };

    const hls = new Hls(config);

    hlsRef.current = hls;
    hls.loadSource(videoLink);

    hls.on(Hls.Events.MANIFEST_PARSED, async (event, data) => {
      // const availableQualities = data.levels.map(l => l.height);
      const availableQualities = qualities;
      availableQualities.unshift(0);

      defaultOptions.quality = {
        default: 0,
        options: availableQualities,
        forced: true,
        onChange: handleUpdateQuality
      };

      defaultOptions.i18n = {
        qualityLabel: {
          0: 'Auto'
        }
      };

      hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
        const span = document.querySelector(
          ".plyr__menu__container [data-plyr='quality'][value='0'] span"
        );

        if (hls.autoLevelEnabled) {
          span.innerHTML = `AUTO (${hls.levels[data.level].height})`;
        } else {
          span.innerHTML = `AUTO`;
        }
      });

      playerRef.current = new Plyr(videoElmRef.current, defaultOptions);
      window.hlsPlayer = playerRef.current;

      playerRef.current.on('timeupdate', () => {
        onTimeUpdate(playerRef.current.currentTime);
      });

      playerRef.current.on('ratechange', e => {
        updateVideoState(e?.detail?.plyr?.config?.speed?.selected);
      });

      playerRef.current.on('play', event => {
        onPlay(event);
        setPlayed(true);
        setIsEnd(false);
      });

      playerRef.current.on('restart', event => {
        onPlay(event);
        setPlayed(true);
        setIsEnd(false);
        playerRef.current?.play();
      });

      playerRef.current.on('pause', event => {
        onPause(event);
        setPlayed(false);
      });

      playerRef.current.on('ended', event => {
        onEnded(event);
        playerRef.current.stop();
        setPlayed(false);
        setIsEnd(true);
        document?.exitFullscreen();
      });

      playerRef.current.once('canplay', () => {
        if (playerRef.current) {
          playerRef.current.currentTime = currentTime;
          playerRef.current?.play();
        }
      });

      playerRef.current.on('controlshidden', () => {
        controlVisibleTrigger?.(false);
      });

      playerRef.current.on('controlsshown', () => {
        controlVisibleTrigger?.(true);
      });
    });

    hls.attachMedia(videoElmRef.current);

    window.hls = hls;
  };

  useEffect(() => {
    if (!videoElmRef) {
      return;
    }

    if (!Hls.isSupported()) {
      console.error('Your browser not supported HLS (HTTP Live Streaming)');
    }

    init(src, qualities);
  }, []);

  useEffect(() => {
    return () => {
      playerRef.current?.destroy();
      hlsRef.current?.destroy();
      window.hls = null;
    }
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.currentTime = currentTime;
    }
  }, [currentTime, playerRef]);

  useEffect(() => {
    if (videoLinkRef.current !== src) {
      videoLinkRef.current = src;
      init(src, qualities).then(() => {
        if (playerRef.current) {
          playerRef.current.currentTime = currentTime;
          playerRef.current?.play();
        }
      });
    }
  }, [init, src, qualities]);

  return (
    <div className={cn('hls-player', { 'hls-player--restart': isEnd })}>
      <video
        ref={videoElmRef}
        className={cn('hls-player__video')}
        crossOrigin='anonymous'
        data-video={dataVideoName}
        controls
        autoPlay
        playsInline
      >
        <source key={src} src={src} />
      </video>

      <NativeWatermark
        watermark={watermark}
        dataName={dataVideoName}
        played={played}
      />
    </div>
  );
};

HlsPlayer.prototype = {
  src: propTypes.string,
  watermark: propTypes.string,
  currentTime: propTypes.number,

  onEnded: propTypes.func,
  onPause: propTypes.func,
  onPlay: propTypes.func,
  onTimeUpdate: propTypes.func
};
