import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HomeworkWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
`;

export const Table = styled.table`
  border: 1px solid rgba(188, 185, 182, 0.1);
  width: 100%;
`;

export const TableRow = styled.tr`
  border: 1px solid rgba(188, 185, 182, 0.1);
  padding: 12px 24px;
`;

export const TableDirection = styled.td`
  border: ${props =>
    props.head ? 'none' : '1px solid rgba(188, 185, 182, 0.1)'};
  padding: 12px 24px;
`;

export const TableHead = styled.thead`
  background: rgba(188, 185, 182, 0.1);
  text-align: center;
`;

export const TableHeadTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #848080;
`;

export const TableBody = styled.tbody``;

export const TableContent = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #000000;
`;

export const ResultWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  height: 33px;
`;

export const ResultImg = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

export const TableContentInnerTop = styled.span`
display: inline-block;
margin-right: 5px;
margin-bottom: 5px;
`
