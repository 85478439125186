import { authService } from '@auth/services/auth.service';
import { tryCatchSagaFactory } from '@utils/try-catch-saga';
import { all, call, takeLatest } from 'redux-saga/effects';
import { authActions } from './auth.slice';

export const tryCatchSaga = tryCatchSagaFactory(authActions)

export function* requestRegister({ payload }) {
    const res = yield call(authService.register, payload.data)
    yield payload.callback(res)
}

export function* signUpWithGoogle({payload}) {
    const res = yield call(authService.signUpWithGoogle, payload.data)
    yield payload.callback(res)
}

export function* verifyPhoneNumber({ payload }) {
    const res = yield call(authService.verifyPhoneNumber, payload.data)
    yield payload?.callback(res)
}

export function* requestLogin({ payload }) {
    const res = yield call(authService.login, payload.data)
    yield payload.callback(res)
}

export function* requestForgetPasswordPhoneVerify({payload}){
    const res = yield call(authService.requestForgetPasswordPhoneVerify, payload.data)
    yield payload.callback(res)
}

export function* requestForgetPasswordCode({payload}) {
    const res = yield call(authService.forgetPasswordCode, payload.data)
    yield payload.callback(res);
}

export function* authRootSaga() {
    yield all([
        takeLatest(authActions.register, tryCatchSaga(requestRegister)),
        takeLatest(authActions.verifyPhoneNumber, tryCatchSaga(verifyPhoneNumber)),
        takeLatest(authActions.login, tryCatchSaga(requestLogin)),
        takeLatest(authActions.forgetPasswordPhone, tryCatchSaga(requestForgetPasswordPhoneVerify)),
        takeLatest(authActions.forgetPasswordCode, tryCatchSaga(requestForgetPasswordCode)),
        takeLatest(authActions.signUpWithGoogle, tryCatchSaga(signUpWithGoogle)),
    ]);
}

