import { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { commonActions } from '@store/common'
import { AllCoursesActions } from '@modules/all-courses/store'
import { hideCaptchaBadge } from '@utils/toggleRecaptchaBadge'
import { useHideRecaptchaVisibility } from '@utils/useHideRecaptchaVisibility'

export const useMyCoursesProp = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const myCourses = useSelector((state) => state.common.myCourses)
  const myCoursesLoading = useSelector((state) => state.common.myCoursesLoading)
  useHideRecaptchaVisibility(true)
  const recommendedCourses = useSelector((state) => state.common.recommendedCourse)
  const recommendedCourseLoading = useSelector((state) => state.common.recommendedCourseLoading)

  const sidebarState = useSelector((state) => state.common.sidebarState)

  const handleBuyCourses = () => {
    history.push('/courses')
  }

  useEffect(() => {
    dispatch(commonActions.setSubHeaderTitle('Mening kurslarim'))
    dispatch(commonActions.setSubheader(true))
    hideCaptchaBadge()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(commonActions?.fetchMyCourses())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(AllCoursesActions.fetchSingleCourseFulfilled({}))
    dispatch(commonActions.fetchRecommendedCourses())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allMyCourses = useMemo(() => {
    return myCourses?.filter((item) => item.status !== 3)
  }, [myCourses])

  const archivedCourses = useMemo(() => {
    return myCourses?.filter((item) => item.status === 3)
  }, [myCourses])

  return {
    handleBuyCourses,
    myCourses,
    sidebarState,
    recommendedCourses,
    recommendedCourseLoading,
    allMyCourses,
    archivedCourses,
    myCoursesLoading,
  }
}
