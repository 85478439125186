import styled from 'styled-components';

export const HomeworkMarkWrapper = styled.div`
    background: #f9f4ef;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;

    .re-send {
        padding: 12px 50px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-top: 24px;
    }
`;

export const HomeworkMarkTitle = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
`;

export const HomeworkMarkSubtitle = styled.h4(
  ({ theme: { down }, fail}) => `
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: ${fail ? '#EA4335' : '#07CD86'};

    ${down('576px')} {
      font-size: 12px;
    }
`);

export const HomeworkFeedback = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.5);
`;
