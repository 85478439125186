import DataTable from 'react-data-table-component';
import styled from 'styled-components';

export const RatingTable = styled(DataTable)`
  border-collapse: collapse;
  .rdt_TableCol,
  .rdt_TableCell
  /* .rdt_TableRow, */
  /* .rdt_TableRow  */ {
    border: 1px solid #d9d9d9;
    border-collapse: collapse;
  }

  .sc-QswQi {
    display: block;
    margin: 0 auto;
  }

  .rdt_TableHeadRow {
    border: none;
  }

  .hWGUPT:not(:last-of-type) {
    border: none;
  }
`;
