import styled from 'styled-components';

export const EmptyWrapper = styled.div`
  height: calc(100vh - 160px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
`;

export const ErrorImage = styled.img`
  width: 200px;
  height: 200px;
`;

export const ErrorTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ba8d5b;
  margin-bottom: 32px;
`;
