import { Heading, UserImg } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { theme } from '@styles/Theme';
import { hoc } from '@utils/hoc';
import { useNavUserProps } from './nav-user.prop';
import {
  Badge,
  BellWrapper,
  NotificationImg,
  ProfileMenu,
  ProfileMenuItem,
  StyledUser
} from './nav-user.style';
import DefaultAvatar from '@assets/img/default-avatar.png';
import { config } from '@config/config';
import { ReactComponent as IconSettings } from '@assets/img/icon-settings.svg';
import { ReactComponent as IconPayment } from '@assets/img/icon-payment.svg';
import { ReactComponent as IconResult } from '@assets/img/result.svg';
import { isLink } from '@utils/linkChecker';
import bell from '@assets/img/bell.svg';

export const NavUser = hoc(
  useNavUserProps,
  ({ collapseRef, profile, notificationCount }) => {
    return (
      <FlexWrapper gap='20px' align='center'>
        <BellWrapper to='/notifications'>
          <NotificationImg src={bell} alt='notification' />
          {!!notificationCount && (
            <Badge>{notificationCount < 10 ? notificationCount : '9+'}</Badge>
          )}
        </BellWrapper>
        <StyledUser>
          <FlexWrapper gap='1.6rem' align='center'>
            <UserImg
              src={
                profile?.photo
                  ? isLink(profile?.photo)
                    ? profile?.photo
                    : `${config.imgApiUrl}/${profile.photo}`
                  : DefaultAvatar
              }
              alt={profile?.firstName}
            />
            <FlexWrapper direction='column' gap='0.2rem'>
              <Heading variant='h5'>
                {profile?.firstName + ' ' + (profile?.lastName || '')}
              </Heading>
              <Heading variant='h6' color={theme.colors.gray}>
                O'quvchi
              </Heading>
            </FlexWrapper>
          </FlexWrapper>
          <ProfileMenu u ref={collapseRef} className='profile-menu'>
            <ProfileMenuItem to='/settings'>
              <IconSettings width={20} height={20} fill='#bfbfbf' />
              Sozlamalar
            </ProfileMenuItem>
            <ProfileMenuItem to='/payments'>
              <IconPayment fill='#bfbfbf' />
              To‘lovlar
            </ProfileMenuItem>
            {/* <ProfileMenuItem to='/my-ratings'>
              <IconResult fill='#bfbfbf' />
              Ko‘rsatgichlarim
            </ProfileMenuItem> */}
          </ProfileMenu>
        </StyledUser>
      </FlexWrapper>
    );
  }
);
