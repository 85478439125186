import styled from "styled-components";
import IconArrowRight from "@assets/img/arrow-right.svg";

export const CurrencyExchangeWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #81755C;
  margin: 12px 0;
`;

export const CurrencyExchangeDesc = styled.p`
  margin-bottom: 32px;
`;

export const CurrencyExchangePrice = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 32px;

    &::after {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      background-image: url(${IconArrowRight});
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 32px;
    }
  }
`;

export const CurrencyExchangeDollar = styled.span`
  margin-bottom: 4px;
`;

export const CurrencyExchangeUZS = styled.span`
  font-size: 24px;
  line-height: 130%;
  color: #000
`;