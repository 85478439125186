import { dateFormat } from './date-format';

export const secondsFormat = sec => {
  if (!sec) return '0:00';
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  let template = '';

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (hours > 0) {
    template += hours + ':';
  }
  template += `${minutes}:${seconds}`;
  return template;
};

export const timeSince = (date, limitDay) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1 && !limitDay) {
    return Math.floor(interval) + ' yil';
  }
  interval = seconds / 2592000;
  if (interval > 1 && !limitDay) {
    return Math.floor(interval) + ' oy';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval > limitDay) return dateFormat(date);
    return Math.floor(interval) + ' kun';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' soat';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' daqiqa';
  }
  return Math.floor(seconds) + ' soniya';
};

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);

  var hDisplay = h > 0 ? h + (h === 1 ? ' soat ' : ' soat ') : '';
  var mDisplay = m > 0 ? m + (m === 1 ? ' daqiqa' : ' daqiqa') : '';
  return hDisplay + mDisplay;
}

export const secondsToHmsHour = d => {
  d = Number(d);
  return Math.floor(d / 3600);
};

export const secondsToHmsMinute = d => {
  d = Number(d);
  return Math.floor((d % 3600) / 60);
};

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return (
    String(minutes).padStart(2, 0) + ':' + (seconds < 10 ? '0' : '') + seconds
  );
}

export const timeFormat = time => {
  const newTime = new Date(time);
  return (
    addLeadingZeros(newTime.getHours(), 2) +
    ':' +
    addLeadingZeros(newTime.getMinutes(), 2)
  );
};

function addLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export const commentTimeFormat = data => {
  const date = new Date(data);

  const month = [
    'yanvar',
    'fevral',
    'mart',
    'aprel',
    'may',
    'iyun',
    'iyul',
    'avgust',
    'sentabr',
    'oktabr',
    'noyabr',
    'dekabr'
  ];

  const year = date.getFullYear();
  return date.getDate() + '-' + month[date.getMonth()] + ', ' + year;
};

export const certificateDate = data => {
  const date = new Date(data);

  const month = [
    'yanvar',
    'fevral',
    'mart',
    'aprel',
    'may',
    'iyun',
    'iyul',
    'avgust',
    'sentabr',
    'oktabr',
    'noyabr',
    'dekabr'
  ];

  return `${date.getDate()} ${month[date.getMonth()]}, ${date.getFullYear()}`;
};

export const getTimeFormatFromDate = date => {
  if (!date) return '00:00';
  const data = new Date(date);

  const hours = data.getHours();
  const minutes = data.getMinutes();

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};
