import { AllCoursesActions } from '@modules/all-courses/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addNotification, removeNotification } from '@components/notifications';
import { RouteLinks } from 'src/constants/route';
import { useMediaQuery } from '@utils/useMediaQuery';
import { commonActions } from '@store/common';

export const useCourseNotPurchasedProp = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const singleCourse = useSelector(state => state.courses?.singleCourse);
  const mq864MaxWidth = useMediaQuery('(max-width: 824px)');

  const handleBuyAllCourses = () => {
    history.push(`${RouteLinks.myCoursesSingle}/${singleCourse?.id}?buy=all`);
  };

  useEffect(() => {
    addNotification({
      description: 'Siz ushbu modulni sotib olmagansiz',
      title: 'Modul sotib olinmagan',
      options: {
        autoClose: false,
        type: 'warning'
      }
    });

    return () => {
      removeNotification?.();
    };
  }, []);

  useEffect(() => {
    if (singleCourse === null) {
      history.push('/not-found');
    }
  }, [singleCourse]);

  useEffect(() => {
    dispatch(commonActions.setSubheader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!id) return;

    dispatch(AllCoursesActions.fetchSingleCourse({id: id}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    singleCourse,
    handleBuyAllCourses,
    mq864MaxWidth
  };
};
