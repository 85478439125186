import styled from "styled-components";

export const StyledButton = styled.button(
    () => `
   background: #EA4335;
   border: 1px solid transparent;
   color: #fff;
   margin-left: 16px;
   transition: all 0.3s;
   border-radius: 100px;
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 19px;
   text-align: center;
   letter-spacing: -0.02em;
   padding: 13px 80px;

   &:hover {
     background:#fff;
     color: #000;
     border: 1px solid #EA4335;
   }
   &:disabled {
     font-weight: bold;
     font-size: 16px;
     line-height: 22px;
     cursor: not-allowed;
     border: none;
     opacity: 0.5;
     }
    }
    
  `,
    ({ theme: { down } }) => `
   ${down('500px')} {
    padding: 8px 16px 12px 10px;
   }
   `);

export const ButtonInner = styled.span`
   display: flex;
   align-items: center;
   justify-content: center;
`

export const ButtonText = styled.span`
   font-weight: bold;
   font-size: 16px;
   line-height: 22px;
`

export const ButtonSpinner = styled.img`
   animation-name: spin;        
   animation-duration: 500ms;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
   
   @keyframes spin {
     from {
        transform: rotate(0deg);
     }
     to {
         transform: rotate(360deg);
     }
   }
`
