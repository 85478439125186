import {
  DeletedCommentImg,
  DeletedCommentTitle,
  DeletedCommentWrapper
} from './deleted-comment.style';
import IconAppeal from '@assets/img/warning.svg';

export const DeletedComment = ({ admin }) => {
  return (
    <DeletedCommentWrapper>
      <DeletedCommentImg src={IconAppeal} />
      <DeletedCommentTitle>
        {`Izoh ${
          admin ? 'admin' : 'foydalanuvchi '
        } tomonidan o‘chirib yuborilgan`}
      </DeletedCommentTitle>
    </DeletedCommentWrapper>
  );
};
