
export const hideCaptchaBadge = () => {
        const badge = document.querySelector('.grecaptcha-badge');
        if (badge && badge instanceof HTMLElement) {
            badge.classList.remove('show')
        }
    };

export const visibleCaptchaBadge = () => {

    const badge = document.querySelector('.grecaptcha-badge');
    if (badge && badge instanceof HTMLElement) {
        badge.classList.add('show')
    }
}
