import Countdown from 'react-countdown';

export const CodeCountDown = ({ children, setRetry, counterRef, time, timeIndex }) => {
  return (
    <Countdown
      ref={counterRef}
      key={timeIndex}
      date={time}
      renderer={({ minutes, seconds, completed }) => {
        if (completed) {
          setRetry(true)
          return children
        } else {
          setRetry(false)
          seconds = seconds < 10 ? '0' + seconds : seconds;
          return (
            <>
              {minutes}:{seconds}
            </>
          );
        }
      }}
    ></Countdown>
  );
};
