import React from 'react';
import {
  Notify,
  NotifyContent,
  NotifyContentDescription,
  NotifyContentTitle,
  NotifyIcon,
  NotifyIconClose
} from './styles';
import {ReactComponent as IconClose} from './icons/close.svg';
import {ReactComponent as IconWarning} from './icons/warning.svg';
import {ReactComponent as IconSuccess} from './icons/success.svg';
import {ReactComponent as IconError} from './icons/error.svg';
import {ReactComponent as IconInfo} from './icons/info.svg';

// type Props = {
//   closeToast?: () => void;
//   description: ReactNode;
//   title: ReactNode;
//   type?: NotificationsTypes;
// };

const iconByType = {
  'info': <IconInfo />,
  'success': <IconSuccess />,
  'error': <IconError />,
  'warning': <IconWarning />,
};

export const NotificationToast = ({title, description, type = 'info', closeToast}) => (
  <Notify type={type}>
    <NotifyIcon type={type}>{iconByType[type]}</NotifyIcon>

    <NotifyContent>
      <NotifyContentTitle>{title}</NotifyContentTitle>
      <NotifyContentDescription>{description}</NotifyContentDescription>
    </NotifyContent>

    <NotifyIconClose onClick={closeToast}>
      <IconClose />
    </NotifyIconClose>
  </Notify>
);
