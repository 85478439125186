import { CourseProgress } from '@components/course-progress';
import { hoc } from '@utils/hoc';
import { BlogCard } from './blog-card';
import { useRightSidebarProps } from './right-sidebar.prop';
import {
  NoBlogTitle,
  NoBlogWrapper,
  RightSidebarWrapper,
  RightSidebarWrapperInner,
  EmptyBlogImg
} from './right-sidebar.style';
import EmptyBlog from '@assets/img/empty-blog.svg';
import { RightSidebarBlogSelektor } from './right-sidebar-skeleton';

export const RightSidebar = hoc(
  useRightSidebarProps,
  ({ blog, recoPostLoad, myCourses, sidebarState, mq1213MaxWidth }) => {
    const isNotVisible = sidebarState && mq1213MaxWidth;

    if (isNotVisible) {
      return null;
    }

    return (
      <RightSidebarWrapper>
        <RightSidebarWrapperInner>
          {recoPostLoad ? (
            <RightSidebarBlogSelektor />
          ) : blog?.id ? (
            <BlogCard />
          ) : (
            <NoBlogWrapper
              justify='center'
              align='center'
              className='no-blog'
              direction='column'
            >
              <EmptyBlogImg src={EmptyBlog} />

              <NoBlogTitle>
                Hozirda platformada hech qanday blog mavjud emas.
              </NoBlogTitle>
            </NoBlogWrapper>
          )}
          {myCourses?.length > 0 &&
            myCourses?.map(myCoursesItem => (
              <CourseProgress
                key={myCoursesItem.id}
                courseTitle={myCoursesItem?.name}
                category={myCoursesItem?.category?.name}
                progress={myCoursesItem?.progress}
                color={myCoursesItem?.color}
                link={myCoursesItem?.id}
              />
            ))}
        </RightSidebarWrapperInner>
      </RightSidebarWrapper>
    );
  }
);
