import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { Route, Switch } from 'react-router-dom';
import {
  LoginTitle,
  NestedLink,
  RightSideInner
} from '../LoginRightSide/login-rightside.style';
import { EmailForm } from './EmailForm';
import { PhoneForm } from './PhoneForm';
import { useRegisterProps } from './register-rightside.prop';
import { RightSide } from './register-rightside.style';
import { RegisterSuccessModal } from './SuccessModal/success-modal.component';

export const RegisterRightSide = hoc(
  useRegisterProps,
  ({ url, path, mqMaxWidth768, successRegister }) => {
    return (
      <>
        <RightSide>
          <RightSideInner>
            <LoginTitle>Ro‘yxatdan o‘tish</LoginTitle>

            <FlexWrapper
              justify={mqMaxWidth768 ? 'center' : 'initial'}
              mb='28px'
            >
              <NestedLink exact to={url} activeClassName='selected'>
                Telefon raqam orqali
              </NestedLink>
              <NestedLink to={`${url}/by-email`} activeClassName='selected'>
                Elektron pochta orqali
              </NestedLink>
            </FlexWrapper>

            <Switch>
              <Route exact path={path} component={PhoneForm} />
              <Route exact path={`${path}/by-email`} component={EmailForm} />
            </Switch>
          </RightSideInner>
        </RightSide>

        {successRegister && <RegisterSuccessModal />}
      </>
    );
  }
);
