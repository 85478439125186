import { AllCoursesActions } from '@modules/all-courses/store'
import { authActions } from '@modules/auth/store'
import {
  BIRTH_DATE,
  CHANGE_LOGIN,
  GENDER_REQUIRED,
  LASTNAME_REQUIRED,
  LOGIN_TYPES,
  MIN_LASTNAME,
  MIN_NAME,
  NAME_REQUIRED,
  PHONE_REQUIREMENT,
  REQUIRED_PHONE_NUMBER,
  alphaNumericLettersReg,
  invalid,
} from '@modules/new-auth/constants'
import { settingsActions } from '@modules/settings/store'
import { storageService } from '@services/storage.service'
import { commonActions } from '@store/common'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

const initialValues = {
  firstName: '',
  lastName: '',
  genderType: '',
  birthDate: '',
  phoneNumber: '123456789',
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(3, MIN_NAME).required(NAME_REQUIRED),
  lastName: Yup.string().min(3, MIN_LASTNAME).required(LASTNAME_REQUIRED),
  phoneNumber: Yup.string().min(9, PHONE_REQUIREMENT).required(REQUIRED_PHONE_NUMBER),
  genderType: Yup.string().required(GENDER_REQUIRED),
  birthDate: Yup.string().required(BIRTH_DATE),
})

const MINIMUM_NAME_LENGTH = 3
const MINIMUM_PHONE_NUMBER_LENGTH = 9

export const useUpdateInfoProp = () => {
  const dispatch = useDispatch()

  const userInfo = useSelector((state) => state.common.profile)
  const updateInfoModalProp = useSelector((state) => state.courses.updateInfoModal)
  const studentInfo = useSelector((state) => state.courses.editStudentInfo)
  const supportLink = useSelector((state) => state.common.supportLink?.[0]?.link)
  const today = useMemo(() => new Date(), [])

  const [initialDate, setInitialDate] = useState('kk/oo/yyyy')
  const [modal, setModal] = useState(false)
  const [gender, setGender] = useState('')
  const [openselect, setopenSelect] = useState(false)
  const [date, setDate] = useState(today)
  const [isAvailable, setIsAvailable] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [selectedYear, setSelectedYear] = useState({})
  const [selectedMonth, setSelectedMonth] = useState({})
  const [selectedDay, setSelectedDay] = useState({})
  const yearOption = []

  const handleCloseModal = () => {
    setModal(false)
    dispatch(AllCoursesActions.setUpdateInfoModal(false))
  }

  const handleChangeUserName = (e) => {
    formik.setErrors({
      ...formik.errors,
      firstName: '',
    })
    if (alphaNumericLettersReg.test(e.target.value)) {
      formik.setErrors({ firstName: invalid })
    }
    const encodedValue = e.target.value.replace(alphaNumericLettersReg, '')

    formik.setFieldValue('firstName', encodedValue)
  }

  const handleBlurUserName = () => {
    const firstName = formik.values.firstName
    if (firstName.length === 0) {
      formik.setErrors({
        ...formik.errors,
        firstName: NAME_REQUIRED,
      })
    }

    if (firstName.length > 0 && firstName.length < 4) {
      formik.setErrors({
        ...formik.errors,
        firstName: MIN_NAME,
      })
    }
  }

  const handleChangeLastName = (e) => {
    formik.setErrors({
      ...formik.errors,
      lastName: '',
    })
    if (alphaNumericLettersReg.test(e.target.value)) {
      formik.setErrors({ lastName: invalid })
    }
    const encodedValue = e.target.value.replace(alphaNumericLettersReg, '')

    formik.setFieldValue('lastName', encodedValue)
  }

  const handleBlurLastName = () => {
    const lastName = formik.values.lastName
    if (lastName.length === 0) {
      formik.setErrors({
        ...formik.errors,
        lastName: LASTNAME_REQUIRED,
      })
    }

    if (lastName.length > 0 && lastName.length < 4) {
      formik.setErrors({
        ...formik.errors,
        lastName: MIN_LASTNAME,
      })
    }
  }

  const handleSubmit = (values) => {
    try {
      const changePhone = {
        data: {
          login: `998${values.phoneNumber}`,
          actionType: CHANGE_LOGIN,
          loginType: LOGIN_TYPES.phone,
        },
        callback: (res) => {
          if (res.success) {
            dispatch(AllCoursesActions.setConfirmModal(true))

            dispatch(
              AllCoursesActions.setConfirmPhone({
                phoneNumber: `998${values.phoneNumber}`,
                leftTime: res?.data?.leftTime,
              }),
            )
            handleCloseModal()
          }

          if (res?.error) {
            formik.setFieldError('phoneNumber', res?.error?.errMsg)
          }
        },
      }

      const submittedInfo = {
        firstName: values.firstName.replace(/[^a-zA-Z0-9']/g, ''),
        lastName: values.lastName.replace(/[^a-zA-Z0-9']/g, ''),
        birthdate: date.toISOString(),
        genderType: values.genderType === 0 ? 'MALE' : 'FEMALE',
        image: userInfo?.photo || '',
        phoneNumber: `998${values?.phoneNumber}`,
      }

      const infoObj = {
        data: submittedInfo,
        callback: (res) => {
          if (res.success) {
            if (!userInfo?.phoneNumber) {
              dispatch(authActions.forgetPasswordPhone(changePhone))
              dispatch(AllCoursesActions.setStudentInfo(res?.data))
              return
            }

            const studentInfo = {
              ...res?.data,
              hasPassword: userInfo.hasPassword,
            }

            dispatch(AllCoursesActions.setStudentInfo(studentInfo))
            dispatch(commonActions.fetchProfileFulfilled(studentInfo))
            storageService.setItem('studentInfo', JSON.stringify(res?.data))
            handleCloseModal()
          }

          if (res?.error) {
            formik.setFieldError('phoneNumber', res?.error?.errMsg)
          }
        },
      }

      dispatch(AllCoursesActions.setEditStudentInfo(infoObj.data))
      dispatch(settingsActions.updateUserInfo(infoObj))
    } catch (error) {
      console.error('Update info error:', error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  })

  const onSubmitForm = (e) => {
    e.preventDefault()
  }

  const handleClickGender = (value) => {
    if (value >= 1 && !gender) {
      formik.setErrors({
        ...formik.errors,
        genderType: 'Jinsingizni belgilang',
      })
    }
    setopenSelect(true)

    formik.setFieldValue('genderType', value)
  }

  const handleChangeGender = (value) => {
    setGender(value)
    formik.setErrors({
      ...formik.errors,
      genderType: '',
    })
  }

  const handleBlurPhoneNumber = () => {
    const phone = formik.values.phoneNumber
    if (phone.length === 0) {
      formik.setErrors({
        ...formik.errors,
        phoneNumber: REQUIRED_PHONE_NUMBER,
      })
    }
  }

  const handleChangePhoneNumber = (e) => {
    formik.setFieldValue('phoneNumber', e.target.value?.trim().replace(/ /g, ''))
    formik.setErrors({
      ...formik.errors,
      phoneNumber: '',
    })
  }

  const handleYearChange = (year) => {
    setSelectedYear(year)
  }

  const handleMonthChange = (month) => {
    setSelectedMonth(month)
  }

  const handleDayChange = (day) => {
    setSelectedDay(day)
  }

  const currentYear = new Date().getFullYear()

  for (let year = 1970; year <= currentYear - 5; year++) {
    yearOption.push({ value: year, label: year })
  }

  const generateDayOptions = () => {
    const daysInMonth = new Date(selectedYear.value, selectedMonth.value, 0).getDate()
    let options = []

    if (selectedYear.value && selectedMonth.value) {
      for (let day = 1; day <= daysInMonth; day++) {
        options.push({
          value: `${day}`.padStart(2, 0),
          label: `${day}`.padStart(2, 0),
        })
      }
    } else {
      for (let day = 1; day <= 31; day++) {
        options.push({
          value: `${day}`.padStart(2, 0),
          label: `${day}`.padStart(2, 0),
        })
      }
    }

    return options
  }

  const months = [
    { value: '01', label: 'Yanvar' },
    { value: '02', label: 'Fevral' },
    { value: '03', label: 'Mart' },
    { value: '04', label: 'Aprel' },
    { value: '05', label: 'May' },
    { value: '06', label: 'Iyun' },
    { value: '07', label: 'Iyul' },
    { value: '08', label: 'Avgust' },
    { value: '09', label: 'Sentabr' },
    { value: '10', label: 'Oktabr' },
    { value: '11', label: 'Noyabr' },
    { value: '12', label: 'Dekabr' },
  ]

  const checkFullBirthdayDate = () => {
    if (selectedDay && selectedMonth && selectedYear) {
      const formatDate = new Date(
        dayjs(`${selectedYear.value}-${selectedMonth.value}-${selectedDay.value}`).format('YYYY-MM-DD'),
      )

      setDate(formatDate)
      formik.setFieldValue('birthDate', date)
      formik.setErrors({ ...formik.errors, birthDate: '' })
    }
  }

  useEffect(() => {
    setModal(updateInfoModalProp)
  }, [updateInfoModalProp])

  useEffect(() => {
    if (
      formik.values.firstName.length >= 3 &&
      formik.values.lastName?.length > 0 &&
      formik.values.phoneNumber?.length > 0 &&
      gender &&
      selectedDay.value &&
      selectedMonth.value &&
      selectedYear.value
    ) {
      setIsAvailable(false)
      checkFullBirthdayDate()
    } else {
      setIsAvailable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.firstName.length,
    formik.values.lastName?.length,
    formik.values.phoneNumber?.length,
    gender,
    selectedDay.value,
    selectedMonth.value,
    selectedYear.value,
  ])

  useEffect(() => {
    if (userInfo?.gender) {
      userInfo?.gender === 1 ? setGender('Erkak') : setGender('Ayol')
    }
  }, [userInfo])

  useEffect(() => {
    if (studentInfo?.genderType) {
      studentInfo.genderType === 'MALE' ? setGender('Erkak') : setGender('Ayol')
    }
  }, [studentInfo])

  useEffect(() => {
    if (userInfo?.birthdate) {
      const [birthYear, birthMonth, birthDay] = dayjs(userInfo?.birthdate).format('YYYY-MM-DD').split('-')
      setSelectedYear({ value: birthYear, label: birthYear })
      setSelectedMonth({ value: birthMonth, label: birthMonth })
      setSelectedDay({ value: birthDay, label: birthDay })
      setDate(new Date(userInfo?.birthdate))
    }
  }, [userInfo])

  useEffect(() => {
    if (userInfo?.firstName || userInfo?.lastName || userInfo?.phoneNumber || userInfo?.gender || userInfo?.birthdate) {
      formik.setValues({
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        genderType: userInfo?.gender === 1 ? 'Erkak' : userInfo?.gender === 2 ? 'Ayol' : '',
        birthDate: initialDate !== 'kk/oo/yyyy' ? initialDate : '',
        phoneNumber: userInfo?.phoneNumber?.slice(3),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, modal])

  useEffect(() => {
    if (studentInfo?.birthdate) {
      setInitialDate(new Date(studentInfo?.birthdate))
      const allMonth = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
      setDate(new Date(studentInfo?.birthdate))
      const date = new Date(studentInfo?.birthdate)
      const day = date.getDate()
      const month = allMonth[date.getMonth()]
      const year = date.getFullYear()
      setInitialDate(day + '/' + month + '/' + year)
    }
  }, [studentInfo])

  useEffect(() => {
    if (studentInfo?.firstName || studentInfo?.lastName) {
      formik.setValues({
        firstName: studentInfo.firstName,
        lastName: studentInfo.lastName,
        genderType: studentInfo?.genderType === 'MALE' ? 0 : 1,
        birthDate: studentInfo?.birthdate,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo, modal])

  useEffect(() => {
    if (
      formik.values.firstName?.length > MINIMUM_NAME_LENGTH &&
      formik.values.lastName?.length > MINIMUM_NAME_LENGTH &&
      formik.values.phoneNumber?.length === MINIMUM_PHONE_NUMBER_LENGTH &&
      date &&
      gender
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [formik.values, date, gender])

  useEffect(() => {
    dispatch(commonActions.fetchSupportLink())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedDay?.value && selectedMonth?.value && selectedYear?.value) {
      formik.setErrors({ ...formik.errors, birthDate: '' })
    }
  }, [selectedDay, selectedMonth, selectedYear])

  useEffect(() => {
    const errors = formik.errors
    const values = formik.values
    if (values?.genderType && values?.phoneNumber && values?.lastName && values?.firstName && values?.birthDate) {
      dispatch(authActions.setShowCaptchaError(true))
    } else if (
      errors?.birthDate ||
      errors?.firstName ||
      errors?.genderType ||
      errors?.lastName ||
      errors?.phoneNumber ||
      errors?.length
    ) {
      dispatch(authActions.setShowCaptchaError(true))
    }
    return () => dispatch(authActions.setShowCaptchaError(false))
  }, [formik.errors, formik.values])

  return {
    modal,
    setModal,
    handleCloseModal,
    formik,
    initialDate,
    handleBlurUserName,
    handleChangeUserName,
    handleChangeLastName,
    handleBlurLastName,
    date,
    handleClickGender,
    handleChangeGender,
    openselect,
    userInfo,
    handleBlurPhoneNumber,
    handleChangePhoneNumber,
    onSubmitForm,
    disabled,
    supportLink,
    studentInfo,
    isAvailable,
    months,
    yearOption,
    selectedDay,
    selectedYear,
    selectedMonth,
    handleDayChange,
    handleYearChange,
    handleMonthChange,
    generateDayOptions,
  }
}
