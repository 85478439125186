import { RouteLinks } from '@constants/route';
import { authActions } from '@modules/auth/store';
import { LoginEmail } from '@modules/new-auth/constants';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

export const useSuccessModalProp = () => {
  const [success, setSuccess] = useState(true);
  const [count, setCount] = useState(10);
  const interval = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const successModalState = useSelector(state => state.auth.successModal);
  const successModalUnmount = () => {
    setCount(10);
    clearInterval(interval.current);
    setSuccess(false);

    if (location.pathname === LoginEmail) {
      history.push('/auth/login/by-email');
      return;
    }

    history.push('/auth/login');
  };

  useEffect(() => {
    if (success) {
      interval.current = setInterval(() => {
        setCount(prev => {
          if (prev <= 0) {
            return prev;
          } else {
            return prev - 1;
          }
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (success && count === 0) {
      setCount(10);
      clearInterval(interval.current);
      setSuccess(false);
      dispatch(authActions.setSuccessModal(false));
      history.push(RouteLinks.auth.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (successModalState) {
      setSuccess(true);
    }
  }, [successModalState]);

  return {
    success,
    setSuccess,
    successModalUnmount,
    count
  };
};
