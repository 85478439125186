import { FlexWrapper } from '@components/layouts/flex-wrapper'
import { Skeleton } from '@components/skeleton/skeleton-box'
import { CourseLessonHeader, CourseLessonHeaderDuration, CourseLessonsWrapper, CourseTitleWrapper } from '@modules/all-courses/components/course-lessons/course-lessons.style'
import React from 'react'
import { LessonTitleWrapper, LessonWrapperTitleSkeleton } from './module-single.style'

export const ModuleSingleSkeletonTheme = () => {
  return (
    <LessonTitleWrapper justify='space-between'>
      <LessonWrapperTitleSkeleton>
        <Skeleton width={55} height={30} />
        <Skeleton fullWidth={50} height={30} />
      </LessonWrapperTitleSkeleton>
      <Skeleton width={50} height={50} />
    </LessonTitleWrapper>
  )
}


export const ModuleSingleSkeletonTabs = () => {
  return (
    <CourseLessonsWrapper>
      <CourseLessonHeader>
        <CourseTitleWrapper>
          <Skeleton fullWidth={90} height={26} marginBottom={10} />
        </CourseTitleWrapper>
        <CourseLessonHeaderDuration>
          <Skeleton fullWidth={50} height={16} marginBottom={16} />
        </CourseLessonHeaderDuration>
      </CourseLessonHeader>
      {
        Array.from(Array(5)).map(() => {
          return <>
            <FlexWrapper justify='space-between'>
              <Skeleton fullWidth={70} height={20} marginBottom={6} />
              <Skeleton width={30} height={20} />
            </FlexWrapper>
            <FlexWrapper>
              <Skeleton fullWidth={40} height={16} marginRight={10} />
              <Skeleton fullWidth={40} height={16} marginBottom={30} />
            </FlexWrapper>
          </>
        })
      }
    </CourseLessonsWrapper>
  )
}
