import { useMemo, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllCoursesActions } from '@all-courses/store';
import { SecondsFormat } from '@utils/secondsToHours';

export const useCourseNotesProp = () => {
  const [isNoteFocused, setIsNoteFocused] = useState(false);
  const [modal, setModal] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [activeLessonSeconds, setActiveLessonSeconds] = useState(0);
  const videoRef = useRef(window.hlsPlayer);
  const isEditableNow = useSelector(state => state.courses.editNote);
  const textareaRef = useRef();
  const activeLesson = useSelector(state => state.courses.activeLesson);
  const activeChapter = activeLesson?.chapter?.id;
  const notes = useSelector(state => state.courses.notes?.chapterNotes);
  const notesLoading = useSelector(state => state.courses.notesLoading);
  const [isLoading, setIsLoading] = useState(false);

  const isAvailableEdit = useSelector(
    state => state.courses?.availableEditNote
  );
  const dispatch = useDispatch();

  const onFocusTextarea = () => {
    videoRef?.current?.pause();
    setIsNoteFocused(true);
  };

  const time = useMemo(() => {
    return SecondsFormat(activeLessonSeconds);
  }, [activeLessonSeconds]);

  const handleDeleteNoteItem = value => {
    setNoteId(value);
  };

  const handleTimeClick = item => {
    const lesson = {
      ...item?.lesson,
      uniqueId: Math.random(),
      lastSecondTime: item.time
    };

    const data = {
      lesson: lesson,
      chapter: activeLesson?.chapter
    };

    dispatch(AllCoursesActions.changeActiveCourse(data));
  };

  const handleClickFormTime = e => {
    e.preventDefault();
  };

  const handleAddNote = e => {
    e?.preventDefault();

    if (isAvailableEdit) {
      const data = {
        info: {
          data: {
            note: textareaRef.current.value,
            videoTime: isAvailableEdit.videoTime
          },
          id: isAvailableEdit?.id
        },
        callback: res => {
          if (res.success) {
            dispatch(AllCoursesActions.setAvailableEditNote(null));
            dispatch(AllCoursesActions.fetchNotes({id: activeChapter}));
            textareaRef.current.value = '';
          }
        }
      };
      dispatch(AllCoursesActions.editNote(data));
      return;
    }

    const data = {
      info: {
        lessonId: activeLesson?.lesson?.id,
        note: textareaRef.current.value,
        videoTime: activeLessonSeconds,
        chapterId: activeChapter
      },
      callback: res => {
        if (res.success) {
          dispatch(AllCoursesActions.fetchNotes({id: activeChapter}));
          textareaRef.current.value = '';
        }
      }
    };

    dispatch(AllCoursesActions.addNote(data));
  };

  const deleteNote = () => {
    try {
      setIsLoading(true);
      const data = {
        id: noteId,
        callback: res => {
          if (res.success) {
            dispatch(AllCoursesActions.fetchNotes({id: activeChapter}));
            setModal(false);
            setIsLoading(false)
          }
          else {
            setIsLoading(false)
          }
        }
      };
       dispatch(AllCoursesActions.deleteNote(data));
      } catch (error) {
      console.error("Course notes delete:", error);
    }
  };

  const handleEditNoteItem = value => {
    textareaRef.current.value = value?.lessonTitle;
    dispatch(AllCoursesActions.setAvailableEditNote(value));
  };

  const cancelEditNotes = () => {
    dispatch(AllCoursesActions.setAvailableEditNote(null));
    dispatch(AllCoursesActions.editNotes(null));
    textareaRef.current.value = '';
  };

  useEffect(() => {
    if (isEditableNow) {
      textareaRef.current.focus();
      textareaRef.current.value = isEditableNow?.text;
      dispatch(AllCoursesActions.editNotes(null));
    }
  }, [isEditableNow]);

  const videoTime = useMemo(() => {
    return Math.floor(videoRef?.current?.currentTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef?.current?.currentTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      const seconds = Math.floor(videoRef?.current?.currentTime);

      setActiveLessonSeconds(seconds);
    }, []);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(AllCoursesActions.setNotesLoading(true))
    if (activeChapter) {
      const data = {
        id: activeChapter,
        callback: () => {
          dispatch(AllCoursesActions.setNotesLoading(false))
        }
      };
      dispatch(AllCoursesActions.fetchNotes(data));
    }

    return () => {
      dispatch(AllCoursesActions.fetchNotesFulfilled([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChapter]);

  return {
    isNoteFocused,
    onFocusTextarea,
    modal,
    setModal,
    handleDeleteNoteItem,
    handleEditNoteItem,
    deleteNote,
    isAvailableEdit,
    cancelEditNotes,
    time,
    textareaRef,
    activeLesson,
    notes,
    handleAddNote,
    handleTimeClick,
    videoTime,
    handleClickFormTime,
    notesLoading,
    isLoading
  };
};
