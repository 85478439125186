import FreeVideo from '@assets/img/freevideo.png';
import BlockModule from '@assets/img/block-module.png';
import WatchedImg from '@assets/img/watched.png';
import WatchingImg from '@assets/img/incomplete-icon.svg';
import NotWatchImg from '@assets/img/now-watched.svg';
import DocumentImg from '@assets/img/document.png';

export const getChapterLessonsVideoStatusImage = (lesson, courseInfoModule) => {
  if (lesson?.isFree && !courseInfoModule.isPaid) {
    return FreeVideo;
  }

  if(!courseInfoModule?.isPaid) {
    return BlockModule
  }

  if (!lesson?.video?.id) {
    return DocumentImg
  }
  if (
    courseInfoModule.isPaid &&
    (courseInfoModule.progress >= 100 || lesson.progress >= 100)
  ) {
    return WatchedImg;
  }  else if (lesson.progress > 0) {
    return WatchingImg;
  }

  return NotWatchImg;
};

export const getCourseModuleVideoStatusImage = course => {
  if (course?.hasFreeLesson && !course?.isPaid) {
    return FreeVideo;
  }
  if (course?.isPaid) {
    if (course.progress >= 100) {
      return WatchedImg;
    } else if (course.progress > 0) {
      return WatchingImg;
    }

    return NotWatchImg;
  }

  return BlockModule;
};

export const getChapterModuleVideoStatusImage = (chapter, course) => {
  if (chapter?.hasFreeLesson && !course?.isPaid) {
    return FreeVideo;
  }

  if (!course.isPaid) {
    return BlockModule;
  }

  if (course.isPaid && chapter.progress >= 100) {
    return WatchedImg;
  } else if (chapter.progress > 0) {
    return WatchingImg;
  }

  return NotWatchImg;
};
