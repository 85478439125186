import { SvgIcon } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import { useAccountError } from './account-error.prop';

import {
  ErrorTitle,
  ErrorText,
  Button,
  PhoneWrapper,
  Phone,
  SvgIconWrapper
} from './account-error.style';

export const AccountError = hoc(
  useAccountError,
  ({ error, setError, openCallCenterBlock, callCenter, handleCLose }) => {
    return (
      <UnModal handleCloseModal={handleCLose} modal={error} setModal={setError}>
        <SvgIconWrapper onClick={handleCLose}>
          <SvgIcon iconName='un-auth' size='60px' />
        </SvgIconWrapper>

        {!callCenter && (
          <>
            <ErrorTitle>Profilingizga kimdir kirdi!</ErrorTitle>
            <ErrorText>Agar siz bo‘lmasangiz, bizga xabar bering.</ErrorText>

            <FlexWrapper justify='space-around'>
              <Button onClick={handleCLose}>Kirishga qaytish</Button>
              <Button onClick={openCallCenterBlock} danger>
                Xabar berish
              </Button>
            </FlexWrapper>
          </>
        )}

        {callCenter && (
          <>
            <ErrorTitle sm>Bizning ishonch telefon raqamlarimiz</ErrorTitle>

            <PhoneWrapper>
              <Phone href='tel:+998788886888'>+998 78 888 6 888</Phone>
            </PhoneWrapper>
          </>
        )}
      </UnModal>
    );
  }
);
