import {
  Button,
  DownloadButton
} from '@modules/certificates/routes/single-certificate/single-certificate.style';
import { hoc } from '@utils/hoc';
import { useCertificateButtonsProp } from './certificate-buttons.prop';
import { Share } from './share';
import { config } from '@config/config';

export const CertificateButtons = hoc(
  useCertificateButtonsProp,
  ({
    handleToMoreInfo = () => null,
    isOpen,
    handleClickShare,
    isTop,
    setIsOpen,
    id
  }) => {
    return (
      <>
        <DownloadButton
          download={`${config.apiUrl}/api/student/certificate/${id}/pdf`}
          href={`${config.apiUrl}/api/student/certificate/${id}/pdf`}
        >
          <Button isTop={isTop} className='download'>
            <span className='download'>Yuklab olish</span>
          </Button>
        </DownloadButton>
        <Button onClick={handleToMoreInfo}>
          <span>Batafsil natijalar</span>
        </Button>
        <Button className='share' onClick={handleClickShare}>
          <span className='share'>Ulashish</span>
          {isOpen && <Share isTop={isTop} setIsOpen={setIsOpen} />}
        </Button>
      </>
    );
  }
);
