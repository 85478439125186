import { PhoneNumberInput } from '@components/phone-number-input';
import {
  Button,
  FormGroup,
  InputError,
  InputLabels
} from '@modules/new-auth/routes/login/login.style';
import { hoc } from '@utils/hoc';
import { Form } from '../forget-phone.style';
import { useInitialFormProp } from './initial-form.prop';

export const InitialForm = hoc(
  useInitialFormProp,
  ({
    initialFormik,
    handleBlurPhone,
    handleChangePhoneInput
  }) => {
    return (
      <Form onSubmit={initialFormik.handleSubmit}>
        <FormGroup mb>
          <InputLabels htmlFor='phone'>Telefon raqam</InputLabels>

          <PhoneNumberInput
            format='## ### ## ##'
            prefix='+998'
            placeholder='00 000 00 00'
            id='phone'
            name='phone'
            onBlur={handleBlurPhone}
            onChange={handleChangePhoneInput}
            values={initialFormik.values.phone}
            className={initialFormik.errors?.phone ? 'input-error' : ''}
          />
          {initialFormik.errors?.phone && (
            <InputError>{initialFormik.errors?.phone}</InputError>
          )}
        </FormGroup>
        <Button>Davom etish</Button>
      </Form>
    );
  }
);
