import styled from 'styled-components';
import AccordionToggleDown from '@assets/img/accordion-toggle-down.svg';
import AccordionToggleDownSmall from '@assets/img/accordion-toggle-down-small.svg';
import BlockTheme from '@assets/img/lock-circle.png';

export const CourseAccordionCard = styled.div`
  cursor: pointer;
  user-select: none;
  background-color: ${props => (props.none ? 'transparent' : '#F8F7EF')};
`;

export const CourseAccordionCardList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 16px;

  li {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const CourseAccordionCardListItem = styled.li`
  position: relative;
  z-index: 1;
  transition: all ease 0.3s;

  &.accordion-card__item--open {
    padding-top: 8px;
    margin-bottom: 0;
    & .accordion-card__item-content--faq {
      display: block;
    }

    & .accordion-card__item-content-wrapper {
      display: block;
    }

    .btn--large {
      transform-origin: center;
      transform: rotate(180deg);
    }
  }

  .btn--small {
    transform-origin: center;
    transform: rotate(180deg);
  }
`;

export const CourseAccordionListItemToggle = styled.button`
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  &.large {
    width: ${props => (props.small ? '26px' : props.block ? '26px' : '32px')};
    height: ${props => (props.small ? '26px' : props.block ? '26px' : '32px')};
    background-image: url(${props => props.small ? AccordionToggleDownSmall : props.block ? BlockTheme : AccordionToggleDown});
    background-size: contain;
    margin: ${props => (props.block ? '0 6px 0 3px' : '0 3px')};
  }
`;

export const AccordionCardWrapper = styled.div`
  display: none;

  &.accordion-card__item--open {
    display: block;
  }
`;

export const AccordionThemeCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;
`;

export const AccordionCardItemContent = styled.div`
  padding-left: 15px;
  border-left: 1px solid #d9d9d9;
  margin-left: 6px;

  &.accordion-card__item-content--course {
    display: none;
  }
`;

export const AccordionInner = styled.div`
  .accordion-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const AccordionCardTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: ${props => (props.sub ? '14px' : '16px')};
  line-height: 28px;
  letter-spacing: -0.02em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #000000;
  margin-left: 10px;
  position: relative;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    white-space: nowrap;
    letter-spacing: -0.02em;
    color: ${props => (props.sub ? 'rgba(0, 0, 0, 0.5)' : '#81755C')};
    margin-right: ${props => props.sub && '5px'};
  }
`;

export const AccordionTheme = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
  display: inline-block;
  margin-left: 14px;

  &:hover {
    text-decoration: underline;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 8px;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const FreeVideo = styled.h4(() => `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: #07cd86;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 42px;

  &:hover {
    text-decoration: underline;
  }`,

  ({theme: {down}}) => `
    ${down('410px')} {
      margin-right: 0;
    }
  `,
);

export const VideoStatus = styled.img`
  width: ${props => (props.small ? '16px' : '20px')};
  height: ${props => (props.small ? '16px' : '20px')};
  border-radius: 50%;
  object-fit: cover;
  margin-top: 5px;

&.document {
  object-fit: contain;
  border: 1px solid #E0E0E0;
  padding: 1px;
};
`;

export const FlexWrapperForThemes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ViewBefore = styled.button`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #07cd86;
  background-color: transparent;
  border: none;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const VideoDuration = styled.span`
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: -0.02em !important;
  color: #ba8d5b !important;
  margin-left: 8px;
`;

export const ModuleWrapper = styled.div`
  padding-left: 16px;
  border-left: 1px solid #d9d9d9;
  margin-left: 8px;
`;

export const Wrapper = styled.div`
  padding: 28px 24px 24px 16px;
  background: #f8f7ef;
`;

export const NewBadge = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #07CD86;
  border-radius: 4px;
  padding: 2px 6px;
  height: 22px;
  margin-top: 5px;
`;

export const NoDataTitle = styled.h4`
  font-size: 12px;
`
