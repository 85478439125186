import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@utils/useMediaQuery';

const courseTypes = {
  open: 1,
  close: 2
};

export const useCourseCardProp = ({ courseType, closeLink }) => {
  const sidebarState = useSelector(state => state.common.sidebarState);
  const history = useHistory();
  const mqMaxWidth724 = useMediaQuery('(369px <= width <= 724px)');
  const mqMaxWidth1024 = useMediaQuery('(794px <= width <= 1024px)');
  const mqMaxWidth1308 = useMediaQuery('(1130px <= width <= 1308px)');
  const mqMaxWidth1561 = useMediaQuery('(1454px <= width <= 1561px)');
  const mqMinWidth1778 = useMediaQuery('(min-width: 1778px)');

  const allCardQueries = useMemo(() => {
    return (
      mqMaxWidth724 ||
      mqMaxWidth1024 ||
      mqMaxWidth1308 ||
      mqMaxWidth1561 ||
      mqMinWidth1778
    );
  }, [
    mqMaxWidth724,
    mqMaxWidth1024,
    mqMaxWidth1308,
    mqMaxWidth1561,
    mqMinWidth1778
  ]);

  const handleClick = (link, e) => {
    e.preventDefault();

    if (courseType === courseTypes.close) {
      window.open(closeLink, '_blank');
      return;
    }

    history.push(link);
  };

  return {
    sidebarState,
    handleClick,
    mqMaxWidth724,
    mqMaxWidth1024,
    mqMaxWidth1308,
    mqMaxWidth1561,
    mqMinWidth1778,
    allCardQueries
  };
};
