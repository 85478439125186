import { hoc } from '@utils/hoc';
import { useCategoryDropDownProps } from './category-drop-down.prop';
import {
  SelectOption,
  SelectOptionsWrapper,
  SelectPlaceholder,
  SelectWrapper
} from './category-drop-down.style';

export const CategoryDropDown = hoc(
  useCategoryDropDownProps,
  ({ activeValue, dropdownRef, toggleSelect, open, _onChange, options }) => {
    return (
      <SelectWrapper ref={dropdownRef} open={open} onClick={toggleSelect}>
        <SelectPlaceholder active={activeValue}>
          {activeValue || "Yo'nalishni tanlang"}
        </SelectPlaceholder>

        <SelectOptionsWrapper active={open}>
          {options?.length > 0 &&
            options?.map((item, index) => (
              <SelectOption
                key={index}
                selected={activeValue === item?.label}
                onClick={_onChange.bind(null, item)}
              >
                {item?.label}
              </SelectOption>
            ))}
        </SelectOptionsWrapper>
      </SelectWrapper>
    );
  }
);
