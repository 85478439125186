import {FlexWrapper} from "@components/layouts/flex-wrapper";
import {CheckerImg, CheckerTitle} from "@modules/new-auth/routes/login/login.style";

export const PasswordCheckerItem = ({ condition, noValues, label, imageEmpty, imageSuccess, imageNotMatch }) => (
    <FlexWrapper gap='50px' align='center'>
        <FlexWrapper gap='8px' align='center'>
        <CheckerImg
            src={noValues ? imageEmpty : condition ? imageSuccess : imageNotMatch}
            alt={noValues ? 'Empty' : condition ? 'Success' : 'Empty'}
        />
        <CheckerTitle>{label}</CheckerTitle>
        </FlexWrapper>
    </FlexWrapper>
);
