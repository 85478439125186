import { storageService } from '@services/storage.service';
import { commonActions } from '@store/common';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useNavUserProps = () => {
  const collapseRef = useRef(null);
  const dispatch = useDispatch();
  const profile = useSelector(state => state.common.profile);
  const notificationCount = useSelector(
    state => state.common.notificationCount
  );

  useEffect(() => {
    dispatch(commonActions.fetchNotificationCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    collapseRef,
    profile,
    notificationCount
  };
};
