import styled from 'styled-components';

export const TextareaContainer = styled.div(
  ({ theme: { down }}) => `
    border: 1px solid #dadada;
    border-radius: 8px;
    padding: 20px 26px 20px 24px;

    ${down('576px')} {
      padding: 16px 16px 16px 10px;
    }

`);

export const TextareaField = styled.textarea(
  ({ theme: { down } }) => `
    box-sizing: border-box;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: -0.02em;
    color: #000000;
    border: none;
    resize: none;
    outline: none;
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }

    &::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: rgba(0, 0, 0, 0.35);
    }

    ${down('768px')} {
        font-size: 14px;
    }

    ${down('576px')} {
        &::placeholder {
          font-size: 14px;
        }
    }
`);

export const ScriptLabel = styled.label(
  ({ theme: { down }}) => `
    width: 20px;
    height: 20px;
    margin-right: 14px;
    cursor: pointer;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
    }

    ${down('576px')} {
      margin-right: 10px;
    }

    ${down('380px')} {
      margin-right: 0;
    }

`);

export const ScriptInput = styled.input`
    display: none;
`;

export const SendBtn = styled.button`
    width: 24px;
    height: 24px;
    position: relative;
    border: none;
    align-self: flex-end;
    background-color: transparent;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
    }
`;

export const ErrorTitle = styled.h3`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 0 auto;
    margin-bottom: 16px;
    max-width: 304px;

    span {
        font-weight: 400;
        font-style: italic;
    }
`;

export const ErrorSubtitle = styled.h3`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.35);
    text-align: center;
    margin: 0 auto;
    margin-bottom: 24px;
    max-width: 343px;

    & + .retry-btn {
        padding: 12px 0;
        display: block;
        width: 100%;
        font-size: 16px;
        line-height: 19px;
    }
`;

export const HomeworkWrapper = styled.div`
    border-radius: 8px;
    background: #f7f7f5;
    overflow-x: hidden;
    position: relative;
    margin-bottom: 16px;

    &.large {
        .slick-initialized {
            padding-left: 45px !important;
            padding-right: 45px !important;
        }
    }

    .slick-track::before {
        content: ${props => props?.files?.length > 3 ? 'none' : ''};
    }

`;

export const Homeworks = styled.div`
    display: flex;
    align-items: center;

    .slick-slide {
        width: 190px !important;
    }

    .slick-track {
        display: flex !important;
        justify-content: flex-start !important;
        width: 100% !important;
        gap: 13px;
        padding: 8px 0;
        align-items: center;
    }

    .slick-initialized {
        width: 100%;
    }

    .slick-arrow.slick-prev {
        display: none !important;
    }

    .slick-arrow.slick-next {
        display: none !important;
    }
`;

export const SliderBtn = styled.button`
    width: 24px !important;
    height: 24px;
    background: rgba(186, 141, 91, 0.1);
    border-radius: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    transition: all ease 0.3s;
    transform: rotate(${props => (props.rotate ? '180deg' : 0)});
    position: absolute;
    left: ${props => (props.rotate ? 'auto' : '11px')};
    right: ${props => (props.rotate ? '11px' : '0')};
    z-index: 22;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &:active {
        background-color: #ba8d5b;

        svg {
            path {
                fill: #ba8d5b;
                stroke: #fff;
            }
        }
    }

    svg {
        width: 6px;
        height: 13px;

        path {
            fill: rgba(186, 141, 91, 0.1);
            stroke: #ba8d5b;
        }
    }
`;

export const CustomSlider = styled.div(
  ({ theme: { down }}) => `
  display: flex;
  gap: 13px;
  padding: 8px 11px;
  width: 100%;

  ${down('768px')} {
    padding: 16px 16px;
    flex-wrap: wrap;
    gap: 20px;
  }
`);
