import { Input } from '@components/atoms/Input'
import { Button, FormGroup, InputError, InputLabels } from '@modules/new-auth/routes/login/login.style'
import { hoc } from '@utils/hoc'
import { useInitialFormProp } from './initial-form.prop'
import { Form } from './initial-form.style'

export const InitialForm = hoc(useInitialFormProp, ({ initialFormik, handleChangeEmail, handleBlurEmailInput }) => {
  return (
    <Form onSubmit={initialFormik.handleSubmit}>
      <FormGroup isRecovery>
        <InputLabels htmlFor="email">Elektron pochtangiz</InputLabels>

        <Input
          placeholder="example@edfix.uz"
          value={initialFormik.values.email}
          onChange={handleChangeEmail}
          onBlur={handleBlurEmailInput}
          className={initialFormik.errors.email ? 'input-error' : ''}
        />

        {initialFormik.errors?.email && <InputError>{initialFormik.errors?.email}</InputError>}
      </FormGroup>
      <Button>Davom etish</Button>
    </Form>
  )
})
