import { AllCoursesActions } from '@all-courses/store';
import {useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@utils/useMediaQuery';

export const useMyCourseAccordion = () => {
  const [clicked, setClicked] = useState(false);
  const [smallClicked, setSmallClicked] = useState(false);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const userInfo = useSelector(state => state.common.profile);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const mqMaxWidth980 = useMediaQuery('(max-width: 980px)');
  const mqMaxWidth650 = useMediaQuery('(max-width: 650px)');
  const mqMaxWidth500 = useMediaQuery('(max-width: 500px)');
  const mqMaxWidth410 = useMediaQuery('(max-width: 410px)');
  const singleCourseInfo = useSelector(state => state.courses.singleCourse);

  const selectedModule = useMemo(() => {
    return parseInt(location?.search.split('=')?.at(-1))
  }, [location]);

  useEffect(() => {
    if(selectedModule) {
      setClicked(selectedModule)
      const info = {
        id: selectedModule,
        callback: res => {
          const updatedCourseModules = singleCourseInfo.modules.map(module => {
            if(module.id === selectedModule) {
              return {
                ...module,
                chapters: res?.data
              }
            }

            return module
          })

          const updatedCourses = {
            ...singleCourseInfo,
            modules: updatedCourseModules
          }

          dispatch(AllCoursesActions.setSingleCourse(updatedCourses))
        }
      }
      dispatch(AllCoursesActions.fetchModuleChapters(info))
    }
  }, [location]);

  const openModalForUnpaidModules = moduleInfo => {
    if (moduleInfo?.item?.isPaid) {
      toggleSmall(moduleInfo.index);
    } else if (
      (moduleInfo?.item?.isPaid || moduleInfo?.item?.hasFreeLesson) &&
      moduleInfo?.chapter?.hasFreeLesson
    ) {
      toggleSmall(moduleInfo.index);
    } else {
      toggleSmall(null);
      history.push(`${location.pathname}?buy=${moduleInfo?.item?.id}`);
    }
  };

  const handleOpenCourseFreeModal = (data, e) => {
    e.stopPropagation();

    setClicked(clicked);
    setSmallClicked(smallClicked);
    dispatch(AllCoursesActions.openFreeCourseModal(true));
    dispatch(AllCoursesActions.getFreeVideo(data));
  };

  const handleClick = (lesson, url, courseId, module, chapter) => {
    if ((!userInfo?.phoneNumber || !userInfo?.lastName) && module?.isPaid) {
      dispatch(AllCoursesActions.setUpdateInfoModal(true));
      return;
    }

    if (module?.isPaid) {
      dispatch(
        AllCoursesActions.changeActiveCourse({
          lesson,
          chapter
        })
      );
      history.push(url);
    } else {
      history.push(`${location.pathname}?buy=${courseId?.course_id}`);
    }
  };

  const handleClickChapterTitle = ({ item, index, url }) => {
    if (!item?.isPaid) {
      if (clicked === index) {
        return setClicked(null);
      }
      setClicked(index);
      return;
    }
    const chapter = item.chapters?.[0];

    const info = {
      chapter,
      lessons: chapter?.lessons?.[0]
    };

    dispatch(AllCoursesActions.changeActiveCourse(info));
    history.push(url);
  };

  const toggle = (index, item) => {

    if(item?.id)  {
      const info = {
        id: item.id,
        callback: res => {

          const updatedCourseModules = singleCourseInfo.modules.map(module => {
            if(module.id === item.id) {
              return {
                ...module,
                chapters: res?.data
              }
            }

            return module
          })

          const updatedCourses = {
            ...singleCourseInfo,
            modules: updatedCourseModules
          }

          dispatch(AllCoursesActions.setSingleCourse(updatedCourses))
        }
      }
      dispatch(AllCoursesActions.fetchModuleChapters(info))
    }

    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  const toggleSmall = index => {
    if (smallClicked === index) {
      return setSmallClicked(null);
    }
    setSmallClicked(index);
  };

  const handleChapterTitleClick = ({ index, item, url }) => {
    if (!item?.isPaid) {
      if (smallClicked === index) {
        return setSmallClicked(null);
      }
      setSmallClicked(index);
      return;
    }
    const chapter = item.chapters?.[0];

    const info = {
      chapter,
      lessons: chapter?.lessons?.[0]
    };

    dispatch(AllCoursesActions.changeActiveCourse(info));
    history.push(url);
  };

  return {
    toggle,
    clicked,
    toggleSmall,
    smallClicked,
    openModalForUnpaidModules,
    handleClick,
    handleOpenCourseFreeModal,
    isOpenPaymentModal,
    setIsOpenPaymentModal,
    mqMaxWidth980,
    mqMaxWidth650,
    mqMaxWidth500,
    mqMaxWidth410,
    handleClickChapterTitle,
    handleChapterTitleClick,
    selectedModule
  };
};
