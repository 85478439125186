export const RouteLinks = {
    auth: {
        login: '/auth/login',
        register: '/auth/register',
        index: '/auth'
    },
    dashboard: '/dashboard',
    myCourses: '/my-courses',
    myCoursesSingle: '/my-course-single',
    courses: '/courses',
    courseSingle: '/course-single',
    settings: '/settings',
    payments: '/payments',
    courseModules: '/courses/module',
    notPurchased: '/course-not-purchased',
    videos: '/videos',
    allSavedVideos: '/all-saved-videos',
    allRecentlyVideos: '/all-recently-videos',
    certificates: '/certificates',
    moreInfo: '/more-info/:id',
    certificate: '/certificate/:id',
    notifications: '/notifications',
    homeworkDetail: '/student/:studentId/homeworks/:id',
    inactiveCourse: '/inactive',
    rating: '/rating',
    myRatings: '/my-ratings',
}
