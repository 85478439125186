import { Input } from '@components/atoms/Input';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { PASSWORD } from '@modules/new-auth/constants';
import CloseEyeImage from '@assets/img/close-eye.svg';
import OpenEyeImage from '@assets/img/open-eye.svg';
import {
  Button,
  EyeButton,
  EyeImg,
  ForgetPassword,
  Form,
  FormGroup,
  InputError,
  InputLabels
} from '@modules/new-auth/routes/login/login.style';
import { hoc } from '@utils/hoc';
import { useEmailForm } from './email-form.prop';
import { ForgetEmail } from './ForgetEmail';
import { RegisterWithGoogle } from '../../RegisterRightSide/RegisterWithGoogle';

export const EmailForm = hoc(
  useEmailForm,
  ({
    inputType,
    formik,
    handleChangePasswordInput,
    handleBlurEmail,
    handleInputChange,
    forgetEmailModal,
    handleOpenEmailForgetModal,
    isCaptchaError,
    handleRecaptchaChange
  }) => {
    return (
      <>
        <Form onSubmit={formik.handleSubmit}>
          <FormGroup mb>
            <InputLabels htmlFor='email'>Elektron pochtangiz</InputLabels>

            <Input
              type='email'
              name='email'
              values={formik.values.email}
              placeholder='example@edfix.uz'
              onBlur={handleBlurEmail}
              onChange={handleInputChange}
              className={formik.errors.email ? 'input-error' : ''}
            />
            {formik.errors?.email && (
              <InputError>{formik.errors?.email}</InputError>
            )}
          </FormGroup>

          <FormGroup>
            <InputLabels>Parol</InputLabels>

            <FlexWrapper
              className={`wrapper ${
                formik.errors.password ? 'input-error' : ''
              }`}
              align='center'
            >
              <Input
                placeholder='Parolingizni kiriting'
                className='password-input'
                type={inputType}
                onChange={handleInputChange}
                name='password'
                values={formik.values.password}
              />

              <EyeButton type='button'>
                <EyeImg
                  src={inputType === PASSWORD ? CloseEyeImage : OpenEyeImage}
                  alt='eye'
                  onClick={handleChangePasswordInput}
                />
              </EyeButton>
            </FlexWrapper>
            {formik.errors?.password && (
              <InputError>{formik.errors?.password}</InputError>
            )}
          </FormGroup>

          <ForgetPassword onClick={handleOpenEmailForgetModal}>
            Parolingizni unutdingizmi?
          </ForgetPassword>
          <Button mb>Kirish</Button>
          <RegisterWithGoogle isLogin />
        </Form>

        {forgetEmailModal && <ForgetEmail />}
      </>
    );
  }
);
