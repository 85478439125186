import { hoc } from '@utils/hoc';
import { Fragment } from 'react';
import { AppealModal } from './appealComment';
import { CancelAppeal } from './CancelAppeal';
import { Comment } from './comment/comment.component';
import { ConfirmDelete } from './ConfirmDelete';
import { useCourseCommentsProps } from './course-comments.props';
import { CourseCommentsContainer } from './course-comments.style';
import { DeletedComment } from './deleted-comment';
import { Replies } from './replies';

export const CourseComments = hoc(
  useCourseCommentsProps,
  ({ items, depth, sort, courses, textareaRef }) => {
    return (
      <>
        <CourseCommentsContainer>
          {items?.map((item, idx) => {
            return (
              <Fragment key={idx + depth}>
                {item?.author ? <Comment item={item} mine /> : <DeletedComment />}
                <Replies
                  depth={depth}
                  sort={sort}
                  item={item}
                  items={item?.children || []}
                  hasParent={item?.author}
                />
              </Fragment>
            );
          })}
        </CourseCommentsContainer>

        {courses?.appealModal && <AppealModal />}
        {courses?.cancelAppealModal && <CancelAppeal />}
        {courses?.confirmDeleteModal && <ConfirmDelete textareaRef={textareaRef} />}
      </>
    );
  }
);

CourseComments.defaultProps = {
  depth: 1
};
