import { config } from '@config/config';
import { certificateActions } from '@modules/certificates/store';
import { useMediaQuery } from '@utils/useMediaQuery';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import { RouteLinks } from 'src/constants/route';
import {hideCaptchaBadge} from "@utils/toggleRecaptchaBadge";

export const useCertificateProp = () => {
  const history = useHistory();
  const { id } = useParams();
  const certificate = useSelector(state => state.certificates.certificate);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.certificates.loading);
  const mq768MaxWidth = useMediaQuery('(max-width: 768px)');

  const handleToMoreInfo = () => {
    history.push(RouteLinks.moreInfo.replace(':id', certificate?.registerSign));
  };

  const imgUrl = useMemo(() => {
    return `${config.imgApiUrl}/${certificate?.image}`;
  }, [certificate?.image]);

  useEffect(() => {
    if (id) {
      dispatch(certificateActions.fetchSingleCertificate(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    hideCaptchaBadge()
  }, [])

  return {
    handleToMoreInfo,
    imgUrl,
    loading,
    certificate,
    mq768MaxWidth
  };
};
