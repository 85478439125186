import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { GeneralLayout } from '@components/layouts/general-layout';
import { HomeworkCard } from '@modules/all-courses/components/course-homework/homework-card';
import { hoc } from '@utils/hoc';
import { useHomeworkProps } from './homework.prop';
import {
  HomeworkFilesCount,
  Homeworks,
  HomeworkText,
  HomeworkTitle,
  HomeworkWrapper,
  MyHomeworks,
} from './homework.style';
import {
  acceptedHomeworkStatus,
  studentStatuses
} from '@modules/certificates/constants';
import { HomeworkMarkSubtitle } from '@modules/all-courses/components/course-homework/homework-mark/homework-mark.style';

export const Homework = hoc(useHomeworkProps, ({ homeworks }) => {
  return (
    <GeneralLayout>
      {homeworks?.homeworkChats?.length > 0 && (
        <HomeworkWrapper>
          <Homeworks>
            <FlexWrapper mb='16px' justify='space-between' align='center'>
              <HomeworkTitle>Uyga vazifa</HomeworkTitle>

              <HomeworkFilesCount>
                Fayllar soni: {homeworks?.homeworkFiles?.length} ta
              </HomeworkFilesCount>
            </FlexWrapper>

            <HomeworkText dangerouslySetInnerHTML={{__html: homeworks?.homeworkInfo?.desc}} />

            <FlexWrapper direction='column' mt='16px' gap='16px'>
              {homeworks?.homeworkFiles?.map(item => (
                <HomeworkCard
                  title={item?.origname}
                  size={item?.size}
                  key={item?.id}
                  link={item?.url}
                />
              ))}
            </FlexWrapper>
          </Homeworks>
          {homeworks?.homeworkChats?.map(item => (
            <>
              {item?.userType === studentStatuses.student && (
                <MyHomeworks>
                  <FlexWrapper mb='10px' justify='space-between' align='center'>
                    <HomeworkTitle>Mening jo‘natmalarim</HomeworkTitle>

                    <HomeworkFilesCount>
                      Fayllar soni: {item?.files?.length} ta
                    </HomeworkFilesCount>
                  </FlexWrapper>

                  <HomeworkText>{item?.desc}</HomeworkText>

                  <FlexWrapper
                    gap='16px'
                    direction='column'
                    mt={item?.files?.length > 0 ? '32px' : '0'}
                  >
                    {item?.files?.map(item => (
                      <HomeworkCard
                        title={item?.origname}
                        size={item?.size}
                        key={item?.id}
                        link={item?.url}
                      />
                    ))}
                  </FlexWrapper>
                </MyHomeworks>
              )}

              {(item?.userType === studentStatuses.teacher ||
                item?.userType === studentStatuses.assistant) && (
                <Homeworks key={item?.id}>
                  <FlexWrapper mb='16px' justify='space-between' align='center'>
                    <HomeworkTitle>O‘qituvchi izohi</HomeworkTitle>

                    {item?.status === acceptedHomeworkStatus ? (
                      <HomeworkMarkSubtitle>
                        Qabul qilingan
                      </HomeworkMarkSubtitle>
                    ) : (
                      <HomeworkMarkSubtitle fail>
                        Qabul qilinmagan
                      </HomeworkMarkSubtitle>
                    )}
                  </FlexWrapper>

                  <HomeworkText>{item?.desc}</HomeworkText>

                  <FlexWrapper
                    gap='16px'
                    direction='column'
                    mt={item?.files?.length > 0 ? '32px' : '0'}
                  >
                    {item?.files?.map(item => (
                      <HomeworkCard
                        title={item?.origname}
                        size={item?.size}
                        key={item?.id}
                        link={item?.url}
                      />
                    ))}
                  </FlexWrapper>
                </Homeworks>
              )}
            </>
          ))}
        </HomeworkWrapper>
      )}
    </GeneralLayout>
  );
});
