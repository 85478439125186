import { commonActions } from '@store/common';
import { useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {myRatingActions} from "@modules/my-ratings/store";
import {ACCEPTED_HOMEWORK, FULL_WATCHED, REJECTED_HOMEWORK, homeworkStatus} from "@modules/my-ratings/constants";
import {CompletedHomework, CompletedHomeworkTitle, HomeworkStatus} from "@modules/my-ratings/my-ratings.style";
import {awardDateFormatter} from "@utils/date-format";
import {storageService} from "@services/storage.service";

export const useMyRatingsProps = () => {
  const user = JSON.parse(storageService.getItem('studentInfo'))
  const dispatch = useDispatch();
  const myRatings = useSelector(state => state.myRatings.studentRatings)
  const loader = useSelector(state => state.myRatings.loading)
  const myCourses = useSelector(state => state.common.myCourses);
  const homeworkColumns = useMemo(() => {
    return [
      {
        name: 'Mavzu / Dars',
        cell: (item) => {
          const title = (item?.lesson?.name && item?.chapter?.name) ? `${item?.chapter?.position}-mavzu ${item?.chapter?.name} / ${item?.lesson?.position}-dars ${item?.lesson?.name} ${item?.isOld ? '(eski)' : ''}` : item?.chapter?.name ? `${item?.chapter?.position}-mavzu ${item?.chapter?.name} ${item?.isOld ? '(eski)' : ''}` : '-'

          if((item?.status === ACCEPTED_HOMEWORK || item?.status === REJECTED_HOMEWORK) && user?.id) {
            const link = `student/${user?.id}/homeworks/${item?.chapter?.id}${item.lesson?.id ? `?lessonId=${item?.lesson?.id}` : ''}`
            return <CompletedHomeworkTitle to={link}>{title}</CompletedHomeworkTitle>
          }
          return <span>{title}</span>;
        }
      },
      {
        name: 'Status',
        cell: (item) => {
          if((item?.status === ACCEPTED_HOMEWORK || item?.status === REJECTED_HOMEWORK) && user?.id) {
            const link = `student/${user?.id}/homeworks/${item?.id}`

            if((item?.status === ACCEPTED_HOMEWORK || item?.status === REJECTED_HOMEWORK) && user?.id) {
              const link = `student/${user?.id}/homeworks/${item?.chapter?.id}${item.lesson?.id ? `?lessonId=${item?.lesson?.id}` : ''}`
              return <CompletedHomework to={link}>
                <HomeworkStatus completed={item?.status === ACCEPTED_HOMEWORK}>{homeworkStatus[item?.status] || '-'}</HomeworkStatus>
              </CompletedHomework>
            }

            return (
              <CompletedHomework to={link}>
                <HomeworkStatus completed={item?.status === ACCEPTED_HOMEWORK}>{homeworkStatus[item?.status] || '-'}</HomeworkStatus>
              </CompletedHomework>
            )
          }

          return <HomeworkStatus completed={item?.status === ACCEPTED_HOMEWORK}>{homeworkStatus[item?.status] || '-'}</HomeworkStatus>;
        }
      },
      {
        name: 'Ball',
        cell: (item) => {
          return <HomeworkStatus>{item?.point || 0}</HomeworkStatus>;
        }
      },
      {
        name: 'Vaqti',
        cell: (item) => {
          return <HomeworkStatus>{awardDateFormatter(item?.changedDate)}</HomeworkStatus>;
        }
      }
    ];
  }, []);

  const videoColumns = useMemo(() => {
    return [
      {
        name: 'Dars',
        cell: (item) => {
          return <span>{`${item?.position}-dars ${item?.title} ${item?.isOld ? '(eski)' : ''}`}</span>;
        }
      },
      {
        name: 'Ko`rilgan',
        cell: (item) => {
          return <HomeworkStatus completed={item?.progress === FULL_WATCHED}>{item?.progress}%</HomeworkStatus>;
        }
      },
      {
        name: 'Ball',
        cell: (item) => {
          return <HomeworkStatus>{item?.point || 0}</HomeworkStatus>;
        }
      },
      {
        name: 'Vaqti',
        cell: (item) => {
          return <HomeworkStatus>{awardDateFormatter(item?.changedDate)}</HomeworkStatus>;
        }
      }
    ];
  }, []);

  useEffect(() => {
    dispatch(commonActions.setSubHeaderTitle('Ko`rsatkichlarim'));
    dispatch(commonActions.setSubheader(true));

    return () => {
      dispatch(commonActions.setSubHeaderTitle(''));
    };
  }, []);

  useEffect(() => {
    dispatch(myRatingActions.fetchMyRatings())
    dispatch(commonActions?.fetchMyCourses());
  }, []);

  return {
    homeworkColumns,
    videoColumns,
    myRatings,
    loader,
    myCourses
  };
};
