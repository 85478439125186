import styled from 'styled-components';

export const CertificateWrapper = styled.div`
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
  max-width: 1120px;
  margin: 28px auto;

  &:first-child {
    margin-bottom: 28px;
  }
`;

export const CertificateImage = styled.img(
  ({ theme: { down } }) => `
    height: 542px;
    max-width: 1120px;
    display: block;
    margin: 0 auto;

    ${down('768px')} {
      height: 400px;
      width: 100%;
      object-fit: contain;
    }
  `
);

export const Wrapper = styled.div`
  padding: 0 15px;
`;
