import { videosActions } from "@modules/videos/store";
import { commonActions } from "@store/common";
import { useMediaQuery } from "@utils/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const useAllVideosProps = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const recentlyVideos = useSelector(state => state.videos?.recentlyViewedVideos);
  const bookmarkVideos = useSelector(state => state.videos?.bookmarkVideos);
  const loading = useSelector(state => state.videos.loading);
  const [page, setPage] = useState(1)
  const bodyRef = useRef(null);
  const mq600MaxWidth = useMediaQuery('(max-width: 600px)');

  const handleClickPagination = (prev, e) => {
    e?.event?.preventDefault();
    setPage(prev?.selected + 1)
    bodyRef.current.scrollIntoView();
  }

  useEffect(() => {
    dispatch(
      commonActions.setIsHasButton({ hasButton: true, link: '/videos' })
    );
    dispatch(commonActions.setSubHeaderTitle(location.pathname === '/all-saved-videos'
    ? 'Saqlangan videolar'
    : 'So‘nggi ko‘rilgan videolar'
    ));

    return () => {
      dispatch(commonActions.setIsHasButton({}));
      dispatch(commonActions.setSubHeaderTitle(''));
    };
  }, []);

  useEffect(() => {
    const info = {
      page: page,
      perPage: 100
    }
    if (location.pathname === '/all-saved-videos') {
      dispatch(videosActions.fetchBookmarkVideos(info));
    } else {
      dispatch(videosActions.fetchRecentyleVideos(info));
    }
  }, [page])

  return {
    recentlyVideos,
    bookmarkVideos,
    location,
    loading,
    handleClickPagination,
    bodyRef,
    activePage: page,
    mq600MaxWidth
  }
}
