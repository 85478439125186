import styled from "styled-components";

export const CancelFile = styled.button`
    background-color: transparent;
    position: absolute;
    top: -7px;
    right: -7px;
    border: none;
    outline: none;
    padding: 0;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }
`;

export const CircularProgress = styled.div`
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
    /* background: conic-gradient(#ba8d5b ${40 * 3.6}deg, #7c7a78 ${10 * 3.6}deg); */

    &::before {
        content: '';
        position: absolute;
        height: 67%;
        width: 67%;
        background-color: #f8f4ef;
        border-radius: 50%;
        top: 2.5px;
        left: 2px;
    }
`;

export const ValueContainer = styled.img`
    position: relative;
    width: 11px;
    height: 11px;
    object-fit: contain;
`;

export const HomeworkFile = styled.div(
  ({ theme: { down }}) => `
    border: 1px solid #f1f0ee;
    border-radius: 8px;
    padding: 8px;
    position: relative;
    background-color: #fff;
    max-width: 186px;
    width: 100%;

    ${down('768px')} {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }

    ${down('576px')} {
      flex: 0 0 100%;
      max-width: 100%;
    }

`);

export const ProggresbarBtn = styled.div`
    background: rgba(186, 141, 91, 0.1);
    border-radius: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
`;

export const HomeWorkFileTitle = styled.h4`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    display: inline-block;
    color: #000000;
`;

export const HomeWorkFileBasename = styled(HomeWorkFileTitle)`
    white-space: nowrap;
    max-width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const HomeWorkFileSubtitle = styled.h5`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.35);
`;
