import { useState } from 'react';

export const useCertificateButtonsProp = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickShare = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    handleClickShare,
    setIsOpen
  };
};
