import { SvgIcon } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { HomeworkCard } from '../homework-card';
import { useMyHomeworkProp } from './my-homwork.prop';
import {
  CountOfFiles,
  EditButton,
  MyHomeworkSubtitle,
  MyHomeworksWrapper,
  MyHomeworkTitle,
  Tooltip,
  ToolTipWrapper
} from './my-homwork.style';
import { dateFormatForHmw } from '@utils/date-format';

export const MyHomework = hoc(
  useMyHomeworkProp,
  ({
    setIsAvailable,
    handleChangeHomeWork,
    mySendedHomeWorks,
    desc,
    files,
    item,
    changeHomework,
    mqMaxWidth430
  }) => {
    return (
      <MyHomeworksWrapper>
        <FlexWrapper justify='space-between'>
          <MyHomeworkTitle>Mening jo‘natmalarim</MyHomeworkTitle>

          <FlexWrapper gap='16px'>
            {!mqMaxWidth430 && (
              <CountOfFiles>
                Fayllar soni: {item?.files?.length || 0} ta
              </CountOfFiles>
            )}
            <ToolTipWrapper>
              {item?.status === 3 || item?.status === 4 || (
                <EditButton
                  disabled={!changeHomework}
                  onClick={handleChangeHomeWork.bind(null, item?.id)}
                >
                  <SvgIcon
                    iconColor='#BA8D5B'
                    iconName='edit-note'
                    size='14px'
                  />
                </EditButton>
              )}
              <Tooltip className={!changeHomework && 'tool-tip'}>
                Afsuski, vazifani faqat 10 daqiqa ichidagina o‘zgartirish mumkin
              </Tooltip>
            </ToolTipWrapper>
          </FlexWrapper>
        </FlexWrapper>

        <FlexWrapper gap={'20px'} justify={'space-between'}>
          <MyHomeworkSubtitle>{item?.desc}</MyHomeworkSubtitle>
          {mqMaxWidth430 && (
            <CountOfFiles>
              Fayllar soni: {item?.files?.length || 0} ta
            </CountOfFiles>
          )}
        </FlexWrapper>

        <FlexWrapper
          wrap
          justify='flex-start'
          gap={'24px'}
          className={'homework-files'}
        >
          {item?.files?.map((item, index) => (
            <HomeworkCard
              key={index}
              title={item?.origname}
              size={item?.size}
              link={item?.url}
            />
          ))}
        </FlexWrapper>

        <FlexWrapper justify='flex-end' pas='2px'>
          <CountOfFiles>
            Jo'natilgan vaqt: {dateFormatForHmw(item?.createdAt)}
          </CountOfFiles>
        </FlexWrapper>
      </MyHomeworksWrapper>
    );
  }
);
