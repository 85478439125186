import { AllCoursesActions } from "@all-courses/store";
import { timeFormat } from "@utils/date-format";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {useMediaQuery} from "@utils/useMediaQuery";

export const useMyHomeworkProp = ({setIsAvailable, homework, item, setSendedHomeWorks}) => {
    const dispatch = useDispatch();
    const mqMaxWidth430 = useMediaQuery("(max-width: 430px)");

    const handleChangeHomeWork = (id) => {
        setIsAvailable(true)
        dispatch(AllCoursesActions.setIsChange({available: true, id: id}));
        setSendedHomeWorks(item)
    };

    const mySendedHomeWorks = useMemo(() => {
        return homework?.homeworkChats?.filter(item => item?.student)
    },[homework?.homeworkChats])

    const pendingResult = useMemo(() => {
        return homework?.homeworkChats?.find(item => item?.status === 2)
    },[homework])

    const cancelResult = useMemo(() => {
        return homework?.homeworkChats?.find(item => item?.status === 3)
    },[homework])

    const changeHomework = useMemo(() => {
        return (timeFormat(item?.createdAt, 10) && item?.status === 2)
    },[item?.createdAt, item?.status])

    return {
        handleChangeHomeWork,
        mySendedHomeWorks,
        pendingResult,
        cancelResult,
        changeHomework,
        mqMaxWidth430
    }
};
