import { FlexWrapper } from '@components/layouts/flex-wrapper'
import { Skeleton } from '@components/skeleton/skeleton-box'
import React from 'react'

export const NotesHeaderSkeleton = () => {
  return (
    <FlexWrapper>
      <Skeleton width={150} height={25} />
    </FlexWrapper>
  )
}

export const NotesSkeleton = () => {
  return (<>
    {
      Array.from(Array(10))?.map((item) => (
        <FlexWrapper mb={'35px'} key={item} direction={'column'} >
          <FlexWrapper width={'100%'} justify='space-between' >
            <FlexWrapper width={'90%'}>
              <Skeleton
                width={50}
                height={30}
                borderRadius={20}
                marginRight={20}
              />
              <Skeleton
                fullWidth={40}
                height={30}
              />
            </FlexWrapper>
            <FlexWrapper>
              <Skeleton
                width={28}
                height={28}
                variant={'circle'}
                marginRight={15}
              />
              <Skeleton
                width={28}
                height={28}
                variant={'circle'}
              />
            </FlexWrapper>
          </FlexWrapper>
          <Skeleton marginTop={15} fullWidth={60} height={16} />
        </FlexWrapper>
      ))
    }
  </>)
}
