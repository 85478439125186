import { CheckboxBase, StyledCheckbox } from './checkbox.styles';
import { ReactComponent as IconChecked } from '@assets/img/checked-tick.svg';

export const Checkbox = ({ isChecked, onChange, isDisabled, ...rest }) => {
  const handleChange = e => {
    onChange(e.target.checked);
  };

  return (
    <CheckboxBase isDisabled={isDisabled}>
      <input
        type={'checkbox'}
        disabled={isDisabled}
        checked={isChecked}
        {...rest}
        onChange={handleChange}
      />
      <StyledCheckbox tabIndex={0}>
        {isChecked && <IconChecked />}
      </StyledCheckbox>
    </CheckboxBase>
  );
};
