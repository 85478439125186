import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useMyCourseCardProp } from './my-course-card.prop';
import {
  MyCourseCardImg,
  MyCourseCardTitle,
  MyCourseCardWrapper,
  CourseProgressInfo,
  CourseProgressBar,
  CourseProgressEnded,
  PlayBtnImage,
  CourseCardInfoTitle,
  CourseCardInfoSubtitle,
  MoreBtn,
  MyCourseCardRightWrapper,
  MyCourseCardLeftWrapper
} from './my-course-card.style';
import { NoImage } from '@components/no-image';
import PlayBtnImg from '@assets/img/nout-play.png';
import Watch from '@assets/img/watch.png';
import { secondsToHms } from '@utils/date-format-time';
import { config } from '@config/config';

export const MyCourseCard = hoc(
  useMyCourseCardProp,
  ({
    small,
    title,
    progress,
    lessonsCount,
    duration,
    id,
    img,
    sidebarState,
    mq1070MaxWidth,
    mq885MaxWidth,
    mq825MaxWidth,
    mq440MaxWidth,
    color,
    mq781MaxWidth,
    mq1122MaxWidth,
    isSidebarOpened,
    mq985MaxWidth,
    mq1122MinWidth
  }) => {
    let CourseCardImgSrc = config.imgApiUrl
    if(img?.[0] === '/') {
      CourseCardImgSrc += img
    } else {
      CourseCardImgSrc += `/${img}`
    }
    return (
      <MyCourseCardWrapper
        sidebarstate={sidebarState ? 'true' : null}
        to={`/my-course-single/${id}`}
        small={small}
      >
        <MyCourseCardLeftWrapper
          direction={mq440MaxWidth ? 'column' : 'row'}
          gap={mq885MaxWidth ? '15px' : '24px'}
        >
          {img ? (
            <MyCourseCardImg color={color} src={CourseCardImgSrc} />
          ) : (
            <NoImage width={160} height={134} />
          )}

          <FlexWrapper direction='column' width={mq781MaxWidth ? "49%" : null}>
            <MyCourseCardTitle title={title} mb={`${title.length > 40 && (!mq825MaxWidth && mq885MaxWidth) ? '5px' : '24px'}`}>{title}</MyCourseCardTitle>

            <CourseProgressInfo>
              {progress === 100
                ? 'Videodarslar yakunlangan'
                : `${progress}% videodarslar yakunlangan`}
            </CourseProgressInfo>
            <CourseProgressBar>
              <CourseProgressEnded width={progress}></CourseProgressEnded>
            </CourseProgressBar>
          </FlexWrapper>
        </MyCourseCardLeftWrapper>

        <MyCourseCardRightWrapper wrap='wrap' justify='space-between'>
          <FlexWrapper>
            <FlexWrapper mr={mq885MaxWidth ? '25px' : '35px'}>
              <FlexWrapper direction='column'>
                <FlexWrapper mb='7px'>
                  <PlayBtnImage src={PlayBtnImg} />
                  <CourseCardInfoTitle>Darslar soni:</CourseCardInfoTitle>
                </FlexWrapper>
                <CourseCardInfoSubtitle>
                  {lessonsCount} ta
                </CourseCardInfoSubtitle>
              </FlexWrapper>
            </FlexWrapper>

            <FlexWrapper mr={mq1122MaxWidth ? '0' : '60px'}>
              <FlexWrapper direction='column'>
                <FlexWrapper mb='7px'>
                  <PlayBtnImage src={Watch} />
                  <CourseCardInfoTitle>Kurs davomiyligi:</CourseCardInfoTitle>
                </FlexWrapper>
                <CourseCardInfoSubtitle>
                  {secondsToHms(duration) || '0'}
                </CourseCardInfoSubtitle>
                {!isSidebarOpened && mq1122MaxWidth && !mq781MaxWidth && !mq825MaxWidth && (
                  <MoreBtn mt='24px'>Batafsil</MoreBtn>
                )}
                  {isSidebarOpened && (mq1122MaxWidth && !mq985MaxWidth) && (
                      <MoreBtn mt='24px'>Batafsil</MoreBtn>
                  )}
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
          {isSidebarOpened && ( mq1122MinWidth || mq781MaxWidth || mq825MaxWidth || mq985MaxWidth ) && <MoreBtn>Batafsil</MoreBtn>}
          {!isSidebarOpened && (mq825MaxWidth || !mq1122MaxWidth) && <MoreBtn>Batafsil</MoreBtn>}
        </MyCourseCardRightWrapper>
      </MyCourseCardWrapper>
    );
  }
);
