import { allCoursesService } from '@all-courses/services/all-categories.service';
import { tryCatchSaga } from '@auth/store';
import { fetchSagaFactory } from '@utils/fetch-saga-factory';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AllCoursesActions } from './all-courses.slice';

export function* fetchAllCourses({ payload }) {
  const res = yield call(allCoursesService.fetchAllCourses, payload);
  yield put(AllCoursesActions.fetchAllCoursesFulfilled(res?.data));
}

export function* fetchSingleCourse({ payload }) {
  const res = yield call(allCoursesService.fetchSingleCourse, payload);
  const updatedData = res.data?.modules?.map(item => {
    return {...item, chapters: null}
  })
  if(res?.data?.modules) {
    res.data.modules = updatedData
  }
  yield put(AllCoursesActions.fetchSingleCourseFulfilled(res?.data));
  yield put(AllCoursesActions.setError(res?.error));
  yield payload.callback(res);
}

export function* fetchModuleChapters({payload}) {
  const res = yield call(allCoursesService.fetchModuleChapters, payload?.id);
  yield payload.callback(res)
}

export function* fetchStudentCourses({ payload }) {
  const res = yield call(allCoursesService.fetchStudentCourses, payload);
  yield put(AllCoursesActions.fetchStudentCoursesFulfilled(res?.data));
}

export function* paySuperFree({ payload }) {
  const res = yield call(allCoursesService.paySuperFree, payload?.data);
  yield payload.callback(res);
}

export function* fetchSingleModule({ payload }) {
  const res = yield call(allCoursesService.fetchModuleSingle, payload?.id);
  yield put(AllCoursesActions.fetchSingleModuleFulfilled(res?.data));
  yield payload?.callback(res);
}

export function* fetchHomework({ payload }) {
  const res = yield call(allCoursesService.fetchCourseHomeWorks, payload);
  yield put(AllCoursesActions.fetchCourseHomeworkFulfilled(res?.data));
}

export function* fetchByLessonHomework({ payload }) {
  const res = yield call(
    allCoursesService.fetchCourseByLessonHomeWorks,
    payload
  );
  yield put(AllCoursesActions.fetchCourseByLessonHomeworkFulfilled(res?.data));
}

export function* sendHomework({ payload }) {
  const res = yield call(allCoursesService.sendHomeWork, payload.info);
  yield payload.callback(res);
}

export function* fetchNotes({ payload }) {
  const res = yield call(allCoursesService.fetchCourseNotes, payload.id);
  yield put(AllCoursesActions.fetchNotesFulfilled(res?.data));
  yield payload.callback(res);
}

export function* deleteNote({ payload }) {
  const res = yield call(allCoursesService.deleteCourseNote, payload.id);
  yield payload.callback(res);
}

export function* addNote({ payload }) {
  const res = yield call(allCoursesService.addNote, payload.info);
  yield payload.callback(res);
}

export function* rateLesson({ payload }) {
  const res = yield call(allCoursesService.rateLesson, payload.data);
  yield payload.callback(res);
}

export function* editNote({ payload }) {
  const res = yield call(allCoursesService.editNote, payload.info);
  yield payload.callback(res);
}

export function* editHomework({ payload }) {
  const res = yield call(allCoursesService.changeHomeWork, payload.info);
  yield payload.callback(res);
}

export function* fetchComments({ payload }) {
  const res = yield call(allCoursesService.fetchComments, payload);
  yield put(AllCoursesActions.fetchCommentsFulfilled(res?.data));
  yield payload.callback(res);
}

export function* fetchAllComments({ payload }) {
  const res = yield call(allCoursesService.fetchComments, payload);
  yield put(AllCoursesActions.getAllCommentsFulfilled(res?.data));
}

export function* addComment({ payload }) {
  const res = yield call(allCoursesService.addComment, payload.info);
  yield payload.callback(res);
}

export function* getLastVideo({ payload }) {
  const res = yield call(allCoursesService.getLastVideo, payload?.data);
  yield put(AllCoursesActions.fetchLastVideoFulfilled(res?.data));
  yield payload?.callback(res);
}

export function* getPromoCode({ payload }) {
  const res = yield call(allCoursesService.promoCodes, payload.info);
  yield payload.callback(res);
}

export function* getCourseDiscount({ payload }) {
  const res = yield call(allCoursesService.courseDiscount, payload);
  yield put(AllCoursesActions.fetchCourseDiscountFulfilled(res.data));
}

export function* requestUpsertLesson(action) {
  const res = yield call(allCoursesService.upsertLesson, action.payload?.data);
  yield action?.payload?.callback(res);
}

export function* payzeToken({ payload }) {
  const res = yield call(allCoursesService.payzeToken, payload.info);
  yield payload.callback(res);
}

export function* voteComment({ payload }) {
  const res = yield call(allCoursesService.vote, payload.data);
  yield payload.callback(res);
}

export function* deleteComment({ payload }) {
  const res = yield call(allCoursesService.deleteComment, payload.id);
  yield payload.callback(res);
}

export function* reportComment({ payload }) {
  const res = yield call(allCoursesService.commentReport, payload.info);
  yield payload.callback(res);
}

export function* editComment({ payload }) {
  const res = yield call(allCoursesService.editComment, payload.data);
  yield payload.callback(res);
}

export function* getExchangeRate({ payload }) {
  const res = yield call(allCoursesService.exchangeRate, payload);
  yield put(AllCoursesActions.exchangeRateFulfilled(res));
}

export function* getCourseLessonFaq({payload}) {
  const res = yield call(allCoursesService.fetchCourselessonFaq, payload);
  yield put(AllCoursesActions.fetchCourseLessonFaqFulfilled(res.data))
  yield payload.callback(res);
}

export function* AllCoursesRootSaga() {
  yield all([
    fetchSagaFactory({
      pattern: 'fetchCategories',
      actions: AllCoursesActions,
      api: {
        method: allCoursesService.fetchCategories,
        callback: res => res.data
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchCoursePaymentInfo',
      actions: AllCoursesActions,
      api: {
        method: allCoursesService.getPaymentInfo,
        callback: res => res.data
      }
    }),
    takeLatest(
      AllCoursesActions.fetchAllCourses,
      tryCatchSaga(fetchAllCourses)
    ),
    takeLatest(
      AllCoursesActions.fetchStudentCourses,
      tryCatchSaga(fetchStudentCourses)
    ),
    takeLatest(
      AllCoursesActions.fetchSingleCourse,
      tryCatchSaga(fetchSingleCourse)
    ),
    takeLatest(
      AllCoursesActions.fetchSingleModule,
      tryCatchSaga(fetchSingleModule)
    ),
    takeLatest(
      AllCoursesActions.fetchCourseHomework,
      tryCatchSaga(fetchHomework)
    ),
    takeLatest(
      AllCoursesActions.fetchCourseByLessonHomework,
      tryCatchSaga(fetchByLessonHomework)
    ),
    takeLatest(AllCoursesActions.fetchModuleChapters, tryCatchSaga((fetchModuleChapters))),
    takeLatest(AllCoursesActions.sendHomework, tryCatchSaga(sendHomework)),
    takeLatest(AllCoursesActions.fetchNotes, tryCatchSaga(fetchNotes)),
    takeLatest(AllCoursesActions.deleteNote, tryCatchSaga(deleteNote)),
    takeLatest(AllCoursesActions.addNote, tryCatchSaga(addNote)),
    takeLatest(AllCoursesActions.editNote, tryCatchSaga(editNote)),
    takeLatest(AllCoursesActions.changeHomework, tryCatchSaga(editHomework)),
    takeLatest(AllCoursesActions.fetchComments, tryCatchSaga(fetchComments)),
    takeLatest(AllCoursesActions.addComment, tryCatchSaga(addComment)),
    takeLatest(AllCoursesActions.fetchLastVideo, tryCatchSaga(getLastVideo)),
    takeLatest(AllCoursesActions.fetchPromoCode, tryCatchSaga(getPromoCode)),
    takeLatest(
      AllCoursesActions.fetchCourseDiscount,
      tryCatchSaga(getCourseDiscount)
    ),
    takeLatest(AllCoursesActions.payzeToken, tryCatchSaga(payzeToken)),
    takeLatest(AllCoursesActions.voted, tryCatchSaga(voteComment)),
    takeLatest(AllCoursesActions.deleted, tryCatchSaga(deleteComment)),
    takeLatest(
      AllCoursesActions.getAllComments,
      tryCatchSaga(fetchAllComments)
    ),
    takeLatest(AllCoursesActions.commentReport, tryCatchSaga(reportComment)),
    takeLatest(AllCoursesActions.editComment, tryCatchSaga(editComment)),
    takeLatest(AllCoursesActions.exchangeRate, tryCatchSaga(getExchangeRate)),
    takeLatest(
      AllCoursesActions.fetchCourseDiscount,
      tryCatchSaga(getCourseDiscount)
    ),

    takeLatest(
      AllCoursesActions.upsertLesson,
      tryCatchSaga(requestUpsertLesson)
    ),

    takeLatest(AllCoursesActions.payForSuperFree, tryCatchSaga(paySuperFree)),
    takeLatest(AllCoursesActions.rateLesson, tryCatchSaga(rateLesson)),
    takeLatest(AllCoursesActions.fetchCourseLessonFaq, tryCatchSaga(getCourseLessonFaq))
  ]);
}
