import { useEffect, useMemo, useRef, useState } from 'react';
import { Payze } from '@payze/payze-js';
import { useDispatch, useSelector } from 'react-redux';
import { AllCoursesActions } from '@modules/all-courses/store';
import { addNotification } from '@components/notifications';
import { useParams, useHistory } from 'react-router-dom';

const dictionary = {
  REQUIRED_USERNAME: 'Karta egasi nomini kiriting',
  REQUIRED_CARD_NUMBER: 'Karta raqamini kiriting',
  PROBLEM_WITH_CARD: "Karta raqam noto'g'ri",
  REQUIRED_EXPIRY_DATE: 'Muddatni kiriting',
  REQUIRED_CVV: 'CVV’ni kiriting'
};

const identicalStyles = 'border-radius: 100px; box-shadow: none';

export const usePayzeModalProps = ({ unMountModal }) => {
  const dispatch = useDispatch();
  const [rememberCard, setRememberCard] = useState(false);
  const transactionId = useSelector(state => state.courses?.transactionId);
  const isShowPaymentModal = useSelector(state => state.common.payzeModal);
  const isSaveCard = useSelector(state => state.courses.isSaveCard);
  const exchangeRate = useSelector(state => state.courses.exchangeRate?.data);
  const courseTotalPrice = useSelector(state => state.courses.courseTotalPrice);
  const [currentRate, setCurrentRate] = useState(0);
  const [newRatePrice, setNewRatePrice] = useState(0);
  const [USDPrice, setUSDPrice] = useState(0);
  const [isChangedCurrency, setIsChangedCurrency] = useState(false);
  const payzeRef = useRef(null);
  const [isValidCardInfo, setIsValidCardInfo] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [count, setCount] = useState(1);
  const { courseId } = useParams();
  const history = useHistory();

  const unMountedModal = () => {
    unMountModal?.();
    setIsPayment(false);
  };

  const errorCallback = () => {
    addNotification({
      description: "Nimadir xato ketdi, birozdan so'ng urinib ko'ring",
      title: 'Xatolik',
      options: {
        autoClose: true,
        type: 'error'
      }
    });

    unMountedModal();
  };

  const successCallback = () => {
    addNotification({
      description: "To'lov muvaffaqiyatli amalga oshirildi!",
      title: 'Muvaffaqiyatli',
      options: {
        autoClose: true,
        type: 'success'
      }
    });

    if (courseId) {
      history.push(`/my-course-single/${courseId}`);
    } else {
      history.push('/my-courses');
    }

    unMountedModal();
  };

  const payzeCustomStyles = useMemo(() => {
    return {
      pan: identicalStyles,
      name: identicalStyles,
      date: identicalStyles,
      cvv: identicalStyles,
      iframeHeight: '210',
      panError: dictionary.REQUIRED_CARD_NUMBER,
      expirationDateError: dictionary.REQUIRED_EXPIRY_DATE,
      cardHolderError: dictionary.REQUIRED_USERNAME,
      cvvError: dictionary.REQUIRED_CVV,
      cardHolderPlaceholder: 'Karta ustidagi ismni kiriting',
      expirationDatePlaceholder: 'OO/YY',
      successCallback,
      errorCallback
    };
  }, [isPayment]);

  const handleOnSubmit = e => {
    e.preventDefault();
    dispatch(AllCoursesActions.exchangeRate());
    if (Number(exchangeRate?.Rate) !== currentRate) {
      setIsChangedCurrency(true);
      setNewRatePrice(Number(exchangeRate?.Rate));
      return;
    }
    payzeRef.current.pay();
    setIsPayment(true);
  };

  useEffect(() => {
    setCurrentRate(Number(exchangeRate?.Rate));
    const USDRate =
      courseTotalPrice / Number(isChangedCurrency ? newRatePrice : currentRate);
    setUSDPrice(Number(USDRate.toFixed(2)) || 0);
  }, [
    courseTotalPrice,
    exchangeRate,
    currentRate,
    newRatePrice,
    isChangedCurrency
  ]);

  useEffect(() => {
    if (!transactionId || !payzeRef?.current || count > 1) {
      return;
    }

    payzeRef?.current?.renderCardInfo();
    setCount(2);
    payzeRef?.current?.validateCardInfo().subscribe(valid => {
      setIsValidCardInfo(valid);
    });
  }, [isShowPaymentModal, transactionId, payzeRef.current]);

  useEffect(() => {
    const payze = Payze(transactionId, { ...payzeCustomStyles });
    payzeRef.current = payze;
  }, [isPayment]);

  useEffect(() => {
    dispatch(AllCoursesActions.exchangeRate());
  }, []);

  const handleSaveCardDateInfo = () => {
    dispatch(AllCoursesActions.setIsSaveCard(!isSaveCard));
  };

  return {
    isSaveCard,
    rememberCard,
    handleOnSubmit,
    handleSaveCardDateInfo,
    USDPrice,
    currentRate,
    newRatePrice,
    isChangedCurrency,
    setIsChangedCurrency,
    unMountedModal,
    isPayment
  };
};
