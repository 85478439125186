import styled from 'styled-components';

export const Scrollable = styled.div`
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background: #dadada;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ba8d5b;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
