import {useEffect, useState} from 'react';
import { useMediaQuery } from '@utils/useMediaQuery';
import {useDispatch, useSelector} from "react-redux";
import {AllCoursesActions} from "@all-courses/store";


export const useCourseFaqProp = () => {
  const [clicked, setClicked] = useState(false);
  const [smallClicked, setSmallClicked] = useState(false);
  const mqMaxWidth900 = useMediaQuery('(max-width: 900px)');
  const mqMaxWidth520 = useMediaQuery('(max-width: 520px)');
  const courseLessonFaq = useSelector(state => state.courses.lessonFaq);
  const isLoading = useSelector(state => state.courses.loading);

  const toggle = (index, event) => {
    event.stopPropagation();

    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  const toggleSmall = index => {
    if (smallClicked === index) {
      return setSmallClicked(null);
    }
    setSmallClicked(index);
  };


  return {
    toggle,
    toggleSmall,
    clicked,
    smallClicked,
    mqMaxWidth900,
    mqMaxWidth520,
    courseLessonFaq,
    isLoading
  };
};
