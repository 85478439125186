import styled from 'styled-components';

export const DeletedCommentWrapper = styled.div`
  background: rgba(188, 185, 182, 0.1);
  border-radius: 6px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 16px;
`;

export const DeletedCommentImg = styled.img`
  width: 18px;
  height: 18px;
`;

export const DeletedCommentTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.8px;
  color: #000000;
`;
