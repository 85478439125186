import React from 'react';
import { hoc } from '@utils/hoc';
import AlertSuccessIcon from '@assets/img/alert-done-icon.svg';
import AlertErrorIcon from '@assets/img/alert-warning.svg';
import { useAlertProps } from './alert.props';
import {
  AlertCloseBtn,
  AlertContainer,
  AlertIcon,
  AlertInner,
  AlertMessage,
  AlertMessageDesc
} from './alert.style';
import { FlexWrapper } from '@components/layouts/flex-wrapper';

export const Alert = hoc(
  useAlertProps,
  ({ handleCloseAlertClick, children, ...props }) => (
    <AlertContainer data-testid='alert-container' {...props}>
      <AlertInner>
        <FlexWrapper align='center'>
          <AlertIcon src={props.success ? AlertSuccessIcon : AlertErrorIcon} />
          <FlexWrapper direction='column'>
            <AlertMessage>{props.message}</AlertMessage>
            <AlertMessageDesc>{props.description}</AlertMessageDesc>
            {children}
          </FlexWrapper>
        </FlexWrapper>
        <AlertCloseBtn onClick={handleCloseAlertClick} />
      </AlertInner>
    </AlertContainer>
  )
);
