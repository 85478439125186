import { hoc } from '@utils/hoc';
import { useSelectGenderProps } from './select-gender.prop';
import {
  SelectOption,
  SelectOptionsWrapper,
  SelectPlaceholder,
  SelectWrapper
} from './select-gender.style';

export const SelectGender = hoc(
  useSelectGenderProps,
  ({
    toggleSelect,
    open,
    _onChange,
    activeValue,
    dropdownRef,
    error,
    openselect
  }) => {
    return (
      <SelectWrapper
        className={error && 'error'}
        ref={dropdownRef}
        onClick={toggleSelect}
      >
        <SelectPlaceholder active={activeValue}>
          {activeValue || 'Jinsingizni belgilang'}
        </SelectPlaceholder>

        {openselect && (
          <SelectOptionsWrapper active={open}>
            <SelectOption
              selected={activeValue === 'Erkak'}
              onClick={_onChange.bind(null, 'Erkak')}
            >
              Erkak
            </SelectOption>
            <SelectOption
              selected={activeValue === 'Ayol'}
              onClick={_onChange.bind(null, 'Ayol')}
            >
              Ayol
            </SelectOption>
          </SelectOptionsWrapper>
        )}
      </SelectWrapper>
    );
  }
);
