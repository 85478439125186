import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useMyCourseAccordion } from './my-course-accordion.prop';
import FreeVideo from '@assets/img/freevideo.png';

import {
  CourseAccordionCard,
  CourseAccordionCardList,
  CourseAccordionCardListItem,
  CourseAccordionListItemToggle,
  AccordionCardWrapper,
  AccordionThemeCard,
  AccordionInner,
  AccordionCardItemContent,
  AccordionCardTitle,
  AccordionTheme,
  FlexWrapperForThemes,
  VideoStatus,
  VideoDuration,
  ViewBefore,
  ModuleWrapper
} from './my-course-accordion.style';
import { secondsFormat } from '@utils/date-format-time';
import {
  getChapterLessonsVideoStatusImage,
  getChapterModuleVideoStatusImage,
  getCourseModuleVideoStatusImage
} from './utils';
import {NewBadge, NoDataTitle} from '../archived-module/archived-module.style';

export const MyCourseAccordion = hoc(
  useMyCourseAccordion,
  ({
    toggle,
    clicked,
    toggleSmall,
    smallClicked,
    courseInfo,
    handleClick,
    handleOpenCourseFreeModal,
    openModalForUnpaidModules,
    mqMaxWidth650,
    mqMaxWidth410,
    courseId,
    handleChapterTitleClick,
    selectedModule
  }) => {
    return (
      <>
        {courseInfo?.modules?.length > 0 ? (
          <CourseAccordionCard>
            <CourseAccordionCardList>
              {courseInfo?.modules?.map((item, index) => (
                <CourseAccordionCardListItem
                  key={index}
                  className={(clicked === index || selectedModule === item?.id) && 'accordion-card__item--open'}
                >
                  <FlexWrapper justify='space-between' align='flex-start'>
                    <FlexWrapper
                      align='flex-start'
                      onClick={toggle.bind(null, index, item)}
                    >
                      <VideoStatus
                        src={getCourseModuleVideoStatusImage(item)}
                        alt='video-status'
                      />
                      {!mqMaxWidth650 && (
                        <AccordionCardTitle>
                          {item?.position + '-modul' || ''}:{' '}
                          {item?.title || 'Kurs nomi'}
                        </AccordionCardTitle>
                      )}
                      {mqMaxWidth650 && (
                        <FlexWrapper direction='column' mr='10px'>
                          <AccordionCardTitle>
                            {item?.position + '-modul' || ''}:{' '}
                            {item?.title || 'Kurs nomi'}
                          </AccordionCardTitle>
                          <AccordionCardTitle>
                            <span>
                              Mavzular soni: {item?.chaptersCount} ta
                            </span>
                          </AccordionCardTitle>
                        </FlexWrapper>
                      )}
                    </FlexWrapper>
                    <FlexWrapper align='center' gap='10px'>
                      {!mqMaxWidth650 && (
                        <AccordionCardTitle>
                          <span>Mavzular soni: {item.chaptersCount} ta</span>
                        </AccordionCardTitle>
                      )}
                      <CourseAccordionListItemToggle
                        type='button'
                        className='large btn--large'
                        aria-label='toggle accordion'
                        onClick={toggle.bind(null, index, item)}
                      />
                    </FlexWrapper>
                  </FlexWrapper>

                  <ModuleWrapper>
                    {
                        item?.chapters?.length === 0 && clicked === index ? (
                            <NoDataTitle>Ma’lumot mavjud emas</NoDataTitle>
                        ):
                            item?.chapters?.map((chapter, chapterIndex) => (
                                <AccordionCardWrapper
                                    key={chapterIndex}
                                    className={
                                        (clicked === index || selectedModule === item?.id) && 'accordion-card__item--open'
                                    }
                                >
                                    <AccordionThemeCard
                                        onClick={openModalForUnpaidModules.bind(null, {
                                            item,
                                            index: `${chapterIndex}-${index}`,
                                            chapter
                                        })}
                                    >
                                        <FlexWrapper align='flex-start'>
                                            <VideoStatus
                                                small
                                                src={getChapterModuleVideoStatusImage(
                                                    chapter,
                                                    item
                                                )}
                                                alt='video-status'
                                            />
                                            {!mqMaxWidth650 && (
                                                <AccordionCardTitle
                                                    sub
                                                >
                                                    {chapter?.position + '-mavzu' || ''}:{' '}
                                                    {chapter?.name || 'Mavzu'}
                                                </AccordionCardTitle>
                                            )}

                                            {mqMaxWidth650 && (
                                                <FlexWrapper direction='column' mr='8'>
                                                    <AccordionCardTitle sub>
                                                        {chapter?.position + '-mavzu' || ''}:{' '}
                                                        {chapter?.name || 'Mavzu'}
                                                    </AccordionCardTitle>
                                                    <AccordionCardTitle sub>
                                  <span className='accordion-card__item-lesson-count'>
                                    Darslar soni: {chapter?.lessonsCount} ta
                                  </span>
                                                    </AccordionCardTitle>
                                                </FlexWrapper>
                                            )}
                                        </FlexWrapper>
                                        <FlexWrapper gap='10px'>
                                            {chapter?.isNew && <NewBadge>Yangi</NewBadge>}
                                            {!mqMaxWidth650 && (
                                                <AccordionCardTitle sub>
                                <span className='accordion-card__item-lesson-count'>
                                  Darslar soni: {chapter?.lessonsCount} ta
                                </span>
                                                </AccordionCardTitle>
                                            )}
                                            <CourseAccordionListItemToggle
                                                type='button'
                                                className={
                                                    smallClicked === `${chapterIndex}-${index}`
                                                        ? 'btn--small large'
                                                        : 'large'
                                                }
                                                aria-label='toggle accordion'
                                                small
                                                block={!item.isPaid && !FreeVideo}
                                                onClick={toggleSmall.bind(
                                                    null,
                                                    `${chapterIndex}-${index}`
                                                )}
                                            />
                                        </FlexWrapper>
                                    </AccordionThemeCard>
                                    <AccordionCardItemContent
                                        className={
                                            smallClicked === `${chapterIndex}-${index}`
                                                ? 'accordion-card__item-content-wrapper--open'
                                                : 'accordion-card__item-content--course'
                                        }
                                    >
                                        {
                                            chapter?.lessons?.length === 0 && smallClicked === `${chapterIndex}-${index}` ? (
                                                <NoDataTitle>Ma'lulumot mavjud emas</NoDataTitle>
                                            ):
                                                chapter?.lessons?.map((les, i) => (
                                                    <FlexWrapperForThemes
                                                        data-id='id'
                                                        key={i}
                                                        onClick={
                                                            les?.isFree && !item.isPaid
                                                                ? handleOpenCourseFreeModal.bind(null, {
                                                                    file: les?.video?.videoId,
                                                                    title: les?.title,
                                                                    index: parseInt(chapterIndex) + 1,
                                                                    video: les?.video
                                                                })
                                                                : undefined
                                                        }
                                                    >
                                                        <FlexWrapper align='flex-start'>
                                                            <VideoStatus
                                                                small
                                                                className={!les?.video?.videoId ? 'document' : ''}
                                                                src={getChapterLessonsVideoStatusImage(
                                                                    les,
                                                                    item
                                                                )}
                                                                alt='video-status'
                                                            />
                                                            <AccordionInner>
                                                                <div
                                                                    onClick={() => {
                                                                        if (!(item?.isPaid || !les?.isFree)) {
                                                                            return;
                                                                        }

                                                                        handleClick(
                                                                            les,
                                                                            `/course-single/${courseId}/module/${item.id}`,
                                                                            {
                                                                                chapter_id: chapter.id,
                                                                                course_id: item.id
                                                                            },
                                                                            item,
                                                                            chapter
                                                                        );
                                                                    }}
                                                                >
                                                                    <AccordionTheme>
                                                                        {les?.position + '-dars'}:{' '}
                                                                        {les?.title || 'Dars'}
                                                                    </AccordionTheme>
                                                                    {mqMaxWidth650 ? (
                                                                        <FlexWrapper
                                                                            direction={mqMaxWidth410 ? 'column' : ''}
                                                                        >
                                                                            <VideoDuration>
                                                                                {les?.video?.duration ? secondsFormat(
                                                                                    les?.video?.duration
                                                                                ) : ''}
                                                                            </VideoDuration>
                                                                            {les?.isFree && !item.isPaid && (
                                                                                <ViewBefore>Bepul ko‘rish</ViewBefore>
                                                                            )}
                                                                        </FlexWrapper>
                                                                    ) : (
                                                                        <VideoDuration>
                                                                            {les?.video?.duration ? secondsFormat(les?.video?.duration) : ''
                                                                              }
                                                                        </VideoDuration>
                                                                    )}
                                                                </div>
                                                                <h3>{les.duration}</h3>
                                                            </AccordionInner>
                                                        </FlexWrapper>
                                                        {!mqMaxWidth650 &&
                                                            les?.isFree &&
                                                            !item.isPaid && (
                                                                <ViewBefore
                                                                    onClick={handleOpenCourseFreeModal.bind(
                                                                        null,
                                                                        {
                                                                            file: les?.video?.videoId,
                                                                            title: les?.title,
                                                                            index: parseInt(chapterIndex) + 1,
                                                                            video: les?.video
                                                                        }
                                                                    )}
                                                                >
                                                                    Bepul ko‘rish
                                                                </ViewBefore>
                                                            )}
                                                    </FlexWrapperForThemes>
                                                ))
                                        }
                                    </AccordionCardItemContent>
                                </AccordionCardWrapper>
                            ))
                    }
                  </ModuleWrapper>
                </CourseAccordionCardListItem>
              ))}
            </CourseAccordionCardList>
          </CourseAccordionCard>
        ) : (
          ''
        )}
      </>
    );
  }
);
