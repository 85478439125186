import styled from 'styled-components';

import InfoIcon from '@assets/img/info-icon.svg';
import DownloadIcon from '@assets/img/download.svg';
import ShareIcon from '@assets/img/share.svg';

export const CertificateWrapper = styled.div(
  ({ theme: { down } }) => `
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  width: 70%;
  height: 542px;

  ${down('968px')} {
    width: 100%;
    height: 400px;
  }
  `
);

export const CertificateImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: ${props => (props.isLoaded ? 'none' : 'inherit')};
`;

export const CertificateInner = styled.div`
  border-radius: 4px;
`;

export const CertificateRightSide = styled.div(
  ({ theme: { down } }) => `
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;
    width: 30%;

    ${down('968px')} {
      width: 100%;
    }
  `
);

export const Button = styled.div`
  background: rgba(186, 141, 91, 0.16);
  border-radius: 100px;
  padding: 12px 0;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.share {
    position: relative;
  }

  &.download {
    background-color: #ba8d5b;
    width: ${props => (props.isTop ? '300px' : 'auto')};
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      width: auto;
    }
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ba8d5b;
    position: relative;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      left: -26px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-repeat: no-repeat;
      background-image: url(${InfoIcon});
    }

    &.download {
      color: #fff;

      &::before {
        background-image: url(${DownloadIcon}) !important;
      }
    }

    &.share {
      &::before {
        background-image: url(${ShareIcon});
      }
    }
  }
`;

export const DownloadButton = styled.a``;

export const CertificateLoader = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CertificateLoaderImg = styled.img`
  display: inline-block;
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
  background: #fff;
  box-sizing: border-box;
`;
