import { useState } from 'react';

export const useLessonRateModalProps = () => {
  const [commentLength, setCommentLength] = useState(0);

  const handleChangeTextarea = e => {
    setCommentLength(e.target.value.length);
  };

  return {
    handleChangeTextarea,
    commentLength
  };
};
