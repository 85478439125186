import { useSelector } from 'react-redux';

export const useUserInfoProp = () => {
  const certificateDetail = useSelector(
    state => state.certificates?.certificateDetail?.student
  );

  return {
    certificateDetail
  };
};
