import { Input } from '@components/atoms/Input';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { UnModal } from '@components/modal';
import { CodeCountDown } from '@modules/auth/components/count-down';
import {
  Button,
  FormGroup,
  InputError,
  InputLabels,
  LoginRecoveryNumberBtn,
  LoginSubtitleRecoverPassword,
  LoginTimer
} from '@modules/new-auth/routes/login/login.style';
import { hoc } from '@utils/hoc';
import { phoneFormatter } from '@utils/phoneFormatter';
import { useConfirmCodeProp } from './confirm-code.prop';
import { ConfirmForm } from './confirm-code.style';

export const ConfirmCode = hoc(
  useConfirmCodeProp,
  ({
    modal,
    setModal,
    handleClose,
    formik,
    timeIndex,
    countRef,
    setRetry,
    confirmPhone,
    retry,
    handleChangeLoginCode,
    handleBlurLoginCode,
    handleSendOtp,
    time,
    handleChangePhoneNumber
  }) => {
    return (
      <UnModal
        unMountModal={handleClose}
        modal={modal}
        setModal={setModal}
        modalTitle='Telefon raqamni tasdiqlash'
      >
        <ConfirmForm onSubmit={formik.handleSubmit}>
          <LoginSubtitleRecoverPassword>
            Maxsus kod
            <LoginRecoveryNumberBtn onClick={handleChangePhoneNumber}>
              {phoneFormatter?.(confirmPhone?.phoneNumber)    }
            </LoginRecoveryNumberBtn>
            raqamiga yuboril(a)di
          </LoginSubtitleRecoverPassword>
          <FormGroup mb>
            <InputLabels
              className={formik?.errors.code && 'error'}
              isError={true}
              htmlFor='code'
            >
              Maxsus kod
            </InputLabels>
            <FlexWrapper
              className={`wrapper ${formik?.errors.code ? 'input-error' : ''}`}
              align='center'
              justify='space-between'
            >
              <Input
                onChange={handleChangeLoginCode}
                id='code'
                type='text'
                placeholder='Maxsus kodni kiriting'
                onBlur={handleBlurLoginCode}
                name='code'
                value={formik?.values.code}
                className={'password-input'}
              />
              <LoginTimer
              onClick={handleSendOtp}
              className={retry ? 'retry' : ''}
              >
                <CodeCountDown
                  timeIndex={timeIndex}
                  counterRef={countRef}
                  time={time}
                  setRetry={setRetry}
                >
                  Qayta yuborish
                </CodeCountDown>
              </LoginTimer>
            </FlexWrapper>
            {formik?.errors?.code && (
              <InputError>{formik?.errors?.code}</InputError>
            )}
          </FormGroup>

          <Button type='submit'>Davom etish</Button>
        </ConfirmForm>
      </UnModal>
    );
  }
);
