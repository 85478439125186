import { SvgIcon } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { secondsFormat } from '@utils/date-format-time';
import { hoc } from '@utils/hoc';
import { useCourseNotesItemProp } from './course-notes-item.prop';
import {
  CourseNotesItemBtn,
  CourseNotesItemText,
  CourseNotesItemTime,
  CourseNotesItemTitle,
  CourseNotesItemWrapper
} from './course-notes-item.style';

export const CourseNotesItem = hoc(
  useCourseNotesItemProp,
  ({ deleteItem, editNote, note, title, time, onTimeClick }) => (
    <CourseNotesItemWrapper>
      <FlexWrapper mb='12px' justify='space-between'>
        <FlexWrapper>
          <CourseNotesItemTime onClick={onTimeClick}>
            {time ? secondsFormat(time) : '00:00'}
          </CourseNotesItemTime>
          <CourseNotesItemTitle>{title || ''}</CourseNotesItemTitle>
        </FlexWrapper>

        <FlexWrapper ml='16px'>
          <CourseNotesItemBtn onClick={editNote}>
            <SvgIcon iconColor='#BA8D5B' iconName='edit-note' size='14px' />
          </CourseNotesItemBtn>
          <CourseNotesItemBtn onClick={deleteItem}>
            <SvgIcon iconColor='#BA8D5B' iconName='delete-note' size='14px' />
          </CourseNotesItemBtn>
        </FlexWrapper>
      </FlexWrapper>

      <CourseNotesItemText>{note}</CourseNotesItemText>
    </CourseNotesItemWrapper>
  )
);
