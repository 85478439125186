import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useVideoCardProps } from './video-card-prop';
import {
    VideoCardCourseName,
    VideoCardItem,
    VideoCardLabel,
    VideoCardLink,
    VideoCardManage,
    VideoCardManageItem,
    VideoCardManageList,
    VideoCardMoreBookmarkMoreBtn,
    VideoCardPlayBtn,
    VideoCardProgress,
    VideoCardProgressBox,
    VideoCardProgressWrap,
    VideoCardTitle,
    VideoCardView,
    VideoCardViewPercentage,
    Wrap
} from './video-card-style';
import { ReactComponent as IconDots } from '@assets/img/icon-dots.svg';
import { ReactComponent as IconTrash } from '@assets/img/icon-trash-bin.svg';
import { ReactComponent as IconUp } from '@assets/img/icon-up.svg';
import { ReactComponent as IconDown } from '@assets/img/icon-down.svg';

export const VideoCard = hoc(
  useVideoCardProps,
  ({
    handleOpenManageListClick,
    isOpenManageList,
    courseName,
    moduleName,
    lessonName,
    courseProgress,
    handlePerHeadBookmarkClick,
    bookmarkId,
    handlePlayVideoClick,
    handleDeleteBookmarkClick,
    item,
    chapterName
  }) => (
    <VideoCardItem>
        <FlexWrapper direction={'column'} justify={'space-between'} height={'fill'}>
            <Wrap data-testid={'video'}>
              <VideoCardLink onClick={handlePlayVideoClick.bind(null, item)}>
                <VideoCardPlayBtn />
              </VideoCardLink>
              <FlexWrapper align='flex-start' justify='space-between' mb='12px'>
                <VideoCardTitle>{lessonName}</VideoCardTitle>
                {bookmarkId && (
                  <VideoCardMoreBookmarkMoreBtn data-testid={'more'} onClick={handleOpenManageListClick}>
                    <IconDots fill={isOpenManageList ? '#ba8d5b' : 'none'} />
                    {isOpenManageList && (
                      <VideoCardManage>
                        <VideoCardManageList>
                          <VideoCardManageItem onClick={handleDeleteBookmarkClick.bind(null, {bookmarkId, item})}>
                            <IconTrash />
                            Ro‘yxatdan o‘chirish
                          </VideoCardManageItem>
                          <VideoCardManageItem
                            onClick={handlePerHeadBookmarkClick.bind(null, bookmarkId, "PER_HAD")}
                          >
                            <IconUp />
                            Ro‘yxat boshiga surish
                          </VideoCardManageItem>
                          <VideoCardManageItem
                            onClick={handlePerHeadBookmarkClick.bind(null, bookmarkId, "TO_END")}
                          >
                            <IconDown />
                            Ro‘yxat oxiriga surish
                          </VideoCardManageItem>
                        </VideoCardManageList>
                      </VideoCardManage>
                    )}
                  </VideoCardMoreBookmarkMoreBtn>
                )}
              </FlexWrapper>
              <FlexWrapper align='flex-start' mb='6px'>
                <VideoCardLabel>Kurs:</VideoCardLabel>
                <VideoCardCourseName>{courseName}</VideoCardCourseName>
              </FlexWrapper>
              <FlexWrapper align='flex-start' mb='6px'>
                <VideoCardLabel>Modul:</VideoCardLabel>
                <VideoCardCourseName>{moduleName}</VideoCardCourseName>
              </FlexWrapper>
              <FlexWrapper align='flex-start' mb='44px'>
                    <VideoCardLabel>Mavzu:</VideoCardLabel>
                    <VideoCardCourseName>{chapterName}</VideoCardCourseName>
                </FlexWrapper>
        </Wrap>
      <FlexWrapper direction={'column'} align='start' mb='10px'>
          <VideoCardProgressWrap>
        <VideoCardViewPercentage>{courseProgress}%</VideoCardViewPercentage>
        <VideoCardView>qismi ko‘rilgan</VideoCardView>
          </VideoCardProgressWrap>
      <VideoCardProgressBox>
        <VideoCardProgress progress={courseProgress} />
      </VideoCardProgressBox>
      </FlexWrapper>
    </FlexWrapper>
    </VideoCardItem>
  )
);
