import {useMemo} from "react";

export const useCoursesModalProps = () => {
  const columns = useMemo(() => {
    return [
      {
        name: 'No',
        grow: 0.1,
        cell: (data, index) => {
          return (
            <span>{index + 1}</span>
          )
        }
      },
      {
        name: 'Kurs nomi',
        cell: (data) => {
          return (
            <h4>{data?.name}</h4>
          )
        }
      }
    ]
  }, [])

  return {
    columns
  }
}
