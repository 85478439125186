import {createSlice} from "@reduxjs/toolkit";

const name = 'myRatings'

const initialState = {
  loading: false,
  error: null,
  studentRatings: null
}

export const {actions: myRatingActions, reducer: myRatingReducer} = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    fetchMyRatings(state) {
      state.loading = true
    },
    fetchMyRatingsFulfilled(state, action) {
      state.studentRatings = action.payload
    }
  }
})
