import React, {useCallback, useEffect, useRef} from 'react';
import {toast} from 'react-toastify';
import {NotificationToast} from './notification-toast';

export const addNotification = ({title, description, options}) =>
  toast(
    ({closeToast}) => (
      <NotificationToast
        closeToast={closeToast}
        description={description}
        title={title}
        type={options?.type}
      />
    ),
    options
  );

export const removeNotification = (id) => {
  toast.dismiss(id);
};

export const updateNotification = (id, options) => {
  toast.update(id, options);
};


export const useRepeatingNotification = (isOnUnmount) => {
  const idRef = useRef('');

  const clearNotificationId = useCallback(() => {
    idRef.current = '';
  }, []);

  useEffect(() => () => {
    if (isOnUnmount && idRef.current) {
      removeNotification(idRef.current);
      idRef.current = '';
    }
  }, []);

  return (message, options) => {
    if (idRef.current) {
      updateNotification(idRef.current);
    } else {
      idRef.current = addNotification(message, {
        ...options,
        hideProgressBar: false,
        onClose: clearNotificationId,
      });
    }
  };
};


export const useRepeatingErrorNotification = (isOnUnmount) => {
  const idRef = useRef('');

  const clearNotificationId = useCallback(() => {
    idRef.current = '';
  }, []);

  useEffect(() => () => {
    if (isOnUnmount && idRef.current) {
      removeNotification(idRef.current);
      idRef.current = '';
    }
  }, []);

  return (message, options) => {
    if (idRef.current) {
      updateNotification(idRef.current);
      return;
    }

    idRef.current = addNotification(message, {
      ...options,
      type: 'error',
      hideProgressBar: false,
      onClose: clearNotificationId,
    });
  };
};
