import { hoc } from '@utils/hoc';
import { SidebarNavLink } from '../sidebar-nav-link';
import { useSidebarNavProp } from './sidebar-nav.prop';
import { StyledNav } from './sidebar-nav.style';
import { ReactComponent as IconHome } from '@assets/img/icon-home.svg';
import { ReactComponent as IconMyCourses } from '@assets/img/icon-my-courses.svg';
import { ReactComponent as IconAllCourses } from '@assets/img/icon-all-courses.svg';
import { ReactComponent as IconVideos } from '@assets/img/icon-videos-list.svg';
import { ReactComponent as IconCertificate } from '@assets/img/certificate.svg';
import { ReactComponent as IconResult } from '@assets/img/result.svg';
import {LogoutBtn} from "@components/layouts/main-layout/sidebar/sidebar.style";
import {ReactComponent as IconExit} from "@assets/img/icon-exit.svg";
import {useMemo} from "react";

export const SidebarNav = hoc(useSidebarNavProp, ({isRotated, handleToggleConfirmModal}) => {
  const navItems = useMemo(
      () => [
        { to: '/dashboard', icon: <IconHome />, label: 'Bosh sahifa' },
        { to: '/my-courses', icon: <IconMyCourses />, label: 'Mening kurslarim' },
        { to: '/courses', icon: <IconAllCourses />, label: 'Barcha kurslar' },
        { to: '/videos', icon: <IconVideos />, label: 'Videolar' },
        { to: '/certificates', icon: <IconCertificate />, label: 'Sertifikatlarim' },
        // { to: '/rating', icon: <IconResult />, label: 'Reyting', isRank: true },
      ],
      []
  );
  return (
    <StyledNav>
      <ul>
        {navItems.map((item, index) => (
            <SidebarNavLink key={index} to={item.to} isRank={item.isRank} onClick={item.onClick}>
              {item.icon}
              <h4 className='sidebar-navs'>{item.label}</h4>
            </SidebarNavLink>
        ))}
        {isRotated && (
            <LogoutBtn>
            <SidebarNavLink onClick={handleToggleConfirmModal} to='/login'>
              <IconExit />
              <h4 className='sidebar-navs'>Chiqish</h4>
            </SidebarNavLink>
            </LogoutBtn>
        )}
      </ul>
    </StyledNav>
  );
});
