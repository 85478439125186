import { addNotification } from '@components/notifications';
import { authActions } from '@modules/auth/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

export const useLoginRightSideProp = () => {
  const { path, url } = useRouteMatch();
  const forgetPhoneModal = useSelector(state => state.auth.forgetPhoneModal);
  const blockModal = useSelector(state => state.auth.blockModal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (blockModal) {
      addNotification({
        title:
          'Urinishlar soni 1 daqiqa ichida 5 martadan ko‘p bo‘lgani sababli keyinroq urinib ko‘ring!',
        description: 'Iltimos, 30 daqiqadan keyin  urinib ko’ring.',
        options: {
          type: 'error',
          autoClose: true
        }
      });
      dispatch(authActions.setBlockModal(false));
    }
  }, [blockModal]);

  return {
    url,
    path,
    forgetPhoneModal,
    blockModal
  };
};
