import NotMatch from '@assets/img/not-match.svg';
import Empty from '@assets/img/empty.svg';
import Success from '@assets/img/checksuccess.svg';
import {lengthChecker, numberReg} from "@modules/new-auth/constants"

export const passwordCheckConditions = [
    {
        condition: (password) => lengthChecker.test(password),
        label: '8 tadan 16 tagacha',
        imageEmpty: Empty,
        imageSuccess: Success,
        imageNotMatch: NotMatch,
    },
    {
        condition: (password) => numberReg.test(password),
        label: 'Kamida 1 ta raqam',
        imageEmpty: Empty,
        imageSuccess: Success,
        imageNotMatch: NotMatch,
    },
];
