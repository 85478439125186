import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CourseCardWrapper = styled(Link)`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  transition: all ease 0.3s;

  &.available {
    &:hover {
      box-shadow: 0 2px 16px 3px rgba(0, 0, 0, 0.1);
    }
  }
`
export const CourseCardTop = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 176px;
  margin-bottom: 12px;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    display: ${props => (props.available ? 'block' : 'none')};
  }
`;

export const SoonTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: absolute;
  z-index: 12;
  margin: auto;
`;

export const CourseCardImg = styled.img`
  width: 100%;
  height: 176px;
  object-fit: contain;
  border-radius: 8px;
  overflow: hidden;
  display: block;
  background-color: ${props => (props.imgColor || '#fff')};
  background-size: cover;
`

export const CourseCardCategoryCore = styled.h4`
  background-color: ${props => (props.bgColor || '#000')};
  color: ${props => (props.textColor || '#fff')};
`

export const CourseCardCategory = styled(CourseCardCategoryCore)(
  ({marginBottom, maxWidth}) => `
  display: inline-block;
  box-sizing: border-box;
  max-width: ${maxWidth ? '65%' : '100%'};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 5px 12px;
  margin-bottom: ${marginBottom ? '0' : '14px'};
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      font-size: 12px;
    }
  `
);

export const CourseCardIcon = styled.img`
  width: 20px;
  height: 15px;
  display: block;
  margin-right: 8px;
  object-fit: contain;
`;

export const CourseCount = styled.h5(
  () => `
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #ba8d5b;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      font-size: 12px;
    }
  `
);

export const CourseTitle = styled.h4(
  () => `
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #000000;
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }
  `
);
