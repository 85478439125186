import { authActions } from '@modules/auth/store'
import {
  EMAIL_DOESNT_MATCH,
  EMAIL_REQUIRED,
  isValidEmail,
  emailReg,
  LOGIN_TYPES,
  PROBLEM_WITH_EMAIL,
  RECOVERY_PASSWORD,
  MINIO_UPLOAD_ERROR,
  UNKNOWN_ERROR,
} from '@modules/new-auth/constants'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { api } from '@services/base.service'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CAPTCHA_ACTIONS } from '@constants/app'

const validationSchema = Yup.object().shape({
  email: Yup.string().email(PROBLEM_WITH_EMAIL).required(EMAIL_REQUIRED),
})

const initialValues = {
  email: '',
}

export const useInitialFormProp = ({ setInitialForm, setTime, setTimeIndex, setEmail }) => {
  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async (values) => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.sendOtp)

      api.setHeader('recaptcha', token)

      const recoveryPassword = {
        data: {
          login: values.email,
          actionType: RECOVERY_PASSWORD,
          loginType: LOGIN_TYPES.email,
        },
        callback: (res) => {
          const resErrId = res?.error?.errId
          setEmail(values.email)
          if (res.success) {
            setInitialForm(false)
            setTime(Date.now() + (res?.data?.leftTime || 0))
            setTimeIndex((prev) => prev + 1)
            api.setHeader('recaptcha', '')
            return
          }

          if (resErrId) {
            initialFormik.setErrors({ email: res?.error?.errMsg })
          }

          if (resErrId === UNKNOWN_ERROR) {
            dispatch(authActions.setForgetEmailModal(false))
            dispatch(authActions.setBlockModal(true))
          }

          if (resErrId === MINIO_UPLOAD_ERROR) {
            setInitialForm(false)
            return
          }
        },
      }

      dispatch(authActions.forgetPasswordPhone(recoveryPassword))
    } catch (error) {
      console.error('Iniitial form:', error)
    }
  }

  const initialFormik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const handleChangeEmail = useCallback((e) => {
    initialFormik.setFieldValue('email', e.target.value?.trim())
    initialFormik.setErrors({ email: '' })
  }, [])

  const handleBlurEmailInput = () => {
    const emailValue = initialFormik.values.email
    if (emailValue.length === 0) {
      initialFormik.setErrors({
        email: EMAIL_REQUIRED,
      })
    }

    if (emailValue.length > 0 && !emailValue.match(isValidEmail)) {
      initialFormik.setErrors({
        email: EMAIL_DOESNT_MATCH,
      })
    } else if (emailValue.length > 0 && !emailValue.match(emailReg)) {
      initialFormik.setErrors({
        email: PROBLEM_WITH_EMAIL,
      })
    }
  }

  return {
    initialFormik,
    handleChangeEmail,
    handleBlurEmailInput,
  }
}
