import styled from "styled-components";

export const MainButton = styled.button`
        background-color: ${props => props.danger ? '#fff' : '#BA8D5B'};
        border-radius: 100px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        white-space: nowrap;
        letter-spacing: -0.02em;
        color: ${props => props.danger ? '#000' : '#fff'};
        padding: 12px 32px 13px 31px;
        transition: all ease 0.3s;
        border: 1px solid ${props => props.danger ? '#ea4335' : 'transparent'}; 

        &:hover {
            border: 1px solid ${props => props.danger ? 'transparent' : '#BA8D5B'}; 
            background-color: ${props => props.danger ? '#ea4335' : '#fff'};
            color: ${props => props.danger ? '#fff' : '#000'};
        }

        &:active {
            background-color: ${props => props.danger ? '#ea4335' : '#A77946 '};
            color: '#fff';
        }

        &:disabled {
            opacity: 0.6;
        }
`