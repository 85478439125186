import { authActions } from '@modules/auth/store';
import { commonActions } from '@store/common';
import { useMediaQuery } from '@utils/useMediaQuery';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

export const useRegisterProps = () => {
  const { path, url } = useRouteMatch();
  const mqMaxWidth768 = useMediaQuery('(max-width: 768px)');
  const dispatch = useDispatch();
  const successRegister = useSelector(state => state.auth.successModal);

  useEffect(() => {
    dispatch(commonActions.fetchSupportLink());
    dispatch(authActions.setSuccessModal(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    path,
    url,
    mqMaxWidth768,
    successRegister
  };
};
