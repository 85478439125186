import { useMemo } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"

export const useCourseHomeworkProp = () => {
    const [isAvailable, setIsAvailable] = useState(false)
    const [sendedHomeWorks, setSendedHomeWorks] = useState(null)
    const homework = useSelector(state => state.courses.homework)

    const mySendedHomeWorks = useMemo(() => {
        return homework?.homeworkChats?.filter(item => !item?.stuff)
    },[homework])

    const availableHomework = useMemo(() => {
        return homework?.homeworkChats?.find(item => item?.status === 4)
    },[homework])

    const pendingResult = useMemo(() => {
        return homework?.homeworkChats?.find(item => item?.status === 2)
    },[homework])

    const cancelResult = useMemo(() => {
        return homework?.homeworkChats?.find(item => item?.status === 3)
    },[homework])

    const isShowTextArea = useMemo(() => {
        return (
            isAvailable || ((!pendingResult && !cancelResult) && (homework?.homeworkChats?.length < 1) && !availableHomework)
        )
    },[availableHomework, cancelResult, homework?.homeworkChats?.length, isAvailable, pendingResult])

    return {
        isAvailable,
        setIsAvailable,
        homework,
        mySendedHomeWorks,
        pendingResult,
        cancelResult,
        availableHomework,
        isShowTextArea,
        setSendedHomeWorks,
        sendedHomeWorks
    }
}
