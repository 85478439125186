import {ButtonInner, ButtonText, ButtonSpinner, StyledButton} from './loader-button.style'
import Loader from '@assets/img/button-spinner.svg'

export const LoaderButton = ({ text, loading=false, disabled, handleDeleteNote }) => {
    return (
        <StyledButton
            type={'submit'}
            disabled={disabled}
            onClick={handleDeleteNote}
        >
            {!loading ?
            <ButtonInner>
              <ButtonText>{text}</ButtonText>
            </ButtonInner> :
              <ButtonSpinner src={Loader} alt="Loader" />
            }
        </StyledButton>
    )
}
