import { hoc } from "@utils/hoc";
import {ErrorTitle, NoDataTitle, RatingWrapper} from "./rating.style";
import { RatingTop } from "./components/rating-top";
import { StudentsRatingTable } from "./components/table/RatingTable";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {ratingActions} from "@modules/rating/store/rating.slice";
import {commonActions} from "@store/common";

export const Rating = hoc(() => {}, () => {
  const dispatch = useDispatch()
  const filterObjects = useSelector(state =>  state.rating?.ratingFilter)
  const ranks = useSelector(state => state.rating?.ratings)
  const myCourses = useSelector(state => state.common.myCourses);
  const myCoursesLoading = useSelector(state => state.common.loading);

  useEffect(() => {
    dispatch(ratingActions.fetchRanks(filterObjects))
  }, [filterObjects]);

  useEffect(() => {
    dispatch(commonActions?.fetchMyCourses());
  }, []);

    return (
        <RatingWrapper>
          {(myCourses?.length === 0 && !myCoursesLoading) && <NoDataTitle>Sizda birorta ham kurs mavjud emasligi sabab reytingda mavjud emassiz</NoDataTitle>}
            <RatingTop />
          {ranks?.total ? (
            <StudentsRatingTable />
          ) : <ErrorTitle>Ma'lumot mavjud emas</ErrorTitle>}

        </RatingWrapper>
    )
})
