import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import {Scrollable} from "@styles/scroll.style";

export const RatingTable = styled(DataTable)`
  border-collapse: collapse;
  .rdt_TableCol,
  .rdt_TableCell
  /* .rdt_TableRow, */
  /* .rdt_TableRow  */ {
    border: 1px solid #e5e5e5;
    border-collapse: collapse;
  }

  .dCGDoy, .sc-izUTpy {
    font-size: 10px;
  }

  .rdt_TableBody {
    max-height: 55vh;
    overflow-y: auto;
  }

  .sc-bfjaSH {
    text-align: center;
    width: 100%;
    justify-content: center;

    @media only screen and (max-width: 700px) {
      font-size: 14px;
    }
  }

  .rdt_TableHeadRow {
    border: none;
  }

  .hWGUPT:not(:last-of-type) {
    border: none;
  }
`;


export const StudentImg = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
`

export const StudentCourseButton = styled.button`
  background: #99CCFF;
  font-size: 12px;
  height: 20px;
  margin: 0 auto;
  color: #1519A5;
  border: 1px solid #328EFF;
`

export const TableContent = styled.h4`
  text-align: center;
  width: 100%;
  font-size: 16px;

  @media only screen and (max-width: 700px) {
    font-size: 12px;
  }
`

export const TableLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TableWrapper = styled(Scrollable)`
  position: relative;
  height: calc(100vh - 295px);
  overflow-y: auto;

  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    gap: 8px;
    @media only screen and (max-width: 576px) {
      gap: 7px;
    }

    li {
      border: 1px solid #e1dfcf;
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #000000;

      @media only screen and (max-width: 576px) {
        width: 23px;
        height: 26px;
      }

      &.selected {
        border: 1px solid #ba8d5b;
        color: #ba8d5b;
      }
    }
  }

  .pagination__item {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .pagination__link {
    text-decoration: none;
    color: #333;
    font-size: 14px;
  }

  .pagination__active {
    background-color: #007bff;
    color: #fff;
  }

  .pagination__disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .previous {
    background: #f8f7ef;
    border-radius: 4px;
    width: 32px;
    height: 32px;

    &.disabled {
      opacity: 0.5;
    }
  }
`
