import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { GeneralLayout } from '@components/layouts/general-layout';
import { CourseInfo } from '@modules/certificates/components/course-info';
import { HomeworkTable } from '@modules/certificates/components/homework';
import { TechInfo } from '@modules/certificates/components/tech-info';
import { UserInfo } from '@modules/certificates/components/user-info';
import { hoc } from '@utils/hoc';
import { useMoreInfoProps } from './more-info.prop';
import { Container, MoreInfoLeft } from './more-info.style';
import { Loader } from '@components/Loader';

export const MoreInfo = hoc(useMoreInfoProps, ({ loading, mq650MaxWidth }) => {
  if (loading) return <Loader />;
  return (
    <GeneralLayout>
      <Container>
        <FlexWrapper
          gap='32px'
          mt='32px'
          justify='space-between'
          align='flex-start'
          direction={mq650MaxWidth ? 'column-reverse' : 'row'}
        >
          <MoreInfoLeft>
            <CourseInfo />

            <TechInfo />

            <HomeworkTable />
          </MoreInfoLeft>
          <UserInfo />
        </FlexWrapper>
      </Container>
    </GeneralLayout>
  );
});
