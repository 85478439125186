import { storageService } from './storage.service';
import { api } from './base.service';

class CommonService {
  constructor() {
    this.api = api;
    this.storageService = storageService;
  }

  getNotificationCount = () => this.api.get('/student/notification/count');

  recommendedCourse = () => this.api.get('/student/recommended-courses');

  myCourses = () => this.api.get('/student/my-courses');

  fetchProfile = () => this.api.get('/student/profile');

  recommendedPost = () => this.api.get('/student/recommended-post');

  getSupportLink = () => this.api.get('/student/support-links');

  lastVideo = data => {
    let lastVideoUrl = '/student/last-video';

    if (data) {
      lastVideoUrl += `?${
        data.moduleId ? `moduleId=${data.moduleId}` : ''
      }&courseId=${data.courseId}`;
    }

    return this.api.get(lastVideoUrl);
  };
}

export const commonService = new CommonService();
