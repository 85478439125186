import { SvgIcon } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useTextAreaProps } from './text-area.prop';
import {
  SendBtn,
  TextareaContainer,
  TextareaField,
  TextAreaWrapper
} from './text-area.style';
import {useMemo} from "react";

export const Textarea = hoc(
  useTextAreaProps,
  ({ _onChange, textarea, _textareaRef, onSubmit, handleSubmit,isLoading, ...rest }) => {

      const isDisabledButton = useMemo(() => {
          return isLoading || (textarea?.length > 1000) || (textarea?.trim()?.length === 0);
      }, [isLoading, textarea?.length]);
    return (
      <TextareaContainer>
        <FlexWrapper justify='space-between'>
          <TextAreaWrapper>
            <TextareaField
              rows={1}
              ref={_textareaRef}
              onChange={e => _onChange(e.target.value)}
              value={textarea}
              onKeyDown={handleSubmit}
              placeholder='Izoh qoldirish'
              {...rest}
            />
            <SendBtn disabled={isDisabledButton} onClick={onSubmit}>
              <SvgIcon
                iconName='send'
                size='20px'
                disabled={isDisabledButton}
                iconColor={textarea?.trim()?.length > 0 && '#BA8D5B'}
              />
            </SendBtn>
          </TextAreaWrapper>
        </FlexWrapper>
      </TextareaContainer>
    );
  }
);
