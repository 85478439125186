import { certificateActions } from '@modules/certificates/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

export const useHomeworkProps = () => {
  const dispatch = useDispatch();
  const { id, studentId } = useParams();
  const location = useLocation()
  const homeworks = useSelector(state => state.certificates?.homeworks);

  useEffect(() => {
    if(location.search.includes('lessonId') && location.search.split('=').at(-1)) {
      dispatch(
        certificateActions.fetchHomeworksByLessonId({
          id: location.search.split('=').at(-1),
          studentId
        })
      )

      return
    }
    if (id) {
      dispatch(
        certificateActions.fetchHomeworks({
          id,
          studentId
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    homeworks
  };
};
