import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useArchivedModuleProp } from './archived-module.prop';

import {
    CourseAccordionCard,
    CourseAccordionCardList,
    CourseAccordionCardListItem,
    CourseAccordionListItemToggle,
    AccordionCardWrapper,
    AccordionThemeCard,
    AccordionInner,
    AccordionCardItemContent,
    AccordionCardTitle,
    AccordionTheme,
    FlexWrapperForThemes,
    VideoStatus,
    VideoDuration,
    ModuleWrapper,
    NewBadge, FreeVideo, NoDataTitle
} from './archived-module.style';
import { secondsFormat } from '@utils/date-format-time';
import {
  getChapterLessonsVideoStatusImage,
  getChapterModuleVideoStatusImage,
  getCourseModuleVideoStatusImage
} from '../my-course-accordion/utils';

export const ArchivedModule = hoc(
  useArchivedModuleProp,
  ({
    toggle,
    clicked,
    smallClicked,
    courseInfo,
    openModalForUnpaidModules,
    handleClick,
    bgc,
    mqMaxWidth520,
    handlePaidChaptersClick,
    selectedModule,
    handleOpenCourseFreeModal
  }) => {
    return (
      <>
        {courseInfo?.length > 0 ? (
          <CourseAccordionCard none={bgc}>
            <CourseAccordionCardList>
              {courseInfo?.map((item, index) => (
                <CourseAccordionCardListItem
                  data-testid={'course_module'}
                  key={index}
                  className={(clicked === index || selectedModule === item?.id) && 'accordion-card__item--open'}
                  onClick={toggle.bind(null, item, index)}
                >
                  <FlexWrapper
                    justify='space-between'
                    align='flex-start'
                    onClick={toggle.bind(null, item, index)}
                  >
                    <FlexWrapper align='flex-start'>
                      <VideoStatus
                        src={getCourseModuleVideoStatusImage(item)}
                        alt='video-status'
                      />
                      {mqMaxWidth520 ? (
                        <FlexWrapper direction='column'>
                          <AccordionCardTitle>
                            {item?.position + '-modul' || ''}:{' '}
                            {item?.title || 'Kurs nomi'}
                          </AccordionCardTitle>
                          <AccordionCardTitle>
                            <span>
                              Mavzular soni: {item.chaptersCount} ta
                            </span>
                          </AccordionCardTitle>
                        </FlexWrapper>
                      ) : (
                        <AccordionCardTitle >
                          {item?.position + '-modul' || ''}:{' '}
                          {item?.title || 'Kurs nomi'}
                        </AccordionCardTitle>
                      )}
                    </FlexWrapper>
                    <FlexWrapper align='center' gap='10px'>
                      {!mqMaxWidth520 ? (
                        <AccordionCardTitle>
                          <span>Mavzular soni: {item.chaptersCount} ta</span>
                        </AccordionCardTitle>
                      ) : null}
                      <CourseAccordionListItemToggle
                        type='button'
                        className={'large btn--large'}
                        aria-label='toggle accordion'
                        onClick={toggle.bind(null, item, index)}
                      />
                    </FlexWrapper>
                  </FlexWrapper>

                  <ModuleWrapper>
                    {item?.chapters?.length === 0 && clicked === index ? (
                      <NoDataTitle>Ma’lumot mavjud emas</NoDataTitle>
                    ):
                      item?.chapters?.map((chapter, chapterIndex) => (
                        <AccordionCardWrapper
                          key={chapterIndex}
                          className={
                            (clicked === index || selectedModule === item?.id) && 'accordion-card__item--open'
                          }
                        >
                          <AccordionThemeCard
                            data-testid={'course_chapter'}
                            onClick={handlePaidChaptersClick.bind(
                              null,
                              item,
                              chapter,
                              `${chapterIndex}-${index}`
                            )}
                          >
                            <FlexWrapper align='flex-start'>
                              <VideoStatus
                                small
                                src={getChapterModuleVideoStatusImage(
                                  chapter,
                                  item
                                )}
                                alt='video-status'
                              />
                              {mqMaxWidth520 ? (
                                <FlexWrapper
                                  direction='column'
                                  align='flex-start'
                                >
                                  <AccordionCardTitle sub>
                                    {chapter?.position + '-mavzu' || ''}: &nbsp;
                                    {chapter?.name || 'Mavzu'}:{' '}
                                  </AccordionCardTitle>
                                  <AccordionCardTitle sub>
                                  <span className='accordion-card__item-lesson-count'>
                                    Darslar soni: {chapter?.lessonsCount} ta
                                  </span>
                                  </AccordionCardTitle>
                                </FlexWrapper>
                              ) : (
                                <AccordionCardTitle sub>
                                  {chapter?.position + '-mavzu' || ''}: &nbsp;
                                  {chapter?.name || 'Mavzu'}:{' '}
                                </AccordionCardTitle>
                              )}
                            </FlexWrapper>
                            <FlexWrapper gap='10px' align='center'>
                              {chapter?.isNew && <NewBadge>Yangi</NewBadge>}
                              {!mqMaxWidth520 && (
                                <AccordionCardTitle sub>
                                <span className='accordion-card__item-lesson-count'>
                                  Darslar soni: {chapter?.lessonsCount} ta
                                </span>
                                </AccordionCardTitle>
                              )}
                              <CourseAccordionListItemToggle
                                type='button'
                                className={
                                  smallClicked === `${chapterIndex}-${index}`
                                    ? 'btn--small large'
                                    : 'large'
                                }
                                aria-label='toggle accordion'
                                small
                                block={!item.isPaid}
                                onClick={openModalForUnpaidModules.bind(null, {
                                  item,
                                  index: `${chapterIndex}-${index}`,
                                  chapter
                                })}
                              />
                            </FlexWrapper>
                          </AccordionThemeCard>
                          <AccordionCardItemContent
                            className={
                              smallClicked === `${chapterIndex}-${index}`
                                ? 'accordion-card__item-content-wrapper--open'
                                : 'accordion-card__item-content--course'
                            }
                          >
                            {chapter?.lessons?.map((les, i) => (
                              <FlexWrapperForThemes key={i}>
                                <FlexWrapper
                                  align='flex-start'
                                  justify="space-between"
                                  data-testid={'course_lesson'}
                                  onClick={
                                    les?.isFree && !item.isPaid
                                      ? handleOpenCourseFreeModal.bind(null, {
                                        file: les?.video?.videoId,
                                        title: les?.title,
                                        index: parseInt(chapterIndex) + 1,
                                        video: les?.video
                                      })
                                      : undefined
                                  }
                                >
                                  <VideoStatus
                                    className={!les?.video?.videoId ? 'document' : ''}
                                    src={getChapterLessonsVideoStatusImage(
                                      les,
                                      item
                                    )}
                                    small
                                    alt='video-status'
                                  />
                                  <AccordionInner>
                                    <div
                                      onClick={
                                         item?.isPaid
                                          ? handleClick.bind(
                                            null,
                                            les,
                                            item.id,
                                            chapter,
                                            item
                                          )
                                          : null
                                      }
                                    >
                                      <AccordionTheme >
                                        {les.position + '-dars'}:{' '}
                                        {les?.title || 'Dars'}
                                      </AccordionTheme>
                                      <VideoDuration>
                                        {les?.video?.duration ? secondsFormat(les?.video?.duration) : ''}
                                      </VideoDuration>
                                    </div>
                                    <h3>{les.duration}</h3>
                                  </AccordionInner>
                                </FlexWrapper>
                                {
                                  les?.isFree && !item?.isPaid && (
                                    <FreeVideo onClick={handleOpenCourseFreeModal.bind(
                                      null,
                                      {
                                        file: les?.video?.videoId,
                                        title: les?.title,
                                        index: parseInt(chapterIndex) + 1,
                                        video: les?.video
                                      }
                                    )}>
                                      Bepul ko'rish
                                    </FreeVideo>
                                  )
                                }
                              </FlexWrapperForThemes>
                            ))}
                          </AccordionCardItemContent>
                        </AccordionCardWrapper>
                      ))
                    }
                  </ModuleWrapper>
                </CourseAccordionCardListItem>
              ))}
            </CourseAccordionCardList>
          </CourseAccordionCard>
        ) : (
          ''
        )}
      </>
    );
  }
);
