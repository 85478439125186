import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import { useCurrencyExchangeModalProps } from './currency-exchange-modal.props';
import {
  CurrencyExchangeDesc,
  CurrencyExchangeDollar,
  CurrencyExchangePrice,
  CurrencyExchangeUZS,
  CurrencyExchangeWrapper
} from './currency-exchange-modal.styles';

export const CurrencyExchangeModal = hoc(
  useCurrencyExchangeModalProps,
  ({ modal, setModal, currentRate, newRatePrice, }) => {
    return (
      <>
        <UnModal
          modal={modal}
          setModal={setModal}
          modalTitle='Ogohlantirish'
          modalWidth='538px'
        >
          <CurrencyExchangeWrapper>
            <CurrencyExchangeDesc>
              Kurs va uning modullari narxida o’zgarish bor. Iltimos, qaytadan
              urinib ko’ring
            </CurrencyExchangeDesc>
            <FlexWrapper align='center'>
              <CurrencyExchangePrice>
                <FlexWrapper direction='column'>
                  <CurrencyExchangeDollar>$1</CurrencyExchangeDollar>
                  <CurrencyExchangeUZS>{currentRate} UZS</CurrencyExchangeUZS>
                </FlexWrapper>
              </CurrencyExchangePrice>
              <CurrencyExchangePrice>
                <CurrencyExchangeDollar>$1</CurrencyExchangeDollar>
                <CurrencyExchangeUZS>{newRatePrice} UZS</CurrencyExchangeUZS>
              </CurrencyExchangePrice>
            </FlexWrapper>
          </CurrencyExchangeWrapper>
        </UnModal>
      </>
    );
  }
);
