import { MainButton } from '@components/atoms/main-button/main-button.style';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useHomeworkMarkProp } from './homework-mark.prop';
import {
  HomeworkFeedback,
  HomeworkMarkSubtitle,
  HomeworkMarkTitle,
  HomeworkMarkWrapper
} from './homework-mark.style';
import { CountOfFiles } from '../my-homwork/my-homwork.style';
import { dateFormatForHmw } from '@utils/date-format';
import { HomeworkCard } from '../homework-card';

export const HomeworkMark = hoc(
  useHomeworkMarkProp,
  ({ item, homework, setIsAvailable, isAvailable, pending }) => {
    const isShowButton =
      item?.status === 3 &&
      !pending &&
      !isAvailable &&
      homework?.homeworkChats &&
      homework?.homeworkChats?.[homework?.homeworkChats.length - 1]?.id ===
        item?.id;
    return (
      <HomeworkMarkWrapper>
        <FlexWrapper justify='space-between' align='center' mb='12px'>
          <HomeworkMarkTitle>O‘qituvchi izohi</HomeworkMarkTitle>

          <HomeworkMarkSubtitle fail={item?.status === 3}>
            {item?.status === 3
              ? 'Vazifa qabul qilinmadi'
              : 'Vazifa qabul qilindi'}
          </HomeworkMarkSubtitle>
        </FlexWrapper>

        <HomeworkFeedback>{item?.desc}</HomeworkFeedback>

        {item?.files?.length > 0 && (
          <FlexWrapper
            wrap
            mt={'25px'}
            justify='flex-start'
            gap={'24px'}
            className={'homework-files'}
          >
            {item?.files?.map((item, index) => (
              <HomeworkCard
                key={index}
                title={item?.origname}
                size={item?.size}
                link={item?.url}
              />
            ))}
          </FlexWrapper>
        )}

        <FlexWrapper justify='flex-end' pas='2px'>
          <CountOfFiles>
            Tekshirdi: {item?.userType === 2 ? "O'qituvchi" : item.userType === 3 ? 'Assistant' : 'student'}{' '}
            {item?.staff?.firstName} {item?.staff?.lastName}
            {'. '}
            {dateFormatForHmw(item?.createdAt)}
          </CountOfFiles>
        </FlexWrapper>

        {isShowButton && (
          <FlexWrapper justify='center'>
            <MainButton onClick={setIsAvailable} className='re-send'>
              Qayta topshirish
            </MainButton>
          </FlexWrapper>
        )}
      </HomeworkMarkWrapper>
    );
  }
);
