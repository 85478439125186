import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { UnModal } from '@components/modal';
import { hoc } from '@utils/hoc';
import { AppealBtn } from '../appealComment/appealComment.style';
import { useCancelAppealProps } from './cancel-appeal.prop';
import { CancelAppealConfirmText } from './cancel-appeal.style';

export const CancelAppeal = hoc(
  useCancelAppealProps,
  ({
    cancelAppealModal,
    setCancelAppealModal,
    handleUnMountCancelAppealModal,
    handleSubmit
  }) => (
    <UnModal
      modal={cancelAppealModal}
      setModal={setCancelAppealModal}
      unMountModal={handleUnMountCancelAppealModal}
    >
      <CancelAppealConfirmText>
        Siz shikoyat qilishdan voz kechdingizmi?
      </CancelAppealConfirmText>

      <FlexWrapper justify='space-between' gap='20px'>
        <AppealBtn onClick={handleUnMountCancelAppealModal} cancel>
          Yo‘q
        </AppealBtn>
        <AppealBtn onClick={handleSubmit}>Ha</AppealBtn>
      </FlexWrapper>
    </UnModal>
  )
);
