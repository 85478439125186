import { RouteLinks } from '@constants/route';
import { SESSION_TERMINATED } from '@modules/new-auth/constants';
import { storageService } from '@services/storage.service';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

export const useLoginProp = () => {
  const token = storageService.getItem('accessToken');
  const profile = useSelector(state => state.common.profile);
  const [sessionState, setSessionState] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const closeModal = () => {
    history.push(RouteLinks.auth.login);
    setSessionState(false);
  };


  useEffect(() => {
    if (location?.search.includes(SESSION_TERMINATED)) {
      setSessionState(true);
    }
  }, [location?.search]);


  return {
    token,
    profile,
    sessionState,
    setSessionState,
    closeModal
  };
};
