import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addNotification } from '@components/notifications'
import { authActions } from '@modules/auth/store'
import {
  LOGIN_TYPES,
  MIN_NAME,
  NAME_REQUIRED,
  PASSWORD,
  passwordRgp,
  PASSWORD_NOT_MATCH,
  PASSWORD_REQUIREMENT,
  PHONE_REQUIRED,
  REQUIRED_PASSWORD,
  REQUIRED_PHONE_NUMBER,
  TEXT,
  alphaNumericLettersReg,
  invalid,
  LOGIN_ALREADY_EXISTS,
  newPasswordReg,
  passwordRequirementsRegs,
} from '@modules/new-auth/constants'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { api } from '@services/base.service'
import { CAPTCHA_ACTIONS } from '@constants/app'

const MANY_REQUESTS = 157

const initialValues = {
  firstName: '',
  phone: '',
  password: '',
  password_check: '',
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(3, MIN_NAME).required(MIN_NAME),
  phone: Yup.string().min(9, PHONE_REQUIRED).required(PHONE_REQUIRED),
  password: Yup.string().matches(passwordRgp, PASSWORD_REQUIREMENT).required(PASSWORD_REQUIREMENT),
  password_check: Yup.string()
    .oneOf([Yup.ref('password'), null], PASSWORD_NOT_MATCH)
    .required(PASSWORD_NOT_MATCH),
})

export const usePhoneFormProp = () => {
  const [inputType, setInputType] = useState(PASSWORD)
  const [checkInputType, setCheckInputType] = useState(PASSWORD)
  const [hasChecker, setHasChecker] = useState(false)
  const dispatch = useDispatch()
  const confirmModal = useSelector((state) => state.auth.confirmPhoneModal)
  const supportLink = useSelector((state) => state.common.supportLink?.[0]?.link)
  const phoneNotificationModal = useSelector((state) => state.auth.phoneNotificationModal)
  const isCaptchaError = useSelector((state) => state.auth?.isShowCaptchaError)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async (values) => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.signUp)

      api.setHeader('recaptcha', token)

      const registerInfo = {
        data: {
          login: `998${values.phone}`,
          password: values.password,
          loginType: LOGIN_TYPES.phone,
          firstName: values.firstName,
        },
        callback: async (res) => {
          if (!res.success && res.error.errId === LOGIN_ALREADY_EXISTS) {
            formik.setErrors({
              phone: res.error.errMsg,
            })
            return
          }

          if (res.success) {
            await dispatch(
              authActions.setConfirmPhone({
                phoneNumber: `998${values.phone}`,
                leftTime: res?.data?.leftTime,
              }),
            )
            api.setHeader('recaptcha', '')
            await dispatch(authActions.setInitialPhone(values.phone))
            await dispatch(authActions.setInitialPassword(values.password))
            await dispatch(authActions.setConfirmPhoneModal(true))
          }

          if (res.error?.errId === MANY_REQUESTS) {
            await dispatch(authActions.setPhoneNotificationModal(true))
          }

          if (res.error?.errId) {
            formik.setErrors({
              phone: res.error.errMsg,
              firstName: res.error.errMsg,
              password: res.error.errMsg,
              password_check: res.err.errMsg, // I corrected the typo here
            })
            dispatch(authActions.setShowCaptchaError(true))
          }
        },
      }
      await dispatch(authActions.register(registerInfo))
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: handleSubmit,
    validateOnBlur: true,
  })

  const handleChangeNewPasswordType = () => {
    setInputType(inputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleChangeCheckInputType = () => {
    setCheckInputType(checkInputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleBlurUserName = () => {
    const firstName = formik.values.firstName
    if (firstName.length === 0) {
      formik.setErrors({
        ...formik.errors,
        firstName: NAME_REQUIRED,
      })
    }

    if (firstName.length > 0 && firstName.length < 3) {
      formik.setErrors({
        ...formik.errors,
        firstName: MIN_NAME,
      })
    }
  }

  const handleBlurPasswordCheck = () => {
    const formikValues = formik.values
    if (formikValues.password !== formikValues.password_check || formikValues.password.length === 0) {
      formik.setErrors({
        ...formik.errors,
        password_check: PASSWORD_NOT_MATCH,
      })
    }
  }

  const handleBlurPhoneNumber = () => {
    const phone = formik.values.phone
    if (phone.length === 0) {
      formik.setErrors({
        ...formik.errors,
        phone: REQUIRED_PHONE_NUMBER,
      })
    }
  }

  const handleBlurPassword = () => {
    setHasChecker(false)
    const formikValues = formik.values

    if (formikValues.password.length === 0) {
      formik.setErrors({
        ...formik.errors,
        password: REQUIRED_PASSWORD,
      })
      return
    }

    if (formik.values.password !== formik.values.password_check) {
      formik.setErrors({
        ...formik.errors,
        password_check: PASSWORD_NOT_MATCH,
      })
    } else {
      formik.setErrors({
        ...formik.errors,
        password_check: '',
      })
    }
    if (!passwordRequirementsRegs.every((req) => req.test(formikValues.password))) {
      formik.setErrors({
        ...formik.errors,
        password: PASSWORD_REQUIREMENT,
      })
    }
  }

  const handleChangeUserName = (e) => {
    formik.setErrors({
      ...formik.errors,
      firstName: '',
    })
    if (alphaNumericLettersReg.test(e.target.value)) {
      formik.setErrors({ firstName: invalid })
    }
    const encodedValue = e.target.value.replace(alphaNumericLettersReg, '')

    formik.setFieldValue('firstName', encodedValue)
  }

  const handleChangePhoneNumber = (e) => {
    formik.setFieldValue('phone', e.target.value.trim().replace(/ /g, ''))
    formik.setErrors({
      ...formik.errors,
      phone: '',
    })
  }

  const handleChangePassword = (e) => {
    formik.handleChange(e)
    const passwordValue = e.target.value
    if (newPasswordReg.test(passwordValue)) {
      formik.setErrors({ password: PASSWORD_REQUIREMENT })
    }
    formik.setErrors({
      ...formik.errors,
      password: '',
    })
  }

  const handleFocusPassword = () => {
    setHasChecker(true)
    formik.setErrors({
      ...formik.errors,
      password: '',
    })
  }

  const handleChangePasswordCheck = (e) => {
    formik.handleChange(e)
    const value = e.target.value
    if (value !== formik.values.password) {
      formik.setErrors({
        ...formik.errors,
        password_check: PASSWORD_NOT_MATCH,
      })
    } else {
      formik.setErrors({
        ...formik.errors,
        password_check: '',
      })
    }
  }

  useEffect(() => {
    if (phoneNotificationModal) {
      addNotification({
        title: 'Urinishlar soni tugadi!',
        description: 'Iltimos, 10 daqiqadan keyin qayta urinib ko’ring.',
        options: {
          type: 'error',
          autoClose: true,
        },
      })
      dispatch(authActions.setPhoneNotificationModal(false))
    }
  }, [phoneNotificationModal])

  return {
    formik,
    inputType,
    checkInputType,
    handleChangeNewPasswordType,
    handleChangeCheckInputType,
    handleBlurUserName,
    handleChangeUserName,
    handleBlurPhoneNumber,
    handleChangePhoneNumber,
    handleBlurPassword,
    handleChangePassword,
    hasChecker,
    handleFocusPassword,
    handleBlurPasswordCheck,
    handleChangePasswordCheck,
    confirmModal,
    supportLink,
    isCaptchaError,
  }
}
