import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useCourseCardProp } from './course-card.prop';
import {
  CourseCardCategory,
  CourseCardIcon,
  CourseCardImg,
  CourseCardTop,
  CourseCardWrapper,
  CourseCount,
  CourseTitle,
  SoonTitle
} from './course-card.style';
import PlayBtnImg from '@assets/img/play-nout.png';
import { config } from '@config/config';

export const CourseCard = hoc(
  useCourseCardProp,
  ({
    sidebarState,
    category,
    moduleCount,
    moduleName,
    color,
    bgColor,
    available,
    handleClick,
    link,
    img,
    closeLink,
    textColor,
    imgColor,
    courseType,
    mqMaxWidth724,
    mqMaxWidth1024,
    mqMaxWidth1308,
    mqMaxWidth1561,
    mqMinWidth1778,
    allCardQueries,
    item,
    ...prop
  }) => {
    let CourseCardImgSrc = config.imgApiUrl
    if(img?.[0] === '/') {
      CourseCardImgSrc += img
    } else {
      CourseCardImgSrc += `/${img}`
    }

    return (
      <CourseCardWrapper
        to={link}
        onClick={handleClick.bind(null, link)}
        {...prop}
      >
        <CourseCardTop available={available} className='course-card-top'>
          <CourseCardImg src={CourseCardImgSrc} imgColor={imgColor} />
          {available && <SoonTitle>Tez kunda</SoonTitle>}
        </CourseCardTop>

        <FlexWrapper
          align={allCardQueries || sidebarState ? 'center' : 'start'}
          justify='space-between'
          direction={allCardQueries || sidebarState ? 'row' : 'column'}
          mb='12px'
          wrap='true'
        >
          <CourseCardCategory
            bgColor={color}
            textColor={textColor}
            marginBottom={allCardQueries || sidebarState}
            maxWidth={allCardQueries || sidebarState}
          >
            {category}
          </CourseCardCategory>

          {!closeLink && (
            <FlexWrapper align='center' ml={sidebarState ? '10px' : '0'}>
              <CourseCardIcon src={PlayBtnImg} />
              <CourseCount>{moduleCount} ta dars</CourseCount>
            </FlexWrapper>
          )}
        </FlexWrapper>

        <CourseTitle>{moduleName}</CourseTitle>
      </CourseCardWrapper>
    );
  }
);
