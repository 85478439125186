import { FlexWrapper } from '@components/layouts/flex-wrapper';
import {
  ChangePage,
  HaveProfile
} from '@modules/new-auth/routes/login/login.style';
import { hoc } from '@utils/hoc';
import { Route, Switch } from 'react-router-dom';
import { EmailForm } from './EmailForm';
import { useLoginRightSideProp } from './login-rightside.prop';
import {
  LoginTitle,
  NestedLink,
  RightSide,
  RightSideInner
} from './login-rightside.style';
import { PhoneForm } from './PhoneForm';
import { ForgetPhone } from './PhoneForm/ForgetPhone';
import oferta from '../../../../assets/img/oferta.pdf'

export const LoginRightSide = hoc(
  useLoginRightSideProp,
  ({ url, path, forgetPhoneModal }) => {
    return (
      <RightSide>
        <RightSideInner>
          <LoginTitle>Kirish</LoginTitle>

          <FlexWrapper mb='28px'>
            <NestedLink exact to={url} activeClassName='selected'>
              Telefon raqam orqali
            </NestedLink>
            <NestedLink to={`${url}/by-email`} activeClassName='selected'>
              Elektron pochta orqali
            </NestedLink>
          </FlexWrapper>

          <Switch>
            <Route exact path={path} component={PhoneForm} />
            <Route exact path={`${path}/by-email`} component={EmailForm} />
          </Switch>

          <FlexWrapper gap='12px' justify='center' align='center'>
            <HaveProfile>Profilingiz yo‘qmi?</HaveProfile>

            <ChangePage to='/auth/register'>Ro‘yxatdan o‘tish</ChangePage>
          </FlexWrapper>
        </RightSideInner>

        {forgetPhoneModal && <ForgetPhone />}
      </RightSide>
    );
  }
);
