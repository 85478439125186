import {useEffect} from 'react';
import tippy from 'tippy.js';

export const useTooltips = () => {
  useEffect(() => {
    tippy('[data-tippy-content]', {
      animation: 'fade',
      delay: [700, 0],
      arrow: true,
      theme: 'light',
    });
  }, [])
}
