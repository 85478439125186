import {myRatingActions} from "@modules/my-ratings/store/my-ratings.slice";
import {tryCatchSagaFactory} from "@utils/try-catch-saga";
import { all } from 'redux-saga/effects';
import {fetchSagaFactory} from "@utils/fetch-saga-factory";
import {myRatingService} from "@modules/my-ratings/api";

export const tryCatchSaga = tryCatchSagaFactory(myRatingActions)

export function* myRatingsRootSaga() {
  yield all([
    fetchSagaFactory({
      pattern: 'fetchMyRatings',
      actions: myRatingActions,
      api: {
        method: myRatingService.getMyRatings,
        callback: res => res?.data
      }
    })
  ])
}
