import VideosSkeleton from '@modules/videos/routes/videos/videos-skeleton';
import { PaginationWrapper, VideoCardList } from '@modules/videos/routes/videos/videos.style';
import { hoc } from '@utils/hoc';
import { VideoCard } from '../video-card.component';
import { useAllVideosProps } from './all-videos.prop';
import { Wrapper, WrapperInner } from './all-videos.style';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import ReactPaginate from 'react-paginate';
import { ReactComponent as LeftPagination } from '@assets/img/left-pagination.svg';
import { ReactComponent as RightPagination } from '@assets/img/right-pagination.svg';

export const AllVideos = hoc(
  useAllVideosProps,
  ({
    recentlyVideos,
    bookmarkVideos,
    mq600MaxWidth,
    bodyRef,
    location,
    loading,
    activePage,
    handleClickPagination
  }) => {
    return (
      <Wrapper>
        <WrapperInner>
          <VideoCardList>
            {location.pathname === '/all-saved-videos'
              ? (loading ? <VideosSkeleton /> : bookmarkVideos?.data?.length > 0 &&
                bookmarkVideos?.data?.map(item => (
                  <VideoCard
                    key={item?.id}
                    item={item}
                    courseName={item?.courseName}
                    moduleName={item?.moduleName}
                    lessonName={item?.lessonName}
                    courseProgress={item?.progress}
                    bookmarkId={item?.id}
                    chapterName={item?.chapterName}
                  />)
                ))
              : recentlyVideos?.map(item => (
                <VideoCard
                  key={item?.id}
                  item={item}
                  courseName={item?.courseName}
                  moduleName={item?.moduleName}
                  lessonName={item?.lessonName}
                  courseProgress={item?.progress}
                  chapterName={item?.chapterName}
                />
              ))}
          </VideoCardList>

            <PaginationWrapper>
              <FlexWrapper ref={bodyRef} mt='34px' justify='flex-end'>
                <ReactPaginate
                  className='pagination'
                  breakLabel='...'
                  nextLabel={<RightPagination />}
                  pageRangeDisplayed={mq600MaxWidth ? 2 : 5}
                  onPageChange={handleClickPagination}
                  previousLabel={<LeftPagination />}
                  forcePage={activePage - 1}
                  renderOnZeroPageCount={null}
                  pageCount={mq600MaxWidth ? bookmarkVideos?.total : Math.ceil(bookmarkVideos?.total / 100)}
                  marginPagesDisplayed={mq600MaxWidth ? 2 : 4}
                />
              </FlexWrapper>
            </PaginationWrapper>

        </WrapperInner>
      </Wrapper>
    );
  }
);
