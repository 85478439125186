import { SvgIcon } from '@components/atoms';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { CourseNotesItem } from './course-notes-item';
import { useCourseNotesProp } from './course-notes.prop';
import NoNotesImage from '@assets/img/no-notes.png';

import {
  CourseNotesBottom,
  CourseNotesBtn,
  CourseNotesCount,
  CourseNotesEditExample,
  CourseNotesEditExampleLeft,
  CourseNotesEditText,
  CourseNotesEditTitle,
  CourseNotesForm,
  CourseNotesNoItemImg,
  CourseNotesNotItemTitle,
  CourseNotesWrapper,
  DeleteBtns,
  DeleteTitle
} from './course-notes.style';
import { UnModal } from '@components/modal';
import { Textarea } from './text-area';
import { NotesHeaderSkeleton, NotesSkeleton } from './course-notes-skeleton';
import {LoaderButton} from "@components/loader-button";

export const CourseNotes = hoc(
  useCourseNotesProp,
  ({
    handleClickFormTime,
    onFocusTextarea,
    modal,
    setModal,
    handleDeleteNoteItem,
    handleEditNoteItem,
    deleteNote,
    isAvailableEdit,
    cancelEditNotes,
    time,
    textareaRef,
    activeLesson,
    notes,
    handleAddNote,
    handleTimeClick,
    videoTime,
    notesLoading,
    isLoading
  }) => {
    return (
      <>
        <CourseNotesWrapper>
          <FlexWrapper justify='space-between' mb='25px'>
            {notesLoading
              ? <NotesHeaderSkeleton />
              : (!!notes?.length && (
                <CourseNotesCount>
                  {notes?.length} ta qayd mavjud
                </CourseNotesCount>
              ))}
          </FlexWrapper>
          {notesLoading
            ? <NotesSkeleton />
            : notes?.length > 0 ? (
              <>
                {notes?.map((item, index) => (
                  <CourseNotesItem
                    setModal={setModal}
                    handleDeleteNoteItem={handleDeleteNoteItem}
                    id={item?.id}
                    handleEditNoteItem={handleEditNoteItem}
                    key={item?.id}
                    note={item?.note}
                    title={
                      item?.lesson?.position + '-dars: ' + item?.lesson?.title
                    }
                    time={item?.videoTime}
                    onTimeClick={handleTimeClick.bind(null, {
                      lesson: item?.lesson,
                      time: item.videoTime
                    })}
                  />
                ))}
              </>
            ) : (
              <FlexWrapper
                justify='center'
                direction='column'
                align='center'
                className='svg-wrapper'
                mb='24px'
              >
                <CourseNotesNoItemImg src={NoNotesImage} />
                <CourseNotesNotItemTitle>
                  Hozirda sizda hech qanday qaydlar mavjud emas.
                </CourseNotesNotItemTitle>
              </FlexWrapper>
            )}

          {isAvailableEdit && (
            <CourseNotesBottom>
              <CourseNotesEditExample>
                <FlexWrapper>
                  <CourseNotesEditExampleLeft>
                    <SvgIcon iconName='edit-notes' />
                  </CourseNotesEditExampleLeft>

                  <FlexWrapper direction='column' ml='8px'>
                    <CourseNotesEditTitle>
                      O‘zgartirilayotgan matn
                    </CourseNotesEditTitle>

                    <CourseNotesEditText>
                      {isAvailableEdit?.text}
                    </CourseNotesEditText>
                  </FlexWrapper>
                </FlexWrapper>

                <CourseNotesBtn onClick={cancelEditNotes}>
                  <SvgIcon iconName='cancel-edit' size='27px' />
                </CourseNotesBtn>
              </CourseNotesEditExample>
            </CourseNotesBottom>
          )}

          <CourseNotesForm onSubmit={handleClickFormTime}>
            <Textarea
              onFocus={onFocusTextarea}
              textareaRef={textareaRef}
              time={time}
              videoTime={videoTime}
              submit={handleAddNote}
            />
          </CourseNotesForm>
        </CourseNotesWrapper>

        {modal && (
          <UnModal modal={modal} setModal={setModal}>
            <DeleteTitle>
              Siz haqiqatdan ham bu qaydni o‘chirishni xohlaysizmi?
            </DeleteTitle>

            <FlexWrapper justify='center'>
              <DeleteBtns onClick={() => setModal(false)}>Yo‘q</DeleteBtns>
              <LoaderButton
                  text={'Ha'}
                  loading={isLoading}
                  disabled={isLoading}
                  handleDeleteNote={deleteNote} />
            </FlexWrapper>
          </UnModal>
        )}
      </>
    );
  }
);
