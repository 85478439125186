import styled from 'styled-components';
import PhoneImg from '@assets/img/phone.svg';

export const ErrorTitle = styled.h4(
  () => `
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;
` , 
  ({ theme: { down } }) => `
      ${down('460px')} {
          font-size : 14px;
          margin-bottom: 12px;
      }
  `
);

export const ErrorText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
  margin-bottom: 32px;
`;

export const Button = styled.button(
  (props) => `
      width: 100%;
      display: block;
      text-align: center;
      background: ${props.danger ? '#EA4335' : '#ba8d5b'};
      border-radius: 100px;
      padding: ${props.danger ? '12px 35px' : '12px 24px'};  
      box-sizing: border-box;
      border: none;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #ffffff;
      margin: ${props.max ? '0 auto' : 'initial'};
      margin-bottom: 24px;
      margin-left: ${props.danger ? '10px' : '0'};
      cursor: pointer;
      max-width: ${props.max ? '308px' : 'initial'};
    ` , 
      ({ theme: { down } }) => `
      ${down('768px')} {
          width: 155px;
          height: 44px;
          padding: 6px 22px;
      }
  `,
    ({ theme: { down } }) => `
    ${down('460px')} {
        font-size : 14px;
        // line-height: 16px;
        padding: 9px 16px;
        margin-bottom: 5px;
        width: 140px;
        height: 36px; 
    }
 `
);

export const PhoneWrapper = styled.div`
      width: 229px;
      display: flex;
      flex-direction: column;
      margin: auto;
      margin-bottom: 10px;
      margin-top: 4px;
`;

export const Phone = styled.a(
  () => `
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #000000;
      padding-left: 28px;
      display: inline-block;
      position: relative;
      margin: 0 auto;
      border: 1px solid #BA8D5B;
      border-radius: 8px;
      padding: 10px 16px 10px 48px;
      width: 100%;

      &::before {
        content: '';
        width: 18.7px;
        height: 18.7px;
        position: absolute;
        left: 19px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
        background-image: url(${PhoneImg});
      }

      &:first-child {
        margin-bottom: 12px;
      }
    `
);

export const SvgIconWrapper = styled.div(
  () => `
     display: flex;
     justify-content: center;
     margin-bottom: 35px;
  ` ,
  ({ theme: { down } }) => `
  ${down('360px')} {
      margin-bottom: 27px;
  }
`
);
