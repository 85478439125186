import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useHomeworkTextareaProp } from './homework-textarea.props';
import 'react-circular-progressbar/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as IconScript } from '@assets/img/icon-script.svg';
import { ReactComponent as IconSend } from '@assets/img/icon-send.svg';

import {
  Homeworks,
  HomeworkWrapper,
  ScriptInput,
  ScriptLabel,
  SendBtn,
  SliderBtn,
  TextareaContainer,
  TextareaField,
  CustomSlider
} from './homework-textarea.style';
import { HomeworkFiles } from '../homework-file';

export const HomeworkTextarea = hoc(
  useHomeworkTextareaProp,
  ({
    _onChange,
    textarea,
    _textareaRef,
    handleSelectFile,
    files,
    handleRemoveFile,
    file,
    setFile,
    fileRef,
    settings,
    sliderRef,
    handlePrev,
    handleNext,
    sidebarState,
    handleSubmit,
    setAllFiles,
    allFiles,
    mqMaxWidth768,
    isDisabledIcon,
    isAvailableSend,
    sendHomeworkLoading,
  }) => {
    return (
      <>
        {files.length > 0 && (
          <HomeworkWrapper
            className={(sidebarState || files.length > 3) && 'large'}
            files={files}
          >
            <Homeworks>
              {files.length > 3 && !mqMaxWidth768 && (
                <SliderBtn onClick={handlePrev}>
                  <svg viewBox='0 0 8 14' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M7.0013 1.66797L1.66797 7.0013L7.0013 12.3346'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </SliderBtn>
              )}

              {mqMaxWidth768 ? (
                <CustomSlider>
                  {files?.map((item, index) => (
                    <HomeworkFiles
                      key={index}
                      index={index}
                      item={item}
                      handleCancel={handleRemoveFile}
                      file={file}
                      setFile={setFile}
                      setAllFiles={setAllFiles}
                      allFiles={allFiles}
                    />
                  ))}
                </CustomSlider>
              ) : (
                <Slider ref={sliderRef} {...settings}>
                  {files?.map((item, index) => (
                    <HomeworkFiles
                      key={index}
                      item={item}
                      index={index}
                      handleCancel={handleRemoveFile}
                      file={file}
                      setFile={setFile}
                      setAllFiles={setAllFiles}
                      allFiles={allFiles}
                    />
                  ))}
                </Slider>
              )}

              {files.length > 3 && !mqMaxWidth768 && (
                <SliderBtn rotate onClick={handleNext}>
                  <svg
                    viewBox='0 0 8 14'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.0013 1.66797L1.66797 7.0013L7.0013 12.3346'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </SliderBtn>
              )}
            </Homeworks>
          </HomeworkWrapper>
        )}

        <TextareaContainer>
          <FlexWrapper align='center' justify='space-between'>
            <TextareaField
              rows={1}
              ref={_textareaRef}
              placeholder='Fayl biriktiring yoki izoh qoldiring'
              value={textarea}
              onChange={e => _onChange(e.target.value)}
            />

            <FlexWrapper>
              <ScriptLabel htmlFor='file'>
                <IconScript fill='#a6a6a6' />
                <ScriptInput
                  onChange={handleSelectFile}
                  id='file'
                  type='file'
                  ref={fileRef}
                />
              </ScriptLabel>
              <SendBtn onClick={handleSubmit} disabled={!isDisabledIcon || isAvailableSend || sendHomeworkLoading}>
                <IconSend fill={(isDisabledIcon && !isAvailableSend) ? '#ba8d5b' : '#dadada'} />
              </SendBtn>
            </FlexWrapper>
          </FlexWrapper>
        </TextareaContainer>
      </>
    );
  }
);
