import styled from 'styled-components';
import {Link} from 'react-router-dom'

export const MyRatingsWrapper = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;

  .rc-collapse-header-text {
    @media only screen and (max-width: 700px) {
      font-size: 14px;
    }
  }

  .rdt_TableCell {
    padding: 10px;
    @media only screen and (max-width: 700px) {
      font-size: 14px;
      padding: 10px;
    }
  }

  .rc-collapse {
    background-color: #fff !important;
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .rc-collapse-header {
      color: #000;
      position: relative;
      border-bottom: 1px solid #d9d9d9;

      .rc-collapse-expand-icon {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 26px;
      }
    }
  }
`;

export const MyRatingWrapperTitle = styled.h3`
  font-size: 24px;
  color: #b98b59;
  margin-bottom: 20px;

  @media only screen and (max-width: 700px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

export const MyRatingsAccordionWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 50px;
  }
`;

export const CourseTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 15px;

  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

export const HomeworkStatus = styled.h4`
  color: ${props => props.completed ? 'green' : '#000'};
  display: block;
  margin: 0 auto;
`

export const CompletedHomework = styled(Link)`
  display: block;
  margin: 0 auto;
  color: #000;
`

export const CompletedHomeworkTitle = styled(Link)`
  color: #000;
`

export const NoData = styled.h4`
  margin: 30px auto;
  text-align: center;
`

export const AllScore = styled.h4`
  font-size: 22px;
  margin-top: 20px;
  color: #000000;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const NoDataTitle = styled.h4`
  text-align: center;
  color: #ba8d5b;
  font-size: 22px;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 15px;
  }
`
