import { createSlice } from '@reduxjs/toolkit';

const name = 'settings';
const initialState = {
  loading: false,
  error: null,
  forgetPassword: false,
  changePhoneNumber: false,
  userUpdate: false,
  editEmail: false,
  blockModal: false
};

export const { reducer: settingsReducer, actions: settingsActions } =
  createSlice({
    name,
    initialState,
    reducers: {
      setLoading(state, action) {
        state.loading = action.payload;
      },
      setError(state, action) {
        state.error = action.payload;
      },
      setForgetPassword(state, action) {
        state.forgetPassword = action.payload;
      },
      setChangePhoneNumber(state, action) {
        state.changePhoneNumber = action.payload;
      },
      setUserUpdate(state, action) {
        state.userUpdate = action.payload;
      },
      changePhoneNumber(state) {
        state.loading = true;
      },
      sentOtp(state) {
        state.loading = true;
      },
      changePassword(state) {
        state.loading = true;
      },
      uploadImg(state) {
        state.loading = true;
      },
      updateUserInfo(state) {
        state.loading = true;
      },
      refreshToken(state) {
        state.loading = true;
      },
      setEditEmail(state, action) {
        state.editEmail = action.payload;
      },
      changeLogin(state) {
        state.loading = true
      },
      setBlockModal(state, action) {
        state.blockModal = action.payload
      }
    }
  });
