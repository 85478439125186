import styled from 'styled-components';

export const SuccessModalTitle = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  max-width: 374px;
  margin-bottom: 16px;
`;

export const SuccessModalSubtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
`;

export const Button = styled.button`
  width: 100%;
  display: block;
  text-align: center;
  background: #ba8d5b;
  border-radius: 100px;
  padding: 12px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: '0 auto';
  margin-bottom: 24px;
  cursor: pointer;
  max-width: '308px';
  border: 1px solid transparent;

  &:hover {
    background-color: #fff;
    border: 1px solid #ba8d5b;
    color: #000;

    span {
      color: #000;
    }
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    display: inline-block;
    margin-left: 16px;
    transform: translateY(0);
  }
`;

export const SuccessImg = styled.img`
  width: 60px;
  height: 60px;
`