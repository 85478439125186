import styled from "styled-components";
import ArrowBottom from '@assets/img/bottom-arrow.svg'

export const SelectWrapper = styled.div`
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 100px;
    width: 100%;
    padding: 11px 20px 14px 16px;
    margin-bottom: 24px;
    position: relative;
    cursor: pointer;
    user-select: none;
    background-color: #fff;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 19;
    }

    &.error {
        border: 1px solid #E84949;
    }

    &::after {
        content: '';
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-image: url(${ArrowBottom});
        background-repeat: no-repeat;
        width: 16px;
        height: 8px;
        background-size: contain;
    }
`

export const SelectPlaceholder = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: ${props => props.active ? '#000' : 'rgba(0, 0, 0, 0.35)'};
`

export const SelectOptionsWrapper = styled.div`
    background-color: #FFFFFF;
    box-shadow: 0px 2px 16px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    display: ${props => props.active ? 'flex' : 'none'};
    position: absolute;
    flex-direction: column;
    gap: 4px;
    left: 0;
    right: 0;
    bottom: -116px;
    margin: auto;
    z-index: 19;
`

export const SelectOption = styled.button`
    background: ${props => props.selected ? 'rgba(186, 141, 91, 0.1)' : '#fff'};
    border: 1px solid #DADADA;
    border-radius: 4px;
    padding: 10px 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    text-align: left;
    outline: none;

    &:active {
        background: rgba(186, 141, 91, 0.1);
    }

    &:hover {
        background: #F1F0EE;
    }
`
