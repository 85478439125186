import { hoc } from '@utils/hoc';
import { useGeneralLayoutProp } from './general-layout.props';
import {
  GeneralLayoutHeader,
  GeneralLayoutWrapper,
  Logo
} from './general-layout.style';
import NTLogo from '@assets/img/edfix-logo.svg';

export const GeneralLayout = hoc(useGeneralLayoutProp, ({ children }) => {
  return (
    <GeneralLayoutWrapper>
      <GeneralLayoutHeader>
        <Logo alt='logo' src={NTLogo} />
      </GeneralLayoutHeader>
      {children}
    </GeneralLayoutWrapper>
  );
});
