import styled from 'styled-components';

export const InputField = styled.input`
  position: relative;
  z-index: 1;

  width: 100%;
  padding: ${props => props.paddingSize || '11px'} 16px ${props => props.paddingSize || '11px'} ${props => (props.isprefix ? '56px' : '16px')};
  background-color: #fff;
  border: 1px solid
    ${props => (props.isError ? 'rgba(255, 0, 0, 0.5)' : '#dadada')};
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  border: ${props => props.border };
  outline: ${props => props.border};


  line-height: 19px;
  font-family: 'Lato';
  font-style: 'normal';
  letter-spacing: -0.02em;

  &.input-error {
    box-shadow: 0 0 1px 1px #e84949;
  }

  &.input-info {
    box-shadow: 0 0 1px 1px #00000059;
  }

  .${props => props.sty} {
    margin-left: 30px;
    border: none;
    outline: none;
  }

  &::placeholder {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.35);
  }

  &:disabled {
    opacity: 0.5;
  }
`;
export const InputWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;

  background-color: #fff;
`;
export const InputPrefix = styled.span`
  position: absolute;
  z-index: 99;
  top: ${props => props.top || '13px'};
  left: 16px;
  font-weight: 600;
  font-size: 16px;

  line-height: 19px;
  letter-spacing: -0.02em;
  font-family: 'Lato';
  font-style: 'normal';
`;
export const InputSuffix = styled.span`
  position: absolute;
  z-index: 99;
  top: ${props => props.top || '12px'};
  right: 16px;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;
