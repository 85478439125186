import { Skeleton } from '@components/skeleton/skeleton-box';
import { hoc } from '@utils/hoc';
import { useCourseTopicProp } from './course-topic.prop';
import { CourseTopicText, CourseTopicTitle } from './course-topic.style';

export const CourseTopic = hoc(useCourseTopicProp, ({ activeLesson, singleModuleLoading }) => {
    return (<>
        {
            singleModuleLoading
                ? <>
                    <Skeleton fullWidth={30} height={30} marginBottom={10} />
                    <Skeleton fullWidth={100} height={14} />
                </>
                : (<>
                    <CourseTopicTitle >
                        {activeLesson?.chapter?.name}
                    </CourseTopicTitle >

                    <CourseTopicText
                        dangerouslySetInnerHTML={{ __html: activeLesson?.chapter?.desc }}
                    >
                    </CourseTopicText>
                </>)
        }
    </>);
});
