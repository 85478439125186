import { hoc } from '@utils/hoc';
import { Header } from './header';
import { useMainLayoutProps } from './main-layout.prop';
import {
  StyledMainLayout,
  StyledMainLayoutInnerWrapper,
  StyledMainLayoutWrapper
} from './main-layout.style';
import { Sidebar } from './sidebar';
import { BottomNav } from './bottom-nav';

export const MainLayout = hoc(
  useMainLayoutProps,
  ({ sidebarState, children, isSidebarOpened, mqMaxWidt768 }) => {
    return (
      <StyledMainLayout>
        <Sidebar />
        <StyledMainLayoutWrapper
            isOpened={isSidebarOpened}
            state={sidebarState}
            isTablet={mqMaxWidt768}
        >
          <Header />
          <StyledMainLayoutInnerWrapper>
            {children}
          </StyledMainLayoutInnerWrapper>
        </StyledMainLayoutWrapper>
        <BottomNav />
      </StyledMainLayout>
    );
  }
);
