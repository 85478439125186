import React from 'react';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { Skeleton } from '@components/skeleton/skeleton-box';
import { useMediaQuery } from '@utils/useMediaQuery';
import { AccordionSkeleton, CourseHeroWrapper, Wrapper, WrapperInner } from './all-courses-single.style';

export const AllCoursesSingleSkeleton = () => {
  const mqMaxWidth600 = useMediaQuery('(max-width: 600px)');
  const mqMaxWidth370 = useMediaQuery('(max-width: 370px)');

  return <Wrapper>
    <WrapperInner>
      <FlexWrapper
        align='center'
        justify='space-between'
        direction={mqMaxWidth600 ? 'column-reverse' : ''} >
        <CourseHeroWrapper>
          <Skeleton marginBottom={36} width={370} height={35} borderRadius={5} />

          <FlexWrapper gap={mqMaxWidth370 ? '14px' : '18px'}>
            <Skeleton width={200} height={45} borderRadius={20} />
            <Skeleton width={150} height={45} borderRadius={20} />
          </FlexWrapper>
        </CourseHeroWrapper>
        <Skeleton width={210} height={210} borderRadius={10} />
      </FlexWrapper>
    </WrapperInner>
  </Wrapper>
};

export const SingleCourseModuleSkeleton = () => {
  const mqMaxWidth1110 = useMediaQuery('(1016px <= width <= 1110px)');
  const mqMaxWidth820 = useMediaQuery('(max-width: 820px)');
  const mqMaxWidth650 = useMediaQuery('(max-width: 650px)');
  return <FlexWrapper
    className='info-wrapper'
    gap={mqMaxWidth1110 || mqMaxWidth820 ? '24px' : '64px'}
    justify={
      mqMaxWidth1110 || mqMaxWidth820
        ? 'space-between'
        : 'flex-start'
    }
    wrap={mqMaxWidth650 ? 'wrap' : ''}
  >
    <FlexWrapper
      className={mqMaxWidth650 && 'info-top'}
      direction='column'
    >
      <Skeleton width={50} height={45} marginBottom={18} />
      <Skeleton width={70} height={15} marginBottom={5} />
      <Skeleton width={70} height={35} marginBottom={2} />
    </FlexWrapper>

    <FlexWrapper
      className={mqMaxWidth650 && 'info-top'}
      direction='column'
    >
      <Skeleton width={50} height={45} marginBottom={18} />
      <Skeleton width={70} height={15} marginBottom={5} />
      <Skeleton width={70} height={35} marginBottom={2} />
    </FlexWrapper>

    <FlexWrapper
      className={mqMaxWidth650 && 'info-top'}
      direction='column'
    >
      <Skeleton width={50} height={45} marginBottom={18} />
      <Skeleton width={70} height={15} marginBottom={5} />
      <Skeleton width={70} height={35} marginBottom={2} />
    </FlexWrapper>

    <FlexWrapper
      className={mqMaxWidth650 && 'info-top'}
      direction='column'
    >
      <Skeleton width={50} height={45} marginBottom={18} />
      <Skeleton width={70} height={15} marginBottom={5} />
      <Skeleton width={70} height={35} marginBottom={2} />
      <FlexWrapper className='course-about'>
      </FlexWrapper>
    </FlexWrapper>
  </FlexWrapper>
}

export const SingleCourseAccardionSkeleton = () => {
  return <div>
    <AccordionSkeleton>
      <Skeleton width={400} height={20} />
      <Skeleton width={200} height={20} />
    </AccordionSkeleton>
  </div>
}
