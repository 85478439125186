import { MainButton } from '@components/atoms/main-button/main-button.style';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MyCourseCardWrapperCore = styled(Link)(
  () => `
    border-radius: 4px;
    padding: 16px 24px 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 24px;
      }
    `,

  ({ theme: { down } }) => `
      ${down('885px')} {
        padding-right: 16px;
      }

      ${down('825px')} {
        align-items: flex-start;
        flex-direction: column;
        gap: 30px;
      }
      ${down('768px')} {
        justify-content: space-between;
      }
      `
);

export const MyCourseCardWrapper = styled(MyCourseCardWrapperCore)`
  background: ${props => (props.small ? '#F7F7F5' : '#ffffff')};

  @media only screen and (max-width: 985px) {
    flex-direction: ${props => props.sidebarstate ? 'column' : 'row'};
    align-items: ${props => props.sidebarstate ? 'flex-start' : 'center'};
    gap: 30px;
  }

  @media only screen and (max-width: 825px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
`;

export const MyCourseCardRightWrapper = styled(FlexWrapper)(
  ({ theme: { down } }) => `
        ${down('825px')} {
            width: 100%;
        }

        ${down('626px')} {
            gap: 30px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        
        ${down('577px')} {
            flex-direction: row;
             justify-content: space-between;
        }
        
    `
);

export const MyCourseCardLeftWrapper = styled(FlexWrapper)(
  ({ theme: { down } }) => `
          ${down('440px')} {
              width: 100%;
          }
      `
);

export const MyCourseCardImgCore = styled.img(
  () => `
    border-radius: 4px;
    width: 170px;
    height: 154px;
    overflow: hidden;
    object-fit: contain;
    display: block;
    `,

  ({ theme: { down } }) => `
        ${down('885px')} {
            width: 150px;
            height: 125px;
        }

        ${down('824px')} {
          width: 170px;
          height: 155px;
      }

        ${down('440px')} {
            width: 100%;
            height: 180px;
        }
    `
);

export const MyCourseCardImg = styled(MyCourseCardImgCore)`
  background: ${props => (props.color ? props.color : '#000')};
`;

export const MyCourseCardTitle = styled.abbr `
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: ${props => (props.mb)};
    max-width: 261px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    cursor: pointer !important;
    border-bottom: 0 !important;
    text-decoration: none;

     ${props => {
      const { down } = props.theme;
      return `
        ${down('885px')} {
            font-size: 22px;
            max-width: 200px;
        }

        ${down('800px')} {
            font-size: 20px;
        }

        ${down('824px')} {
          max-width:300px;
      }

        ${down('440px')} {
            margin-bottom: 16px;
        }
    `;
      }}
`;

export const MyCourseCardTutorImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
`;

export const MyCourseCardTutorName = styled.h4(
  () => `
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #000000;
    max-width: 80%;
    margin-left: 8px;
    max-width: 155px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `,

  ({ theme: { down } }) => `
        ${down('885px')} {
            max-width: 150px;
        }

        ${down('800px')} {
            font-size: 14px;
        }
    `
);

export const CourseProgressInfo = styled.h4(
  () => `
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.75);
        max-width: 344px;
    `,

  ({ theme: { down } }) => `
    ${down('885px')} {
        max-width: 180px;
    }
  `
);

export const CourseProgressBar = styled.div`
  background: rgba(186, 141, 91, 0.1);
  border-radius: 2px;
  width: 100%;
  height: 4px;
  margin-top: 8px;
`;

export const CourseProgressEnded = styled.span`
  background: #ba8d5b;
  border-radius: 2px;
  height: 100%;
  width: ${props => props.width}%;
  display: block;
`;

export const PlayBtnImage = styled.img`
  width: 24px;
  height: 18.3px;
  object-fit: contain;
`;

export const CourseCardInfoTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #4e6575;
  margin-left: 8px;
`;

export const CourseCardInfoSubtitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ba8d5b;
`;

export const MoreBtn = styled(MainButton)`
  padding: 12px 42px 10px 41px !important;
  margin-top: ${props => (props.mt ? '24px' : '0')};

`;
