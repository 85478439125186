import styled from 'styled-components';

export const GeneralLayoutWrapper = styled.div`
    min-height: 100vh;
    background-color: #F1F0EE;
    padding-bottom: 32px;
`

export const GeneralLayoutHeader = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #eae9e7;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  position: sticky;
  top: 0;
  z-index: 3;
`;

export const Logo = styled.img`
    width: 135px;
    height: 28px;
`