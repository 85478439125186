import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledUser = styled.div(
  () => `
    position: relative;
    cursor: pointer;
    padding: 1.2rem 0;
    
    &:hover {
      .profile-menu {
        visibility: visible;
    
        svg {
          transition-duration: 0 !important;
        }
      }
    }
    
    h5 {
      color: #000;
      user-select: none;
    }
    
    h6 {
      user-select: none;
    }
  `,

  ({ theme: { down } }) => `
    ${down('576px')} {
      display: none;
    }
  `
);

export const ProfileMenu = styled.div`
  position: absolute;
  bottom: -112px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  right: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  visibility: hidden;
  transition: visibility 2s ease-in;
`;

export const ProfileMenuItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000000;
  text-decoration: none;
  padding: 18px 15px;
  box-sizing: border-box;

  svg {
    fill: rgba(0, 0, 0, 0.25);
    width: 18px;
    height: 18px;
  }

  &:hover {
    background: rgba(186, 141, 91, 0.1);

    svg {
      fill: #ba8d5b;
      transition-duration: 0 !important;
    }

    &:last-child {
      svg {
        path {
          stroke: #ba8d5b;
        }
      }
    }
  }
`;

export const BellWrapper = styled(Link)`
  position: relative;
  margin-top: 4px;
  cursor: pointer;
`;

export const Badge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ea4335;
  position: absolute;
  top: -21%;
  right: -21%;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.02em;
  color: #f7f7f5;
`;

export const NotificationImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;
