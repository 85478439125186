import styled from 'styled-components';

export const CheckboxBase = styled.label`
  cursor: ${props => (props.isDisabled ? 'auto' : 'pointer')};

  input {
    visibility: hidden;
    position: absolute;
  }

  input[type='checkbox']:checked ~ div {
    background-color: ${props =>
      props.isDisabled ? 'rgba(7, 205, 134, 0.5)' : props.theme.colors.brown};
    box-shadow: none;
    pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  }
`;

export const StyledCheckbox = styled.div`
  width: 2rem;
  height: 2rem;
  box-shadow: inset 0 0 0 2px #cdcdcd;
  cursor: pointer;
  border-radius: 0.4rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
