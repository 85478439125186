import styled from 'styled-components';

export const StyledNavLinkContainerCore = styled.li(
  () => `
    width: 100%;
    height: 6.8rem;
    @media screen and (max-width: 1000px) and (orientation: landscape) and (max-height: 500px) {
      width: 7.1rem;
      height: 5.2rem;
    }
    position: relative;
    cursor: pointer;
    z-index: 2;

    &.rank {
      svg {
        fill: none;
        path {
          width: 50px;
          height: 50px;
        }
      }
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
      fill: #bfbfbf;
      position: absolute;
      left: 2.4rem;
    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #000;
      font-weight: 600;
      padding-left: 6.9rem;
      margin-right: 54px;
      transition: all 0.2s linear;
    }

    a:not(.active-link):hover {
      background-color: rgba(188, 185, 182, 0.1);
    }
  `,

  ({ theme: { down } }) => `
    ${down('576px')} {
      width: 99px;
      height: 60px;
      
      a {
        display: flex;
        justify-context: center;
        align-items: center;
        padding-left: 0;
      }
    }
    svg {
      ${down('986px')} {
        width: 2.4rem;
        height: 2.4rem;
      }

      ${down('576px')} {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  `
);

export const StyledNavLinkContainer = styled(StyledNavLinkContainerCore)`
  .active-link {
    background-color: rgba(186, 141, 91, 0.1);

    .sidebar-navs {
      color: #ba8d5b;
    }

    svg {
      fill: ${props => props.theme.colors.brown};
      stroke: ${props => props.$stroke && props.theme.colors.brown};

      path {
        fill: ${props => props.theme.colors.brown};
        stroke: ${props => props.$stroke && props.theme.colors.brown};
      }
    }

    &::before {
      display: block;
    }

    &.rank {
      svg {
        fill: none;
        path {
          fill: none;
          stroke: #bc8e5b !important;
        }
      }
    }
  }

  svg {
    stroke: ${props => props.$stroke && '#bfbfbf'};
  }
`;
