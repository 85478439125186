import { FlexWrapper } from '@components/layouts/flex-wrapper';
import styled, { css } from 'styled-components';
import IconClose from '@assets/img/icon-close-payment-modal.svg';
import { Button } from '@components/atoms';
import Checked from '@assets/img/checked-pay.svg';
import RightIcon from '@assets/img/promo-right.svg';
import AlertTriangle from '@assets/img/alert-triangle.svg'

const CenterChildren = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DisplayTooltip = `
  transition-delay: 500ms;
  visibility: visible;
`;

export const PaymentModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  h5 {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 421px) {
    padding-top: 27px;
    padding: 27px;
  }
`;

export const CourseModal = styled.div`
  position: relative;
  min-width: 506px;
  display: flex;
  border-radius: 0.8rem;
  overflow: auto;
  cursor: default;
  background-color: ${props => props.theme.colors.white};

  @media only screen and (max-width: 550px) {
    min-width: 90%;
    max-width: 90%;
  }
  @media only screen and (max-width: 450px) and (min-width: 361px) {
    margin-top: 27px;
    margin-bottom: 27px;
    min-width: 328px;
  }
  @media only screen and (max-width: 360px) {
    margin-top: 27px;
    margin-bottom: 27px;
    min-width: 328px;
  }
`;

export const CloseIframeModal = styled.button(
  () => `
  position: absolute;
  top: 34px;
  right: 32px;
  z-index: 99;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  width: 24px;
  height: 24px;
  background-image: url(${IconClose});
  background-repeat: no-repeat;
  background-size: contain;
`,
  ({ theme: { down } }) => `
  ${down('450px')}{
    top: 18px;
    right: 6px;
  }
`
);

export const ModalContent = styled.div(
  () => `
    width: 50.6rem;
  `,
  ({ theme: { down } }) => `
      ${down('450px')}{
        width: 328px;
      }
  `
);

export const PaymentModalTop = styled.div(
  () => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.05);
  `
);

export const PaymentModalContentScroll = styled.div`
  overflow: auto;
  max-height: 50vh;
  padding: 20px;

  ::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-track {
    background-color: #dadada;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ba8d5b;
    border-radius: 10px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.8rem;
`;

export const PaymentModalCLoseBtn = styled.button`
  background-color: transparent;
  border: 0;
  width: 24px;
  height: 24px;
  background-image: url(${IconClose});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const TitlePrimary = styled.h3`
  margin-bottom: ${({ mb }) => mb || 'initial'};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const CollapsibleButton = styled.button`
  border: none;
  border-radius: 100px;
  background-color: #fff;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

export const Collapsible = styled.div`
  position: relative;
  margin-bottom: 1.6rem;
  padding: 1.2rem;
  background-color: #f9f9f9;
  transition: height 200ms ease-in-out;
  min-height: ${({ minHeight }) => minHeight};
  overflow: hidden;

  ${({ isCollapsed, maxHeight }) =>
    isCollapsed &&
    css(
      () => `
            max-height: ${maxHeight};
        `,
      ({ theme: { down } }) => `
        ${down('450px')}{
            height: 20.5rem;
        }
        `
    )}

  & > button {
    position: absolute;
    top: 1.4rem;
    right: 1.4rem;
  }
`;

export const PromoCodeCollapseBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: ${props => (props.collapsed ? '16px' : '0')};
  cursor: pointer;
`;

export const CourseDetails = styled.div`
  margin-bottom: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid #dadada;

  h5 {
    line-height: 1.2rem;
    font-weight: 600;
    color: #81755c;
    margin-bottom: 0.4rem;
  }
`;

export const RightGradient = styled.div`
  flex-grow: 1;
  background-image: conic-gradient(
    from 299deg at 100% 100%,
    #7000ff 41deg,
    #ffc700 61deg,
    #7000ff 360deg
  );
  background-repeat: repeat-y;
  background-blend-mode: overlay;
`;

export const PreferredPaymentMethod = styled(CourseDetails)`
  padding-bottom: 1.5rem;
`;

export const TotalPrice = styled(FlexWrapper)`
  margin-bottom: 2.4rem;
  position: relative;
  padding-top: 20px;
  border-top: 0.1rem solid rgb(218 218 218 / 40%);

  span {
    font-style: normal;
    text-align: end;
    font-weight: 700;
    font-size: 18px;
    line-height: 23.4px;
    letter-spacing: -0.02em;
    color: #000000;
  }
`;

export const TotalBoughtPrice = styled(FlexWrapper)`
  margin-bottom: 2.4rem;
  position: relative;
  padding-top: 20px;
  border-top: 0.1rem solid rgb(218 218 218 / 40%);
  color: ${props => (props.isActive ? '#000' : '#9b9b9b')};

  span {
    font-style: normal;
    text-align: end;
    font-weight: 700;
    font-size: 18px;
    line-height: 23.4px;
    letter-spacing: -0.02em;
    text-align: start;
  }
`;

export const PurchaseDiscount = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 16px;
`;

export const TotalPriceCount = styled.div`
  font-weight: 700;
  font-size: 28px;
  text-align: end;
`;

export const PromodCodeSubTitle = styled.h5`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
`;

export const PromoCodeWrapper = styled.div`
  div {
    width: 100%;

    div {
      background-color: transparent;
    }
  }
`;

export const PromoButton = styled.button`
  background: #ba8d5b;
  border-radius: 50%;
  position: relative;
  width: 44px;
  height: 39px;
  border: none;
  outline: none;

  &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.6;
  }

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-image: url(${RightIcon});
  }
`;

export const PaymentType = styled(FlexWrapper)`
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 12px;

  @media only screen and (max-width: 552px) {
    flex-direction: column;
  }

  label {
    width: 21rem;
    height: 4.4rem;
    border-radius: 8px;
    background-color: #fff;
    position: relative;

    div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      margin-left: 20px;
    }

    input[type='radio'] {
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 0;
      color: #07cf86;
      border: 1px solid #f1f0ee;
      border-radius: 50%;
      position: absolute;
      top: 12px;
      left: 10px;
      width: 20px;
      height: 20px;
    }
    input[type='radio']:checked {
      background-image: url(${Checked});
      background-size: cover;
      border: none;
    }
  }
`;

export const ModuleTypeCount = styled.div`
  ${CenterChildren};
  width: 1.6rem;
  height: 1.6rem;

  line-height: 1.6rem;
  font-size: 1rem;
  color: ${props => props.theme.colors.brown};

  border: 0.1rem solid ${props => props.theme.colors.brown};
  border-radius: 0.2rem;
  cursor: pointer;
  position: relative;

  &:hover {
    & > div {
      ${DisplayTooltip}
    }
  }
`;

export const ErrorMsg = styled.div`
  height: ${props => props.height || '2.4rem'};
  font-size: 1.2rem;
  color: ${props => props.theme.colors.red50};
`;

// Modules
export const ModuleTypes = styled(FlexWrapper)`
  color: ${props => props.theme.colors.brown};
`;

export const ToolTip = styled.div`
  position: absolute;
  top: calc(100% + 2rem);
  left: 50%;
  transform: translateX(-50%);
  padding: 1.2rem;
  background-color: #323232;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #fff;
  visibility: hidden;
  border-radius: 0.8rem;
  white-space: nowrap;
  z-index: 9999;
`;

export const ModulesWrapper = styled(FlexWrapper)`
  flex-direction: column;
  margin-right: 10px;

  ${({ maxHeightModule }) =>
    css(
      () => `
            max-height: ${maxHeightModule};
        `,
      ({ theme: { down } }) => `
        ${down('450px')}{
            height: 14.8rem;
        }
        `
    )}
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.3rem;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background-color: #dadada;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.brown};
    border-radius: 10px;
  }
`;

export const Module = styled(FlexWrapper)(
  () => `
        padding-right: 1.6rem;
        flew-wrap: no-wrap;
    `,
  ({ theme: { down } }) => `
      ${down('450px')}{
        align-items: flex-start;
        flex-wrap: wrap;
      }
    `
);

export const ModuleNameCore = styled.div`
  color: ${props => (props.isActive ? '#000' : '#5a5a5a')};
`;

export const ModuleName = styled(ModuleNameCore)(
  () => `
        flex-grow: 1;
        white-space: nowrap;
        max-width: 27rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    `,
  ({ theme: { down } }) => `
      ${down('553px')}{
        max-width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    `
);

export const ModulPriceCore = styled.div`
  color: ${props =>
    props.isPurchased
      ? 'rgba(7, 205, 134, 0.5)'
      : props.isActive
      ? '#000'
      : '#5a5a5a'};
`;
export const ModulePrice = styled(ModulPriceCore)(
  () => `
        font-size: 1.2rem;
        line-height: 1.6rem;
        justify-self: flex-end;
        margin-left: auto;
        white-space: nowrap;
    `,
  ({ theme: { down } }) => `
      ${down('450px')}{
        margin-left: 32px;
      }
    `
);

export const ErrorInfo = styled.div`
  max-width: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #000000;
  border-radius: 8px;
  padding: 13px 13px 13px 38px;
  margin-bottom: 15px;
  background: #f8f7ef;
  position: relative;

  &:after {
    content: url(${AlertTriangle});
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const FooterWrapper = styled.div`
  padding: 16px 20px;
  box-shadow: 0px -4px 28px rgba(0, 0, 0, 0.05);
`;

export const ModalFooter = styled(FlexWrapper)(
  () => `
        padding-top: 1.4rem;
        border-top: 0.1rem solid #dadada;

        p {
            margin-top: 15px;
            text-align: center;
            max-width: 80%;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.02em;
        }

        a {
            text-decoration: none;
            color: #2697ff;

            :hover {
                text-decoration: underline;
            }
        }
    `,
  ({ theme: { down } }) => `
      ${down('482px')}{
        p {
            margin-top: 15px;
            max-width: 100%;
            margin-bottom: 8px;
        }
        padding-top: 1rem;
      }
    `,
  ({ theme: { down } }) => `
      ${down('450px')}{
        p {
            max-width: 280px;
            margin-bottom: 8px;
        }
        padding-top: 1rem;
      }
    `
);

export const FormControl = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 16px;
`;

export const ButtonCore = styled(Button)(
  () => `
        width: 100%;
    `,
  ({ theme: { down } }) => `
      ${down('482px')}{
        width: 100%;
        height: 100%;
        margin: auto;
      }
    `,
  ({ theme: { down } }) => `
      ${down('450px')}{
        width: 100%;
        height: 100%;
      }
    `
);

export const ActivedPromoCode = styled.div`
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 100px;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ba8d5b;
  }
`;

export const PromoCodeSalePrice = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: ${props => (props.sale ? '#BA8D5B' : '#000')};
  text-decoration: ${props => (props.sale ? 'line-through' : 'none')};
`;

export const IframeModal = styled.iframe`
  position: absolute;
  min-width: 506px;
  height: 85%;
  display: ${props => (props.isHideIframeModal ? 'block' : 'none')};
  z-index: 98;
  border-radius: 0.8rem;

  @media only screen and (max-width: 550px) {
    min-width: 90%;
    max-width: 90%;
  }
  @media only screen and (max-width: 450px) and (min-width: 361px) {
    margin-top: 27px;
    margin-bottom: 27px;
    min-width: 348px;
  }
  @media only screen and (max-width: 360px) {
    margin-top: 27px;
    margin-bottom: 27px;
    min-width: 328px;
  }
`;

export const AlertMessage = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2px;
`;

export const AlertMessageDesc = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
`;
