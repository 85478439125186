import { all, call, put } from 'redux-saga/effects';
import { commonActions } from './common.slice';
import { commonService } from 'src/services/common.service';
import { fetchSagaFactory } from '@utils/fetch-saga-factory';

export function* requestProfile() {
  try {
    const profile = yield call(commonService.getProfile);
    yield put(commonActions.fetchProfileFulfilled(profile.student));
  } catch (err) {
    yield put(commonActions.setError(err));
  } finally {
    yield put(commonActions.setLoading(false));
  }
}

export function* commonRootSaga() {
  yield all([
    fetchSagaFactory({
      pattern: 'fetchRecommendedCourses',
      actions: commonActions,
      api: {
        method: commonService.recommendedCourse,
        callback: res => res?.data?.recommendedCourses
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchProfile',
      actions: commonActions,
      api: {
        method: commonService.fetchProfile,
        callback: res => res?.data
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchMyCourses',
      actions: commonActions,
      api: {
        method: commonService.myCourses,
        callback: res => res?.data?.myCourses
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchSupportLink',
      actions: commonActions,
      api: {
        method: commonService.getSupportLink,
        callback: res => res?.data?.supportLinks
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchRecommendedPost',
      actions: commonActions,
      api: {
        method: commonService.recommendedPost,
        callback: res => res?.data
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchLastVideo',
      actions: commonActions,
      api: {
        method: commonService.lastVideo,
        callback: res => res
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchNotificationCount',
      actions: commonActions,
      api: {
        method: commonService.getNotificationCount,
        callback: res => res?.data
      }
    })
  ]);
}
