import styled from 'styled-components';

export const Form = styled.form`
  margin-top: 24px;

  button {
    margin-top: 8px;
    display: inline-block;
  }
`;
