import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';

export const RightSidebarWrapper = styled.div(
  () => `
    width: 320px;
    display: block;
    padding: 24px;
    background: #ffffff;
    padding-right: 14px;
    border-radius: 4px;
    overflow: hidden;
  `,
  ({theme:{down}}) => `
    ${down('1015px')} {
      display: none;
    }
  `
);

export const RightSidebarWrapperInner = styled(Scrollable)(
  () => `
    max-height: calc(100vh - 210px);
    overflow: auto;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .no-blog {
        align-self: center;
        svg {
          width: 40px;
          align-self: center;
          height: 40px;
        }

    }
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      gap: 12px;
      height: auto;
      min-width: 176px;
    }
     ${down('668px')} {
      max-height: initial;
    }
  `
);

export const NoBlogTitle = styled.div(
  () => `
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ba8d5b;
    margin-top: 26px;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      font-size: 14px;
    }
  `
);

export const EmptyBlogImg = styled.img(
  () => `
    height: 40px;
    width: 40px;
  `,
  ({ theme: { down } }) => `
    ${down('668px')} {
      height: 80px;
      width: 80px;
    }
  `
);

export const NoBlogWrapper = styled(FlexWrapper)(
  ({ theme: { down } }) => `
  background: #f7f7f5;
  border-radius: 8px;
  padding: 28px 7px 19px 7px;

  ${down('668px')} {
    width: 100%;
  }
`
);
