import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useUnModalProps } from './modal.prop';
import closeImg from '@assets/img/close.svg';

import {
  UnModalWrapper,
  UnModalInner,
  UnModalTitle,
  UnModalBottom,
  UnModalHead
} from './modal.style';

export const propOrInitial = prop => prop;

export const UnModal = hoc(
  useUnModalProps,
  ({
    modalTitle,
    modalWidth,
    children,
    modal = false,
    modalRef,
    handleCloseModal,
    profile,
    equal
  }) => {
    return (
      modal && (
        <UnModalWrapper isOpen>
          <UnModalInner profile={profile} modalWidth={modalWidth} equal={equal}>
            <FlexWrapper
              align='center'
              justify={!modalTitle ? 'flex-end' : 'space-between'}
            >
              <UnModalHead>
                {modalTitle && <UnModalTitle>{modalTitle}</UnModalTitle>}
              </UnModalHead>

              <img
                ref={modalRef}
                className='close-img'
                onClick={handleCloseModal}
                src={closeImg}
                alt='close'
                width={11}
                height={11}
              />
            </FlexWrapper>
            <UnModalBottom>{children}</UnModalBottom>
          </UnModalInner>
        </UnModalWrapper>
      )
    );
  }
);
