import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

export const useSelectGenderProps = ({
  onChange = () => null,
  handleClickGender = () => null,
  defaultValue
}) => {
  const [open, setOpen] = useState(false);
  const [activeValue, setActiveValue] = useState('');
  const dropdownRef = useRef(null);
  const [count, setCount] = useState(0);

  const toggleSelect = e => {
    if (e.target === dropdownRef.current) {
      setCount(count + 1);
      handleClickGender(count);
    }

    setOpen(!open);
  };

  useEffect(() => {
    if (defaultValue) {
      setActiveValue(defaultValue);
    }
  }, [defaultValue]);

  const _onChange = (value, e) => {
    e.preventDefault();
    setActiveValue(value);
    setOpen(false);
    onChange(value);
  };

  return {
    toggleSelect,
    open,
    _onChange,
    activeValue,
    dropdownRef
  };
};
