import { api } from '@services';

export class AuthService {
  constructor() {
    this.api = api;
  }

  register = data => this.api.post('/student/signup', data);

  verifyPhoneNumber = data => this.api.put('/student/verify', data);

  login = data => this.api.post('/student/signin', data);

  requestForgetPasswordPhoneVerify = data =>
    this.api.post('/student/sendOtp', data);

  forgetPasswordCode = data => this.api.post('/student/recovery-psw', data);

  signUpWithGoogle = data => this.api.post('/student/google-signup', data);

  refreshToken = () => this.api.get('/student/refresh-token');
}

export const authService = new AuthService();
