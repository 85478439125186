import { FlexWrapper } from '@components/layouts/flex-wrapper';
import styled from 'styled-components';

export const PendingWrapper = styled(FlexWrapper)(
  ({ theme: { down }}) => `
    background: #f8f7ef;
    border-radius: 4px;
    padding: 16px 40px;
    box-sizing: border-box;

    ${down('768px')} {
      padding: 16px;
      gap: 28px;
    }

    ${down('576px')} {
      padding: 10px 14px;
      gap: 12px;
    }
`);

export const PendingImg = styled.img`
    width: 44px;
    height: 44px;
`;

export const PendingTitle = styled.h4(
  ({ theme: { down }}) => `
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;

    ${down('576px')} {
      font-size: 14px;
    }
`);
