import React from 'react';
import { NavLink } from 'react-router-dom';

import { StyledNavLinkContainer } from './sidebar-nav-link.style';

export const SidebarNavLink = ({
  to,
  iconName,
  $stroke,
  onClick,
  children,
  isRank
}) => {
  return (
    <StyledNavLinkContainer className={isRank ? 'rank' : ''} $stroke={$stroke}>
      <NavLink
        onClick={onClick}
        className={isRank ? 'rank' : ''}
        to={to}
        activeClassName='active-link'
      >
        {iconName}
        {children}
      </NavLink>
    </StyledNavLinkContainer>
  );
};
