import { storageService } from '@services/storage.service';
import { commonActions } from '@store/common';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useRegisterProp = () => {
  const token = storageService.getItem('accessToken');
  const profile = useSelector(state => state.common.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.setSession(false));
  }, []);

  return {
    token,
    profile
  };
};
