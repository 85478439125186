import React from 'react'
import {
    AccordionCardWrapper,
    AccordionThemeCard,
    CourseAccordionCard,
    CourseAccordionCardList,
    CourseAccordionCardListItem,
    CourseAccordionListItemToggle,
    CourseFaqTitle,
    CourseLessonFaqDescription,
    ModuleWrapper
} from './course-faq.style'
import { FlexWrapper } from '@components/layouts/flex-wrapper'
import { useCourseFaqProp } from './course-faq.props'
import { hoc } from '@utils/hoc'

export const CourseFaq = hoc(
  useCourseFaqProp,
    ({
    clicked,
    toggle,
    courseLessonFaq,
  }) => {
  return (
    <>
      <CourseFaqTitle>Ko'p so'raladigan savollar</CourseFaqTitle>

      <CourseAccordionCard>
        <CourseAccordionCardList>
          {courseLessonFaq?.length > 0 && courseLessonFaq.map((item, index) => (
            <CourseAccordionCardListItem
              key={index}
              className={clicked === index && 'accordion-card__item--open'}
              onClick={toggle.bind(null, index)}
            >
              <FlexWrapper
                justify='space-between'
                mr={'10px'}
                ml={'10px'}
                align='center'
              >
                  <CourseFaqTitle>{index + 1}. {item.question}</CourseFaqTitle>
                <CourseAccordionListItemToggle
                  type='button'
                  className={'large btn--large'}
                  aria-label='toggle accordion'

                >
                </CourseAccordionListItemToggle>
              </FlexWrapper>
              <ModuleWrapper>
                  <AccordionCardWrapper
x                    className={
                      clicked === index && 'accordion-card__item--open'
                    }
                  >
                    <AccordionThemeCard>
                      <FlexWrapper align='flex-start'>
                          <CourseLessonFaqDescription>{item.answer}</CourseLessonFaqDescription>
                          </FlexWrapper>
                    </AccordionThemeCard>
                  </AccordionCardWrapper>
              </ModuleWrapper>
            </CourseAccordionCardListItem>
          ))}
        </CourseAccordionCardList>
      </CourseAccordionCard>
    </>
  );
});
