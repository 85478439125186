import { useEffect } from 'react';
import { useImperativeHandle, useRef } from 'react';
import { useState } from 'react';

export const useTextAreaProps = ({
  onChange,
  textareaRef,
  submit = () => null,
  videoTime,
  isLoading
}) => {
  const [textarea, setTextArea] = useState('');
  const _textareaRef = useRef();

  const _onChange = value => {
    setTextArea(value);
    if (_textareaRef.current) {
      _textareaRef.current.style.height = 'auto';
      _textareaRef.current.style.height =
        _textareaRef.current.scrollHeight + 'px';
    }
    onChange?.(textarea);
  };

  const handleSubmit = e => {
    if (textarea?.length === 0 || videoTime < 1) return;

    if (e?.keyCode === 13 && e?.ctrlKey && textarea?.trim()?.length) {
      submit?.();
    }
  };

  useEffect(() => {
    if (!textarea.length) {
      _textareaRef.current.style.height = 'auto';
    }
  }, [textarea]);

  useImperativeHandle(textareaRef, () => ({
    focus: () => {
      _textareaRef.current.focus();
    },
    get value() {
      return _textareaRef.current.value;
    },
    set value(val) {
      _textareaRef.current.value = val;
      setTextArea(val);
    }
  }));

  return {
    _onChange,
    textarea,
    _textareaRef,
    handleSubmit
  };
};
