import {createSlice} from "@reduxjs/toolkit";

const name = 'rating'

const initialState = {
  loading: false,
  error: null,
  ratings: [],
  ratingFilter: {
    page: 1,
    perPage: 10,
    order: 'ASC',
    courseId: null
  },
  studentCourses: {
    modal: false,
    courses: []
  },
  courses: [],
}

export const {actions: ratingActions, reducer: ratingReducers} = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    fetchRanks(state, action) {
      state.loading = true
    },
    fetchRanksFulfilled(state, action) {
      state.ratings = action.payload
    },
    setRatingFilter(state, action) {
      state.ratingFilter = action.payload
    },
    setStudentCourseModal(state, action) {
      state.studentCourses = action.payload
    },
    fetchCourses(state) {
      state.loading = true
    },
    fetchCoursesFulfilled(state, action) {
      state.courses = action.payload
    }
  }
})
