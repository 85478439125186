import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAppProps } from './app.props';
import { hoc } from '@utils/hoc';
import { ProtectedRoute } from '@components/protected-route';
import { NotificationContainer } from '@components/notifications';
import { Error } from '@components/error';
import { retry } from '@utils/retry';
import { AccountError } from '@components/account-error';
import { RouteLinks } from './constants/route';
import { ErrorPage } from '@modules/error/error';
import { Loader } from '@components/Loader';
import { CourseNotPurchased } from '@modules/all-courses/components/not-purchased';
import { AuthRoutes } from '@modules/new-auth/routes';
import { AllVideos } from '@modules/videos/components/all-videos/all-videos.component';
import { MoreInfo } from '@modules/certificates/routes/more-info';
import { Homework } from '@modules/certificates/routes/homework';
import { Certificate } from '@modules/certificates/routes/certificate';
import { InactiveCourse } from '@modules/InactiveCourse/inactivecourse';
import { AllCoursesRoutes } from '@all-courses/routes';
import { InactiveModule } from '@modules/InactiveCourse/inactive-module';
import { Rating } from '@modules/rating';
import { MyRatings } from '@modules/my-ratings';

const retryConfig = {
  retriesLeft: 5,
  interval: 1000,
  cb: () => ({
    default: <Error errorText='Internetga ulanib bo‘lmadi' />
  })
};

const DashboardRoutes = lazy(() =>
  retry(
    () =>
      import('@dashboard/routes').then(m => ({
        default: m.DashboardRoutes
      })),
    retryConfig
  )
);

const SettingsRoutes = lazy(() =>
  retry(
    () =>
      import('@settings/routes').then(m => ({
        default: m.SettingsRoutes
      })),
    retryConfig
  )
);

const PaymentsRoutes = lazy(() =>
  retry(
    () =>
      import('@payments/routes').then(m => ({
        default: m.PaymentsRoutes
      })),
    retryConfig
  )
);

const NotificationRoutes = lazy(() =>
  retry(
    () =>
      import('@notifications/routes').then(m => ({
        default: m.NotificationRoutes
      })),
    retryConfig
  )
);

const ALlCoursesRoutes = lazy(() =>
  retry(
    () =>
      import('@all-courses/routes').then(m => ({
        default: m.AllCoursesRoutes
      })),
    retryConfig
  )
);

const VideosRoutes = lazy(() =>
  retry(
    () =>
      import('@videos/routes').then(m => ({
        default: m.VideosRoutes
      })),
    retryConfig
  )
);

const CertificatesRoutes = lazy(() =>
  retry(
    () =>
      import('@certificates/routes').then(m => ({
        default: m.CertificatesRoutes
      })),
    retryConfig
  )
);

// CertificatesRoutes
export const App = hoc(useAppProps, () => (
  <Suspense fallback={<Loader />}>
    <AccountError />
    <Switch>
      <Route path={RouteLinks.auth.index} component={AuthRoutes} />
      <Route exact path={RouteLinks.moreInfo} component={MoreInfo} />
      <Route exact path={RouteLinks.certificate} component={Certificate} />
      <Route exact path={`${RouteLinks.homeworkDetail}`} component={Homework} />
      <Redirect exact from='/' to={RouteLinks.dashboard} />
      <ProtectedRoute path={RouteLinks.dashboard} component={DashboardRoutes} />
      <ProtectedRoute path={RouteLinks.courses} component={ALlCoursesRoutes} />
      {/* <ProtectedRoute path={RouteLinks.rating} component={Rating} />
      <ProtectedRoute path={RouteLinks.myRatings} component={MyRatings} /> */}
      <ProtectedRoute
        path={RouteLinks.certificates}
        component={CertificatesRoutes}
      />
      <ProtectedRoute
        path={`${RouteLinks.courseSingle}/:id`}
        component={ALlCoursesRoutes}
      />
      <ProtectedRoute
        exact
        path={RouteLinks.myCourses}
        component={AllCoursesRoutes}
      />
      <ProtectedRoute
        path={`${RouteLinks.myCoursesSingle}/:id`}
        component={ALlCoursesRoutes}
      />
      <ProtectedRoute
        path={`${RouteLinks.notPurchased}/:id`}
        component={CourseNotPurchased}
      />
      <ProtectedRoute path={RouteLinks.settings} component={SettingsRoutes} />
      <ProtectedRoute path={RouteLinks.payments} component={PaymentsRoutes} />
      <ProtectedRoute path={RouteLinks.videos} component={VideosRoutes} />
      <ProtectedRoute
        path={RouteLinks.notifications}
        component={NotificationRoutes}
      />
      <ProtectedRoute path={RouteLinks.allSavedVideos} component={AllVideos} />
      <ProtectedRoute
        path={RouteLinks.allRecentlyVideos}
        component={AllVideos}
      />
      <ProtectedRoute path='/inactive' component={InactiveCourse} />
      <ProtectedRoute path='/inactive-module' component={InactiveModule} />
      <ProtectedRoute path='*' component={ErrorPage} />
    </Switch>

    <NotificationContainer />
  </Suspense>
));
