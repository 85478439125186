import { hoc } from '@utils/hoc';
import { UnModal } from '@components/modal';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { usePayzeModalProps } from './payze-modal.props';
import {
  ButtonCore,
  NextAnotherCardBtn,
  PayzeFormDesc,
  PayzeFormHead,
  PayzeFormInfo,
  PayzeFormModal,
  PayzeFormPaymentText,
  PayzeFormPrice,
  RememberCardImg,
  RememberCardLabel,
  RememberCardNumber,
  RememberCardUserName,
  RememberedCardItem,
  RememberedCardsList,
  RememberedModalWrapper,
  PayzeButton,
  IframeWrapper
} from './payze-modal.styles';
import VisaShortLogo from '@assets/img/visa-short-logo.svg';
import { CurrencyExchangeModal } from '../currency-exchange-modal';

export const PayzeModal = hoc(
  usePayzeModalProps,
  ({
    modal,
    setModal,
    unMountedModal,
    isChangedCurrency,
    setIsChangedCurrency,
    rememberCard,
    handleOnSubmit,
    USDPrice,
    currentRate,
    newRatePrice,
    isPayment
  }) => {
    return (
      <>
        <UnModal
          modal={modal}
          setModal={setModal}
          unMountModal={unMountedModal}
          modalTitle='Visa orqali to‘lash'
          modalWidth='538px'
        >
          {rememberCard && (
            <RememberedModalWrapper>
              <RememberedCardsList>
                <RememberedCardItem>
                  <RememberCardLabel>
                    <FlexWrapper align='center'>
                      <input
                        type='radio'
                        name='rememberCard'
                        aria-label={'remember card'}
                        value={'cards'}
                      />
                      <RememberCardImg
                        src={VisaShortLogo}
                        width='28'
                        height='28'
                        alt='card-type'
                      />
                      <RememberCardNumber>
                        4151 68** **** 8565
                      </RememberCardNumber>
                      <RememberCardUserName>
                        Narzullaev Jasurbek
                      </RememberCardUserName>
                    </FlexWrapper>
                  </RememberCardLabel>
                </RememberedCardItem>
              </RememberedCardsList>
              <NextAnotherCardBtn>Boshqa karta orqali</NextAnotherCardBtn>
              <ButtonCore type='submit' buttonType='button' size='large'>
                Davom ettirish
              </ButtonCore>
            </RememberedModalWrapper>
          )}

          <PayzeFormModal>
            <PayzeFormHead justify='space-between' align='center'>
              <PayzeFormInfo>
                <PayzeFormPaymentText>To‘lov summasi</PayzeFormPaymentText>
                <PayzeFormPrice>${USDPrice}</PayzeFormPrice>
                <PayzeFormDesc>
                  O’zbekiston Markaziy Banki kursiga ko’ra taxminan hisoblangan
                </PayzeFormDesc>
              </PayzeFormInfo>
            </PayzeFormHead>

            <form onSubmit={handleOnSubmit}>
              <IframeWrapper iframe={isPayment}>
              <div id='card'>
                <div id='card-info' />
              </div>
              </IframeWrapper>
              {
                !isPayment && (
                  <FlexWrapper>
                <PayzeButton cancel={true} onClick={unMountedModal}>
                  Bekor qilish
                </PayzeButton>
                <PayzeButton type='submit'>To‘lov qilish</PayzeButton>
              </FlexWrapper>
                )
              }
            </form>

            {isChangedCurrency && (
              <CurrencyExchangeModal
                modal={isChangedCurrency}
                setModal={setIsChangedCurrency}
                currentRate={currentRate}
                newRatePrice={newRatePrice}
              />
            )}
          </PayzeFormModal>
        </UnModal>
      </>
    );
  }
);
