import {api} from '@services/base.service'

class RatingService {
  constructor() {
    this.api = api
  }

  getRatings = (data) => this.api.get(`/student/ranks?${data?.page ? `page=${data?.page}` : ''}${data?.perPage ? `&perPage=${data?.perPage}` : ''}${`&orderBy=${data?.order || 'ASC'}${`&courseId=${data?.courseId || ''}`}`}`)

  getCourses = () => this.api.get('/student/courses-for-ranking?page=1&perPage=100')
}

export const ratingService = new RatingService()
