import { addNotification } from '@components/notifications'
import { CAPTCHA_ACTIONS } from '@constants/app'
import { authActions } from '@modules/auth/store'
import {
  ENTER_SMS_CODE,
  LOGIN_TYPES,
  PASSWORD,
  passwordRgp,
  PASSWORD_NOT_MATCH,
  PASSWORD_REQUIREMENT,
  REQUIRED_PASSWORD,
  TEXT,
  newPasswordReg,
  passwordRequirementsRegs,
} from '@modules/new-auth/constants'
import { api } from '@services/base.service'
import { useFormik } from 'formik'
import { useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

const initialValues = {
  code: '',
  new_password: '',
  new_password_check: '',
}

const MANY_REQUESTS = 157

const validationSchema = Yup.object().shape({
  code: Yup.string().min(6, ENTER_SMS_CODE).max(6, ENTER_SMS_CODE).required(ENTER_SMS_CODE),
  new_password: Yup.string().matches(passwordRgp, PASSWORD_REQUIREMENT).required(REQUIRED_PASSWORD),
  new_password_check: Yup.string()
    .oneOf([Yup.ref('new_password'), null], PASSWORD_NOT_MATCH)
    .required(PASSWORD_NOT_MATCH),
})

export const useRecoveryFormProp = ({ phoneNumber, setInitialForm, setTime, setTimeIndex, timeIndex }) => {
  const [retry, setRetry] = useState(false)
  const countRef = useRef()
  const [inputType, setInputType] = useState(PASSWORD)
  const [checkInputType, setCheckInputType] = useState(PASSWORD)
  const [hasChecker, setHasChecker] = useState(false)
  const [hasPasswordLength, setHasPasswordLength] = useState(false)
  const [isIncludeNumber, setIsIncludeNumber] = useState(false)
  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const isCaptchaError = useSelector((state) => state.auth?.isShowCaptchaError)

  const handleEditPhoneNumber = () => {
    setInitialForm(true)
  }

  const handleSendOtp = async () => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.sendOtp)

      api.setHeader('recaptcha', token)

      const info = {
        data: {
          login: phoneNumber,
          actionType: 'RECOVERY_PASSWORD',
          loginType: LOGIN_TYPES.phone,
        },
        callback: (res) => {
          if (res.success) {
            setTime(Date.now() + (res?.data?.leftTime || 0))
            setTimeIndex(timeIndex + 1)
            api.setHeader('recaptcha', '')
          }
        },
      }

      dispatch(authActions.forgetPasswordPhone(info))
    } catch (error) {
      console.error('Send OTP Login Phone:', error)
    }
  }

  const handleSubmit = async (values) => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.recoveryPassword)

      api.setHeader('recaptcha', token)
      const info = {
        data: {
          login: phoneNumber,
          code: values.code,
          loginType: LOGIN_TYPES.phone,
          newPassword: values.new_password,
        },
        callback: (res) => {
          if (res.success) {
            dispatch(authActions.setForgetPhoneModal(false))
            api.setHeader('recaptcha', '')
            addNotification({
              title: 'Muvaffaqiyatli o‘zgartirildi',
              description: 'Parolingiz muvaffaqiyatli o‘zgartirildi',
              options: {
                autoClose: true,
                type: 'success',
              },
            })
          }

          if (res.error?.errId === MANY_REQUESTS) {
            dispatch(authActions.setBlockModal(true))
            dispatch(authActions.setForgetPhoneModal(false))
          }

          if (res.error) {
            formik.setFieldError('code', res?.error?.errMsg)
            dispatch(authActions.setShowCaptchaError(true))
          }
        },
      }
      await dispatch(authActions.forgetPasswordCode(info))
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const handleChangeLoginCode = (e) => {
    formik.setFieldValue('code', e.target.value)
    formik.setErrors({ ...formik.errors, code: null })
  }

  const handleBlurLoginCode = (e) => {
    if (formik.values.code.length !== 6) {
      formik.setErrors({ ...formik.errors, code: ENTER_SMS_CODE })
    }
  }

  const handleChangePassword = (e) => {
    formik.handleChange(e)
    const passwordValue = e.target.value
    if (newPasswordReg.test(passwordValue)) {
      formik.setErrors({ new_password: PASSWORD_REQUIREMENT })
    }
    formik.setErrors({ ...formik.errors, new_password: '' })
  }

  const handleBlurPassword = () => {
    setHasChecker(false)
    const formikValues = formik.values

    if (formikValues.new_password.length === 0) {
      formik.setErrors({
        ...formik.errors,
        new_password: REQUIRED_PASSWORD,
      })
    }

    if (formikValues.new_password !== formikValues.new_password_check) {
      formik.setErrors({
        ...formik.errors,
        new_password_check: PASSWORD_NOT_MATCH,
      })
    } else {
      formik.setErrors({
        ...formik.errors,
        new_password_check: '',
      })
    }

    if (!passwordRequirementsRegs.every((req) => req.test(formikValues.new_password))) {
      formik.setErrors({
        ...formik.errors,
        new_password: PASSWORD_REQUIREMENT,
      })
    }
  }

  const handleBlurNewPasswordCheck = () => {
    const formikValues = formik.values

    if (formikValues.new_password !== formikValues.new_password_check || formikValues.new_password.length === 0) {
      formik.setErrors({
        ...formik.errors,
        new_password_check: PASSWORD_NOT_MATCH,
      })
    }
  }

  const handleChangeNewPasswordType = () => {
    setInputType(inputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleChangeCheckInputType = () => {
    setCheckInputType(checkInputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleChangeNewPasswordCheck = (e) => {
    formik.handleChange(e)
    const value = e.target.value
    if (value !== formik.values.new_password) {
      formik.setErrors({
        ...formik.errors,
        new_password_check: PASSWORD_NOT_MATCH,
      })
    } else {
      formik.setErrors({
        ...formik.errors,
        new_password_check: '',
      })
    }
  }

  const handleFocusPassword = () => {
    setHasChecker(true)
    formik.setErrors({
      ...formik.errors,
      new_password: '',
    })
  }

  return {
    formik,
    retry,
    countRef,
    setRetry,
    inputType,
    checkInputType,
    handleChangeLoginCode,
    handleBlurLoginCode,
    handleChangePassword,
    handleBlurPassword,
    hasChecker,
    handleFocusPassword,
    hasPasswordLength,
    isIncludeNumber,
    handleChangeNewPasswordCheck,
    handleBlurNewPasswordCheck,
    handleChangeNewPasswordType,
    handleChangeCheckInputType,
    handleEditPhoneNumber,
    handleSendOtp,
    isCaptchaError,
  }
}
