import styled from 'styled-components';
import HalfLogo from '@assets/img/half-logo.svg';

export const NotPurchasedWrapper = styled.div(
  ({ theme: { down } }) => `
    background: #ffffff;
    border-radius: 4px;
    padding: 32px;
    display: flex;
    margin-top: -40px;
    gap: 32px;
    justify-content: space-between;

    ${down('824px')} {
      padding: 20px;
      flex-direction: column-reverse;
      align-items: center;
      gap: 20px;
    }
  `
);

export const NotPurchasedRightCore = styled.div`
  background: ${props => props.color || '#fafafa'};
`;

export const NotPurchasedRight = styled(NotPurchasedRightCore)(
  ({ theme: { down } }) => `
    border-radius: 12px;
    width: 50%;
    background-image: url(${HalfLogo});
    background-position: right center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    ${down('824px')} {
      width: 100%;
    }
  `
);

export const CourseImg = styled.img`
  width: 274px;
  height: 274px;
  object-fit: cover;
`;

export const CourseCategory = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: ${props => props.textColor || '#000'};
  background-color: ${props => props.color || 'transparent'};
  display: inline-block;
  padding: 5px 12px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const CourseTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 8px;
`;

export const CourseText = styled.p(
  ({ theme: { down } }) => `
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.5);

    ${down('824px')} {
      font-size: 14px;
      margin-bottom: 18px;
    }
  `
);

export const NotPurchasedLeft = styled.div(
  ({ theme: { down } }) => `
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 20px;

    ${down('824px')} {
      max-width: 100%;
      gap: 0;
    }
  `
);

export const NotPurchasedBuyBtn = styled.button(
  ({ theme: { down } }) => `
    background: #ba8d5b;
    border-radius: 100px;
    width: 269px;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    padding: 11px 0 14px 0;
    border: 1px solid transparent;
    transition: 0.4s linear;

    &:hover {
      border: 1px solid #ba8d5b;
      background-color: #fff;
      color: #000000;
    }

    &:active {
      background: #a77946;
      color: #ffffff;
    }

    ${down('824px')} {
      display: block;
      margin: 0 auto;
    }
  `
);
