import { tryCatchSaga } from "@modules/auth/store";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { videosServices } from "../services/videos.service";
import { videosActions } from "./videos.slice";

export function* fetchRecentlyVideos({payload}) {
  const res = yield call(videosServices.recentlyVideos, payload);
  yield put(videosActions.fetchRecentyleVideosFulfilled(res.data))
}

export function* fetchBookmarkVideos({payload}) {
  const res = yield call(videosServices.fetchBookmarkVideos, payload);
  yield put(videosActions.fetchBookmarkVideosFulfilled(res.data))
}

export function* bookmarkVideo({payload}) {
  const res = yield call(videosServices.bookmarkVideo, payload.info);
  yield payload.callback(res);
}

export function* changePositionBookmarVideo({payload}) {
  const res = yield call(videosServices.changePositionBookmarVideo, payload);
  yield payload.callback(res);
}

export function* deleteBookmarkVideo({payload}) {
  const res = yield call(videosServices.deleteBookmarkVideo, payload.id);
  yield payload.callback(res);
}

export function* VideosRootSaga() {
  yield all([
    takeLatest(videosActions.fetchRecentyleVideos, tryCatchSaga(fetchRecentlyVideos)),
    takeLatest(videosActions.bookmarkVideo, tryCatchSaga(bookmarkVideo)),
    takeLatest(videosActions.fetchBookmarkVideos, tryCatchSaga(fetchBookmarkVideos)),
    takeLatest(videosActions.changePositionBookmarVideo, tryCatchSaga(changePositionBookmarVideo)),
    takeLatest(videosActions.deleteBookmarkVideo, tryCatchSaga(deleteBookmarkVideo)),
  ])
}