import { acceptedHomeworkStatus } from '@modules/certificates/constants';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RouteLinks } from 'src/constants/route';

export const useHomeworkProp = () => {
  const history = useHistory();
  const certificateDetail = useSelector(
    state => state.certificates?.certificateDetail
  );

  const handleClickLink = (homework, e) => {
    e.preventDefault();

    if (homework?.status === acceptedHomeworkStatus) {
      history.push(
        `${RouteLinks.homeworkDetail
          ?.replace(':id', homework?.chapterId)
          .replace(':studentId', certificateDetail?.student?.id)}${homework?.lessonId ? `?lessonId=${homework?.lessonId}` : ''}`
      );
    }
  };

  return {
    certificateDetail,
    handleClickLink,
  };
};
