import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useCourseProgressProp } from './course-progress.prop';
import { ReactComponent as IconCourse } from '@assets/img/icon-course.svg';
import {
  CourseProgressBar,
  CourseProgressEnded,
  CourseProgressIconBtn,
  CourseProgressInfo,
  CourseProgressSubtitle,
  CourseProgressTitle,
  CourseProgressWrapper
} from './course-progress.style';

export const CourseProgress = hoc(
  useCourseProgressProp,
  ({ courseTitle, category, progress, color, link }) => {
    return (
      <CourseProgressWrapper to={`/my-course-single/${link}`}>
        <FlexWrapper gap='16px' mb='18px'>
          <CourseProgressIconBtn color={color}>
            <IconCourse fill='#ba8d5b' />
          </CourseProgressIconBtn>

          <FlexWrapper direction='column' gap='4px'>
            <CourseProgressTitle>{courseTitle}</CourseProgressTitle>

            <CourseProgressSubtitle>{category}</CourseProgressSubtitle>
          </FlexWrapper>
        </FlexWrapper>

        <CourseProgressInfo>
          {progress === 100
            ? 'Videodarslar yakunlangan'
            : `${progress}% videodarslar yakunlangan`}
        </CourseProgressInfo>
        <CourseProgressBar>
          <CourseProgressEnded width={progress} />
        </CourseProgressBar>
      </CourseProgressWrapper>
    );
  }
);
