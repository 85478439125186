import { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { AllCoursesActions } from '@all-courses/store'
import { config } from '@config/config'
import { commonActions } from '@store/common'
import { useMediaQuery } from '@utils/useMediaQuery'
import { parseQueryToString } from '@utils/get-query'
import { CourseTypes } from '@modules/all-courses/constants'
import { useHideRecaptchaVisibility } from '@utils/useHideRecaptchaVisibility'

export const useAllCoursesSingle = () => {
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false)
  const [isOpenPayzeModal, setIsOpenPayzeModal] = useState(false)
  const [openedModule, setOpenedModule] = useState(false)
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const history = useHistory()
  const location = useLocation()
  const singleCourse = useSelector((state) => state.courses.singleCourse)
  const purchasingModule = useSelector((state) => state.courses?.purchasingModule)
  const isShowPayzeModal = useSelector((state) => state.common.payzeModal)
  const isShowUpdateInfoModal = useSelector((state) => state.courses.updateInfoModal)
  const isShowConfirmModal = useSelector((state) => state.courses.confirmCodeModal)
  const mqMaxWidth1110 = useMediaQuery('(1016px <= width <= 1110px)')
  const mqMaxWidth1015 = useMediaQuery('(max-width: 1015)')
  const mqMaxWidth820 = useMediaQuery('(max-width: 820px)')
  const mqMaxWidth685 = useMediaQuery('(max-width: 685px)')
  const mqMaxWidth650 = useMediaQuery('(max-width: 650px)')
  const mqMaxWidth600 = useMediaQuery('(max-width: 600px)')
  const mqMaxWidth370 = useMediaQuery('(max-width: 370px)')
  useHideRecaptchaVisibility(true)
  const [isAllPurchasedModalShown, setIsAllPurchasedModalShown] = useState(false)
  const [isModulePurchased, setIsModulePurchased] = useState(false)
  const singleCourseInfo = useSelector((state) => state.courses.singleCourse)

  const handleGoLanding = () => {
    window.open(config.landing + '/courses/' + singleCourse?.id, '_blank')
  }

  const search = useMemo(() => parseQueryToString(location.search), [location.search])

  const isAllModuleBought = useMemo(() => {
    return singleCourse?.modules?.every((module) => module.isPaid)
  }, [singleCourse?.modules])

  useEffect(() => {
    if (isAllModuleBought) {
      setIsModulePurchased(false)
    }
  }, [isAllModuleBought])

  const handleClose = () => {
    setIsAllPurchasedModalShown(false)
    setIsModulePurchased(false)
    dispatch(AllCoursesActions.setPurchasingModule(search?.buy))
    history.push(location.pathname)
  }

  const handleOpenPaymentModal = () => {
    if (isAvailableBuy) return
    setIsOpenPaymentModal(true)
  }

  const isAvailableBuy = useMemo(() => {
    return singleCourse?.modules?.every((module) => module.isPaid) || singleCourse.status === 3
  }, [singleCourse])

  const handleClosePaymentModal = () => {
    setIsOpenPaymentModal(false)
    dispatch(AllCoursesActions.setPurchasingModule(search?.buy))
    history.push(location.pathname)
  }

  const unMoundPayzeModal = () => {
    dispatch(commonActions.setPayzeModal(false))
    history.push(location.pathname)
  }

  const isPaidInThisCourse = useMemo(() => {
    const moduleId = singleCourse?.modules?.find((module) => module?.id === Number(search?.buy))

    return moduleId?.isPaid
  }, [singleCourse, search?.buy])

  useEffect(() => {
    dispatch(commonActions.setIsHasButton({ hasButton: true, link: '/courses' }))
    dispatch(commonActions.setSubHeaderTitle('Kurs dasturi'))
    dispatch(commonActions.setSubheader(true))

    return () => {
      dispatch(commonActions.setIsHasButton({}))
      dispatch(commonActions.setSubHeaderTitle(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!courseId) {
      return
    }

    dispatch(
      AllCoursesActions.fetchSingleCourse({
        id: courseId,
        callback: (res) => {
          if (res?.data === null && !res?.success) {
            history.push('/not-found')
          }
        },
      }),
    )
  }, [])

  useEffect(() => {
    if (isAvailableBuy && search.buy === 'all') {
      setIsAllPurchasedModalShown(true)
    } else if (search.buy) {
      setIsModulePurchased(true)
    }
  }, [isAvailableBuy, search.buy])

  useEffect(() => {
    if ((search?.buy && singleCourse.modules?.length) || purchasingModule) {
      singleCourse?.modules?.forEach((item) => {
        if (search?.buy === String(item.id) || purchasingModule === String(item.id)) {
          setOpenedModule(search.buy)

          const info = {
            id: item.id,
            callback: (res) => {
              const updatedCourseModules = singleCourseInfo.modules.map((module) => {
                if (module.id === item.id) {
                  return {
                    ...module,
                    chapters: res?.data,
                  }
                }

                return module
              })

              const updatedCourses = {
                ...singleCourseInfo,
                modules: updatedCourseModules,
              }

              dispatch(AllCoursesActions.setSingleCourse(updatedCourses))
            },
          }
          dispatch(AllCoursesActions.fetchModuleChapters(info))
        }
      })
    }
  }, [search.buy, singleCourse.modules?.length, purchasingModule, location.pathname])

  useEffect(() => {
    if (search.buy) {
      setIsOpenPaymentModal(true)
    } else if (isShowPayzeModal || isModulePurchased || isAllPurchasedModalShown) {
      setIsOpenPaymentModal(false)
    }
  }, [search.buy, singleCourse, isShowPayzeModal, isModulePurchased, isAllPurchasedModalShown])

  useEffect(() => {
    setIsOpenPayzeModal(isShowPayzeModal)
  }, [isShowPayzeModal])

  useEffect(() => {
    if (singleCourse?.id && singleCourse?.courseType === CourseTypes.close) {
      window.location = `${singleCourse?.detailUrl}`
    }
  }, [singleCourse])

  return {
    handleGoLanding,
    handleOpenPaymentModal,
    isOpenPaymentModal,
    setIsOpenPaymentModal,
    handleClosePaymentModal,
    singleCourse,
    mqMaxWidth1110,
    mqMaxWidth1015,
    mqMaxWidth820,
    mqMaxWidth685,
    mqMaxWidth650,
    mqMaxWidth600,
    mqMaxWidth370,
    courseId,
    isShowPayzeModal,
    isOpenPayzeModal,
    setIsOpenPayzeModal,
    isAvailableBuy,
    unMoundPayzeModal,
    isAllPurchasedModalShown,
    setIsAllPurchasedModalShown,
    handleClose,
    isAllModuleBought,
    isModulePurchased,
    isShowUpdateInfoModal,
    isShowConfirmModal,
    isPaidInThisCourse,
  }
}
