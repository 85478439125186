import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';

export const Wrapper = styled(Scrollable)(
  ({ theme: { down } }) => `
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 14px;

  ${down('576px')} {
    padding-bottom: 50px;
    padding-right: 8px;
  }
`
);

export const WrapperInner = styled.div(
  () => `
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  `,
  ({ theme: { down } }) => `
    ${down('768px')} {
      padding: 16px 16px 24px 16px;
    }
  `
);

export const ALlCoursesWrapperTitle = styled.h3(
  ({ theme: { down } }) => `
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 24px;

  ${down('768px')} {
    font-size: 20px;
    line-height: 28px;
  }
  ${down('460px')} {
    font-size: 16px;
    line-height: 19px;
  }
  `
);

export const NoCourseTitle = styled.h4(
  ({ theme: { down } }) => `
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #ba8d5b;
  margin-bottom: 33px;
  text-align: center;
  padding: 0 15px;

  ${down('768px')} {
    font-size: 16px;
  }
  ${down('460px')} {
    font-size: 14px;
  }
`
);

export const NoCourseImg = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

export const CategoryBtn = styled.button(
  ({ theme: { down } }) => `
  background: #f7f7f5;
  border-radius: 32px;
  padding: 15px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  outline: none;
  border: 1px solid transparent;
  color: rgba(0, 0, 0, 0.7);
  transition: all ease 0.3s;

  &:hover {
    background: #ffffff;
    color: #000000;
    border: 1px solid #ba8d5b;
  }

  &:active {
    background: #a77946;
    color: #ffffff;
    border: 1px solid #fff;
  }

  &.active {
    background: #ba8d5b;
    color: #ffffff;
  }

  ${down('768px')} {
    padding: 7px 20px;
  }
`
);

export const AllCoursesWrapper = styled.div(
  () => `
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 380px));
  justify-content: space-between;
  gap: 24px;

  `,
  ({ theme: { down }, threeCardsWithSidebar, twoCardsWithSidebar, oneCardWithSidebar, justifyContent, justifyContentCenter }) => `
    ${down('1846px')} {
      grid-template-columns: repeat(5, minmax(260px, 380px));
    }
    ${down('1562px')} {
      grid-template-columns: ${threeCardsWithSidebar ? 'repeat(3, minmax(260px, 380px)' : 'repeat(4, minmax(260px,380px)'});
    }
    ${down('1308px')} {
      grid-template-columns: ${twoCardsWithSidebar ? 'repeat(2, minmax(260px, 380px)' : 'repeat(3, minmax(260px,380px)'});
      justify-content: ${justifyContent ? 'space-evenly' : 'space-between'};
    }
    ${down('1024px')} {
      grid-template-columns: ${oneCardWithSidebar ? 'repeat(auto-fill, minmax(260px, 380px)' : 'repeat(2, minmax(260px,380px)'});
      justify-content: ${justifyContentCenter ? 'center' : 'space-evenly'};
    }
    ${down('724px')} {
      grid-template-columns: repeat(auto-fill, minmax(260px,380px));
      justify-content: center;
    }

  `
)

export const AllCoursesCategoriesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`

export const AllCoursesSkeletonWrapper = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
`
