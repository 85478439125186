import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { hoc } from '@utils/hoc';
import { useMyCoursesProp } from './my-courses.prop';
import EmptyBox from '@assets/img/empty-box.png';
import {
  AllCourses,
  BuyButton,
  EmptyCourseImg,
  EmptyCourseTitle,
  EmptyTitle,
  RecommendedWrapper,
  Wrapper,
  WrapperInner,
  WrapperTitle,
  WrapperTitleForRecommend
} from './my-courses.style';
import { CourseCard } from '@components/course-card';
import { MyCourseCard } from '@all-courses/components/my-course-card';
import { memo } from 'react';
import MyCoursesSkeleton from './my-courses-skeleton';
import { LastSeenRecoSkeleton } from '@modules/dashboard/routes/dashboard/dashboard-skeleton';

export const MyCourses = memo(
  hoc(
    useMyCoursesProp,
    ({
      handleBuyCourses,
      myCourses,
      recommendedCourses,
      recommendedCourseLoading,
      allMyCourses,
      archivedCourses,
      sidebarState,
      myCoursesLoading
    }) => {

      return (
        <Wrapper>
          {myCoursesLoading ? (
            <MyCoursesSkeleton />
          ) : myCourses?.length ? (
            allMyCourses.map(item => (
              <MyCourseCard
                key={item.id}
                title={item?.name}
                progress={item?.progress}
                lessonsCount={item?.lessonsCount}
                duration={item?.courseDuration}
                id={item?.id}
                img={item?.imageIllustration}
                color={item?.color}
              />
            ))
          ) : (
            <WrapperInner>
              <WrapperTitle>Mening kurslarim</WrapperTitle>

              <FlexWrapper direction='column' align='center'>
                <EmptyTitle>
                  Hozirda sizda hech qanday kurs mavjud emas
                </EmptyTitle>

                <BuyButton onClick={handleBuyCourses}>
                  Kurs xarid qilish
                </BuyButton>
              </FlexWrapper>
            </WrapperInner>
          )}

          {myCoursesLoading ? (
            <MyCoursesSkeleton />
          ) : (
            myCourses?.length <= 1 && (
              <WrapperInner top={true}>
                <FlexWrapper mb='28px' justify='space-between' align='center'>
                  <WrapperTitleForRecommend>
                    Sizga tavsiya qilamiz
                  </WrapperTitleForRecommend>

                  <AllCourses to='/courses'>
                    {recommendedCourses?.length > 6
                      ? 'Barcha kurslarni ko‘rish'
                      : ' '}
                  </AllCourses>
                </FlexWrapper>

                {recommendedCourseLoading ? (
                  <LastSeenRecoSkeleton />
                ) : recommendedCourses?.length > 0 ? (
                  <RecommendedWrapper sidebarState={sidebarState}>
                    {recommendedCourses?.slice(0, 6)?.map(item => (
                      <CourseCard
                        className='available'
                        moduleName={item?.name}
                        category={item?.category?.name}
                        moduleCount={item?.lessonsCount}
                        available={item.isComingSoon}
                        key={item.id}
                        imgColor={item?.color}
                        color={item?.category?.color}
                        textColor={item?.category?.textColor}
                        img={item?.imageIllustration}
                        link={`/course-single/${item.id}`}
                        courseType={item.courseType}
                        closeLink={item?.detailUrl}
                      />
                    ))}
                  </RecommendedWrapper>
                ) : (
                  <FlexWrapper
                    justify='center'
                    align='center'
                    direction='column'
                    gap='32px'
                    width='100%'
                  >
                    <EmptyCourseTitle>
                      Hozirda platformada hech qanday kurs mavjud emas
                    </EmptyCourseTitle>

                    <EmptyCourseImg src={EmptyBox} />
                  </FlexWrapper>
                )}
              </WrapperInner>
            )
          )}

          {myCoursesLoading ? (
            <MyCoursesSkeleton />
          ) : (
            archivedCourses?.length > 0 && (
              <WrapperInner top>
                <WrapperTitle small={true}>Arxivlangan kurslar</WrapperTitle>
                <>
                  {archivedCourses.map(item => (
                    <MyCourseCard
                      small={true}
                      key={item.id}
                      title={item?.name}
                      teacher={
                        (item?.teacher?.firstName || '-') +
                        ' ' +
                        (item?.teacher?.lastName || '-')
                      }
                      teacherImg={item?.teacher?.photo}
                      progress={item?.progress}
                      lessonsCount={item?.lessonsCount}
                      duration={item?.courseDuration}
                      id={item?.id}
                      img={item?.imageIllustration}
                    />
                  ))}
                </>
              </WrapperInner>
            )
          )}
        </Wrapper>
      );
    }
  ),
  []
);
