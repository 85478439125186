import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
`;

export const StyledInnerHeaderCore = styled.div(
  () => `
    align-items: center;
    position: relative;
    height: 6.4rem;
    padding: 0 6rem 0 1.6rem;
    display: flex;
    border-bottom: 1px solid #eae9e7;
    justify-content: space-between;
  `,

  ({ theme: { down } }) => `
    ${down('986px')} {
      padding: 1rem 2.4rem 1.6rem;
    }
  `
);

export const StyledInnerHeader = styled(StyledInnerHeaderCore)`
  background-color: ${props => props.theme.colors.white};
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  height: 30px;
`;

export const StyledImg = styled.img`
  width: auto;
`;

export const HeaderRight = styled.div``;

export const BurgerBtn = styled.button(
  () => `
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 28px;
    padding: 0;

    &.active {
      background-color: #f8f7ef;
    }
  `,

  ({ theme: { down } }) => `
    ${down('576px')} {
      display: none;
    }
  `
);
