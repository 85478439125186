import styled from 'styled-components';

export const Modal = styled.div`
  align-content: center;
  align-items: center;
  display: grid;
  height: 100%;
  justify-items: center;
  position: relative;
  width: 100%;

  .player__iframe {
    margin: 0;
    padding: 0;
    border: 0;
    position: relative;
    z-index: 4;
    width: 100%;
  }
`;

export const ModalWrapper = styled.div(
  () => `
    overflow: hidden;
    position: relative;
    width: 80%;
    background: #000;
   `,
  ({ theme: { down } }) => `
    ${down('968px')} {
      height: 100%;
      width: 100%;
    }
   `
);

export const ModalWrapperTop = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.01) 90%
    ),
    rgba(0, 0, 0, 0) 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 32px 24px;
  top: 0;
  transition: all linear 0.5s;
  width: 100% !important;
  z-index: 22;
`;

export const ModalTitleWrapper = styled.div``;

export const ModalSubtitle = styled.h4`
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 22px;
  margin: 0 0 4px 0;
`;

export const ModalTitle = styled.h3`
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 33px;
  margin: 0;
`;

export const ModalCloseBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 858;

  img {
    height: 36px;
    width: 36px;
  }
`;

export const Loader = styled.span`
  width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 5px solid #e39f55;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    z-index: 1;
  
    @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    } 
`
