import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AllCoursesActions } from '@all-courses/store'
import { commonActions } from '@store/common'
import { useMediaQuery } from '@utils/useMediaQuery'
import { hideCaptchaBadge } from '@utils/toggleRecaptchaBadge'
import { useHideRecaptchaVisibility } from '@utils/useHideRecaptchaVisibility'

export const useAllCoursesProp = () => {
  const [active, setActive] = useState('all')
  const [isDropDown, setIsDropDown] = useState(false)
  const [emptyError, setEmptyError] = useState("Bu kategoriya bo'yicha hech qanday kurs mavjud emas")
  const [id, setId] = useState(null)
  const dispatch = useDispatch()
  const categories = useSelector((state) => state.courses.categories.courseCategories)
  const categoriesRef = useRef(null)
  const allCourses = useSelector((state) => state.courses.allCourses?.courses)
  const allCourseLoading = useSelector((state) => state.courses.allCourseLoading)
  const loading = useSelector((state) => state.courses.loading)
  const myCourses = useSelector((state) => state.common.myCourses)
  const sidebarState = useSelector((state) => state.common.sidebarState)
  const [selectedOption, setSelectedOption] = useState(null)
  const mq768MaxWidth = useMediaQuery('(max-width: 768px)')
  const mq1562MaxWidth = useMediaQuery('(1292px <= width <= 1562px)')
  const mq1292MaxWidth = useMediaQuery('(1025px <= width <= 1292px)')
  const mq870MaxWidth = useMediaQuery('(768px <= width <= 870px)')
  useHideRecaptchaVisibility(true)

  useEffect(() => {
    if (mq768MaxWidth && categoriesRef?.current?.clientHeight > 130) {
      setIsDropDown(true)
    } else if (categoriesRef?.current?.clientHeight > 181) {
      setIsDropDown(true)
    }
  }, [categoriesRef?.current?.clientHeight, mq768MaxWidth])

  const handleSetActiveBtn = useCallback(
    (status) => {
      setActive(status.name)
      setId(status.id)
      dispatch(AllCoursesActions.fetchAllCourses(status.id))
    },
    [setActive],
  )

  useEffect(() => {
    return () => dispatch(AllCoursesActions.fetchAllCoursesFulfilled([]))
  }, [])

  const handleToggleDropDown = (value) => dispatch(AllCoursesActions.fetchAllCourses(value))

  const options = useMemo(
    () =>
      categories?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        }
      }),
    [categories],
  )

  const allOptions = useMemo(
    () =>
      options
        ? [
            {
              label: 'Barchasi',
              value: 0,
            },
            ...options,
          ]
        : [],
    [options],
  )

  useEffect(() => {
    dispatch(AllCoursesActions.fetchAllCourses(0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    const cleanup = () => {
      dispatch(AllCoursesActions.fetchCategoriesFulfilled([]))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    dispatch(AllCoursesActions.fetchSingleCourseFulfilled({}))
    dispatch(AllCoursesActions.fetchCategories())

    return cleanup
  }, [dispatch, AllCoursesActions, commonActions])

  const categoryId = useMemo(() => categories?.filter((category) => category.id === id), [categories, id])

  const myCoursesId = useMemo(() => myCourses?.filter((myCourse) => myCourse.category.id === id), [myCourses, id])

  useEffect(() => {
    if (active === 'all') {
      if (!myCourses?.length && !allCourses?.length) {
        setEmptyError('Hozirda platformada hech qanday kurs mavjud emas')
      } else if (myCourses?.length && !allCourses?.length) {
        setEmptyError('Hozirda platformada hech qanday yangi kurs mavjud emas')
      }
    } else if (!allCourses?.length) {
      if (!myCoursesId?.length && categoryId?.length) {
        setEmptyError('Bu kategoriya bo‘yicha hech qanday kurs mavjud emas')
      } else if (myCoursesId?.length && categoryId?.length) {
        setEmptyError('Bu kategoriya bo‘yicha hech qanday yangi kurs mavjud emas')
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, allCourses?.length, myCourses?.length, categoryId, myCoursesId])

  useEffect(() => {
    dispatch(commonActions.setSubHeaderTitle('Barcha kurslar'))
    dispatch(commonActions.setSubheader(true))
    hideCaptchaBadge()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    active,
    handleSetActiveBtn,
    categories,
    emptyError,
    allCourses,
    sidebarState,
    allOptions,
    selectedOption,
    setSelectedOption,
    handleToggleDropDown,
    categoriesRef,
    isDropDown,
    mq1562MaxWidth,
    mq1292MaxWidth,
    mq870MaxWidth,
    allCourseLoading,
  }
}
