import { FlexWrapper } from '@components/layouts/flex-wrapper'
import { Skeleton } from '@components/skeleton/skeleton-box'
import React from 'react'
import { AllCoursesCategoriesWrapper, AllCoursesSkeletonWrapper, AllCoursesWrapper } from './all-courses.style'
import { useAllCoursesProp } from './all-courses.prop'
import { VideoCardItem } from '@modules/videos/components/video-card-style'

export default function AllCoursesTabSkeleton() {
  return (
    <FlexWrapper
      align='center'
      wrap='true'
      mb='30px'
      gap='16px'
    >
      <Skeleton width={105} height={50} borderRadius={32} />
      <Skeleton width={105} height={50} borderRadius={32} />
      <Skeleton width={105} height={50} borderRadius={32} />
    </FlexWrapper>
  )
}

export function AllCoursesSkeleton({
  threeCardsWithSidebar,
  twoCardsWithSidebar,
  oneCardWithSidebar,
  justifyContent,
  justifyContentCenter,
}) {
  return (
    <AllCoursesWrapper
      threeCardsWithSidebar={threeCardsWithSidebar}
      twoCardsWithSidebar={twoCardsWithSidebar}
      oneCardWithSidebar={oneCardWithSidebar}
      justifyContent={justifyContent}
      justifyContentCenter={justifyContentCenter}
    >
      <AllCoursesSkeletonWrapper>
        <Skeleton height={180} marginBottom={12}
          fullWidth={100} />
        <AllCoursesCategoriesWrapper>
          <Skeleton width={130} height={28} />
          <Skeleton width={130} height={28} />
        </AllCoursesCategoriesWrapper>
        <Skeleton fullWidth={100} height={28} />
      </AllCoursesSkeletonWrapper>
      <AllCoursesSkeletonWrapper>
        <Skeleton height={180} marginBottom={12}
          fullWidth={100} />
        <AllCoursesCategoriesWrapper>
          <Skeleton width={130} height={28} />
          <Skeleton width={130} height={28} />
        </AllCoursesCategoriesWrapper>
        <Skeleton fullWidth={100} height={28} />
      </AllCoursesSkeletonWrapper>
      <AllCoursesSkeletonWrapper>
        <Skeleton height={180} marginBottom={12}
          fullWidth={100} />
        <AllCoursesCategoriesWrapper>
          <Skeleton width={130} height={28} />
          <Skeleton width={130} height={28} />
        </AllCoursesCategoriesWrapper>
        <Skeleton fullWidth={100} height={28} />
      </AllCoursesSkeletonWrapper>
      <AllCoursesSkeletonWrapper>
        <Skeleton height={180} marginBottom={12}
          fullWidth={100} />
        <AllCoursesCategoriesWrapper>
          <Skeleton width={130} height={28} />
          <Skeleton width={130} height={28} />
        </AllCoursesCategoriesWrapper>
        <Skeleton fullWidth={100} height={28} />
      </AllCoursesSkeletonWrapper>
      <AllCoursesSkeletonWrapper>
        <Skeleton height={180} marginBottom={12}
          fullWidth={100} />
        <AllCoursesCategoriesWrapper>
          <Skeleton width={130} height={28} />
          <Skeleton width={130} height={28} />
        </AllCoursesCategoriesWrapper>
        <Skeleton fullWidth={100} height={28} />
      </AllCoursesSkeletonWrapper>

    </AllCoursesWrapper>
  )
}
