import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

export const useCategoryDropDownProps = ({
  defaultValue,
  onChange = () => null
}) => {
  const [open, setOpen] = useState(false);
  const [activeValue, setActiveValue] = useState('Barchasi');
  const dropdownRef = useRef(null);

  const toggleSelect = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (defaultValue) {
      setActiveValue(defaultValue);
    }
  }, [defaultValue]);

  const _onChange = (value, e) => {
    e.preventDefault();
    setOpen(false);
    onChange?.(value?.value);
    setActiveValue(value?.label);
  };

  return {
    toggleSelect,
    open,
    _onChange,
    activeValue,
    dropdownRef
  };
};
