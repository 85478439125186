import { hoc } from '@utils/hoc';

import { useDropDownProps } from './drop-down.props';

import {
  DropDownHolder,
  DropDownItemsWrapper,
  DropDownTitle,
  DropDownItem
} from './drop-down.style';

export const DropDownComponent = hoc(
  useDropDownProps,
  ({ open, options, activeValue, toggleDropdown, _onChange, courseAward, perPage }) => {
    return (
      <DropDownHolder>
        <DropDownTitle className={`${open ? 'open' : ''}`} data-testid='field-dropdown' onClick={toggleDropdown}>
          {activeValue.label || 'Barcha kurslar'}
        </DropDownTitle>

        <DropDownItemsWrapper perPage={perPage} courseAward={courseAward} active={open}>
          {options?.map((item, index) => (
            <DropDownItem
              key={index}
              mine={item?.isPaid}
              active={item?.value === activeValue?.value}
              data-testid={'field-dropdown-option-' + item.value}
              onClick={_onChange.bind(null, item.value)}
            >
              {item.label}
            </DropDownItem>
          ))}
        </DropDownItemsWrapper>
      </DropDownHolder>
    );
  }
);
