import { useEffect } from 'react';

export const useInputProps = ({ onChange, value, name }) => {
  const _onChange = e => {
    const value = e.target.value;
    onChange?.(value);
  };

  useEffect(() => {
    if (value === undefined || value === null) {}
  }, [value, name]);

  return {
    _onChange
  };
};
