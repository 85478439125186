import { api } from '@services';

export class NotificationService {
  constructor() {
    this.api = api;
  }

  getNotifications = props =>
    this.api.get(
      `/student/notifications?page=${props?.page}&perPage=10&${
        props.actionType && `actionType=${props?.actionType}`
      }`
    );

  readNotification = id => this.api.patch(`/student/notification/${id}/read`);

  getSingleNotification = id => this.api.get(`/student/notification/${id}`);
}

export const notificationService = new NotificationService();
