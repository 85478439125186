export const phoneFormatter = (str) => {
    if(!str) return ''
    str = '+' + str
    str = str.split('')
    const country = str.slice(0, 4).join('')
    const company = str.slice(4, 6).join('');
    const firstThird = str.slice(6, 9).join('');
    const additionaldouble = str.slice(9, 11).join('')
    const lastAdditionalDouble = str.slice(11, 13).join('')
    return `${country} ${company} ${firstThird} ${additionaldouble} ${lastAdditionalDouble}`
}