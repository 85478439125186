import { addNotification } from '@components/notifications'
import { CAPTCHA_ACTIONS } from '@constants/app'
import { authActions } from '@modules/auth/store'
import {
  ANOTHER_EMAIL,
  EMAIL_REQUIRED,
  emailReg,
  LOGIN_TYPES,
  MIN_NAME,
  NAME_REQUIRED,
  PASSWORD,
  passwordRgp,
  PASSWORD_NOT_MATCH,
  PASSWORD_REQUIREMENT,
  PROBLEM_WITH_EMAIL,
  REQUIRED_PASSWORD,
  TEXT,
  alphaNumericLettersReg,
  invalid,
  LOGIN_ALREADY_EXISTS,
  newPasswordReg,
  passwordRequirementsRegs,
} from '@modules/new-auth/constants'
import { api } from '@services/base.service'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

const initialValues = {
  firstName: '',
  email: '',
  password: '',
  password_check: '',
}

const MANY_REQUESTS = 157
const FIRSTNAME_MINIMUM_LENGTH = 3

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(3, MIN_NAME).required(MIN_NAME),
  email: Yup.string().email().required(EMAIL_REQUIRED),
  password: Yup.string().matches(passwordRgp, PASSWORD_REQUIREMENT).required(PASSWORD_REQUIREMENT),
  password_check: Yup.string()
    .oneOf([Yup.ref('password'), null], PASSWORD_NOT_MATCH)
    .required(PASSWORD_NOT_MATCH),
})

export const useEmailFormProp = () => {
  const [inputType, setInputType] = useState(PASSWORD)
  const [checkInputType, setCheckInputType] = useState(PASSWORD)
  const [hasChecker, setHasChecker] = useState(false)
  const [hasPasswordLength, setHasPasswordLength] = useState(false)
  const [isIncludeNumber, setIsIncludeNumber] = useState(false)
  const dispatch = useDispatch()
  const supportLink = useSelector((state) => state.common.supportLink?.[0]?.link)
  const emailConfirmModal = useSelector((state) => state.auth.confirmEmailModal)
  const emailNotification = useSelector((state) => state.auth.emailNotification)
  const isCaptchaError = useSelector((state) => state.auth?.isShowCaptchaError)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleChangeNewPasswordType = () => {
    setInputType(inputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleChangeCheckInputType = () => {
    setCheckInputType(checkInputType === PASSWORD ? TEXT : PASSWORD)
  }

  const handleSubmit = async (values) => {
    try {
      const token = await executeRecaptcha(CAPTCHA_ACTIONS.signUp)

      api.setHeader('recaptcha', token)

      const registerInfo = {
        data: {
          login: values.email,
          password: values.password,
          loginType: LOGIN_TYPES.email,
          firstName: values.firstName,
        },
        callback: (res) => {
          if (!res.success && res.error.errId === LOGIN_ALREADY_EXISTS) {
            formik.setErrors({
              email: res.error.errMsg,
            })
            return
          }

          if (res.success) {
            api.setHeader('recaptcha', '')
            dispatch(
              authActions.setConfirmPhone({
                email: values.email,
                leftTime: res?.data?.leftTime,
              }),
            )
            dispatch(authActions.setInitialEmail(values.email))
            dispatch(authActions.setInitialPassword(values.password))
            dispatch(authActions.setConfirmEmailModal(true))
          }

          if (res?.error?.errId === MANY_REQUESTS) {
            dispatch(authActions.setEmailNotificationModal(true))
          }

          if (res?.error?.errId === ANOTHER_EMAIL) {
            formik.setErrors({
              email: res.error.errMsg,
            })
            return
          }

          if (res.error?.errId) {
            formik.setErrors({
              firstName: res.error.errMsg,
              email: res.error.errMsg,
              password: res.error.errMsg,
              password_check: res.error.errMsg,
            })
            dispatch(authActions.setShowCaptchaError(true))
          }
        },
      }

      await dispatch(authActions.register(registerInfo))
    } catch (error) {
      console.error('Registration error:', error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })

  const handleBlurUserName = () => {
    const firstName = formik.values.firstName
    if (firstName.length === 0) {
      formik.setErrors({
        ...formik.errors,
        firstName: NAME_REQUIRED,
      })
    }

    if (firstName.length > 0 && firstName.length < 3) {
      formik.setErrors({
        ...formik.errors,
        firstName: MIN_NAME,
      })
    }
  }

  const handleBlurEmail = () => {
    const emailLength = formik.values.email.length
    if (emailLength === 0) {
      formik.setErrors({ ...formik.errors, email: EMAIL_REQUIRED })
    }

    if (!formik.values.email.match(emailReg)) {
      formik.setErrors({
        ...formik.errors,
        email: PROBLEM_WITH_EMAIL,
      })
    }
  }

  const handleChangeEmail = (e) => {
    formik.handleChange(e)

    formik.setErrors({
      ...formik.errors,
      email: '',
    })
  }

  const handleChangeUserName = (e) => {
    formik.setErrors({
      ...formik.errors,
      firstName: '',
    })
    if (alphaNumericLettersReg.test(e.target.value)) {
      formik.setErrors({ firstName: invalid })
    }
    const encodedValue = e.target.value.replace(alphaNumericLettersReg, '')

    formik.setFieldValue('firstName', encodedValue)
  }

  const handleFocusPassword = () => {
    setHasChecker(true)
    formik.setErrors({
      ...formik.errors,
      password: '',
    })
  }

  const handleBlurPassword = () => {
    setHasChecker(false)
    const formikValues = formik.values

    if (formikValues.password.length === 0) {
      formik.setErrors({
        ...formik.errors,
        password: REQUIRED_PASSWORD,
      })
      return
    }

    if (formik.values.password !== formik.values.password_check) {
      formik.setErrors({
        ...formik.errors,
        password_check: PASSWORD_NOT_MATCH,
      })
    } else {
      formik.setErrors({
        ...formik.errors,
        password_check: '',
      })
    }

    if (!passwordRequirementsRegs.every((req) => req.test(formikValues.password))) {
      formik.setErrors({
        ...formik.errors,
        password: PASSWORD_REQUIREMENT,
      })
    }
  }

  const handleChangePassword = (e) => {
    formik.handleChange(e)
    const passwordValue = e.target.value
    if (newPasswordReg.test(passwordValue)) {
      formik.setErrors({ password: PASSWORD_REQUIREMENT })
    }
    formik.setErrors({
      ...formik.errors,
      password: '',
    })
  }

  const handleBlurPasswordCheck = () => {
    const formikValues = formik.values
    if (formikValues.password !== formikValues.password_check || formikValues.password.length === 0) {
      formik.setErrors({
        ...formik.errors,
        password_check: PASSWORD_NOT_MATCH,
      })
    }
  }

  const handleChangePasswordCheck = (e) => {
    formik.handleChange(e)
    const value = e.target.value
    if (value !== formik.values.password) {
      formik.setErrors({
        ...formik.errors,
        password_check: PASSWORD_NOT_MATCH,
      })
    } else {
      formik.setErrors({
        ...formik.errors,
        password_check: '',
      })
    }
  }

  useEffect(() => {
    if (emailNotification) {
      addNotification({
        title: 'Urinishlar soni tugadi!',
        description: 'Iltimos, 10 daqiqadan keyin qayta urinib ko’ring.',
        options: {
          type: 'error',
          autoClose: true,
        },
      })
      dispatch(authActions.setEmailNotificationModal(false))
    }

    return () => dispatch(authActions.setShowCaptchaError(false))
  }, [emailNotification])

  return {
    formik,
    handleChangeNewPasswordType,
    handleChangeCheckInputType,
    checkInputType,
    inputType,
    hasChecker,
    hasPasswordLength,
    isIncludeNumber,
    handleBlurUserName,
    handleChangeUserName,
    handleFocusPassword,
    handleBlurPassword,
    handleChangePassword,
    handleBlurPasswordCheck,
    handleChangePasswordCheck,
    handleChangeEmail,
    handleBlurEmail,
    emailConfirmModal,
    supportLink,
    isCaptchaError,
  }
}
