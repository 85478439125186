import styled from 'styled-components';

export const StyledNav = styled.nav`
  transition: all 0.32s ease;
  @media screen and (max-width: 1000px) and (orientation: landscape) and (max-height: 500px) {
    height: 100%;
    & ul {
      display: grid;
      grid-template-rows: repeat(auto-fit, minmax(44px, 1fr));
      height: 100%;
    }  
  }
  .sidebar-navs {
    width: 0;
    opacity: 0;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #000000;
  }
`;
