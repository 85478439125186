import {ratingActions} from "@modules/rating/store/rating.slice";
import {tryCatchSagaFactory} from "@utils/try-catch-saga";
import {fetchSagaFactory} from "@utils/fetch-saga-factory";
import { all } from 'redux-saga/effects';
import {ratingService} from "@modules/rating/services/rating.service";

export const tryCatchSaga = tryCatchSagaFactory(ratingActions)

export function* ratingRootSaga() {
  yield all([
    fetchSagaFactory({
      pattern: 'fetchRanks',
      actions: ratingActions,
      api: {
        method: (data) => ratingService.getRatings(data),
        callback: res => res?.data
      }
    }),
    fetchSagaFactory({
      pattern: 'fetchCourses',
      actions: ratingActions,
      api: {
        method: ratingService.getCourses,
        callback: res => res?.data
      }
    }),
  ])
}
