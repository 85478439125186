/* eslint-disable no-restricted-globals */
import React from 'react';
import { hoc } from '@utils/hoc';
import { useProtectedRouteProps } from './protected-route.props';
import { Redirect, Route } from 'react-router-dom';
import { RouteLinks } from 'src/constants/route';
import { MainLayout } from '@components/layouts/main-layout';

/**
 * <ProtectedRoute />
 */
export const ProtectedRoute = hoc(
  useProtectedRouteProps,
  ({ component: Component, profile, token, isLoggedIn, ...props }) => (
    <Route
      {...props}
      component={(...props) =>
        profile && token && !isLoggedIn ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={`${
              RouteLinks.auth.login
            }?redirect_inside=${location?.pathname.replace(/\/$/, '')}${
              location?.search
            }`}
          />
        )
      }
    />
  )
);
