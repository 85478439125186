import styled from 'styled-components';
import Calendar from '@assets/img/calendar.png';
import { FlexWrapper } from '@components/layouts/flex-wrapper';
import { Scrollable } from '@styles/scroll.style';
import Select from 'react-select';

export const ProfileUpdateWrapperStyled = styled(Scrollable)`
  max-height: 600px;
`;

export const Form = styled.form(
  () => `position: relative;
        user-select: none;
        .date-picker {
        display: none;
        }

        .custom-date-picker.sdp {
        width: 308px !important;
        position: absolute;
        top: -132px;
        z-index: 20;
        background: #ffffff;
        box-shadow: 8px 3px 22px 10px rgba(150, 150, 150, 0.11);
        border-radius: 16px;
        padding: 24px;
        user-select: none;
        left: 0;
        right: 0;
        margin: auto;

        .sdp--dates-grid {
            gap: 15px;
        }

        .sdp--header {
            padding-bottom: 20px;
            border-bottom: 0.8px solid #ba8d5b;
        }

        .sassy--option__active {
          padding: 4px;
        }

        .sassy--select__dropdown {
            max-height: 165px;
            overflow-y: scroll;
            margin: 10px -12px;
            padding: 12px;

            .sassy--option {
            font-size: 1.2rem;
            }

            &::-webkit-scrollbar {
            width: 2px;
            border-radius: 40px;
            }

            &::-webkit-scrollbar-track {
            background: #dadada;
            border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
            background: #ba8d5b;
            border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
            background: #555;
            }
        }

        .sassy--option__active {
            background-color: #ba8d5b;
        }

        .sassy--option {
            border: 1px solid transparent;
            &:hover {
            background-color: #ba8d5b;
            color: #fff;
            }
        }

        .sdp--text.sdp--text__inactive {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            text-transform: uppercase;
            color: #4e6575;
        }

        p {
            margin-bottom: 5px;
        }

        .sdp--square-btn.sdp--date-btn.sdp--text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            color: #000000;
            width: 24px;
            height: 24px;
            border: 1px solid transparent;
            transition: all ease 0.3s;
            border-radius: 50%;

            &.sdp--date-btn__selected {
            background: #ba8d5b;
            color: #ffffff;
            }

            &.sdp--text__inactive {
            color: #e7e2dd;
            }

            &:hover {
            border: 1px solid #ba8d5b;
            }
        }

        .sdp--square-btn.sdp--square-btn__shadowed.sdp--square-btn__outlined {
            border: 1px solid #ba8d5b;
            border-radius: 50%;
            object-fit: contain;
            width: 22px;
            height: 22px;

            &:hover {
            background: #e7e2dd;
            border: 1px solid transparent;

            svg {
                color: #ba8d5b;
            }
            }

            &:active {
            background: #ba8d5b;
            border: 1px solid transparent;

            svg {
                color: #fff;
            }
            }

            svg {
            display: block;
            width: 10px;
            height: 10px;
            object-fit: contain;
            }
        }

        .sdp--header__main {
            p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #000000;
            }
        }
        }
    `
);

export const InputLabels = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.35);
  display: block;
  margin-right: ${props => (props.isError ? '6px' : '')};

  &.error {
    color: #ea4335;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: ${props => (props.mb ? '24px' : '0')};
  position: relative;
  .wrapper {
    border-radius: 100px;
    overflow: hidden;
    border: 2px solid #dadada;

    &.focused {
      border: 2px solid #000;
    }

    &.error {
      border: 2px solid #e84949 !important;
    }
  }
  .password-input {
    border: none;
    outline: none;
  }

  &:first-child {
    margin-top: 24px;
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;

  span {
    visibility: hidden;
    background: #313131;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0001em;
    color: #ffffff;
    padding: 12px;
    position: absolute;
    top: 45px;
    z-index: 2;
    left: ${props => (props.huge ? '' : props.gender ? '-30px' : '-90px')};
    margin: auto;
    display: block;
    width: max-content;
    max-width: 277px;
    text-align: center;
    transition: all ease 0.2s;
  }

  &:hover {
    span {
      visibility: visible;

      @media only screen and (max-width: 986px) {
        right: ${props =>
          props.psw ? '-125px' : props?.modalPhone ? 'initial' : '-180px'};
        left: ${props => (props?.modalPhone ? '-90px' : 'auto')};
        top: 20px;
        max-width: ${props => (props.psw ? '150px' : 'auto')};
        min-width: ${props => (props.psw ? 'auto' : '200px')};
      }
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  display: block;
  text-align: center;
  background: #ba8d5b;
  border-radius: 100px;
  padding: 12px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: ${props => (props.max ? '0 auto' : 'initial')};
  margin-bottom: 24px;
  border: 1px solid transparent;
  cursor: pointer;
  max-width: ${props => (props.max ? '308px' : 'initial')};

  &:hover {
    background-color: #fff;
    border: 1px solid #ba8d5b;
    color: #000;

    span {
      color: #000;
    }
  }

  &:active {
    background: #a77946;
    color: #fff;

    span {
      color: #fff;
    }
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    display: inline-block;
    margin-left: 16px;
    transform: translateY(0);
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const UserImgContainer = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    .inner {
      visibility: visible;

      button {
        visibility: visible;
        display: flex;
      }
    }
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    right: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    visibility: hidden;
    overflow: hidden;
  }
`;

export const EditBtn = styled.button`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: none;
  outline: none;
  visibility: hidden;
  display: none;
  padding: 0;

  &:hover {
    &:last-child {
      svg {
        stroke: #ba8d5b;
      }
    }
  }

  &:first-child {
    margin-right: 8px;
  }

  svg {
    width: 13px;
    height: 10px;
    stroke: black;
  }
`;

export const UserImg = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
`;
export const ButtonInputWrapper = styled.div`
  position: absolute;
  right: ${props => (props.tablet ? '-19px' : ' -2px')};
  bottom: ${props => (props.tablet ? '-31px' : '4px')};
  display: inline-block;
  overflow: hidden;
  cursor: pointer;

  input[type='file']::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`;

export const ImageFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
`;

export const Btn = styled.button`
  background: ${props => (props.color ? props.color : '#BA8D5B')};
  border-radius: 50%;
  color: white;
  font-size: 22px;
  font-weight: 700;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin-left: ${props => props.ml};
  position: relative;
  z-index: 10;
  padding: 0;

  svg {
    fill: #fff;
    position: absolute;
  }
`;

export const ImgModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  z-index: 22;

  &.visible {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
`;

export const ImgModalInner = styled.div(
  () => `
    width: 400px;
    height: 400px;
    background-color: #fff;
    padding: 20px;
  `,

  ({ theme: { down } }) => `
    ${down('576px')} {
      max-width: 350px;
      max-height: 350px;
      width: 100%;
      height: 100%;
    }
  `
);

export const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const DateInput = styled.div`
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 100px;
  padding: 11px 16px 14px 16px;
  position: relative;
  cursor: pointer;

  &.error {
    border: 1px solid #e84949 !important;
  }
`;

export const CalendarImg = styled.div`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(${Calendar});
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  margin: auto;
`;

export const DateText = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: ${props => (props.date ? '#000' : 'rgba(0, 0, 0, 0.35)')};
  display: inline-block;
  user-select: none;
`;

export const TabletBtn = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(186, 141, 91, 0.1);
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TabletBtnsWrapper = styled(FlexWrapper)`
  transform: translateX(-16px);
`;

export const ErrorDisabledInputStyled = styled.span`
  font-size: 12px;
  opacity: 0.5;
  line-height: 17px;
`;


export const DateCalendarSelect = styled(Select)`
  width: 120px;
  position: relative;
  z-index: 100;
`;
