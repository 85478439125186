import { useSelector } from 'react-redux';

export const useCourseInfoProp = () => {
  const certificateDetail = useSelector(
    state => state.certificates.certificateDetail
  );

  return {
    certificateDetail
  };
};
