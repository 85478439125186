import { createSlice } from '@reduxjs/toolkit';

const name = 'AllCourses';

const initialState = {
  loading: false,
  allCourseLoading: true,
  myCoursesLoading: true,
  singleCourseLoading: true,
  activeLessonLoading: true,
  lastVideoLoading: true,
  singleModuleLoading: true,
  notesLoading: true,
  commentsLoading: true,
  error: null,
  categories: [],
  freeCourseModal: false,
  overlay: false,
  videoId: null,
  editNote: false,
  allCourses: [],
  singleCourse: {},
  singleModule: {},
  homework: {},
  studentCourses: [],
  courseDiscount: null,
  notes: [],
  isChange: false,
  comments: [],
  replyInfo: {},
  promoCode: {},
  activeLesson: null,
  activeModules: null,
  activeCourse: null,
  lastVideo: undefined,
  sendedHomeworks: null,
  defaultTab: null,
  availableEditNote: null,
  payzeToken: null,
  transactionId: '',
  appealModal: false,
  cancelAppealModal: false,
  confirmDeleteModal: false,
  isSaveCard: false,
  reportedCommentId: null,
  alertMsg: '',
  allComments: [],
  commentEditInfo: {},
  exchangeRate: {},
  courseTotalPrice: 0,
  updateInfoModal: false,
  confirmCodeModal: false,
  confirmCodeValues: {
    phoneNumber: null,
    leftTime: null
  },
  studentInfo: null,
  editStudentInfo: null,
  isAvailableSend: false,
  bookmarkLoader: true,
  paymentInfo: null,
  lessonFaq: [],
  purchasingModule: null,
};

export const { actions: AllCoursesActions, reducer: AllCoursesReducer } =
  createSlice({
    name,
    initialState,
    reducers: {
      setLoading(state, action) {
        state.loading = action.payload;
      },
      setPurchasingModule(state, action) {
        state.purchasingModule = action.payload
      },
      setLastVideoLoading(state, action) {
        state.lastVideoLoading = action.payload;
      },
      setNotesLoading(state, action) {
        state.notesLoading = action.payload;
      },
      setBookmarkLoader(state, action) {
        state.bookmarkLoader = action.payload;
      },
      setCommentsLoading(state, action) {
        state.commentsLoading = action.payload;
      },
      setError(state, action) {
        state.error = action.payload;
        state.ready = true;
      },
      setDefault(state, action) {
        state.defaultTab = action.payload;
      },
      fetchCategories(state) {
        state.loading = true;
      },
      setIsAvailableSend(state, action) {
        state.isAvailableSend = action.payload;
      },
      fetchCategoriesFulfilled(state, action) {
        state.categories = action.payload;
      },
      openFreeCourseModal(state, action) {
        state.freeCourseModal = action.payload;
      },
      getFreeVideo(state, action) {
        state.videoId = action.payload;
      },
      removeFreeVideo(state) {
        state.videoId = null;
      },
      editNotes(state, action) {
        state.editNote = action.payload;
      },
      fetchAllCourses(state) {
        state.loading = true;
        state.allCourseLoading = true;
      },
      fetchAllCoursesFulfilled(state, action) {
        state.allCourses = action.payload;
        state.allCourseLoading = false;
      },
      fetchSingleCourse(state) {
        state.loading = true;
        state.singleCourseLoading = true;
      },
      fetchSingleCourseFulfilled(state, action) {
        state.singleCourse = action.payload;
        state.singleCourseLoading = false;
      },
      fetchCoursePaymentInfo(state, action) {
        state.loading = true;
      },
      fetchCoursePaymentInfoFulfilled(state, action) {
        state.paymentInfo = action.payload;
      },
      setSingleCourse(state, action) {
        state.singleCourse = action.payload
      },
      resetSingleCourse(state) {
        state.singleCourse = null;
      },
      fetchSingleModule(state) {
        state.loading = true;
        state.singleModuleLoading = true;
        state.lastVideoLoading = true;
      },
      fetchSingleModuleWithoutLoading(state) {
        state.loading = true;
        state.lastVideoLoading = false;
      },
      fetchSingleModuleFulfilled(state, action) {
        state.singleModule = action.payload;
        state.singleModuleLoading = false;
        state.lastVideoLoading = false;
      },
      fetchCourseHomework(state) {
        state.loading = true;
      },
      fetchCourseHomeworkFulfilled(state, action) {
        state.homework = action.payload;
      },
      fetchCourseByLessonHomework(state) {
        state.loading = true;
      },
      fetchCourseByLessonHomeworkFulfilled(state, action) {
        state.homework = action.payload;
      },
      fetchCourseDiscount(state) {
        state.loading = true;
      },
      fetchCourseDiscountFulfilled(state, action) {
        state.courseDiscount = action.payload;
      },
      sendHomework(state) {
        state.loading = true;
      },
      fetchNotes(state) {
        state.loading = true;
      },
      fetchNotesFulfilled(state, action) {
        state.notes = action.payload;
      },
      deleteNote(state) {
        state.loading = true;
      },
      addNote(state) {
        state.loading = true;
      },
      editNote(state) {
        state.loading = true;
      },
      setIsChange(state, action) {
        state.isChange = action.payload;
      },
      changeHomework(state) {
        state.loading = true;
      },
      fetchComments(state) {
        state.loading = true;
      },
      fetchCommentsFulfilled(state, action) {
        state.comments = action.payload;
      },
      addComment(state) {
        state.loading = true;
      },
      rateLesson(state) {
        state.loading = true;
      },
      setReply(state, action) {
        state.replyInfo = action.payload;
      },
      changeActiveCourse(state, action) {
        state.activeLesson = action.payload;
      },
      changeActiveModule(state, action) {
        state.activeModules = action.payload;
      },
      changeActiveLessonCourse(state, action) {
        state.activeCourse = action.payload;
      },
      fetchLastVideo(state) {
        state.loading = true;
        state.lastVideoLoading = true;
      },
      fetchLastVideoFulfilled(state, action) {
        state.lastVideo = action.payload;
        state.lastVideoLoading = false;
      },
      fetchStudentCourses(state) {
        state.loading = true;
      },
      fetchStudentCoursesFulfilled(state, action) {
        state.studentCourses = action.payload;
      },
      fetchPromoCode(state, action) {
        state.promoCode = action.payload;
      },
      setAvailableEditNote(state, action) {
        state.availableEditNote = action.payload;
      },
      upsertLesson(state, action) {
        if (action.payload.progress >= 99) {
          state.completedLessons[action.payload.lesson_id] =
            action.payload.lessonId;
        }
      },
      setSendedHomeWorks(state, action) {
        state.sendedHomeworks = action.payload;
      },
      payzeToken(state, action) {
        state.payzeToken = action.payload;
      },
      getTransactionId(state, action) {
        state.transactionId = action.payload;
      },
      setAppealModal(state, action) {
        state.appealModal = action.payload;
      },
      setCancelAppealModal(state, action) {
        state.cancelAppealModal = action.payload;
      },
      setConfirmDeleteModal(state, action) {
        state.confirmDeleteModal = action.payload;
      },
      voted(state) {
        state.loading = true;
      },
      setReportedCommentId(state, action) {
        state.reportedCommentId = action.payload;
      },
      deleted(state) {
        state.loading = true;
      },
      setAlertMsg(state, action) {
        state.alertMsg = action.payload;
      },
      commentReport(state) {
        state.loading = true;
      },
      getAllComments(state) {
        state.loading = true;
      },
      getAllCommentsFulfilled(state, action) {
        state.allComments = action.payload;
      },
      setCommentEditInfo(state, action) {
        state.commentEditInfo = action.payload;
      },
      editComment(state) {
        state.loading = true;
      },
      setIsSaveCard(state, action) {
        state.isSaveCard = action.payload;
      },
      exchangeRate(state) {
        state.loading = true;
      },
      exchangeRateFulfilled(state, action) {
        state.exchangeRate = action.payload;
      },
      setCourseTotalPrice(state, action) {
        state.courseTotalPrice = action.payload;
      },
      setUpdateInfoModal(state, action) {
        state.updateInfoModal = action.payload;
      },
      setConfirmModal(state, action) {
        state.confirmCodeModal = action.payload;
      },
      setConfirmPhone(state, action) {
        state.confirmCodeValues = action.payload;
      },
      setStudentInfo(state, action) {
        state.studentInfo = action.payload;
      },
      setEditStudentInfo(state, action) {
        state.editStudentInfo = action.payload;
      },
      payForSuperFree(state, action) {
        state.loading = true;
      },
      fetchModuleChapters(state) {
        state.loading = true
      },
      fetchCourseLessonFaq(state) {
        state.loading = true
      },
      fetchCourseLessonFaqFulfilled(state, action) {
        state.lessonFaq = action.payload;
      },
      clearCourseLessonFaq(state) {
        state.lessonFaq = []
      }
    }
  });
