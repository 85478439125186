import { Scrollable } from '@styles/scroll.style';
import styled from 'styled-components';

export const Wrapper = styled(Scrollable)(
  () => `
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 14px;
`
);

export const PaginationWrapper = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  position: relative;

  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    gap: 8px;

    li {
      border: 1px solid #f8f7ef;
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #000000;

      &.selected {
        border: 1px solid #ba8d5b;
        color: #ba8d5b;
      }
    }
  }

  .pagination__item {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .pagination__link {
    text-decoration: none;
    color: #333;
    font-size: 14px;
  }

  .pagination__active {
    background-color: #007bff;
    color: #fff;
  }

  .pagination__disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .previous {
    background: #f8f7ef;
    border-radius: 4px;
    width: 32px;
    height: 32px;

    &.disabled {
      opacity: 0.5;
    }
  }
`;

export const WrapperInner = styled.div(
  () => `
    padding: 24px;
    background: #ffffff;
    border-radius: 4px;
    
    &:last-child {
      margin-top: 24px;
    }
  `,

  ({ theme: { down } }) => `
  ${down('768px')} {
    padding: 20px;
  }

    ${down('576px')} {
      padding: 16px;
    }
  `
);

export const WrapperTitle = styled.h3(() =>`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
`,

({ theme: { down } }) => `
  ${down('1035px')} {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  ${down('720px')} {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }`
);

export const WrapperMoreBtn = styled.button(() => `
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #ba8d5b;
  background-color: transparent;
  border: 0;

  &:hover {
    text-decoration: underline;
  }`,

  ({ theme: { down } }) => `
  ${down('1035px')} {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  ${down('720px')} {
    display: block;
    font-size: 12px;
    line-height: 14px;
    margin: 0 auto;
    margin-top: 16px;
  }`
);

export const VideoCardList = styled.ul(() => `
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`,
  ({theme: {down}}) => `
    ${down('720px')} {
      justify-content: center;
    }
  `
);