import { authActions } from '@modules/auth/store';
import { storageService } from '@services/storage.service';
import { commonActions } from '@store/common';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {useScreenRotation} from "@utils/useScreenRotation";
import {useMediaQuery} from "@utils/useMediaQuery";

export const useSidebarProps = () => {
  const delayHandler = useRef(null);
  const sidebarRef = useRef(null);
  const sidebarState = useSelector(state => state.common.sidebarState);
  const [modalState, setModalState] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const isRotated = useScreenRotation();
  const mqMinHeight555 = useMediaQuery('(min-height: 555px)');


  const handleMouseEnter = () => {
    if (sidebarState || (isRotated && !mqMinHeight555)) return;
    delayHandler.current = setTimeout(() => {
      sidebarRef.current?.classList.add('hover');
    }, 300);
  };

  const handleMouseLeave = () => {
    if (sidebarState || (isRotated && !mqMinHeight555)) return;
    clearTimeout(delayHandler.current);
    sidebarRef.current.classList.remove('hover');
  };

  const handleToggleConfirmModal = (e) => {
    e.preventDefault();
    setModalState(true)
  }

  const handleCloseConfirmModal = () => {
    setModalState(false)
  }

  const handleLogOut = () => {
    storageService.removeRefreshToken();
    storageService.removeAccessToken();
    storageService.removeItem('studentInfo')
    dispatch(commonActions.fetchProfileFulfilled(null))
    history.push('/auth/login')
  }

  return {
    handleMouseEnter,
    handleMouseLeave,
    sidebarRef,
    sidebarState,
    setModalState,
    modalState,
    handleToggleConfirmModal,
    handleCloseConfirmModal,
    handleLogOut,
    isRotated
  };
};
