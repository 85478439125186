import styled from 'styled-components';

export const propOrInitial = prop => prop || 'initial';

const FlexWrapper = styled.div`
  display: ${props => props.block ? 'block' : 'flex'};
  flex-direction: ${props => props.direction || 'initial'};
  justify-content: ${props => props.justify || 'initial'};

  align-items: ${props => props.align || 'initial'};
  gap: ${props => props.gap};
  height: ${props => props.height === 'fill' && '100%'};
  width: ${props => props.width};

  flex-wrap: ${props => (props.wrap ? 'wrap' : 'no-wrap')};

  margin: ${({ m }) => propOrInitial(m)};
  margin-top: ${({ mt }) => propOrInitial(mt)};
  margin-right: ${({ mr }) => propOrInitial(mr)};
  margin-bottom: ${({ mb }) => propOrInitial(mb)};
  margin-left: ${({ ml }) => propOrInitial(ml)};

  margin-top: ${props => props.pas};
  padding: ${({p}) => propOrInitial(p)};
`;

export { FlexWrapper };
