import styled from 'styled-components';

export const TechInfoWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 20px;
`;

export const TechInfoList = styled.ul`
  margin-top: 12px;
  padding: 0;
  margin: 0;
`;

export const TechInfoListItem = styled.li`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  padding: 12px 0 12px 0;
  margin-left: 18px;
  position: relative;
  z-index: 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(186, 141, 91, 0.1);
  }

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #BA8D5B;
    position: absolute;
    left: -18px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;
