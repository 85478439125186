import { useSelector } from 'react-redux';
import {useMediaQuery} from "@utils/useMediaQuery";

export const useMainLayoutProps = () => {
  const sidebarState = useSelector(state => state.common.sidebarState);
  const isSidebarOpened = useSelector(state => state.common.sidebarState)
  const mqMaxWidt768 = useMediaQuery('(max-width: 768px)');


  return {
    sidebarState,
    isSidebarOpened,
    mqMaxWidt768
  };
};
