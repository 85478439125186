import styled from 'styled-components';
import IconClose from '@assets/img/icon-close-payment-modal.svg';

export const AlertContainer = styled.div`
  min-width: 328px;
  display: ${props => (props.isShowAlert ? 'block' : 'none')};
  position: fixed;
  top: 32px;
  z-index: 9999;
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  padding: 16px;
  right: 32px;
  border-radius: 4px;
  border-bottom: ${props =>
    props.success ? '2px solid #07CD86' : '2px solid #EA4335'};

  @media only screen and (max-width: 576px) {
    right: 0;
    left: 0;
    margin: 0 15px;
  }
`;

export const AlertInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AlertIcon = styled.img`
  margin-right: 16px;
`;

export const AlertCloseBtn = styled.button`
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 0;
  margin-left: 16px;
  background-image: url(${IconClose});
`;

export const AlertMessage = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2px;
`;

export const AlertMessageDesc = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
`;
