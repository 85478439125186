import styled from 'styled-components';

export const CourseLessonsWrapper = styled.div`
  border-radius: 4px;
   @media only screen and (max-width: 390px) {
    max-width: 390px;
  }
  
  .course-item:last-child {
    h3 {
      &:hover + span {
        top: -58px;
        bottom: auto;
      }
    }
  }
`;

export const CourseLessonHeader = styled.div(
  ({ theme: { down } }) => `
    ${down('1034px')} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 16px;
      }
    `
);

export const CourseLessonHeaderTitle = styled.h2(
  () => `
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #ba8d5b;
    margin-bottom: 10px;
    max-width: 300px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
   `,

  ({ theme: { down } }) => `
    ${down('1034px')} {
        max-width: 240px;
        margin-right: 16px;
        margin-bottom: 0;
      }
    `
);

export const CourseTitleWrapper = styled.div`
  position: relative;

  h2:hover + span {
    visibility: visible;
  }

  span {
    background: #313131;
    border-radius: 8px;
    min-width: 170px;
    max-width: 177px;
    padding: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0001em;
    color: #ffffff;
    position: absolute;
    height: auto;
    z-index: 2;
    display: inline-block;
    visibility: hidden;
    bottom: -58px;
    left: 0;
    margin: auto;
    right: 0;
    transition: all ease 0.4s;
  }
`;

export const CourseLessonHeaderDuration = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #81755c;
`;
