import { AllCoursesReducer } from '@all-courses/store';
import { authReducer } from '@auth/store';
import { dashboardReducer } from '@dashboard/store';
import { videosReducer } from '@modules/videos/store';
import { PaymentsReducer } from '@payments/store';
import { settingsReducer } from '@settings/store';
import { commonReducer } from './common';
import { notificationReducer } from '@modules/notifications/store';
import { certificateReducer } from '@modules/certificates/store';
import {ratingReducers} from "@modules/rating/store/rating.slice";
import {myRatingReducer} from "@modules/my-ratings/store";

export const rootReducer = {
  common: commonReducer,
  auth: authReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  courses: AllCoursesReducer,
  payments: PaymentsReducer,
  videos: videosReducer,
  notifications: notificationReducer,
  certificates: certificateReducer,
  rating: ratingReducers,
  myRatings: myRatingReducer
};
