import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllCoursesActions } from '@all-courses/store';
import { ModalPlayer } from '../ModalPlayer';
import { FreeModalOverlay } from './free-modal.style';

export const FreeModal = () => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const state = useSelector(state => state.courses?.freeCourseModal);
  const overlayState = useSelector(state => state.overlay?.state);

  const handleClose = event => {
    if (event.target === modalRef.current) {
      dispatch(AllCoursesActions.openFreeCourseModal(false));
      dispatch(AllCoursesActions.getFreeVideo(null));
    }
  };

  return (
    <FreeModalOverlay
      ref={modalRef}
      onClick={handleClose}
      className={state && !overlayState && 'free-course-modal__open'}
    >
      {state && !overlayState && <ModalPlayer />}
    </FreeModalOverlay>
  );
};
